import axiosInstance from "../axiosInstance";

export const weightPackageService = {
  create: async (obj) => {
    try {
      let response = await axiosInstance.post("/weight-package-mapping", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, searchValue, clientId) => {
    try {
      let response = await axiosInstance.get(
        `/weight-package-mapping?$limit=${limit || 15}&$skip=${
          skip || 0
        }&$populate=client_id&$populate=warehouse&${
          searchValue ? `&type[$search]=${searchValue}` : ""
        }&${clientId ? `client_id=${clientId}` : ""}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(
        `/weight-package-mapping/${id}`,
        obj
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(
        `/weight-package-mapping/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
