import React from 'react'
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog
} from '@mui/material'
import 'react-quill/dist/quill.snow.css'
import CloseIcon from '@mui/icons-material/Close'
import CustomTypography from '../../globalStyles/fonts'

const GlobalNetworkDesignDetailsModal = ({ network, open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <DialogTitle>{'Global Network Design Details'}</DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: '10px' }} />
        <DialogContent sx={{ minWidth: 400 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Supplier Location
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={network?.supplier_location || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Fulfillment Location
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={network?.fulfillment_location || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Shipping Service
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={network?.shipping_service || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Package Type
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={network?.package_type || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Weight
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={network?.weight || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button onClick={onClose} variant="outlined" style={{ textTransform: 'none' }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default GlobalNetworkDesignDetailsModal
