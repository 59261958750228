import React, { useState, useEffect, useCallback } from 'react'
import { Autocomplete, TextField, CircularProgress, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import { countryService } from '../../services/country-service'

const CountryAutocomplete = ({
  selectedCountry,
  onCountryChange,
  width,
  label = 'Select Country',
  errorMessage,
  hideLabel,
  backgroundColor,
  style
}) => {
  const [countries, setCountries] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)

  const fetchCountries = useCallback(
    debounce(async (searchQuery, pageNumber, isLoadMore = false) => {
      if (isLoadMore) {
        setLoadMoreLoading(true)
      } else {
        setLoading(true)
      }

      try {
        const response = await countryService.fetch(10, pageNumber * 10, searchQuery)
        if (pageNumber === 0) {
          setCountries(response.data)
        } else {
          setCountries((prev) => [...prev, ...response.data])
        }
        setCount(response.total)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false)
        } else {
          setLoading(false)
        }
      }
    }, 300),
    []
  )

  useEffect(() => {
    fetchCountries(searchValue, page)
  }, [searchValue, page])

  const handleCountryChange = (event, value) => {
    if (value && value.name === 'Load More') {
      return
    }
    if (onCountryChange) {
      onCountryChange(value)
    }
    setSearchValue('')
  }

  const handleSearchChange = (event) => {
    const value = event.target.value
    setSearchValue(value)
    setPage(0)
    setCountries([])
  }

  const handleLoadMoreClick = () => {
    if (countries.length < count && !loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  const optionsWithLoadMore = [...countries]
  if (countries.length < count && countries.length % 10 === 0) {
    optionsWithLoadMore.push({ name: 'Load More' })
  }

  return (
    <Autocomplete
      size="small"
      id="country-select"
      options={optionsWithLoadMore}
      getOptionLabel={(option) => option.name || ''}
      value={selectedCountry}
      onChange={handleCountryChange}
      renderOption={(props, option) =>
        option.name === 'Load More' ? (
          <li
            {...props}
            onClick={handleLoadMoreClick}
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            <Typography color="primary" variant="body2" style={{ marginRight: 8 }}>
              Load More...
            </Typography>
            {loadMoreLoading && <CircularProgress color="inherit" size={16} />}
          </li>
        ) : (
          <li {...props}>{option.name}</li>
        )
      }
      renderInput={(params) => (
        <TextField
          sx={{ backgroundColor: backgroundColor }}
          {...params}
          label={hideLabel ? '' : label}
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      )}
      style={{ ...style, minWidth: width }}
    />
  )
}

export default CountryAutocomplete
