import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";

const AddParameterModal = ({ open, onClose, onSubmit }) => {
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    setFileName(uploadedFile?.name || "");
  };

  const handleSubmit = () => {
    onSubmit({ name, file });
    handleCancel(); // Reset the form and close the modal on submit
  };

  const handleCancel = () => {
    setName("");
    setFile(null);
    setFileName("");
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      aria-labelledby="add-parameter-modal-title"
      aria-describedby="add-parameter-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}
      >
        <Typography id="add-parameter-modal-title" variant="h6" component="h2">
          Add Parameter
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          label="Name"
          value={name}
          onChange={handleNameChange}
        />
        <Button variant="contained" component="label" fullWidth sx={{ mt: 2 }}>
          {fileName ? (
            <CustomTypography>{fileName}</CustomTypography>
          ) : (
            "Upload File"
          )}
          <input type="file" hidden onChange={handleFileChange} />
        </Button>
        <Box
          sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 1 }}
        >
          <Button
            onClick={handleCancel}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddParameterModal;
