import React, { useCallback, useContext, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { rateCardService } from '../../utils/services/rateCardService'
import { Divider, Grid, IconButton, Typography } from '@mui/material'
import AppContext from '../context/context'
import CloseIcon from '@mui/icons-material/Close'
import ClientAutocomplete from '../clientAutocomplete'
import { toast } from 'react-toastify'
import ZoneAutocomplete from '../zoneAutocomplete'
import CarrierServiceAutocomplete from '../carrierServiceAutocomplete'

const RateCardModal = ({ open, handleClose, onAddSuccess, selectedRateCard, onSelectClient }) => {
  const [serviceType, setServiceType] = useState({ name: '' })
  const [weight, setWeight] = useState('')
  const [price, setPrice] = useState('')
  const [zone, setZone] = useState({ name: '' })
  const [formErrors, setFormErrors] = useState({})
  const [selectedClient, setSelectedClient] = useState({ name: '', _id: '' })
  const { user } = useContext(AppContext)



  const initialdata = () => {
    setPrice('')
    setWeight('')
    setServiceType({ name: '' })
    setFormErrors({})
    setSelectedClient({ name: '' })
    setZone({ name: '' })
  }
  const clientId = localStorage.getItem('client_id')

  const validateForm = () => {
    const errors = {}
    if (!serviceType.name) {
      errors.serviceType = 'Service Type is required'
    }
    if (!weight) {
      errors.weight = 'Weight is required'
    } else if (isNaN(weight)) {
      errors.weight = 'Weight  must be a number'
    } else if (weight < 0) {
      errors.weight = 'Weight  must be greater than 0'
    }
    if (!price) {
      errors.price = 'Price is required'
    } else if (isNaN(price)) {
      errors.price = 'Price  must be a number'
    } else if (price < 0) {
      errors.price = 'Price  must be a greater than 0'
    }
    if (!zone.name) {
      errors.zone = 'Zone is required'
    }
    if (!clientId && !selectedClient?.name) {
      errors.selectedClient = 'Client is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSave = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      const client_id = clientId ? clientId : selectedClient?._id
      const response = await rateCardService.create({
        service_type: serviceType._id,
        weight: Number(weight),
        price: Number(price),
        zone: zone._id,
        client_id: client_id
      })
      onAddSuccess && onAddSuccess()
      toast.success("Ratecard created successfully!")
      handleClose()
      initialdata()
    } catch (error) {
      toast.error("Failed to create Ratecard")
      console.error('Delete failed:', error)
    }
  }
  const handleEditData = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      const client_id = clientId ? clientId : selectedClient?._id
      const response = await rateCardService.edit(
        {
          weight: Number(weight),
          price: Number(price),
          zone: zone._id ? zone._id : selectedRateCard.zone?._id,
          service_type: serviceType._id ? serviceType._id : selectedRateCard.service_type?._id,
          client_id: client_id
        },
        selectedRateCard._id
      )
      onAddSuccess && onAddSuccess()
      toast.success("Ratecard edited successfully!")
      initialdata()
    } catch (error) {
      toast.error("Failed to edit Ratecard")
      console.error('Delete failed:', error)
    }
    initialdata()
    handleClose()
  }
  useEffect(() => {
    if (selectedRateCard) {
      setPrice(selectedRateCard?.price)
      setWeight(selectedRateCard?.weight)
      setZone({ name: selectedRateCard?.zone?.name })
      setServiceType({ name: selectedRateCard?.service_type?.name })
      setSelectedClient({
        name: selectedRateCard?.client_id?.business_name || selectedRateCard?.client_id?.name,
        _id: selectedRateCard?.client_id?._id
      })
    }
  }, [selectedRateCard])

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        initialdata();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >{`${
          selectedRateCard ? "Ëdit" : "Create"
        } Rate Card Data`}</DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Choose Service Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <CarrierServiceAutocomplete
              onServiceChange={(newValue) => setServiceType(newValue)}
              selectedService={serviceType}
              width="100%"
              hideLabel
              errorMessage={formErrors.serviceType}
              hideLable={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Weight <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              type="number"
              placeholder="Enter Weight"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              fullWidth
              size="small"
              inputProps={{ min: 0 }}
              error={!!formErrors.weight}
              helperText={formErrors.weight}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Price <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              type="number"
              placeholder="Enter Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              size="small"
              inputProps={{ min: 0 }}
              error={!!formErrors.price}
              helperText={formErrors.price}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Choose Zone <span style={{ color: "red" }}>*</span>
            </Typography>

            <ZoneAutocomplete
              width={"100%"}
              onZoneChange={(zone) => {
                setZone(zone);
              }}
              selectedZone={zone}
              errorMessage={formErrors.zone}
              hideLabel={true}
            />
          </Grid>
          {user.role === "admin" ? (
            <Grid item xs={12} sm={6}>
              <Typography
                className="form-lable-style"
              >
                Choose Client <span style={{ color: "red" }}>*</span>
              </Typography>
              <ClientAutocomplete
                hideLabel={true}
                selectedClient={selectedClient}
                onClientChange={(client) => {
                  setSelectedClient(client);
                  onSelectClient && onSelectClient(client);
                }}
                errorMessage={formErrors.selectedClient}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            color: "#007DFF",
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            handleClose();
            initialdata();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedRateCard ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: "none",
          }}
        >
          {selectedRateCard ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RateCardModal
