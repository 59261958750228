import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";

import AppContext from "../context";
import { productService } from "../../services/product-service";
import CloseIcon from "@mui/icons-material/Close";
import FileSaver from "file-saver";
import { orderService } from "../../services/order-service";
const FileUploadModal = ({
  open,
  onClose,
  rateCard,
  product,
  selectedData,
  onSubmitUploadData,
  onClickShowUpload,
  type,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [ports, setPorts] = useState([]);
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const { user } = useContext(AppContext);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      setSelectedValue(null);
      setFileName("");
      setSelectedClient({ name: "" });
    }
  }, [open]);

  const bulkUpload = async () => {
    onSubmitUploadData && onSubmitUploadData(selectedFile);
  };

  const fileUploadRef = React.useRef();
  const handleFileUpload = (event) => {
    event.preventDefault();
    fileUploadRef.current.click();
  };

  const uploadFile = async () => {
    try {
      const uploadedFile = fileUploadRef.current.files[0];
      setFileName(uploadedFile);
      setSelectedFile(uploadedFile);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSampleDownload = async () => {
    try {
      let response;
      let fileName;
      let fileType;

      if (type === "product") {
        response = await productService.downloadSample();
        fileName = "product-template.xlsx";
        fileType = "application/vnd.ms-excel";
      } else if (type === "order") {
        response = await orderService.downloadSample();
        fileName = "order-template.xlsx";
        fileType = "application/vnd.ms-excel";
      }

      if (!response || !response.base64) {
        throw new Error("Invalid file response");
      }

      let byteCharacters = atob(response.base64);
      let byteArrays = [];
      let sliceSize = 1024;

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }

      let blob = new Blob(byteArrays, { type: fileType });
      FileSaver.saveAs(blob, fileName);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSelectedValue(null);
        setFileName("");
        setSelectedClient({ name: "" });
        onClose && onClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <DialogTitle>
          <Typography
            fontSize={18}
            fontWeight={500}
            color={"#000000"}
            fontFamily={"Poppins"}
          >
            {`Upload Data`}
          </Typography>
        </DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Divider />
      <DialogContent sx={{ minWidth: 500 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ mt: 2 }}>
            <input
              onChange={uploadFile}
              type="file"
              id="excelFile"
              accept=".xls,.xlsx"
              ref={fileUploadRef}
              hidden
            />
            <Box>
              <Typography
                fontFamily={"poppins"}
                fontSize={"14px"}
                fontWeight={400}
                color="#11193F"
              >
                Upload
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Button
                  style={{
                    backgroundColor: fileName ? "#007DFF" : "#EBEBEB",
                    marginTop: 5,
                    justifyContent: "flex-start",
                    color: "#000000",
                    textTransform: "none",
                    color: fileName ? "#fff" : "#000",
                    border: "1px solid #D9D9D9",
                  }}
                  onClick={handleFileUpload}
                >
                  <Typography>{fileName?.name || "Choose File"}</Typography>
                </Button>
                <Box sx={{ marginLeft: { xs: 1, sm: 2 }, marginTop: 1 }}>
                  {!fileName && (
                    <Typography color="#757477" fontSize={16} fontWeight={400}>
                      No file chosen
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: "#007613",
                  textTransform: "none",
                  backgroundColor: "#CBFFD3",
                  border: "1px solid #007613",
                  gap: 5,
                  padding: 5,
                }}
                onClick={handleSampleDownload}
              >
                <img
                  src="/excel-file.png"
                  width={20}
                  height={15}
                  alt="Sample Excel"
                />
                <Typography fontSize={14} fontWeight={400}>
                  Download Template
                </Typography>
              </Button>
            </div>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          onClick={() => {
            setSelectedValue(null);
            setFileName("");
            setSelectedClient({ name: "" });
            onClose && onClose();
          }}
          variant="outlined"
          style={{ textTransform: "none", border: "1px solid #A0AEC0" }}
        >
          <Typography
            style={{ color: "#A0AEC0" }}
            fontSize={14}
            fontWeight={600}
            fontFamily={"poppins"}
          >
            Cancel
          </Typography>
        </Button>
        {/* <Button
          variant="contained"
          onClick={onClickShowUpload}
          style={{ textTransform: "none" }}
        >
          <Typography style={{ color: "#FFF" }} fontSize={14} fontWeight={600}>
            Show Upload Status
          </Typography>
        </Button> */}
        <Button
          variant="contained"
          onClick={bulkUpload}
          style={{ textTransform: "none", backgroundColor: "#DC585C" }}
        >
          <Typography style={{ color: "#FFF" }} fontSize={14} fontWeight={600}>
            Upload
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadModal;
