import axiosInstance from "../axiosInstance";

export const rateLookupService = {
  fetch: async (data, port, priceSortOrder, serviceDaySortOrder, clientId) => {
    try {
      const body = {
        destination: data.destination,
        weight: data.weight,
        limit: data.limit || 15,
        skip: data.skip || 0,
        port: port?._id,
      };

      if (priceSortOrder !== null) {
        body.sort_price = priceSortOrder === "asc" ? 1 : -1;
      }

      if (serviceDaySortOrder !== null) {
        body.service_day = serviceDaySortOrder === "asc" ? 1 : -1;
      }

      const response = await axiosInstance.post("/rate-lookup", body);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
