export const getZipZoneKeyName = (key) => {
  switch (key) {
    case "source":
      return "Source";
    case "destination":
      return "Destination";
    case "service_day":
      return "Service Day";
    case "zone_name":
      return "Zone";
    case "port_name":
      return "Port";
    case "action":
      return "Action";
    case "state":
      return "State";
    default:
      return key;
  } // zip9 or other
};
