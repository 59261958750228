// api.js

import axiosInstance from "../axiosInstance";

export const omsLocationService = {
  fetch: async (data) => {
    try {
      let response = await axiosInstance.post("/fetch-oms-location", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchLocation: async (oms_id) => {
    try {
      let response = await axiosInstance.get(
        `/warehouse-location?oms_config=${oms_id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  create: async (data) => {
    try {
      let response = await axiosInstance.post(
        "/warehouse-location-custom",
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
