import React from "react";
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Box,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../globalStyles/fonts";

const NotificationModal = ({ onClose }) => {
  return (
    <div sx={{ p: 2 ,}}>
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
        {/* <DialogTitle>
          <CustomTypography color={"#05004E"} fontSize={16} fontWeight={500}>
            {"Notifications"}
          </CustomTypography>
        </DialogTitle> */}
        {/* <IconButton>
          <CloseIcon onClick={onClose} />
        </IconButton> */}
      {/* </div> */}
      {/* <Divider style={{ marginBottom: "10px" }} /> */}
      <DialogContent>
        <CustomTypography fontSize={14}>
          There is no notification at the moment.
        </CustomTypography>
      </DialogContent>
      <DialogActions style={{ padding: 10 }}>
        {/* <Button
          onClick={onClose}
          variant="outlined"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button> */}
      </DialogActions>
    </div>
  );
};
export default NotificationModal;
