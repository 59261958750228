import React, { useEffect, useState } from "react";
import ShipmentCostModal from "../../components/shimentCostModal";
import DashboardContainer from "../../components/dashboard-container";
import { Box, CircularProgress } from "@mui/material";
import ShipmentCostListView from "../../components/shipment-cost-list";
import EstimateModal from "../../components/estimate-modal";
import { partnerService } from "../../utils/services/partnerService";
import PartnerModal from "../../components/partnerModal";
import PartnerList from "../../components/partner-list";
import AirWaybillForm from "../../components/airWaybillFormModal";

const Partner = () => {
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [searchValue, setSearchValue] = useState("");
  const [clientFilter, setClientFilter] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const clientId = localStorage.getItem("client_id");

  const fetchPartner = async () => {
    setLoading(true);
    try {
      const response = await partnerService.fetch(
        10,
        0,
        clientFilter?._id,
        searchValue
      );
      setPartnerList(response?.data);
      setLoading(false);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPartner();
  }, [page, rowsPerPage, clientFilter, searchValue]);
  return (
    <>
      <DashboardContainer
        hideUploadButton
        header={"Partner List"}
        title={"+ Add Partner"}
        onClickAddButton={() => setOpenModal(true)}
        setViewMode={setViewMode}
        onSearchData={(x) => {
          if (x.trim()) {
            setSearchValue(x);
            setPage(0);
          } else {
            setSearchValue("");
          }
        }}
        // onSelectClient={(e) => setClientFilter(e)}
        hideFilterClient={true}
      >
       

        <PartnerModal
          selectedPartner={selectedPartner}
          handleClose={() => {
            setOpenModal(false);
            setSelectedPartner(null);
          }}
          open={openModal}
          onAddData={fetchPartner}
        />
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <PartnerList
            dataCount={dataCount}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            onDeleteData={fetchPartner}
            onEditData={(e) => {
              setSelectedPartner(e);
              setOpenModal(true);
            }}
            data={partnerList}
            viewMode={viewMode}
            selectedPartner={selectedPartner}
            setSelectedPartner={setSelectedPartner}
          />
        )}
      </DashboardContainer>
    </>
  );
};

export default Partner;
