import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material'

const CountryViewModal = ({ country, openModal, handleCloseModal, province }) => {
  const [selectedCountry, setSelectedCountry] = useState(null)

  useEffect(() => {
    setSelectedCountry(country)
  }, [country])

  return (
    <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
      <DialogTitle>Country Details</DialogTitle>
      <DialogContent dividers>
        {selectedCountry && (
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  value={selectedCountry.name || ''}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={'Code'}
                  value={selectedCountry.code || ''}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              {province ? (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Country"
                    value={selectedCountry.country?.name || ''}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Currency"
                    value={selectedCountry.currency || ''}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CountryViewModal
