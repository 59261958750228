import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'

const OverPackDetails = ({ OverPack }) => {
  if (!OverPack) return null

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
        m: 'auto',
        border: '1px solid #E0E0E0',
        borderBottom: 'none'
      }}
    >
      <CustomTypography
        variant="h6"
        gutterBottom
        style={{
          padding: '8px',
          borderBottom: '1px solid #E0E0E0',
          backgroundColor: '#E0F7FA',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minWidth: '50%'
        }}
      >
        <CustomTypography>Order ID: #{OverPack.orderId}</CustomTypography>
        <CustomTypography>OVER-PACK STATUS</CustomTypography>
        <CustomTypography></CustomTypography>
      </CustomTypography>

      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#F5F5F5', fontWeight: 'bold', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>OverPack Tracking Number:</CustomTypography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{OverPack.OverPackId}</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Status:</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{OverPack.status}</CustomTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Carrier:</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{OverPack.carrierName}</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Date:</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{OverPack.date}</CustomTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Origin:</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{OverPack.origin}</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Destination:</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{OverPack.destination}</CustomTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Current Location:</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{OverPack.currentLocation}</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Last Update:</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{OverPack.lastUpdate}</CustomTypography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default OverPackDetails
