import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress
} from '@mui/material'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { orderService } from '../../services/order-service'

const UploadStatusDataOverView = ({ open, onClose, data }) => {
  const [uploadResultData, setUploadResultData] = useState(null)
  const [resultModalOpen, setResultModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const COLORS = ['#4caf50', '#f44336']

  const getPieChartData = (data) => {
    if (!data) return []
    return [
      { name: 'Success', value: data?.upload_status?.success },
      { name: 'Error', value: data?.upload_status?.error }
    ]
  }

  const fetchError = async (id) => {
    try {
      setLoading(true)
      const response = await orderService.bulkUploadResult(id) // Ensure this is a promise
      setUploadResultData(response.data)
      setLoading(false)
      setResultModalOpen(true)
    } catch (error) {
      setLoading(false)
      console.error(error)
      throw error
    }
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const total = data.upload_status.success + data.upload_status.error
      const percentage = ((payload[0].value / total) * 100).toFixed(1)
      return (
        <div
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}
        >
          <p>{`${payload[0].name}: ${payload[0].value} (${percentage}%)`}</p>
        </div>
      )
    }
    return null
  }

  return (
    <>
      {/* Main Modal */}
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Upload Status</DialogTitle>
        <DialogContent>
          <Box sx={{ height: 250, mt: 2 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={getPieChartData(data)}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label={({ name, value }) => `${name}: ${value}`}
                >
                  {getPieChartData(data).map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="bottom" height={36} />
              </PieChart>
            </ResponsiveContainer>
          </Box>
          {data && (
            <Box
              sx={{
                mt: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderTop: '1px solid #e0e0e0',
                paddingTop: 2
              }}
            >
              <Typography variant="h6">Summary</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                  <Typography sx={{ minWidth: 120, color: 'text.secondary' }}>Total Records:</Typography>
                  <Typography sx={{ fontWeight: 500 }}>{data?.upload_status?.total_records}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                <Typography sx={{ minWidth: 120, color: 'text.secondary' }}>Success:</Typography>
                <Typography sx={{ fontWeight: 500, color: '#4caf50' }}>
                  {data?.upload_status?.success}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                  <Typography sx={{ minWidth: 120, color: 'text.secondary' }}>Error:</Typography>
                  <Typography sx={{ fontWeight: 500, color: '#f44336' }}>
                    {data?.upload_status?.error}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => fetchError(data?.upload_status?._id)}
                    sx={{ color: '#f44336', marginLeft: 7 }}
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Result Modal */}
      <Dialog open={resultModalOpen} onClose={() => setResultModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Error Details</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              {uploadResultData && uploadResultData.length > 0 ? (
                uploadResultData.map((errorItem, index) => (
                  <Box
                    key={errorItem._id || index}
                    sx={{
                      mb: 4,
                      padding: 2,
                      border: '1px solid #e0e0e0',
                      borderRadius: 2,
                      backgroundColor: '#f9f9f9',
                      mt: 2
                    }}
                  >
                    {/* Display the error message */}
                    <Typography variant="h6" color="error" gutterBottom>
                      {errorItem.error?.message || 'Error message not available'}
                    </Typography>

                    {/* Display item properties */}
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
                        Item Details:
                      </Typography>
                      {Object.entries(errorItem.error?.item || {}).map(([key, value]) => (
                        <Box
                          key={key}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 1
                          }}
                        >
                          <Typography sx={{ color: 'text.secondary', fontWeight: 500 }}>
                            {key.replace(/_/g, ' ')}:
                          </Typography>
                          <Typography sx={{ fontWeight: 500 }}>{String(value)}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography>No errors found.</Typography>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResultModalOpen(false)} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UploadStatusDataOverView
