import React, { useEffect, useState } from 'react'
import DashboardContainer from '../../components/dashboard-container'
import OrderList from '../../components/order-management-list'
import { orderService } from '../../utils/services/orderService'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import CustomPagination from '../../components/pagination'
import FileSaver from 'file-saver'
import { Button, IconButton } from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import ExportIcon from '../../globalStyles/icons/export-icon'
const OrderManagement = () => {
  const [clientFilter, setClientFilter] = useState(null)
  const changeDateFormat = (data) => {
    const dateStr = data
    const date = new Date(dateStr)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }
  const [loading, setLoading] = useState(false)
  const [dateRange, setDateRange] = useState([
    changeDateFormat(new Date(new Date().setMonth(new Date().getMonth() - 2))),
    changeDateFormat(new Date(new Date().setDate(new Date().getDate() + 2)))
  ])
  const [orderData, setOrderData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [dataCount, setDataCount] = useState(rowsPerPage)
  const clientId = localStorage.getItem('client_id')

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  const fetchOrderData = async (signal) => {
    setLoading(true)
    try {
      const skip = page * rowsPerPage
      const [startDate, endDate] = dateRange || []
      const response = await orderService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue,
        statusFilter,
        startDate,
        endDate,
        { signal } // Pass the signal to the API call
      )
      setDataCount(response.total)
      setOrderData(response.data)
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted')
      } else {
        console.error('Fetch error:', error)
      }
    } finally {
      setLoading(false)
    }
  }
  const fetchAllOrders = async () => {
    setLoading(true)
    try {
      const skip = page * rowsPerPage
      const [startDate, endDate] = dateRange || []
      const response = await orderService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue,
        statusFilter,
        startDate,
        endDate
      )
      setDataCount(response.total)
      setOrderData(response.data)
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted')
      } else {
        console.error('Fetch error:', error)
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    // Always fetch data when the dependencies change
    fetchOrderData(signal)

    // Mark as fetched after the first successful call

    return () => {
      // Abort the request when a new one starts or when unmounting
      controller.abort()
    }
  }, [page, rowsPerPage, searchValue, dateRange, statusFilter, clientFilter])

  const handleExport = async () => {
    setLoading(true)
    try {
      const response = await orderService.exportOrders(clientId || '')
      let sliceSize = 1024
      let byteCharacters = atob(response.file)
      let bytesLength = byteCharacters.length
      let slicesCount = Math.ceil(bytesLength / sliceSize)
      let byteArrays = new Array(slicesCount)
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize
        let end = Math.min(begin + sliceSize, bytesLength)
        let bytes = new Array(end - begin)
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0)
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes)
      }
      let blob = new Blob(byteArrays, { type: response.contentType })
      FileSaver.saveAs(new Blob([blob], {}), response.filename)
      // handleDownload(response)

      // Assuming 'response' contains an array of objects
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted')
      } else {
        console.error('Fetch error:', error)
      }
    } finally {
      setLoading(false)
    }
  }

  // Convert data to CSV format

  return (
    <DashboardContainer
      headerButtons={
        <Button
          size="small"
          style={{
            backgroundColor: '#007DFF',
            margin: 3,
            minWidth: 120,
            textTransform: 'none',
            fontSize: 14,
            height: 30
          }}
          variant="contained"
          onClick={handleExport}
        >
          <IconButton>
            <ExportIcon />
          </IconButton>
          <CustomTypography fontSize="14px" fontWeight={500} color="#FFFFFF">
            Export
          </CustomTypography>
        </Button>
      }
      hideUploadButton={true}
      // hideFilterClient={true}
      hideAddButton={true}
      order
      header={'Order Management System'}
      onSelectDate={(x) => setDateRange([changeDateFormat(x.startDate), changeDateFormat(x.endDate)])}
      onSearchData={setSearchValue}
      onClickExport={handleExport}
      onClickRefresh={fetchAllOrders}
      onSelectClient={(e) => {
        setClientFilter(e)
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <OrderList data={orderData} onStatusFilterChange={setStatusFilter} fetchOrderData={fetchOrderData} />
      )}

      {/* <TablePagination
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffffff',
          padding: '2px',
          borderTop: '1px solid #dddddd'
        }}
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </DashboardContainer>
  )
}

export default OrderManagement
