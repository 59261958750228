import axiosInstance from "../axiosInstance";

export const warehouseOrderService = {
  fetch: async (limit, orderNo, skip) => {
    try {
      let response = await axiosInstance.get(
        `/wh-order?$limit=${limit || 100}&orderNo=${orderNo}&$populate=provider`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  edit: async (data) => {
    try {
      let response = await axiosInstance.patch(`/wms/stock-in`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/asn-request/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
