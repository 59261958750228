// api.js

import axiosInstance from "../axiosInstance";
import config from "../../config";

export const manifestIdService = {
  fetch: async () => {
    try {
      let response = await axiosInstance.get(
        `${config.baseURL}/fetch-manifest`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
