import React, { useState } from "react";
import dayjs from "dayjs";
import { isMobile } from "../../globalStyles/mobile-design";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  TablePagination,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme, isExpandable }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: isExpandable ? "#D7E9FF" : "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: isExpandable ? "#D7E9FF" : "#F6F6F6",
    border: "1px solid #EAECF0",
  },

  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const OrderList = ({
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  count,
}) => {
  const startIndex = page * rowsPerPage;
  const navigate = useNavigate();

  const MobileCardView = ({ data }) => {
    return (
      <Box
        sx={{
          width: "100%",
          padding: 0.5,
          maxHeight: "100%",
          overflowY: "scroll",
          paddingBottom: 5,
        }}
      >
        {data?.map((row, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 2,
              backgroundColor: "#ffff",
              borderRadius: 4,
              position: "relative",
            }}
          >
            <CardContent>
              <Box position="absolute" top={2} right={5}>
                {row?.action}
              </Box>
              <Box display="flex" flexDirection="column">
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Order Id
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color={row.error ? "#ba090c" : "#333333"}
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {row?.order_id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Customer
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      :
                      {row?.customer?.name ||
                        `${row?.customer?.first_name || ""} ${
                          row?.customer?.last_name || ""
                        }`}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Order Date
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {dayjs(row?.order_date).format("YYYY-MM-DD")}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Shipment ID
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      :{" "}
                      {[
                        ...new Set(row?.shipments?.map((x) => x?.shipment)),
                      ].join(", ")}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="##333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {row?.status}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  };
  return (
    <>
      <div
        style={{
          overflowY: "scroll",
          display: "flex",
          maxHeight: `calc(100vh - 170px)`,
          width: "100%",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::WebkitScrollbar": { width: 0 },
        }}
      >
        {isMobile() ? (
          <MobileCardView data={data} />
        ) : (
          <TableContainer
            sx={{
              marginTop: 1,
              maxHeight: "100%",
              overflowY: "scroll",
              // paddingInline: 0.4,
              // ...style,
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                border: "1px solid #EAECF0",
              }}
            >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    <Typography
                      fontSize="14px"
                      color="#333333"
                      fontWeight={500}
                      fontFamily={"poppins"}
                    >
                      SL NO
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        ORDER ID
                      </Typography>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        CUSTOMER NAME
                      </Typography>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        TRACKING NUMBER
                      </Typography>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        CUSTOMER STATE
                      </Typography>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        ORDER DATE
                      </Typography>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        SHIPMENT ID
                      </Typography>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontSize="14px"
                        color="#333333"
                        fontWeight={500}
                        fontFamily={"poppins"}
                      >
                        STATUS
                      </Typography>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                    ></Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => {
                  return (
                    <>
                      <StyledTableRow key={row.order_id}>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                            textAlign={"start"}
                          >
                            {startIndex + index + 1}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color={
                              row.status === "On Hold" ? "#ba090c" : "#667085"
                            }
                            fontWeight={400}
                            fontFamily={"poppins"}
                            //textAlign={"start"}
                          >
                            {row.order_id}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                          >
                            {row.customer?.name ||
                              `${row?.customer?.first_name || ""} ${
                                row?.customer?.last_name || ""
                              }`}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                          >
                            {`${
                              row.shipments?.length
                                ? row.shipments[0]?.tracking_number
                                : ""
                            }`}
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                          >
                            {`${row.shipping_address?.province || ""}`}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                          >
                            {dayjs(row.order_date).format("YYYY-MM-DD")}
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          <Typography
                            fontSize="12px"
                            color="#667085"
                            fontWeight={400}
                            fontFamily={"poppins"}
                          >
                            {new Set(row?.shipments?.map((x) => x.shipment))}
                          </Typography>
                        </StyledTableCell>

                        <StyledTableCell>
                          {row.status ? (
                            <Typography
                              fontSize="12px"
                              fontWeight={400}
                              fontFamily={"poppins"}
                            >
                              <span
                                style={{
                                  padding: "5px 10px",
                                  display: "inline-block",
                                  backgroundColor:
                                    row.status === "Confirmed"
                                      ? "#D7E9FF"
                                      : row.status === "Shipped"
                                      ? "#F6F6F6"
                                      : row.status === "Cancelled"
                                      ? "#FFDEDF"
                                      : "",
                                  color:
                                    row.status === "Confirmed"
                                      ? "#0070FF"
                                      : row.status === "Shipped"
                                      ? "#037847"
                                      : row.status === "Cancelled"
                                      ? "#D13438"
                                      : "#333333",
                                  borderRadius: "14px",
                                }}
                              >
                                {row.status}
                              </span>
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{ width: "3%", textAlign: "center" }}
                        >
                          {row?.action}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <TablePagination
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "2px",
            borderTop: "1px solid #dddddd",
          }}
          rowsPerPageOptions={[10, 15, 25, 45, 75, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default OrderList;
