// api.js

import axiosInstance from '../axiosInstance'

export const supplierService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post('/supplier', data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/supplier?$limit=${limit || 15}&$skip=${skip || 0}&${clientId ? `client=${clientId}&` : ''}&${
          searchValue ? `&name[$search]=${searchValue}` : ''
        }&$populate=client`
      )
      return response.data
    } catch (error) {
      throw error
    }
  },

  edit: async (id, data) => {
    try {
      let response = await axiosInstance.patch(`/supplier/${id}`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/supplier/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
