// api.js

import axiosInstance from "../axiosInstance";

export const carrierServiceManagement = {
  create: async (name, carrierId, clientId) => {
    try {
      let response = await axiosInstance.post("/service_type", {
        name: name,
        carrier_id: carrierId,
        client_id: clientId,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/service_type?$limit=${limit || 15}&$skip=${skip || 0}&${
          clientId ? `client_id=${clientId}` : ""
        }&$populate=carrier_id${
          searchValue ? `&name[$search]=${searchValue}` : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (name, id, carrierId, clientId) => {
    try {
      let response = await axiosInstance.patch(`/service_type/${id}`, {
        name: name,
        carrier_id: carrierId,
        client_id: clientId,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/service_type/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
