import React, { useEffect, useState } from 'react'
import { Modal, Box, Typography, TextField, Button } from '@mui/material'

const AddEditCountry = ({ open, setOpen, handleSubmit, selectedData }) => {
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    currency: ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const onSubmit = () => {
    handleSubmit(formData)
    setFormData({ name: '', code: '', currency: '' }) // Reset form after submission
  }

  const handleClose = () => {
    setOpen(false)
    setFormData({ name: '', code: '', currency: '' })
  }
  useEffect(() => {
    if (selectedData) {
      setFormData({
        name: selectedData.name,
        code: selectedData.code,
        currency: selectedData.currency
      })
    }
  }, [selectedData])
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="add-country-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Add Country
        </Typography>
        <TextField
          fullWidth
          label="Country Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Country Code"
          name="code"
          value={formData.code}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Currency"
          name="currency"
          value={formData.currency}
          onChange={handleChange}
          margin="normal"
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            sx={{ backgroundColor: '#DC585C' }}
            disabled={!formData.name.trim() || !formData.code.trim() || !formData.currency.trim()}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddEditCountry
