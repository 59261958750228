import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { shippingPriceService } from "../../utils/services/shippingPriceService";
import debounce from "lodash.debounce";

const ShipmentServiceAutocomplete = ({
  selectedClient,
  shipmentService,
  setShipmentService,
  formErrors,
  width = 200,
  label = "Select Service",
  errorMessage,
  hideLabel,
}) => {
  const [serviceData, setServiceData] = useState([]);
  const [serviceSearchValue, setServiceSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const fetchServiceData = useCallback(
    debounce(async (searchQuery, pageNumber, isLoadMore = false) => {
      if (isLoadMore) {
        setLoadMoreLoading(true);
      } else {
        setLoading(true);
      }

      try {
        if (selectedClient?._id) {
          const response = await shippingPriceService.fetch(
            10,
            pageNumber * 10,
            selectedClient._id,
            searchQuery
          );
          if (pageNumber === 0) {
            setServiceData(response.data);
          } else {
            setServiceData((prevData) => [...prevData, ...response.data]);
          }
          setTotal(response.total || 0);
        } else {
          setServiceData([]);
        }
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 300),
    [selectedClient?._id]
  );

  useEffect(() => {
    if (serviceSearchValue === "") {
      fetchServiceData("", 0); // Fetch all services when search value is empty
    }
  }, [serviceSearchValue, fetchServiceData]);

  useEffect(() => {
    fetchServiceData(serviceSearchValue, page);
  }, [serviceSearchValue, page, fetchServiceData]);

  const handleServiceChange = (event, value) => {
    if (value && value.type === "Load More") {
      return;
    }
    if (value) {
      setShipmentService(value);
    }
    setServiceSearchValue("");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setServiceSearchValue(value);
    setPage(0);
    setServiceData([]);
  };

  const handleLoadMoreClick = () => {
    if (serviceData.length < total && !loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Add "Load More" option every 10 items if more data is available
  const optionsWithLoadMore = [...serviceData];
  if (serviceData.length < total && serviceData.length % 10 === 0) {
    optionsWithLoadMore.push({ type: "Load More" });
  }

  return (
    <Autocomplete
      disabled={!selectedClient}
      size="small"
      id="service-select"
      options={optionsWithLoadMore}
      getOptionLabel={(option) => option.type || ""}
      value={shipmentService || null}
      onChange={handleServiceChange}
      renderOption={(props, option) =>
        option.type === "Load More" ? (
          <li
            {...props}
            onClick={handleLoadMoreClick}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <Typography
              color="primary"
              variant="body2"
              style={{ marginRight: 8 }}
            >
              Load More...
            </Typography>
            {loadMoreLoading && <CircularProgress color="inherit" size={16} />}
          </li>
        ) : (
          <li {...props}>{option.type}</li>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!formErrors.shipment_service || !!errorMessage}
          helperText={formErrors.shipment_service || errorMessage}
          placeholder={label}
          margin="dense"
          label={hideLabel ? "" : label}
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      style={{ minWidth: width, marginRight: 10 }}
    />
  );
};

export default ShipmentServiceAutocomplete;
