import { SvgIcon } from "@mui/material";
import React from "react";

const ImportIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.76666 10L9.9 12.1333L12.0333 10"
          stroke="#DC585C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.90002 11.8167L10 4"
          stroke="#DC585C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33333 10C3.33333 13.6833 5.83333 16.6667 10 16.6667C14.1667 16.6667 16.6667 13.6833 16.6667 10"
          stroke="#DC585C"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default ImportIcon;
