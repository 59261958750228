import React, { useState } from 'react'
import './checkout.css'

const CheckoutScreen = ({ cartItems, onCreateOrder }) => {
  const [address, setAddress] = useState({
    name: '',
    street: '',
    city: '',
    state: '',
    zip: ''
  })

  const handleOrderCreation = () => {
    onCreateOrder({
      items: cartItems,
      address
    })
  }

  return (
    <div className="checkout-container">
      <h2>Shipping Address</h2>
      <div className="checkout-form">
        <input
          type="text"
          placeholder="Name"
          value={address.name}
          onChange={(e) => setAddress({ ...address, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Street"
          value={address.street}
          onChange={(e) => setAddress({ ...address, street: e.target.value })}
        />
        <input
          type="text"
          placeholder="City"
          value={address.city}
          onChange={(e) => setAddress({ ...address, city: e.target.value })}
        />
        <input
          type="text"
          placeholder="State"
          value={address.state}
          onChange={(e) => setAddress({ ...address, state: e.target.value })}
        />
        <input
          type="text"
          placeholder="ZIP Code"
          value={address.zip}
          onChange={(e) => setAddress({ ...address, zip: e.target.value })}
        />
        <button className="create-order-btn" onClick={handleOrderCreation}>
          Create Order
        </button>
      </div>
    </div>
  )
}

export default CheckoutScreen
