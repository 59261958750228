// api.js

import axiosInstance from '../axiosInstance'

export const clientMapping = (mapKey) => {
  return {
    create: async (data) => {
      try {
        let response = await axiosInstance.post(
          `/${
            mapKey === 'zip_zone' ? 'zip-zone' : mapKey === 'service_type' ? 'service-type' : mapKey
          }-client`,
          data
        )
        return response.data
      } catch (error) {
        throw error
      }
    },
    fetch: async (id, limit, skip) => {
      try {
        let response = await axiosInstance.get(
          `/${
            mapKey === 'zip_zone' ? 'zip-zone' : mapKey === 'service_type' ? 'service-type' : mapKey
          }-client?$limit=${limit || 15}&$skip=${skip || 0}&${mapKey}=${id}&$populate=client`
        )
        return response.data
      } catch (error) {
        throw error
      }
    },
    edit: async (data, id) => {
      try {
        let response = await axiosInstance.patch(
          `/${
            mapKey === 'zip_zone' ? 'zip-zone' : mapKey === 'service_type' ? 'service-type' : mapKey
          }-client/${id}`,
          data
        )
        return response.data
      } catch (error) {
        throw error
      }
    },
    delete: async (id) => {
      try {
        let response = await axiosInstance.delete(
          `/${
            mapKey === 'zip_zone' ? 'zip-zone' : mapKey === 'service_type' ? 'service-type' : mapKey
          }-client/${id}`
        )
        return response.data
      } catch (error) {
        throw error
      }
    }
  }
}
