export const distanceUnits = [
  { name: 'centimeter', abbreviation: 'cm', factor: 0.01 },
  { name: 'decimeter', abbreviation: 'dm', factor: 0.1 },
  { name: 'meter', abbreviation: 'm', factor: 1 },
  { name: 'decameter', abbreviation: 'dam', factor: 10 },
  { name: 'hectometer', abbreviation: 'hm', factor: 100 },
  { name: 'kilometer', abbreviation: 'km', factor: 1000 },
  { name: 'inch', abbreviation: 'in', factor: 0.0254 },
  { name: 'foot', abbreviation: 'ft', factor: 0.3048 },
  { name: 'yard', abbreviation: 'yd', factor: 0.9144 },
  { name: 'mile', abbreviation: 'mi', factor: 1609.34 },
  { name: 'nautical mile', abbreviation: 'nmi', factor: 1852 }
]

export const convertDistance = (fromUnit, toUnit, value) => {
  // Find the factors for the two units
  const fromUnitData = distanceUnits.find((unit) => unit.abbreviation === fromUnit)
  const toUnitData = distanceUnits.find((unit) => unit.abbreviation === toUnit)

  if (!fromUnitData || !toUnitData) {
    throw new Error('Invalid unit abbreviation')
  }

  // Convert the value to meters first, then convert to the target unit
  const valueInMeters = value * fromUnitData.factor
  const convertedValue = valueInMeters / toUnitData.factor

  return convertedValue
}

// // Example usage:
// const result = convertDistance('cm', 'm', 150) // Converts 150 centimeters to meters
// console.log(result) // Output: 1.5
