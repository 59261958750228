import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { portService } from "../../utils/services/portService";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterIcon from "../../globalStyles/icons/filter-icon";
import CustomTypography from "../../globalStyles/fonts";
import { isMobile } from "../../globalStyles/mobile";
import PortAutocomplete from "../portAutocomplete";

const RateLookupTable = ({ data, onFilterPort, sortFilter }) => {
  const [portFilter, setPortFilter] = useState(null);
  const [port, setPort] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElPort, setAnchorElPort] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const autocompleteRefPort = useRef(null);

  const handlePortFilterChange = (newValue) => {
    setPortFilter(newValue);
    onFilterPort && onFilterPort(newValue, selectedFilter, sortOrder);
  };
  const filteredPorts = [
    { name: "None" },
    ...port.filter((port) =>
      port.name?.toLowerCase().includes(searchQuery.toLowerCase())
    ),
  ];
  useEffect(() => {
    if (anchorElPort && autocompleteRefPort.current) {
      autocompleteRefPort.current.focus();
    }
  }, [anchorElPort]);

  const handlePortFilterClick = (event) => {
    console.log("Filter icon clicked");
    setAnchorElPort(event.currentTarget);
    //setimeout 3sec
    setTimeout(() => {
      if (autocompleteRefPort.current) {
        autocompleteRefPort.current.focus();
      }
    }, 3000);
  };

  const handlePortMenuClose = () => {
    setAnchorElPort(null);
  };
  const handleSort = (field, order) => {
    if (field === "service_day" || field === "price") {
      setSelectedFilter(field);
      setSortOrder(order);
      sortFilter && sortFilter(field, order, portFilter);
    } else {
      setSelectedFilter(field);
      setSortOrder(order === "asc" ? "desc" : "asc");
      sortFilter &&
        sortFilter(field, order === "asc" ? "desc" : "asc", portFilter);
    }
  };

  const fetchPortData = async () => {
    const clientId = localStorage.getItem("client_id");
    const response = await portService.fetch(15, 0, clientId);
    setPort(response.data);
  };

  useEffect(() => {
    fetchPortData();
  }, []);

  const getSortIcon = (field) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ArrowDropUpIcon
          fontSize="small"
          style={{
            color: "black",
            opacity: selectedFilter === field && sortOrder === "asc" ? 0.5 : 1,
            cursor: "pointer",
            marginBottom: -8,
          }}
          onClick={() => handleSort(field, "asc")}
        />
        <ArrowDropDownIcon
          fontSize="small"
          style={{
            color: "black",
            opacity: selectedFilter === field && sortOrder === "desc" ? 0.5 : 1,
            cursor: "pointer",
          }}
          onClick={() => handleSort(field, "desc")}
        />
      </div>
    );
  };
  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data?.map((x, index) => (
          <Card key={index} sx={{ marginBottom: 2 }}>
            <CardContent>
              <div style={{ width: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Destination: {x?.destination}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>State:{x?.state}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Port:{x?.port?.name}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Zone:{x?.zone?.name}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>
                      Service:{" "}
                      {`${x.ratecard.service_type?.name}(${x.ratecard.carrier.name})`}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Service Day:{x?.service_day}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Weight:{x.ratecard?.weight}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Price:{x.ratecard?.price}</Typography>
                  </Box>
                </Box>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  };
  return (
    <div
      style={{
        height: "65vh",
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 100,
      }}
      className="table-container"
    >
      {isMobile() ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: isMobile() ? 8 : 0,
            }}
          >
            <div
              style={{
                overflowX: "auto",
                display: "flex",
                alignItems: "center",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <div style={{ display: "flex", gap: 5 }}>
                <PortAutocomplete
                  onPortChange={(port) => {
                    setPortFilter(port);
                    onFilterPort && onFilterPort(port);
                  }}
                  selectedPort={portFilter}
                  label={"Filter by Port"}
                  width={150}
                />
                <FormControl
                  size="small"
                  margin="dense"
                  style={{ minWidth: 100, marginLeft: 5 }}
                >
                  <InputLabel>Filter by</InputLabel>
                  <Select
                    value={selectedFilter}
                    onChange={(event) =>
                      handleSort(event.target.value, sortOrder)
                    }
                    label="Filter by"
                  >
                    <MenuItem value="service_day">ServiceDay</MenuItem>
                    <MenuItem value="price">Price</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  size="small"
                  margin="dense"
                  style={{ minWidth: 100, marginLeft: 5 }}
                >
                  <InputLabel>Sort Order</InputLabel>
                  <Select
                    value={sortOrder}
                    onChange={(event) =>
                      handleSort(selectedFilter, event.target.value)
                    }
                    label="Sort Order"
                  >
                    <MenuItem value="asc">Ascending</MenuItem>
                    <MenuItem value="desc">Descending</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <MobileCardView data={data} />
        </>
      ) : (
        <TableContainer
          sx={{
            maxHeight: "60vh",
            overflowY: "auto",
            paddingInline: 0.4,
          }}
        >
          <Table
            sx={{
              //minWidth: 900,
              borderCollapse: "separate",
              borderSpacing: "0 7px",
              paddingBottom: 7,
            }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              {/* <TableRow>
                {[
                  "Destination",
                  "State",
                  "Port",
                  "Zone",
                  "Service",
                  "Service Day",
                  "Weight",
                  "Price",
                ].map((item) => (
                  <TableCell
                    key={item}
                    style={{
                      fontWeight: "bold",
                      color: "#6877C1",
                      fontSize: 15,
                      padding: 20,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {item}
                      {item === "Port" && (
                        <>
                          <span>
                            <IconButton
                              size="small"
                              aria-label="filter list"
                              onClick={handlePortFilterClick}
                            >
                              <FilterAltIcon />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handlePortMenuClose}
                            >
                              <Autocomplete
                                getOptionLabel={(option) => option?.name}
                                value={portFilter}
                                defaultValue={port[0]}
                                ref={autocompleteRef}
                                onChange={handlePortFilterChange}
                                options={port}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Port"
                                    size="small"
                                    InputLabelProps={{
                                      shrink:
                                        portFilter !== null ||
                                        anchorEl !== null,
                                    }}
                                  />
                                )}
                                style={{ minWidth: 150 }}
                              />
                            </Menu>
                          </span>
                        </>
                      )}
                      {(item === "Service Day" || item === "Price") && (
                        <span style={{ marginLeft: 8 }}>
                          {getSortIcon(
                            item === "Service Day" ? "service_day" : "price"
                          )}
                        </span>
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow> */}
              <TableRow
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 4px 0px #00000040",
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    padding: 5,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Destination
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    State
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEEEE",
                    borderBottom: "1px solid #EEEEEE",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Port
                    <IconButton
                      size="small"
                      aria-label="filter list"
                      onClick={handlePortFilterClick}
                    >
                      <FilterIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorElPort}
                      open={Boolean(anchorElPort)}
                      onClose={handlePortMenuClose}
                    >
                      <div
                        style={{
                          padding: "10px",
                          maxHeight: "300px",
                          overflow: "auto",
                        }}
                      >
                        <TextField
                          label="Search Port"
                          variant="outlined"
                          fullWidth
                          value={searchQuery}
                          onChange={handleSearchChange}
                          size="small"
                        />
                        <List>
                          {filteredPorts.map((port, index) => (
                            <ListItem
                              onClick={() => handlePortFilterChange(port)}
                              sx={{
                                cursor: "pointer",
                                borderWidth: 0.5,
                                margin: 0.5,
                                backgroundColor:
                                  port?.name === portFilter?.name &&
                                  port?.name !== "None"
                                    ? "#C3E1FF"
                                    : "#FFF",
                              }}
                              key={index}
                            >
                              {port?.name}
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    </Menu>
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Zone
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Service
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    padding: 5,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Service Day
                  </CustomTypography>
                  {getSortIcon("service_day")}
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Weight
                  </CustomTypography>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    padding: 5,
                    display: "flex",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Price
                  </CustomTypography>
                  {getSortIcon("price")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => (
                <TableRow
                  style={{
                    marginBottom: 10,
                    backgroundColor: "#fff",
                    borderRadius: 8,
                    boxShadow: "0px 0px 4px 0px #00000040",
                  }}
                  key={index}
                >
                  <TableCell
                    align="center"
                    style={{
                      borderBottom: "none",
                      borderRadius: "8px 0 0 8px",
                      cursor: "pointer",
                      textAlign: "center",
                      padding: 4,
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {item.destination}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 4 }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {" "}
                      {item.state}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 4 }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {" "}
                      {item.port?.name}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 4 }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {item.zone?.name}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 4 }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {" "}
                      {`${item.ratecard.service_type?.name}(${item.ratecard.carrier.name})`}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      padding: 4,
                      textAlign: "center",
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {item.service_day}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 4 }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {item.ratecard?.weight}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                      padding: 10,
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {" "}
                      {item.ratecard.price}
                    </CustomTypography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default RateLookupTable;
