import React, { useEffect, useState, useContext } from 'react'
import DashboardContainer from '../../components/dashboard-container'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/material'
import { productService } from '../../utils/services/productService'
import ProductList from '../../components/product-list'
import AppContext from '../../components/context/context'
import { clientService } from '../../utils/services/clientService'
import { toast } from 'react-toastify'
import ProductUploadModal from '../../components/file-upload-modal'
import UploadStatusChartDialog from '../../components/upload-status'
import { useNavigate } from 'react-router-dom'
import CreateASN from '../../components/create-asn'

export default function Product() {
  const initialData = {
    name: '',
    description: '',
    images: [],
    status: '',
    product_type: '',
    variants: []
    //vendor: null,
  }
  const navigate = useNavigate()

  const [productDetails, setProductDetails] = useState(initialData)
  const [productData, setProductData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [dataCount, setDataCount] = useState(rowsPerPage)
  const [formErrors, setFormErrors] = useState({})
  const [selectedClient, setSelectedClient] = useState({ name: '' })
  const [searchValue, setSearchValue] = useState('')
  const [variants, setVariants] = useState([])
  const [description, setDescription] = useState('')
  const [imageUrl, setImageUrl] = useState([])
  const [clientData, setClientData] = useState([])
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [clientFilter, setClientFilter] = useState(null)
  const [viewMode, setViewMode] = useState('list')
  const [filter, setFilter] = useState('All') // New state for filter
  const clientId = localStorage.getItem('client_id')
  const { user } = useContext(AppContext)
  const [vendor, setVendor] = useState(null)
  const [showUploadStatus, setShowUploadStatus] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(false)
  const [tempProduct, setTempProduct] = useState([])
  const [showASNModal, setShowASNModal] = useState(false)

  const fetchTempProductData = async () => {
    try {
      const response = await productService.fetchTempProduct(rowsPerPage, 0, clientId)
      setTempProduct(response.data)
      if (response.data.length) {
        setShowImportModal(true)
      }
    } catch (error) {
      console.error('Fetch failed:', error)
    }
  }

  const validateForm = () => {
    let hasValidVariant = false
    // Check product details
    if (!productDetails.name || !productDetails.currency || !productDetails.status) {
      return false
    }

    // Check variants
    for (let index = 0; index < variants.length; index++) {
      const variant = variants[index]

      const isVariantValid =
        variant.option &&
        variant.price >= 0 &&
        variant.weight_unit &&
        variant.weight >= 0 &&
        variant.height_unit &&
        variant.height >= 0 &&
        variant.width_unit &&
        variant.width >= 0 &&
        variant.length_unit &&
        variant.length >= 0 &&
        variant.sku

      if (isVariantValid) {
        hasValidVariant = true
        break // Exit loop if a valid variant is found
      }
    }

    // Return false if no valid variant is found
    if (!hasValidVariant) {
      return false
    }

    // Check client and vendor
    if (!selectedClient?.name || !vendor?._id) {
      return false
    }

    // If all checks passed, return true
    return true
  }

  const fetchProductData = async () => {
    setLoading(true)
    try {
      const skip = page * rowsPerPage
      const response = await productService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue,
        filter // Pass the filter to the fetch function
      )
      setProductData(response.data)
      setDataCount(response.total)
    } catch (error) {
      console.error('Fetch failed:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProductData()
  }, [page, rowsPerPage, clientFilter, searchValue, filter])
  useEffect(() => {
    console.log('validateForm()', validateForm())
  }, [productDetails, variants])

  const syncProductData = async () => {
    // fetchImportedCatalog();
    setLoading(true)
    try {
      const response = await productService.syncProduct({
        client_id: clientId,
        core: true
      })
      console.log(response)
      toast.success('Product import has been successfully initiated!')
    } catch (error) {
      console.error('Fetch failed:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <DashboardContainer
        showUploadStatus
        onSearchData={(x) => {
          // Check if there’s a search term
          if (x.trim()) {
            setSearchValue(x)
            setPage(0) // Reset to page 0 when there’s a search term
          } else {
            setSearchValue('') // Clear the search value if empty search term
          }
        }}
        onClickAddButton={() => {
          // localStorage.setItem('path', `/manage-product/create`)
          navigate('/manage-product/create')
        }}
        header={'Product List'}
        onSelectClient={(e) => {
          setClientFilter(e)
        }}
        selectedClient={clientFilter?.name}
        onHandleUploadButton={() => setOpenUploadModal(true)}
        product
        title={'+ Product'}
        setViewMode={setViewMode}
        onClickRefresh={fetchProductData}
        onClickSyncProduct={syncProductData}
        onClickCreateASN={() => {
          console.log('ASASA')

          setShowASNModal(true)
        }}
      >
        <CreateASN openDialog={showASNModal} handleCloseDialog={() => setShowASNModal(false)}></CreateASN>
        {/* <ImportProductModal
          open={showImportModal}
          setOpen={() => setShowImportModal(!showImportModal)}
          selectedOption={selectedOption}
          setSelectedOption={(e) => setSelectedOption(e)}
          handleConfirm={importProduct}
          handleDiscardAll={discardAll}
        /> */}
        <ProductUploadModal
          onClickStatus={() => setShowUploadStatus(true)}
          showUploadStatus
          onSuccessUpload={() => fetchProductData()}
          product
          onSubmitUploadData={() => setOpenUploadModal(false)}
          onClose={() => setOpenUploadModal(false)}
          open={openUploadModal}
          title={'Product List'}
        ></ProductUploadModal>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
              color: 'white'
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
        <ProductList
          data={productData}
          onEditData={fetchProductData}
          onDeleteData={fetchProductData}
          client={clientId}
          viewMode={viewMode}
          filter={filter}
          setFilter={setFilter}
          deleteTitle={'Delete Product'}
          dataCount={dataCount}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
        />
        <UploadStatusChartDialog
          onClose={() => setShowUploadStatus(false)}
          open={showUploadStatus}
        ></UploadStatusChartDialog>
      </DashboardContainer>
    </>
  )
}
