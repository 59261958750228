import React, { useState } from 'react'
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Page, Text, View, Document, StyleSheet, PDFViewer, pdf } from '@react-pdf/renderer'
import { purchaseOrderService } from '../../utils/services/purchaseOrderService'

const AddPurchaseOrderModal = ({ open, onClose, product }) => {
  const intialData = {
    company_name: '',
    company_address: '',
    company_phone: '',
    bill_to_name: '',
    bill_to_address: '',
    bill_to_phone: '',
    quotation_no: '',
    date: '',
    payment_due: '',
    items: [{ item_name: '', hsn: '', rate: '', mrp: '', tax: '', amount: '' }],
    sub_total: '',
    amount_in_words: '',
    terms_and_conditions: ''
  }
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(intialData)
  const [previewOpen, setPreviewOpen] = useState(false)
  const handleChange = (e, index = null) => {
    const { name, value } = e.target

    if (index !== null) {
      const updatedItems = [...purchaseOrderDetails.items]
      updatedItems[index][name] = value
      setPurchaseOrderDetails((prevDetails) => ({
        ...prevDetails,
        items: updatedItems
      }))
    } else {
      setPurchaseOrderDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value
      }))
    }
  }

  const handleAddItem = () => {
    setPurchaseOrderDetails((prevDetails) => ({
      ...prevDetails,
      items: [...prevDetails.items, { item_name: '', hsn: '', rate: '', mrp: '', tax: '', amount: '' }]
    }))
  }

  const handleRemoveItem = (index) => {
    const updatedItems = [...purchaseOrderDetails.items]
    updatedItems.splice(index, 1)
    setPurchaseOrderDetails((prevDetails) => ({
      ...prevDetails,
      items: updatedItems
    }))
  }

  const handleSave = async () => {
    try {
      // Generate the PDF blob
      const blob = await pdf(<OrderPDFDocument />).toBlob()
      // let yy = blob.read().toString('base64')
      // debugger
      // // Create a FormData object to send the file
      const pdfFile = new File([blob], 'document.pdf', { type: 'application/pdf' })

      // Create a FormData object
      const formData = new FormData()
      formData.append('pdf_file', pdfFile)
      formData.append('product_id', product?._id)
      formData.append('supplier', product?.vendor?._id)
      formData.append('company_name', purchaseOrderDetails?.company_name)
      formData.append('company_address', purchaseOrderDetails?.company_address)
      formData.append('company_phone', purchaseOrderDetails?.company_phone)
      formData.append('bill_to_name', purchaseOrderDetails?.bill_to_name)
      formData.append('bill_to_address', purchaseOrderDetails?.bill_to_address)
      formData.append('bill_to_phone', purchaseOrderDetails?.bill_to_phone)
      formData.append('quotation_no', purchaseOrderDetails?.quotation_no)
      formData.append('date', purchaseOrderDetails?.date)
      formData.append('payment_due', purchaseOrderDetails?.payment_due)
      formData.append('items', JSON.stringify(purchaseOrderDetails?.items))
      formData.append('sub_total', purchaseOrderDetails?.sub_total)
      formData.append('amount_in_words', purchaseOrderDetails?.amount_in_words)
      formData.append('terms_and_conditions', purchaseOrderDetails?.terms_and_conditions)
      for (let [key, value] of formData.entries()) {
        console.log(key, value)
      }
      // Send the PDF to the endpoint
      const response = await purchaseOrderService.create(formData)
      if (response) {
        console.log(response)
      }
      // if (!response.ok) {
      //   throw new Error('Failed to save PDF')
      // }

      // If successful, close the modal and reset the form
      handleClose()
      setPurchaseOrderDetails(intialData)

      // Optionally, you can show a success message to the user
      console.log('PDF saved successfully')
    } catch (error) {
      // Handle any errors
      console.error('Error saving PDF:', error)
      // Optionally, you can show an error message to the user
    }
  }
  const handlePreview = () => {
    setPreviewOpen(true)
  }

  const handleClosePreview = () => {
    setPreviewOpen(false)
  }
  const handleClose = () => {
    onClose && onClose()
    setPurchaseOrderDetails(intialData)
  }
  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontSize: 12,
      lineHeight: 1.5,
      color: '#333'
    },
    section: {
      marginBottom: 10,
      padding: 10,
      borderBottom: '1px solid #eee'
    },
    header: {
      fontSize: 18,
      marginBottom: 10,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      backgroundColor: '#f5f5f5',
      padding: 10,
      borderRadius: 5
    },
    subHeader: {
      fontSize: 14,
      fontWeight: 'bold',
      marginBottom: 5,
      color: '#555',
      borderBottom: '2px solid #333',
      paddingBottom: 5
    },
    table: {
      display: 'table',
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginBottom: 10
    },
    tableRow: {
      flexDirection: 'row'
    },
    tableColHeader: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#f2f2f2',
      fontWeight: 'bold',
      color: '#000',
      padding: 5,
      textAlign: 'left'
    },
    tableColValue: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
      textAlign: 'left'
    },
    bold: {
      fontWeight: 'bold'
    }
  })

  const OrderPDFDocument = () => {
    return (
      <Document>
        <Page style={styles.page}>
          <View style={styles.header}>
            <Text>Purchase Order</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.subHeader}>Company Information</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text>Company Name</Text>
                </View>
                <View style={styles.tableColValue}>
                  <Text>{purchaseOrderDetails.company_name}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text>Address</Text>
                </View>
                <View style={styles.tableColValue}>
                  <Text>{purchaseOrderDetails.company_address}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text>Phone</Text>
                </View>
                <View style={styles.tableColValue}>
                  <Text>{purchaseOrderDetails.company_phone}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.subHeader}>Bill To</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text>Name</Text>
                </View>
                <View style={styles.tableColValue}>
                  <Text>{purchaseOrderDetails.bill_to_name}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text>Address</Text>
                </View>
                <View style={styles.tableColValue}>
                  <Text>{purchaseOrderDetails.bill_to_address}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text>Phone</Text>
                </View>
                <View style={styles.tableColValue}>
                  <Text>{purchaseOrderDetails.bill_to_phone}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.subHeader}>Order Details</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text>Quotation No:</Text>
                </View>
                <View style={styles.tableColValue}>
                  <Text>{purchaseOrderDetails.quotation_no}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text>Date:</Text>
                </View>
                <View style={styles.tableColValue}>
                  <Text>{purchaseOrderDetails.date}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text>Payment Due:</Text>
                </View>
                <View style={styles.tableColValue}>
                  <Text>{purchaseOrderDetails.payment_due}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.section, { paddingBottom: 0 }]}>
            <Text style={styles.subHeader}>Items</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text>S.No</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text>Item</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text>HSN</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text>Rate</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text>MRP</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text>Tax</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text>Amount</Text>
                </View>
              </View>
              {purchaseOrderDetails.items.map((item, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableColValue}>
                    <Text>{index + 1}</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text>{item.item_name}</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text>{item.hsn}</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text>{item.rate}</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text>{item.mrp}</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text>{item.tax}</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text>{item.amount}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.keyText}>{`Sub Total:  ${purchaseOrderDetails?.sub_total}`}</Text>
            {/* <Text style={styles.valueText}></Text> */}
            <Text style={styles.keyText}>{`Amount in Words:  ${purchaseOrderDetails.amount_in_words}`}</Text>
            {/* <Text style={styles.valueText}></Text> */}
          </View>

          <View style={styles.section}>
            <Text style={styles.keyText}>Terms and Conditions:</Text>
            <Text style={styles.valueText}>{purchaseOrderDetails.terms_and_conditions}</Text>
          </View>
        </Page>
      </Document>
    )
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-purchase-order-modal-title"
      aria-describedby="add-purchase-order-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          // border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          height: '90vh',
          overflow: 'auto'
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography id="add-purchase-order-modal-title" variant="h6" component="h2">
            Purchase Order
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box component="form" mt={2}>
          <Typography variant="h6">Company Information</Typography>
          <TextField
            label="Company Name"
            name="company_name"
            fullWidth
            margin="normal"
            value={purchaseOrderDetails.company_name}
            onChange={handleChange}
          />
          <TextField
            label="Address"
            name="company_address"
            fullWidth
            margin="normal"
            value={purchaseOrderDetails.company_address}
            onChange={handleChange}
          />
          <TextField
            type="tel"
            label="Phone No"
            name="company_phone"
            fullWidth
            margin="normal"
            value={purchaseOrderDetails.company_phone}
            onChange={handleChange}
          />

          <Typography variant="h6" mt={3}>
            Bill To
          </Typography>
          <TextField
            label="Name"
            name="bill_to_name"
            fullWidth
            margin="normal"
            value={purchaseOrderDetails.bill_to_name}
            onChange={handleChange}
          />
          <TextField
            label="Address"
            name="bill_to_address"
            fullWidth
            margin="normal"
            value={purchaseOrderDetails.bill_to_address}
            onChange={handleChange}
          />
          <TextField
            type="tel"
            label="Phone No"
            name="bill_to_phone"
            fullWidth
            margin="normal"
            value={purchaseOrderDetails.bill_to_phone}
            onChange={handleChange}
          />

          <Typography variant="h6" mt={3}>
            Order Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Quotation No"
                name="quotation_no"
                fullWidth
                margin="normal"
                value={purchaseOrderDetails.quotation_no}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Date"
                name="date"
                type="date"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                value={purchaseOrderDetails.date}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Payment Due"
                name="payment_due"
                fullWidth
                margin="normal"
                value={purchaseOrderDetails.payment_due}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" mt={3}>
            Items
          </Typography>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>HSN</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>MRP</TableCell>
                  <TableCell>Tax</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchaseOrderDetails.items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <TextField
                        name="item_name"
                        fullWidth
                        margin="normal"
                        value={item.item_name}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="hsn"
                        fullWidth
                        margin="normal"
                        value={item.hsn}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        name="rate"
                        fullWidth
                        margin="normal"
                        value={item.rate}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        name="mrp"
                        fullWidth
                        margin="normal"
                        value={item.mrp}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="tax"
                        fullWidth
                        margin="normal"
                        value={item.tax}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        name="amount"
                        fullWidth
                        margin="normal"
                        value={item.amount}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleRemoveItem(index)}>
                        <RemoveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={handleAddItem}
            sx={{ mt: 2 }}
          >
            Add Item
          </Button>

          <Typography variant="h6" mt={3}>
            Summary
          </Typography>
          <TextField
            type="number"
            label="Sub Total"
            name="sub_total"
            fullWidth
            margin="normal"
            value={purchaseOrderDetails.sub_total}
            onChange={handleChange}
          />
          <TextField
            label="Amount in Words"
            name="amount_in_words"
            fullWidth
            margin="normal"
            value={purchaseOrderDetails.amount_in_words}
            onChange={handleChange}
          />
          <TextField
            label="Terms and Conditions"
            name="terms_and_conditions"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            value={purchaseOrderDetails.terms_and_conditions}
            onChange={handleChange}
          />
        </Box>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button style={{ color: 'red' }} onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handlePreview} variant="contained" sx={{ ml: 2, backgroundColor: '#2bc4d6' }}>
            Preview
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary" sx={{ ml: 2 }}>
            Save
          </Button>
        </Box>
        <Dialog open={previewOpen} onClose={handleClosePreview} maxWidth="lg" fullWidth>
          <DialogContent>
            <PDFViewer style={{ width: '100%', height: '80vh' }}>{OrderPDFDocument()}</PDFViewer>
          </DialogContent>
        </Dialog>
      </Box>
    </Modal>
  )
}

export default AddPurchaseOrderModal
