// api.js

import axiosInstance from "../axiosInstance";

export const webhookService = {
  create: async (data, client_id, shop_name) => {
    try {
      let response = await axiosInstance.post(
        `/webhook?id=${client_id}&shop_name=${shop_name}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (client_id, shop_name) => {
    try {
      let response = await axiosInstance.get(
        `/webhook?client_id=${client_id}&shop_name=${shop_name}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (data, id, shop_name, client_id) => {
    try {
      let response = await axiosInstance.patch(
        `/webhook/${id}?shop_name=${shop_name}&client_id=${client_id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id, shop_name, client_id) => {
    try {
      let response = await axiosInstance.delete(
        `/webhook/${id}?shop_name=${shop_name}&client_id=${client_id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
