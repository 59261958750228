// api.js

import axiosInstance from '../axiosInstance'

export const globalNetworkService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post('/global-network-design', data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/global-network-design?$limit=${limit || 15}&$skip=${skip || 0}&${
          clientId ? `client_id=${clientId}&` : ''
        }&${searchValue ? `&supplier_location[$search]=${searchValue}` : ''}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  },

  edit: async (id, data) => {
    try {
      let response = await axiosInstance.patch(`/global-network-design/${id}`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/global-network-design/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
