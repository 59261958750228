import React, { useState, useEffect, useCallback } from 'react'
import { Autocomplete, TextField, CircularProgress, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import { customerService } from '../../services/customer-service'

const CustomerAutocomplete = ({
  selectedCustomer,
  onCustomerChange,
  width = 200,
  label = 'Select Customer',
  errorMessage,
  hideLabel,
  backgroundColor
}) => {
  const [allCustomer, setAllCustomer] = useState([])
  const [customerSearchValue, setCustomerSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)

  const fetchCustomerData = useCallback(
    debounce(async (searchQuery, pageNumber, isLoadMore = false) => {
      if (isLoadMore) {
        setLoadMoreLoading(true)
      } else {
        setLoading(true)
      }

      try {
        const response = await customerService.fetch(10, pageNumber * 10, searchQuery)
        if (pageNumber === 0) {
          setAllCustomer(response.data)
        } else {
          setAllCustomer((prevClients) => [...prevClients, ...response.data])
        }
        setTotal(response.total)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false)
        } else {
          setLoading(false)
        }
      }
    }, 300),
    []
  )

  useEffect(() => {
    fetchCustomerData(customerSearchValue, page)
  }, [customerSearchValue, page])

  const handleCustomerChange = (event, value) => {
    if (value && value.name === 'Load More') {
      return
    }
    if (onCustomerChange) {
      onCustomerChange(value)
    }
    setCustomerSearchValue('')
  }

  const handleSearchChange = (event) => {
    const value = event.target.value
    setCustomerSearchValue(value)
    setPage(0)
    setAllCustomer([])
  }

  const handleLoadMoreClick = () => {
    if (allCustomer.length < total && !loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  // Add "Load More" option every 10 clients if more data is available
  const optionsWithLoadMore = [...allCustomer]
  if (allCustomer.length < total && allCustomer.length % 10 === 0) {
    optionsWithLoadMore.push({ name: 'Load More' })
  }

  return (
    <Autocomplete
      size="small"
      id="customer-select"
      options={optionsWithLoadMore}
      getOptionLabel={(option) => option.name || ''}
      value={selectedCustomer}
      onChange={handleCustomerChange}
      renderOption={(props, option) =>
        option.name === 'Load More' ? (
          <li
            {...props}
            onClick={handleLoadMoreClick}
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          >
            <Typography color="primary" variant="body2" style={{ marginRight: 8 }}>
              Load More...
            </Typography>
            {loadMoreLoading && <CircularProgress color="inherit" size={16} />}{' '}
          </li>
        ) : (
          <li {...props}>{option.name}</li>
        )
      }
      renderInput={(params) => (
        <TextField
          sx={{ backgroundColor: backgroundColor }}
          {...params}
          label={hideLabel ? '' : label}
          //   placeholder="Select Customer"
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      )}
      style={{ minWidth: width, marginRight: 10 }}
    />
  )
}

export default CustomerAutocomplete
