import React, { useEffect, useState } from 'react'
import DataCard from '../../components/data-card'
import GraphCard from '../../components/graph-card'
import OrderList from '../../components/order-management-list'
import { Box, TablePagination, Typography } from '@mui/material'
import { orderService } from '../../utils/services/orderService'
import ForecastGraphCard from '../../components/forecastGraph'
import { isMobile } from '../../globalStyles/mobile'

const DashboardScreen = () => {
  const [orderData, setOrderData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [statusFilter, setStatusFilter] = useState('')
  const [clientFilter, setClientFilter] = useState(null)
  const clientId = localStorage.getItem('client_id')
  const fetchOrderData = async () => {
    try {
      const response = await orderService.fetch(
        rowsPerPage,
        page * 15,
        clientId ? clientId : clientFilter?._id,
        null,
        statusFilter
      )
      setOrderData(response)
    } catch (error) {
      console.error('Fetch error:', error)
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
  }

  useEffect(() => {
    fetchOrderData()
  }, [rowsPerPage, page, statusFilter, clientFilter])

  const handleClientFilterChange = (client) => {
    setClientFilter(client)
  }
  const customScrollbarStyle = {
    maxHeight: 'calc(100vh - 80px)',
    overflowX: 'auto',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#273894 #D9D9D9',
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#D9D9D9',
      borderRadius: '14px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#273894',
      borderRadius: '14px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#273894'
    }
  }

  return (
    <div style={customScrollbarStyle}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile() ? 'column' : 'row',
          justifyContent: 'space-around',
          overflow: 'auto',
          minHeight: 200
        }}
      >
        <DataCard cardTitle={'Orders'} cardCount={'__/__'} showIconButton />
        <GraphCard title={'Delivery'} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DataCard
            cardTitle={'Cost'}
            cardCount={'$__'}
            showIconButton={false}
            smallRoundBoxBg={'#4BFF69'}
            backgroundColor={'#DCFCE7'}
          />
          <a
            style={{
              textAlign: 'left',
              marginLeft: 20,
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
          >
            More KPI(s)...
          </a>
        </div>
      </div>
      <div>
        <Typography
          style={{
            fontSize: 20,
            fontWeight: 700,
            color: '#151D48',
            textAlign: 'left',
            padding: '20px 0px 20px 0px'
          }}
        >
          Inventory Forecast
        </Typography>
        {/* <p>{scrollPosition.toString()}</p> */}
        <ForecastGraphCard onClientFilterChange={handleClientFilterChange} />
      </div>
      <div style={{ paddingBottom: 10 }}>
        <Typography
          style={{
            fontSize: 20,
            fontWeight: 700,
            color: '#151D48',
            textAlign: 'left',
            padding: '20px 0px 20px 0px',
            marginLeft: 5
          }}
        >
          Orders
        </Typography>

        <OrderList
          style={{ height: '70vh', paddingBottom: 5 }}
          data={orderData?.data}
          onStatusFilterChange={setStatusFilter}
          fetchOrderData={fetchOrderData}
        />
        <TablePagination
          style={{
            // position: 'fixed',
            backgroundColor: '#ffffff',
            borderTop: '1px solid #dddddd',
            marginBottom: 10
          }}
          rowsPerPageOptions={[15, 25, 45, 75, 100]}
          component="div"
          count={orderData?.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  )
}

export default DashboardScreen
