// portLookupService.js
import axiosInstance from "../axiosInstance";

export const portLookupService = {
  fetch: async (zipCode, filter, order, limit, skip, clientId) => {
    try {
      let sortQuery = "";
      if (filter && order) {
        sortQuery = `&$sort[${filter}]=${order}`;
      }
      let response = await axiosInstance.get(
        `/zip_zone?destination=${zipCode}${sortQuery}&$limit=${
          limit || 15
        }&$skip=${skip || 0}${
          clientId ? `&client_id=${clientId}` : ""
        }&$populate=port&$populate=zone`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
