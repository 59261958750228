import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography/Typography'
import config from '../../config'
import 'react-toastify/dist/ReactToastify.css'

import {
  Box,
  Card,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import 'react-quill/dist/quill.snow.css'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { isMobile } from '../../globalStyles/mobile-design'
import { ActionTableCellComponent } from '../action-component'
import DeleteConfirmationModal from '../delete-confirmation-modal'
import { countryService } from '../../services/country-service'
import { toast } from 'react-toastify'
import AddEditCountry from '../add-edit-country'
import CountryViewModal from '../country-details-modal'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    color: theme.palette.common.white,
    padding: '6px 6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '6px 6px'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
    border: '1px solid #EAECF0'
  },

  '&:lastChild td, &:lastChild th': {
    border: 0
  }
}))

const CountryList = ({
  data,
  rowsPerPage,
  page,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
  onSuccess
}) => {
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: 'cover', height: '300px' }}
      />
    </div>
  )
  const handleViewClick = (country) => {
    setSelectedCountry(country)
    setOpenViewModal(true)
  }
  const handleEditClick = (country) => {
    setSelectedCountry(country)
    setOpenEditModal(true)
    console.log(country)
  }

  const handleDeleteClick = (country) => {
    setOpenConfirmation(true)
    setSelectedCountry(country)
  }
  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      const response = await countryService.delete(selectedCountry._id)
      setSelectedCountry(null)
      onSuccess && onSuccess()
      toast.success('Country Deleted successfully!')
      setOpenConfirmation(false)
    } catch (error) {
      console.error('Delete failed:', error)
    }
  }
  const handleEditCountry = (data) => {
    try {
      const response = countryService.edit(
        {
          ...data
        },
        selectedCountry._id
      )
      setOpenEditModal(false)
      setSelectedCountry(null)
      onSuccess && onSuccess()
      console.log(response)
      toast.success('Country Updated successfully!')
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    setCountries(
      data.map((country) => ({
        ...country,
        action: (
          <ActionTableCellComponent
            selectedData={country}
            handleViewClick={handleViewClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        )
      }))
    )
  }, [data])
  return (
    <div
      style={{
        overflowY: 'scroll',
        display: 'flex',
        maxHeight: `calc(100vh - 170px)`,
        width: '100%',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::WebkitScrollbar': { width: 0 }
      }}
    >
      <TableContainer
        sx={{
          mt: 3,
          overflowY: 'auto',
          paddingInline: 0.4,
          maxHeight: '100%'
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            border: '1px solid #EAECF0'
          }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                  fontFamily={'poppins'}
                >
                  NAME
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                  fontFamily={'poppins'}
                >
                  CODE
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                  fontFamily={'poppins'}
                >
                  CURRENCY
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                  ACTION
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {countries.length === 0 ? (
              <StyledTableRow>
                <TableCell colSpan={8} align="center">
                  <NoDataImage />
                </TableCell>
              </StyledTableRow>
            ) : (
              countries?.map((country, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {country.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {country.code}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {country.currency}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell sx={{ width: '3%', textAlign: 'center' }}>
                    {country?.action}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffffff',
          padding: '2px',
          borderTop: '1px solid #dddddd'
        }}
        rowsPerPageOptions={[10, 15, 25, 45, 75, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DeleteConfirmationModal
        title={`Are you sure you want to delete  ${selectedCountry?.name} ?`}
        open={openConfirmation}
        handleClose={() => {
          setOpenConfirmation(false)
          setSelectedCountry(null)
        }}
        handleDelete={(e) => {
          confirmDelete(e)
        }}
      />
      <AddEditCountry
        setOpen={setOpenEditModal}
        handleSubmit={handleEditCountry}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        selectedData={selectedCountry}
      ></AddEditCountry>
      <CountryViewModal
        handleCloseModal={() => {
          setOpenViewModal(false)
          setSelectedCountry(null)
        }}
        openModal={openViewModal}
        country={selectedCountry}
      ></CountryViewModal>
    </div>
  )
}

export default CountryList
