// import ReactDOM from 'react-dom/client';

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            color: "black", // Default text color
            backgroundColor: "transparent", // Default background
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px transparent inset", // Remove autofill background
              WebkitTextFillColor: "black", // Autofill text color
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px transparent inset", // Same here for InputBase
            WebkitTextFillColor: "black", // Autofill text color globally
            caretColor: "black", // Caret color
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#222fff",
    },
    secondary: {
      main: "#ff2222",
    },
    background: {
      default: "#f0f0f0",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
    },
  },
});

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
