import axiosInstance from '../axiosInstance'

export const asnRequestService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post('/asn-request', {
        ...data
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetch: async (limit, productId, skip) => {
    try {
      let response = await axiosInstance.get(
        `/asn-request?$limit=${limit || 100}&$populate=provider&product=${productId}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  },

  edit: async (data) => {
    try {
      let response = await axiosInstance.patch(`/wms/stock-in`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/asn-request/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
