import React, { useEffect, useState, useContext } from "react";
import DashboardContainer from "../../components/dashboard-container";
import AddModal from "../../components/add-modal";
import { supplierService } from "../../utils/services/supplierService";
import TablePagination from "@mui/material/TablePagination";
import { CircularProgress, Box } from "@mui/material";
import AppContext from "../../components/context/context";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SupplierList from "../../components/supplier-list";

export default function Supplier() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [supplierData, setSupplierData] = useState({
    name: "",
    client: "",
    email: "",
    min_order_qty: "",
    max_order_qty: "",
  });
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [formErrors, setFormErrors] = useState({});
  const [selectedClient, setSelectedClient] = useState({
    name: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [clientFilter, setClientFilter] = useState(null);
  const clientId = localStorage.getItem("client_id");
  const { user } = useContext(AppContext);
  const [viewMode, setViewMode] = useState("list");
  const navigate = useNavigate();
  const validateForm = () => {
    const errors = {}
   if (!supplierData.name) {
     errors.name = "Supplier name is required";
   }
   if (!supplierData.min_order_qty) {
     errors.min_order_qty = "Min Order Quantity is required";
   } else if (supplierData.min_order_qty < 0) {
     errors.min_order_qty = "Min Order Quantity must be greater than 0";
   }
   if (!supplierData.max_order_qty) {
     errors.max_order_qty = "Max Order Quantity is required";
   } else if (supplierData.max_order_qty < 0) {
     errors.max_order_qty = "Max Order Quantity must be greater than 0";
   }
   if (
     supplierData.min_order_qty >= 0 &&
     supplierData.max_order_qty >= 0 &&
     supplierData.max_order_qty < supplierData.min_order_qty
   ) {
     errors.min_order_qty =
       "Min Order Quantity must be less than Max Order Quantity";
     errors.max_order_qty =
       "Max Order Quantity must be greater than Min Order Quantity ";
   }
   if (!clientId && !selectedClient?.name) {
     errors.selectedClient = "Client is requred";
   }
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleClientFilterChange = (event, newValue) => {
    setClientFilter(newValue);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const fetchSupplierData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage
      const response = await supplierService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue
      );
      setSuppliers(response.data);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSupplierData();
  }, [page, rowsPerPage, clientFilter, searchValue]);

  const createSupplier = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;
      const response = await supplierService.create({
        ...supplierData,
        client: client_id,
      });
      toast.success("Supplier  Created successfully!");
      fetchSupplierData();
      setShowAddModal(false);
      setSupplierData({
        name: "",
        client: "",
        email: "",
        min_order_qty: "",
        max_order_qty: "",
      });
      setSelectedClient({ name: "" });
    } catch (error) {
      console.error("Create failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_carrier.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );
  return (
    <>
      <DashboardContainer
        hideFilterClient
        onSearchData={(x) => {
          setSearchValue(x);
          setPage(0);
        }}
        onClickAddButton={() => {
          setShowAddModal(true);
          setFormErrors({});
        }}
        hideUploadButton={true}
        header={"Supplier List"}
        onSelectClient={(e) => {
          setClientFilter(e);
        }}
        selectedClient={selectedClient?.name}
        setViewMode={setViewMode}
        title={"+ Add Supplier"}
      >
        <AddModal
          selectedClientData={selectedClient}
          title={"Supplier"}
          onClickAdd={(e) => {
            createSupplier(e);
          }}
          onSelectClient={(newData) => {
            console.log(newData);
            setSelectedClient(newData);
          }}
          onChangeMinOrdQty={(e) =>
            setSupplierData({ ...supplierData, min_order_qty: e })
          }
          onChangeMaxOrdQty={(e) =>
            setSupplierData({ ...supplierData, max_order_qty: e })
          }
          onChangeEmail={(e) => setSupplierData({ ...supplierData, email: e })}
          onChangeName={(e) => setSupplierData({ ...supplierData, name: e })}
          handleClose={() => setShowAddModal(false)}
          open={showAddModal}
          formErrors={formErrors}
          supplier
        ></AddModal>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit"></CircularProgress>
          </Box>
        )}
        {suppliers?.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <NoDataImage />
          </div>
        ) : (
          <>
            <SupplierList
              onDeleteData={() => fetchSupplierData()}
              onEditData={() => fetchSupplierData()}
              data={suppliers}
              viewMode={viewMode}
              deleteTitle={"Delete Supplier"}
            ></SupplierList>
          </>
        )}
        <TablePagination
          rowsPerPageOptions={[15, 25, 45, 75, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "2px",
            borderTop: "1px solid #dddddd",
          }}
        />
      </DashboardContainer>
    </>
  );
}
