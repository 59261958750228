// api.js

import axiosInstance from "../axiosInstance";

export const boxcWebhookService = {
    create: async (data, client_id) => {
        try {
            let response = await axiosInstance.post(`/boxc-webhook?client_id=${client_id}`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    fetch: async (client_id) => {
        try {
            let response = await axiosInstance.get(`/boxc-webhook?client_id=${client_id}`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    edit: async (data, id, client_id) => {
        try {
            let response = await axiosInstance.patch(`/boxc-webhook/${id}?client_id=${client_id}`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    delete: async (id, client_id) => {
        try {
            let response = await axiosInstance.delete(`/boxc-webhook/${id}?client_id=${client_id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};
