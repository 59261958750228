// api.js

import axiosInstance from "../axiosInstance";

export const customCleranceService = {
  create: async (obj) => {
    try {
      let response = await axiosInstance.post("/custom-clearance", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/custom-clearance?$populate=client_id&$limit=${
          limit || 15
        }&$skip=${skip || 0}&${clientId ? `client_id=${clientId}` : ""}&${
          searchValue ? `&type[$search]=${searchValue}` : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(`/custom-clearance/${id}`, obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/custom-clearance/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
