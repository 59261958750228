// api.js

import axiosInstance from "../axiosInstance";

export const providerService = {
  create: async (obj) => {
    try {
      let response = await axiosInstance.post("/provider", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/provider?&$limit=${limit || 15}&$skip=${skip || 0}&${
          clientId ? `client_id=${clientId}` : ""
        }&${searchValue ? `&name[$search]=${searchValue}` : ""}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(`/provider/${id}`, obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/provider/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
