import React, { useReducer, useState } from "react";
import { boxcWebhookService } from "../../utils/services/boxcWebhookService";
import { toast } from "react-toastify";
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    CircularProgress,
    Grid,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import { isMobile } from "../../globalStyles/mobile";

const init = [{ webhook: { topic: "", address: "" } }];
const reducer = (state, action) => {
    switch (action?.type) {
        case "INIT_DATA":
            return init;
        case "ADD_WEBHOOK":
            return [...state, { webhook: { topic: "", address: "" } }];

        case "REMOVE_WEBHOOK":
            return state.filter((_, i) => i !== action.index);

        case "WEBHOOK_CHANGE":
            return state.map((x, i) =>
                i === action.index
                    ? {
                        webhook: {
                            ...x.webhook,
                            ...action.value,
                        },
                    }
                    : x
            );

        case "EDIT_WEBHOOK":
            return [
                {
                    webhook: {
                        address: action.value.address,
                        topic: action.value.topic,
                    },
                },
            ];
        case "RESET_TO_INIT":
            return init;
        default:
            return state;
    }
};

const TransportManagement = ({ onEditData, data, clientId }) => {
    const [webhooks, setWebhooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newWebhook, setNewWebhook] = useReducer(reducer, init);
    const [openWebhook, setOpenWebhook] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [selectedWebhook, setSelectedWebhook] = useState({
        webhook: { address: "", id: "" }
    });

    const fetchWebhookData = async () => {
        setLoading(true);
        try {
            const response = await boxcWebhookService.fetch(clientId);
            setWebhooks(response.webhooks);
        } catch (error) {
            console.error("Fetch failed:", error);
        } finally {
            setLoading(false);
        }
    };

    const submit = async () => {
        if (selectedWebhook?.webhook?.id) {
            try {
                setLoading(true);
                await boxcWebhookService.edit(
                    {
                        webhook: {
                            address: newWebhook[0].webhook.address
                        },
                    },
                    selectedWebhook.webhook.id,
                    clientId
                );
                fetchWebhookData(clientId);
                toast.success("Updated successfully!");
                setSelectedWebhook({ address: "", id: "" });
                setNewWebhook({ type: "INIT_DATA" });
            } catch (error) {
                console.error("Update failed:", error);
            } finally {
                setLoading(false);
            }
        } else {
            try {
                setLoading(true);
                await boxcWebhookService.create(
                    newWebhook.filter((x) => x.webhook.address && x.webhook.topic),
                    clientId
                );
                fetchWebhookData(clientId);
                toast.success("Created successfully!");
                setNewWebhook({ type: "INIT_DATA" });
            } catch (error) {
                console.error("Create failed:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const confirmDelete = async (id) => {
        try {
            setLoading(true);
            await boxcWebhookService.delete(id, clientId);
            fetchWebhookData(clientId);
            toast.success("Deleted successfully!");
            setOpenConfirmation(false);
            setLoading(false);
        } catch (error) {
            console.error("Delete failed:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (webhook) => {
        setSelectedWebhook({ webhook: webhook });
        setNewWebhook({ type: "EDIT_WEBHOOK", value: webhook });
    };

    const handleRowClick = (index) => {
        setOpenWebhook(openWebhook === index ? null : index);
        if (openWebhook !== index)
            fetchWebhookData(clientId);
    };

    const handleCancel = () => {
        setNewWebhook({ type: "RESET_TO_INIT" });
        setSelectedWebhook({ address: "", id: "" });
    };
    return (
      <Box>
        <div
          style={{
            display: "flex",
            width: "100%",
            background: "#C3E1FF",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 7,
            marginTop: 8,
          }}
        >
          <CustomTypography
            fontSize={14}
            style={{ fontWeight: "600", marginLeft: "40%" }}
          >
            TRANSPORT MANAGEMENT
          </CustomTypography>
          {data?.length ? (
            <IconButton
              style={{
                color: "#FEC50F",
                padding: 0,
                background: "#FEC50F33",
                height: 30,
                width: 30,
                borderRadius: 5,
                marginLeft: 15,
                boxShadow: "0px 0px 4px 0px #00000040",
                marginTop: 5,
              }}
              aria-label="edit"
              onClick={() => {
                onEditData && onEditData(data);
              }}
            >
              <BorderColorIcon sx={{ height: 20, width: 20 }} />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
        {data?.length ? (
          data?.map((provider, i) => (
            <React.Fragment key={i}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "0.5px solid #737791",
                  minHeight: 40,
                  width: "100%",
                  flexDirection: isMobile() ? "column" : "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    borderRight: "0.5px solid #737791",
                    width: isMobile() ? "100%" : "50%",
                    background: "#EEEEEEEE",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                    minHeight: 40,
                  }}
                >
                  <CustomTypography fontSize={13}>Provider</CustomTypography>
                </Box>
                <div
                  style={{
                    display: "flex",
                    borderRight: "0.5px solid #737791",
                    width: isMobile() ? "100%" : "50%",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                    minHeight: 40,
                    borderBottom: isMobile() ? "0.5px solid #737791" : "none",
                  }}
                >
                  <CustomTypography fontSize={13}>
                    {provider?.provider}
                  </CustomTypography>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: isMobile() ? "100%" : "20%",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                    minHeight: 40,
                  }}
                >
                  <div
                    style={{
                      border: "0.5px solid #007BFF",
                      padding: 6,
                      borderRadius: 5,
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRowClick(i)}
                  >
                    <img alt="link" src="/link.png" />
                    <CustomTypography
                      fontSize={13}
                      style={{ color: "#007BFF", marginLeft: 1 }}
                    >
                      Webhook
                    </CustomTypography>
                  </div>
                </div>
              </Box>
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 9999,
                    color: "white",
                  }}
                >
                  <CircularProgress color="inherit" />
                </Box>
              )}
              {openWebhook === i && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // marginInline: "2%",
                    marginBottom: 20,
                    borderBottom: "0.5px solid #737791",
                    padding: 10,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    {newWebhook?.map((x, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          // width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          sx={{ maxWidth: { xs: "100%", md: "100%" } }}
                        >
                          <Grid item xs={12} md={5}>
                            <CustomTypography fontSize={13}>
                              Type
                            </CustomTypography>
                            <TextField
                              size="small"
                              value={x.webhook.topic}
                              onChange={(e) =>
                                setNewWebhook({
                                  type: "WEBHOOK_CHANGE",
                                  index: index,
                                  value: { topic: e.target.value },
                                })
                              }
                              placeholder="Topic"
                              fullWidth
                              margin="dense"
                            />
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <CustomTypography fontSize={13}>
                              URL
                            </CustomTypography>
                            <TextField
                              size="small"
                              value={x.webhook.address}
                              onChange={(e) =>
                                setNewWebhook({
                                  type: "WEBHOOK_CHANGE",
                                  index: index,
                                  value: { address: e.target.value },
                                })
                              }
                              placeholder="Address"
                              fullWidth
                              margin="dense"
                            />
                          </Grid>
                          <Grid>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                marginTop: 22,
                                marginLeft: 20,
                              }}
                            >
                              {!selectedWebhook?.webhook?.id && index > 0 && (
                                <IconButton
                                  style={{
                                    color: "#D13438",
                                    padding: 0,
                                    background: "#D1343833",
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "5px",
                                    boxShadow: "0px 0px 4px 0px #00000040",
                                    marginLeft: 15,
                                  }}
                                  aria-label="delete"
                                  onClick={() =>
                                    setNewWebhook({
                                      type: "REMOVE_WEBHOOK",
                                      index: index,
                                    })
                                  }
                                >
                                  <img
                                    style={{ height: "15px", width: "15px" }}
                                    alt="delete"
                                    src="/delete.png"
                                  />
                                </IconButton>
                              )}
                              {newWebhook.length === 1 &&
                                !selectedWebhook?.webhook?.id && (
                                  <Button
                                    onClick={() => submit()}
                                    sx={{
                                      marginLeft: 1,
                                      textTransform: "none",
                                    }}
                                    variant="contained"
                                  >
                                    Submit
                                  </Button>
                                )}
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                    <div
                      style={{
                        marginTop: 20,
                        justifyContent: "flex-end",
                        display: "flex",
                        width: "83%",
                      }}
                    >
                      {newWebhook.length > 1 || selectedWebhook?.webhook?.id ? (
                        <>
                          <Button
                            onClick={() => submit()}
                            sx={{ marginRight: 1, textTransform: "none" }}
                            variant="contained"
                          >
                            Submit
                          </Button>
                          <Button
                            onClick={handleCancel}
                            sx={{ textTransform: "none", marginRight: 1 }}
                            variant="outlined"
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div>
                    {!selectedWebhook?.webhook?.id && (
                      <div
                        style={{
                          width: "20%",
                          //  padding: 10,
                          cursor: "pointer",
                          marginBottom: 10,
                        }}
                        onClick={() => setNewWebhook({ type: "ADD_WEBHOOK" })}
                      >
                        <CustomTypography
                          fontSize={13}
                          style={{ color: "#007BFF", marginLeft: 1 }}
                        >
                          + Add More
                        </CustomTypography>
                      </div>
                    )}
                  </div>
                  <CustomTypography
                    fontSize={13}
                    style={{ paddingInline: 1, fontWeight: "600" }}
                  >
                    Webhook List
                  </CustomTypography>
                  <TableContainer
                    sx={{
                      minHeight: "200px",
                      overflow: "auto",
                      mt: 1,
                      maxHeight: 400,
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ background: "#EEEEEEEE" }}>
                            <CustomTypography fontWeight={600} fontSize={13}>
                              Type
                            </CustomTypography>
                          </TableCell>
                          <TableCell sx={{ background: "#EEEEEEEE" }}>
                            <CustomTypography fontWeight={600} fontSize={13}>
                              URL
                            </CustomTypography>
                          </TableCell>
                          <TableCell sx={{ background: "#EEEEEEEE" }}>
                            <CustomTypography fontWeight={600} fontSize={13}>
                              Action
                            </CustomTypography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {webhooks?.map((webhook, index) => (
                          <TableRow sx={{ maxHeight: 200 }} key={index}>
                            <TableCell sx={{ borderBottom: 0 }}>
                              <CustomTypography fontSize={13}>
                                {webhook.topic}
                              </CustomTypography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: 0 }}>
                              <CustomTypography fontSize={13}>
                                {webhook.address}
                              </CustomTypography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: 0 }}>
                              <IconButton
                                style={{
                                  color: "#FEC50F",
                                  padding: 0,
                                  background: "#FEC50F33",
                                  height: 30,
                                  width: 30,
                                  borderRadius: 5,
                                  boxShadow: "0px 0px 4px 0px #00000040",
                                  marginTop: 5,
                                }}
                                aria-label="edit"
                                onClick={() => handleEdit(webhook)}
                              >
                                <BorderColorIcon
                                  sx={{ height: 20, width: 20 }}
                                />
                              </IconButton>
                              <IconButton
                                style={{
                                  color: "#D13438",
                                  padding: 0,
                                  background: "#D1343833",
                                  height: 30,
                                  width: 30,
                                  borderRadius: 5,
                                  marginLeft: 15,
                                  boxShadow: "0px 0px 4px 0px #00000040",
                                  marginTop: 5,
                                }}
                                aria-label="delete"
                                onClick={() => setOpenConfirmation(true)}
                              >
                                <img
                                  style={{ height: 15, width: 15 }}
                                  alt="delete"
                                  src="/delete.png"
                                />
                              </IconButton>
                            </TableCell>
                            <DeleteConfirmationModal
                              title={`Are you sure you want to delete this Webhook?`}
                              open={openConfirmation}
                              handleClose={() => setOpenConfirmation(false)}
                              handleDelete={() =>
                                confirmDelete(webhook.id, data.shop_name)
                              }
                            />
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </React.Fragment>
          ))
        ) : (
          <></>
        )}
      </Box>
    );
}
export default TransportManagement;