import React, { useEffect, useState, useContext } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import AppContext from "../context/context";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { customCleranceService } from "../../utils/services/customCleranceService";
import { manifestIdService } from "../../utils/services/manifestIdService";
import ManifestAutocomplete from "../manifestAutocomplete";

const CustomClearanceModal = ({
  open,
  handleClose,
  onAddData,
  selectedCustomClearance,
}) => {
  const [manifestId, setManifestId] = useState({});
  const [manifestData, setManifestData] = useState([]);
  const [customClearanceDetails, setCustomClearanceDetails] = useState({
    air_waybill_number: "",
    flight_name: "",
    routing: "",
  });
  const [dateDetails, setDateDetails] = useState({
    email_date: null,
    depart_date: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const { user } = useContext(AppContext);
  const clientId = localStorage.getItem("client_id");

  const fetchManifestData = async () => {
    try {
      const response = await manifestIdService.fetch(15, 0);
      setManifestData(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };

  useEffect(() => {
    fetchManifestData();
  }, []);

  useEffect(() => {
    if (selectedCustomClearance) {
      setDateDetails({
        email_date: selectedCustomClearance?.email_date,
        depart_date: formatDate(selectedCustomClearance?.depart_date),
      });
      setCustomClearanceDetails({
        air_waybill_number: selectedCustomClearance?.air_waybill_number,
        flight_name: selectedCustomClearance?.flight_name,
        routing: selectedCustomClearance?.routing, 
      });
      setManifestId(selectedCustomClearance?.manifest_ids || []);
    } else {
      initialData();
    }
  }, [selectedCustomClearance]);

const formatDate = (dateString) => {
  if (!dateString) return "";
  return dayjs(dateString).format("YYYY-MM-DD");
};
  const initialData = () => {
    setDateDetails({ email_date: null, depart_date: null });
    setCustomClearanceDetails({
      air_waybill_number: "",
      flight_name: "",
      routing: "",
    });
    setManifestId([]);
    setFormErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomClearanceDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await customCleranceService.edit(selectedCustomClearance._id, {
        ...customClearanceDetails,
        ...dateDetails,
        manifest_ids: manifestId,
      });
      onAddData && onAddData();
      toast.success("Pre-Alert Mail updated successfully!");
      initialData();
    } catch (e) {
      toast.error("Failed to update Pre-Alert Mail.");
      console.error(e);
    }
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await customCleranceService.create({
        ...customClearanceDetails,
        ...dateDetails,
        manifest_ids: manifestId,
      });

      onAddData && onAddData();
      toast.success("Pre-Alert Mail created successfully!");
      handleClose();
      initialData();
    } catch (e) {
      toast.error("Failed to create Pre-Alert Mail.");
      console.error(e);
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!customClearanceDetails.air_waybill_number) {
      errors.air_waybill_number = "Air-Waybill Number is required.";
    } else if (isNaN(customClearanceDetails.air_waybill_number)) {
      errors.air_waybill_number = "Air-Waybill Number must be a number.";
    }

    if (!dateDetails.email_date) {
      errors.email_date = "Email Date is required.";
    }

    if (!customClearanceDetails.flight_name) {
      errors.flight_name = "Flight Name is required.";
    }

    if (!dateDetails.depart_date) {
      errors.depart_date = "Depart Date is required.";
    }

    if (!customClearanceDetails.routing) {
      errors.routing = "Routing is required.";
    }

    if (!manifestId.length) {
      errors.manifestId = "At least one Manifest ID must be selected.";
    }

    setFormErrors(errors);
    console.log("Form errors:", errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        initialData();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {selectedCustomClearance ? "Edit" : "Create"} Pre-Alert Mail
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Select Manifest IDs <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            {/* <Autocomplete
              multiple
              size="small"
              value={manifestId}
              onChange={(event, newValue) => {
                setManifestId(newValue);
              }}
              options={manifestData}
              getOptionLabel={(option) => option?._id?.toString()}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?._id?.toString()}
                </li>
              )}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    placeholder="Select Manifest IDs"
                    margin="dense"
                    error={!!formErrors.manifestId}
                  />
                  {!!formErrors.manifestId && (
                    <FormHelperText error>
                      {formErrors.manifestId}
                    </FormHelperText>
                  )}
                </>
              )}
            /> */}
            <ManifestAutocomplete
              manifestId={manifestId}
              setManifestId={setManifestId}
              formErrors={formErrors}
              hideLabel 
              width={250} 
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Air-Waybill Number <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="air_waybill_number"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={customClearanceDetails.air_waybill_number}
              onChange={handleInputChange}
              inputProps={{ min: 0 }}
              fullWidth
              margin="dense"
              placeholder="Enter Air-Waybill Number"
              error={!!formErrors.air_waybill_number}
            />
            {!!formErrors.air_waybill_number && (
              <FormHelperText error>
                {formErrors.air_waybill_number}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Email Date <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="email_date"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={dateDetails.email_date}
              onChange={handleDateChange}
              fullWidth
              margin="dense"
              placeholder="dd/mm/yyyy"
              error={!!formErrors.email_date}
            />
            {!!formErrors.email_date && (
              <FormHelperText error>{formErrors.email_date}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Flight Name <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="flight_name"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={customClearanceDetails.flight_name}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.flight_name}
              placeholder="Enter Flight Name"
            />
            {!!formErrors.flight_name && (
              <FormHelperText error>{formErrors.flight_name}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Depart Date <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="depart_date"
              type="date"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={dateDetails.depart_date}
              onChange={handleDateChange}
              fullWidth
              margin="dense"
              error={!!formErrors.depart_date}
            />
            {!!formErrors.depart_date && (
              <FormHelperText error>{formErrors.depart_date}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Routing <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              name="routing"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={customClearanceDetails.routing}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.routing}
              placeholder="Enter Routing"
            />
            {!!formErrors.routing && (
              <FormHelperText error>{formErrors.routing}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            color: "#007DFF",
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            handleClose();
            initialData();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedCustomClearance ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: "none",
          }}
        >
          {selectedCustomClearance ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomClearanceModal;
