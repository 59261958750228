import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography/Typography'
import config from '../../config'
import 'react-toastify/dist/ReactToastify.css'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Popover
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import 'react-quill/dist/quill.snow.css'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { isMobile } from '../../globalStyles/mobile-design'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteConfirmationModal from '../../components/delete-confirmation-modal'
import { productService } from '../../services/product-service'
import { toast } from 'react-toastify'

const defaultImage = '/product.png'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    color: theme.palette.common.white,
    padding: '6px 6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '6px 6px'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
    border: '1px solid #EAECF0'
  },

  '&:lastChild td, &:lastChild th': {
    border: 0
  }
}))
const ProductTableCell = React.memo(({ product, handleViewClick, handleEditClick, handleDeleteClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const handleActionClick = (action) => {
    handlePopoverClose() // Close the popover before executing the action
    switch (action) {
      case 'view':
        handleViewClick(product) // Pass `product` directly
        break
      case 'edit':
        handleEditClick(product)
        break
      case 'delete':
        handleDeleteClick(product)
        break
      default:
        break
    }
  }

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? 'primary.main' : 'inherit'
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: isMobile() ? 'bottom' : 'top',
          horizontal: isMobile() ? 'center' : 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        disableRestoreFocus={true}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
          <Button
            onClick={() => handleActionClick('view')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <VisibilityIcon />
              <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                View
              </Typography>
            </Box>
          </Button>

          <Button
            onClick={() => handleActionClick('edit')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <EditIcon style={{ color: 'orange' }} />
              <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                Edit
              </Typography>
            </Box>
          </Button>

          <Button
            onClick={() => handleActionClick('delete')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <DeleteIcon style={{ color: 'red' }} />
              <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                Delete
              </Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  )
})
const ProductList = ({
  data,
  rowsPerPage,
  page,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
  onDeleteData
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([])
  const fetchImage = (image) => {
    if (image && image.image) {
      return `${config.baseURL}/fetch-file/${image.image}`
    }
    return defaultImage
  }
  const fetchProductImage = (images) => {
    if (images.length) {
      const featureImage = images?.find((img) => img.feature_image)
      if (featureImage && featureImage.image) {
        return `${config.baseURL}/fetch-file/${featureImage.image}`
      } else {
        return `${config.baseURL}/fetch-file/${images[0].image}`
      }
    }
    return defaultImage
  }
  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await productService.delete(selectedProduct._id)
      onDeleteData && onDeleteData()
      toast.success('Product Deleted successfully!')
      setOpenConfirmation(false)
      setLoading(false)
    } catch (error) {
      console.error('Delete failed:', error)
    } finally {
      setLoading(false)
    }
  }
  const navigate = useNavigate()
  const handleViewClick = (product) => {
    setSelectedProduct(product)
    // setOpenViewModal(true)
  }
  const handleEditClick = (product) => {
    navigate('/manage-product', {
      state: {
        product: product?._id,
        isEditing: true
      }
    })
  }

  const handleDeleteClick = (product) => {
    setSelectedProduct(product)
    setOpenConfirmation(true) // Open confirmation dialog before deleting carrier
  }

  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: 'cover', height: '300px' }}
      />
    </div>
  )
  const MobileCardView = ({ data }) => {
    return (
      <Box
        sx={{
          width: '100%',
          padding: 0.5,
          maxHeight: '100%',
          overflowY: 'scroll',
          paddingBottom: 5
        }}
      >
        {data.length === 0 ? (
          <NoDataImage />
        ) : (
          data?.map((product, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: '#ffffff',
                borderRadius: 4,
                position: 'relative'
              }}
            >
              <CardContent>
                <Box display="flex" flexDirection="column">
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={fetchProductImage(product.images)}
                      alt={product.name}
                      style={{
                        width: 80,
                        height: 90,
                        objectFit: 'contain',
                        marginBottom: 20
                      }}
                    />
                    <div style={{ width: '70%' }}>
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Box
                          display="flex"
                          alignItems="center"
                          mb={1}
                          sx={{
                            maxWidth: '90%'
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            variant="h6"
                          >
                            {product?.name}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mb={1}>
                          <Typography>
                            Status :{' '}
                            <Chip
                              label={product.status}
                              sx={{
                                padding: '2px 4px',
                                borderRadius: '4px',
                                backgroundColor:
                                  product.status.toLowerCase() === 'active' ? '#CBFFD3' : '#E1E1E1'
                              }}
                            />
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          mb={1}
                          sx={{
                            maxWidth: '80%'
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            Supplier : {product.vendor}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </Box>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    )
  }
  useEffect(() => {
    setProducts(
      data.map((product) => ({
        ...product,
        action: (
          <ProductTableCell
            product={product}
            handleViewClick={handleViewClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        )
      }))
    )
  }, [data])
  return (
    <div
      style={{
        overflowY: 'scroll',
        display: 'flex',
        maxHeight: `calc(100vh - 170px)`,
        width: '100%',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::WebkitScrollbar': { width: 0 }
      }}
    >
      {isMobile() ? (
        <MobileCardView data={products} />
      ) : (
        <TableContainer
          sx={{
            mt: 3,
            overflowY: 'auto',
            paddingInline: 0.4,
            maxHeight: '100%'
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              border: '1px solid #EAECF0'
            }}
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  ></div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={'poppins'}
                    >
                      NAME
                    </Typography>
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={'poppins'}
                    >
                      CATEGORY
                    </Typography>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={'poppins'}
                    >
                      STATUS
                    </Typography>
                  </div>
                </StyledTableCell>

                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={'poppins'}
                    >
                      SUPPLIER
                    </Typography>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      title={true}
                      color="#333333"
                      fontWeight={500}
                      fontFamily={'poppins'}
                    >
                      ACTION
                    </Typography>
                  </div>
                </StyledTableCell>

                {/* <StyledTableCell>
                  <Typography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                    Action
                  </Typography>
                </StyledTableCell> */}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {products.length === 0 ? (
                <StyledTableRow>
                  <TableCell colSpan={8} align="center">
                    <NoDataImage />
                  </TableCell>
                </StyledTableRow>
              ) : (
                products?.map((product, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'left',
                          alignItems: 'center',
                          maxWidth: 250
                        }}
                      >
                        <img
                          src={fetchProductImage(product.images)}
                          alt={product.name}
                          style={{
                            width: 30,
                            height: 30,
                            objectFit: 'cover',
                            marginRight: 10,
                            borderRadius: '4px'
                          }}
                        />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'left',
                          alignItems: 'center',
                          maxWidth: 250
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: '12px',
                            color: '#000',
                            fontWeight: 400,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontFamily: 'poppins'
                          }}
                        >
                          {product.name}
                        </Typography>
                      </div>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Typography
                        fontSize="12px"
                        color="#000"
                        fontWeight={400}
                        fontFamily={'poppins'}
                        textAlign={'left'}
                      >
                        {product.product_type || ''}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      {product.status === 'active' || product.status === 'Active' ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src="/Dot.png" alt="dot" style={{ marginRight: '8px' }} />
                          <Typography fontSize="14px" color="#007613" fontWeight={400} fontFamily={'Poppins'}>
                            {product.status?.charAt(0).toUpperCase() + String(product.status).slice(1)}
                          </Typography>
                        </div>
                      ) : (
                        <Typography fontSize="14px" color="#626262" fontWeight={400} fontFamily={'poppins'}>
                          <span
                            style={{
                              padding: '5px 10px',
                              display: 'inline-block',
                              fontSize: '14px'
                            }}
                          >
                            {product.status}
                          </span>
                        </Typography>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography fontSize="12px" color="#000" fontWeight={400} fontFamily={'poppins'}>
                        {product.vendor}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell sx={{ width: '3%', textAlign: 'center' }}>
                      {product.action}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffffff',
          padding: '2px',
          borderTop: '1px solid #dddddd'
        }}
        rowsPerPageOptions={[10, 15, 25, 45, 75, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DeleteConfirmationModal
        title={`Are you sure you want to delete  ${selectedProduct?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          confirmDelete(e)
        }}
      />
    </div>
  )
}

export default ProductList
