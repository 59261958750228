import React from "react";
import { SvgIcon } from "@mui/material";

const CustomProviderIcon = ({ fill }) => {
  return (
    <>
      <SvgIcon>
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="512.000000pt"
          height="512.000000pt"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill={fill}
            stroke="none"
          >
            <path
              d="M1906 4910 c-704 -81 -1308 -526 -1584 -1168 -335 -780 -130 -1682
508 -2240 114 -100 298 -221 435 -288 126 -61 338 -134 389 -134 62 0 98 74
61 127 -13 18 -13 22 0 33 8 7 15 28 15 47 0 29 -12 47 -86 125 -95 100 -169
207 -237 343 -42 84 -77 162 -77 172 0 10 133 65 239 99 128 41 161 63 161
106 0 40 -14 63 -47 77 -24 10 -44 7 -129 -18 -55 -17 -138 -46 -184 -66 -47
-19 -88 -35 -92 -35 -16 0 -77 255 -103 435 -9 61 -19 167 -23 238 l-7 127
442 0 443 0 0 -170 c0 -180 6 -210 47 -229 37 -17 62 -13 88 14 25 24 25 26
25 205 l0 180 446 0 447 0 -6 -77 c-4 -43 -10 -126 -13 -184 l-7 -105 28 -22
c35 -28 67 -28 100 1 29 25 37 69 50 275 l7 112 330 0 331 0 -7 -67 c-3 -38
-14 -114 -23 -170 -13 -80 -14 -107 -5 -127 25 -55 95 -58 133 -6 43 61 73
441 50 649 -39 359 -163 680 -372 961 -75 102 -246 280 -345 360 -261 210
-573 348 -914 405 -113 19 -403 27 -514 15z m124 -621 l0 -461 -67 6 c-176 18
-350 59 -499 117 -138 54 -139 55 -118 104 140 339 369 598 599 676 94 33 85
80 85 -442z m253 440 c161 -53 313 -185 444 -383 57 -86 175 -328 165 -338
-13 -13 -206 -86 -292 -111 -89 -26 -229 -51 -342 -63 l-68 -6 0 461 c0 407 2
461 15 461 9 0 44 -9 78 -21z m-794 -131 c-87 -101 -180 -246 -246 -385 -31
-65 -57 -120 -59 -121 -4 -6 -146 93 -212 148 l-63 52 42 37 c134 116 332 237
497 302 53 21 98 39 100 39 2 0 -25 -32 -59 -72z m1266 42 c178 -71 389 -196
510 -303 l49 -44 -38 -34 c-40 -35 -188 -144 -218 -160 -14 -8 -26 9 -76 115
-71 148 -167 296 -252 389 -34 37 -59 67 -56 67 3 0 39 -13 81 -30z m-1807
-583 c42 -32 101 -73 130 -90 36 -22 50 -36 46 -47 -17 -44 -76 -293 -93 -396
-20 -121 -39 -301 -44 -409 l-2 -60 -329 -3 -329 -2 7 87 c26 337 177 702 405
977 l54 65 39 -32 c22 -18 74 -58 116 -90z m2641 -82 c128 -187 217 -392 264
-605 20 -89 47 -270 47 -315 0 -3 -148 -5 -328 -5 l-328 0 -13 161 c-14 191
-49 409 -92 568 -17 64 -33 125 -36 136 -3 16 13 32 85 78 48 32 122 87 163
122 l74 64 48 -52 c27 -29 79 -97 116 -152z m-2014 -230 c99 -28 272 -60 383
-70 l72 -7 0 -309 0 -309 -440 0 -440 0 0 78 c0 156 46 455 100 653 l21 75
107 -42 c59 -24 147 -55 197 -69z m1418 -42 c48 -198 87 -474 87 -615 l0 -38
-445 0 -445 0 0 308 0 309 113 12 c61 7 157 21 212 32 108 23 316 89 374 119
65 34 65 34 104 -127z m-2006 -878 c5 -108 24 -288 44 -409 17 -99 73 -341 93
-396 3 -10 -20 -31 -77 -67 -45 -29 -120 -83 -167 -122 l-85 -70 -40 46 c-229
263 -395 654 -421 996 l-7 87 329 -2 329 -3 2 -60z m252 -1091 c64 -136 162
-290 250 -392 l62 -72 -58 21 c-166 60 -362 172 -516 296 -67 54 -68 55 -50
76 24 27 238 185 253 186 3 1 29 -51 59 -115z"
            />
            <path
              d="M1922 2420 c-18 -4 -45 -18 -59 -31 -55 -51 -53 -20 -53 -946 0 -821
1 -860 19 -900 31 -66 61 -77 230 -83 l144 -5 15 -43 c19 -59 101 -145 170
-179 49 -24 69 -28 142 -28 71 0 94 4 142 27 73 34 140 102 169 173 l23 55
555 0 556 0 18 -49 c23 -67 97 -143 172 -178 51 -24 73 -28 145 -28 74 0 92 4
140 28 30 16 70 43 88 60 39 36 92 123 92 150 0 14 10 17 68 17 37 0 85 5 107
11 53 15 129 91 144 144 16 60 14 599 -3 647 -13 35 -356 602 -402 665 -13 17
-41 42 -61 55 -37 22 -47 23 -308 28 l-270 5 -5 160 c-6 176 -10 190 -70 228
l-35 22 -920 2 c-506 0 -935 -2 -953 -7z m1818 -975 l0 -825 -438 0 -438 0
-24 57 c-29 69 -95 139 -165 173 -41 20 -65 24 -140 25 -77 0 -98 -4 -147 -27
-71 -33 -131 -94 -166 -169 l-27 -59 -112 0 -113 0 0 818 c0 450 3 822 7 825
3 4 402 7 885 7 l878 0 0 -825z m674 386 c18 -19 15 -20 -148 -23 l-166 -3
-32 -33 -33 -32 0 -238 c0 -264 4 -283 68 -322 30 -19 52 -20 365 -20 l332 0
0 -250 c0 -295 2 -290 -99 -290 l-60 0 -27 60 c-31 70 -102 141 -172 173 -37
17 -66 22 -137 22 -78 0 -97 -4 -147 -28 -70 -34 -142 -112 -165 -178 -17 -47
-19 -49 -55 -49 l-38 0 0 615 0 615 248 0 c233 0 250 -1 266 -19z m193 -308
c44 -71 89 -145 102 -165 l23 -38 -271 0 -271 0 0 158 c0 87 3 162 7 165 3 4
80 7 169 7 l163 0 78 -127z m-2004 -817 c103 -42 140 -183 72 -272 -32 -42
-95 -73 -145 -74 -79 0 -161 68 -176 146 -26 139 118 255 249 200z m1787 -6
c72 -37 111 -123 90 -201 -16 -57 -46 -95 -95 -119 -71 -34 -144 -24 -198 28
-42 40 -57 75 -57 133 0 136 139 221 260 159z"
            />
          </g>
        </svg>
      </SvgIcon>
    </>
  );
};
export default CustomProviderIcon;
