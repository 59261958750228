import { SvgIcon } from '@mui/material'
import React, { useState } from 'react'

const SupplierIcon = ({ fill }) => {
  return (
    <SvgIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="512.000000pt"
        height="512.000000pt"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill || '#000000'}
          stroke="none"
        >
          <path
            d="M60 4875 l0 -245 445 0 445 0 0 -2115 0 -2115 398 0 398 0 18 -60
c47 -158 179 -282 343 -325 239 -62 495 84 567 323 l19 62 482 0 483 0 12 -47
c40 -162 193 -307 358 -342 242 -51 491 96 556 328 l17 61 230 0 229 0 0 435
0 435 -350 0 -350 0 0 1100 0 1100 -165 0 -165 0 0 780 0 780 -855 0 -855 0 0
-775 0 -775 -165 0 -165 0 0 -1105 0 -1105 -270 0 -270 0 0 1925 0 1925 -695
0 -695 0 0 -245z m1220 -2115 l0 -2200 -80 0 -80 0 0 2115 0 2115 -450 0 -450
0 0 85 0 85 530 0 530 0 0 -2200z m1660 1955 l0 -155 235 0 235 0 0 155 0 155
230 0 230 0 0 -695 0 -695 -695 0 -695 0 0 695 0 695 230 0 230 0 0 -155z
m310 80 l0 -75 -75 0 -75 0 0 75 0 75 75 0 75 0 0 -75z m-380 -1700 l0 -215
300 0 300 0 0 215 0 215 365 0 365 0 0 -1020 0 -1020 -1025 0 -1025 0 0 1020
0 1020 360 0 360 0 0 -215z m440 80 l0 -135 -135 0 -135 0 0 135 0 135 135 0
135 0 0 -135z m1590 -2340 l0 -275 -149 0 -149 0 -18 53 c-27 84 -53 127 -113
190 -96 101 -202 147 -341 147 -217 0 -397 -136 -460 -347 l-13 -43 -483 0
-483 0 -12 43 c-27 105 -111 217 -207 278 -144 91 -360 92 -502 1 -97 -62
-182 -175 -209 -279 l-12 -43 -149 0 -150 0 0 275 0 275 1725 0 1725 0 0 -275z
m-2532 -80 c227 -124 221 -442 -11 -562 -45 -23 -64 -27 -137 -27 -73 0 -92 4
-137 27 -138 72 -205 214 -169 357 20 78 67 145 130 188 66 44 97 52 191 49
66 -3 89 -8 133 -32z m1910 0 c230 -125 217 -460 -22 -566 -156 -68 -328 -7
-406 145 -31 61 -35 77 -34 145 0 86 21 144 73 204 68 77 145 109 256 104 66
-3 90 -8 133 -32z"
          />
          <path d="M3320 3890 l0 -80 200 0 200 0 0 80 0 80 -200 0 -200 0 0 -80z" />
          <path d="M3320 3670 l0 -80 200 0 200 0 0 80 0 80 -200 0 -200 0 0 -80z" />
          <path d="M3380 1890 l0 -80 290 0 290 0 0 80 0 80 -290 0 -290 0 0 -80z" />
          <path d="M3380 1580 l0 -80 290 0 290 0 0 80 0 80 -290 0 -290 0 0 -80z" />
          <path
            d="M2148 696 c-53 -19 -83 -40 -111 -78 -115 -155 -9 -368 183 -368 294
0 309 431 16 456 -30 3 -67 -1 -88 -10z m116 -167 c51 -40 22 -122 -44 -122
-66 0 -95 82 -44 122 15 12 34 21 44 21 10 0 29 -9 44 -21z"
          />
          <path
            d="M4056 695 c-53 -19 -85 -44 -121 -94 -24 -34 -30 -54 -33 -109 -3
-59 0 -74 25 -122 41 -78 111 -120 200 -120 174 0 282 169 209 326 -49 106
-172 158 -280 119z m119 -170 c46 -45 19 -118 -43 -118 -69 0 -99 80 -46 122
35 27 59 26 89 -4z"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default SupplierIcon
