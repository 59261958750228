import { Box, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import SimulationDataTable from "../../components/simulation-data-table";
import SimulationUploadModal from "../../components/simulation-file-upload";
import UploadStatusModal from "../../components/upload-status-cards";
import { simulationService } from "../../utils/services/simulationService";

export default function Simulation({ onFilterPort }) {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedSimulationData, setSelectedSimulationData] = useState(null);
  const [allXlHeaders, setAllXlHeaders] = useState([]);
  const [showUploadStatusModal, setShowUploadStatusModal] = useState(false);
  const [allSimulationData, setAllSimulationData] = useState([]);
  const [uploadedData, setUploadedData] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const clientId = localStorage.getItem("client_id");
  const handleUploadFile = async (e) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", e);
      const response = await simulationService.fetchHeaders(formData);
      setAllXlHeaders(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    setSelectedSimulationData(e);
    setOpenUploadModal(true);
  };
  const fetchSimulationData = async () => {
    setLoading(true);
    try {
      const response = await simulationService.getSimulationData(
        rowsPerPage,
        page * 15,
        clientId,
        searchQuery
      );
      setAllSimulationData(response.data);
      setLoading(false);
      setDataCount(response.total);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  const fetchUploadStatusData = async () => {
    setLoading(true);
    try {
      const response = await simulationService.fetchUploadStatusData();
      setUploadedData(response.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  const deleteStatus = async (e) => {
    setLoading(true);
    try {
      const response = await simulationService.deleteUploadStatus(e);
      if (response) {
        setUploadedData(uploadedData?.filter((data) => data?._id !== e));
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSimulationData();
  }, [page, rowsPerPage, searchQuery]);
  useEffect(() => {
    fetchUploadStatusData();
  }, []);
  return (
    <DashboardContainer
      onSearchData={(x) => setSearchQuery(x)}
      searchBarPlaceholder={"Search by zip code..."}
      hideAddButton
      simulation
      onUploadSimulationData={(e) => handleUploadFile(e)}
      hideFilterClient
    >
      <div
        style={{
          overflowX: "auto",
          display: "flex",
          alignItems: "center",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::WebkitScrollbar": {
            width: "100%",
          },
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="small"
          style={{
            backgroundColor: "#007DFF",
            height: 40,
            marginRight: 10,
          }}
          variant="contained"
          onClick={() => {
            setShowUploadStatusModal(true);
            fetchUploadStatusData();
          }}
        >
          Show Upload Status
        </Button>
        {/* <TextField
          sx={{ width: "20%" }}
          size="small"
          label="Enter the zip code"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        /> */}
        {/* <Autocomplete
          getOptionLabel={(option) => option?.name}
          value={portFilter}
          onChange={handlePortFilterChange}
          options={port}
          renderInput={(params) => (
            <TextField {...params} label="Select Port" size="small" />
          )}
          style={{ minWidth: 200, margin: 10 }}
        /> */}
        {/* <div
          style={{
            display: "flex",
            marginRight: 8,
          }}
        >
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Sort</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedFilter}
              label="Sort"
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              <MenuItem sx={{ fontSize: 13 }} value="service_day">
                Asc. Price
              </MenuItem>
              <MenuItem sx={{ fontSize: 13 }} value="zone_number">
                Desc. Price
              </MenuItem>
            </Select>
          </FormControl>
        </div> */}
        {/* <Button
          sx={{ marginInline: 2, height: 40, backgroundColor: "#4E7EF8" }}
          variant="contained"
          color="primary"
          onClick={handleSearch}
        >
          Search
        </Button> */}
      </div>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <SimulationDataTable
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        page={page}
        data={allSimulationData}
        dataCount={dataCount}
      ></SimulationDataTable>
      <SimulationUploadModal
        onAddData={fetchSimulationData}
        allXlHeaders={allXlHeaders}
        selectedSimulationData={selectedSimulationData}
        onClose={() => setOpenUploadModal(false)}
        open={openUploadModal}
      ></SimulationUploadModal>
      <UploadStatusModal
        simulation
        onDeleteStatus={(e) => deleteStatus(e)}
        handleClose={() => setShowUploadStatusModal(false)}
        data={uploadedData}
        open={showUploadStatusModal}
      ></UploadStatusModal>
    </DashboardContainer>
  );
}
