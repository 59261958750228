// api.js

import axiosInstance from '../axiosInstance'

export const purchaseOrderService = {
  create: async (poData) => {
    try {
      let response = await axiosInstance.post('/purchase-order', poData)
      return response.data
    } catch (error) {
      throw error
    }
  },

 fetch: async (limit, skip, productId, clientId, searchValue) => {
  try {
    let response = await axiosInstance.get(
      `/purchase-order?$limit=${limit || 15}&$skip=${skip || 0}${productId ? `&product_id=${productId}` : ""}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
},

  edit: async (poData, id) => {
    try {
      let response = await axiosInstance.patch(`/purchase-order/${id}`, {
        ...poData
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/purchase-order/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
