import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CustomTypography from "../../globalStyles/fonts";
import { isMobile } from "../../globalStyles/mobile";

const PortLookupTable = ({ data, sortFilter, selectedFilter, sortOrder }) => {
  const handleSort = (field, order) => {
    if (sortFilter) {
      sortFilter(field, order);
    }
  };

  const getSortIcon = (field) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ArrowDropUpIcon
          fontSize="small"
          style={{
            color: "black",
            opacity: selectedFilter === field && sortOrder === "asc" ? 0.5 : 1,
            cursor: "pointer",
            marginBottom: -8,
          }}
          onClick={() => handleSort(field, "asc")}
        />
        <ArrowDropDownIcon
          fontSize="small"
          style={{
            color: "black",
            opacity: selectedFilter === field && sortOrder === "desc" ? 0.5 : 1,
            cursor: "pointer",
          }}
          onClick={() => handleSort(field, "desc")}
        />
      </Box>
    </div>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {
        
          data?.map((x, index) => (
            <Card key={index} sx={{ marginBottom: 2 }}>
              <CardContent>
                <div style={{ width: "100%" }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>Destination: {x?.destination}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>
                        State:{x?.state}
                     
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>
                        Port:{x?.port?.name}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>
                        Zone:{x?.zone?.name}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>
                        Service Day:{x?.service_day}
                      </Typography>
                    </Box>
                  </Box> 
                </div>
              </CardContent>
            </Card>
          ))
        }
      </Box>
    );
  };

  return (
    <div
      style={{
        height: "65vh",
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 100,
      }}
      className="table-container"
    >
      {isMobile() ? (
          <MobileCardView data={data} />
      ) : (
        <TableContainer
          sx={{
            maxHeight: "60vh",
            overflowY: "auto",
            paddingInline: 0.4,
          }}
        >
          <Table
            sx={{
              //minWidth: 900,
              borderCollapse: "separate",
              borderSpacing: "0 7px",
              paddingBottom: 7,
            }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              {/* <TableRow>
                {["Destination", "State", "Port", "Zone", "Service Day"].map(
                  (item) => (
                    <TableCell
                      key={item}
                      style={{
                        fontWeight: "bold",
                        color: "#6877C1",
                        fontSize: 15,
                        padding: 25,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {item}
                        {item === "Service Day" && (
                          <div style={{ marginLeft: 8 }}>
                            {getSortIcon("service_day")}
                          </div>
                        )}
                      </div>
                    </TableCell>
                  )
                )}
              </TableRow> */}
              <TableRow
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 4px 0px #00000040",
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    padding: 5,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Destination
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    State
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Port
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    textAlign: "center",
                    alignItems: "center",
                    padding: 5,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Zone
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderBottom: "none",
                    // textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Service Day
                  </CustomTypography>
                  {getSortIcon("service_day")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => (
                <TableRow
                  style={{
                    marginBottom: 10,
                    backgroundColor: "#fff",
                    borderRadius: 8,
                    boxShadow: "0px 0px 4px 0px #00000040",
                  }}
                  key={index}
                >
                  <TableCell
                    align="center"
                    style={{
                      borderBottom: "none",
                      borderRadius: "8px 0 0 8px",
                      cursor: "pointer",
                      textAlign: "center",
                      padding: 8,
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {" "}
                      {item.destination}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 8 }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {" "}
                      {item.state}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 8 }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {" "}
                      {item.port?.name}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 8 }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {" "}
                      {item.zone?.name}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                      padding: 8,
                      borderBottom: "none",
                      textAlign: "center",
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {" "}
                      {item.service_day}
                    </CustomTypography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default PortLookupTable;
