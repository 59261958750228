// api.js

import axiosInstance from "../axiosInstance";

export const shippingPriceService = {
  create: async (obj) => {
    try {
      let response = await axiosInstance.post("/shipping-price-map", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let query = `/shipping-price-map?$populate=client_id`;

      if (limit) query += `&$limit=${limit}`;
      if (skip) query += `&$skip=${skip}`;
      if (clientId) query += `&client_id=${clientId}`;
      //if (searchValue) query += `&type[$search]=${searchValue}`;

      let response = await axiosInstance.get(query);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  edit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(
        `/shipping-price-map/${id}`,
        obj
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/shipping-price-map/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
