import { SvgIcon } from '@mui/material'
import React, { useState } from 'react'

const Started = ({ fill }) => {
  return (
    <SvgIcon sx={{ height: 20, width: 20 }}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.8327 6.99996C12.8327 10.22 10.2193 12.8333 6.99935 12.8333C3.77935 12.8333 1.16602 10.22 1.16602 6.99996C1.16602 3.77996 3.77935 1.16663 6.99935 1.16663C10.2193 1.16663 12.8327 3.77996 12.8327 6.99996Z"
          stroke="#4B7BBD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.1632 8.85503L7.35487 7.77586C7.03987 7.58919 6.7832 7.14003 6.7832 6.77253V4.38086"
          stroke="#4B7BBD"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default Started
