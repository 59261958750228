// api.js

import axiosInstance from "../axiosInstance";

export const freightBrokerService = {
  create: async (obj) => {
    try {
      let response = await axiosInstance.post("/freight-broker", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/freight-broker?$limit=${limit || 15}&$skip=${skip || 0}&${
          searchValue ? `&name[$search]=${searchValue}` : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(`/freight-broker/${id}`, obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/freight-broker/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
