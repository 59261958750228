import React, { useState, useContext } from 'react'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import IconButton from '@mui/material/IconButton/IconButton'
import BorderColorIcon from '@mui/icons-material/BorderColor'

import {
  Box,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import AppContext from '../context/context'
import CustomTypography from '../../globalStyles/fonts'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import CarrierDetailsModal from '../carrierViewDetailsModal'
import { isMobile } from '../../globalStyles/mobile'
import { globalNetworkService } from '../../utils/services/globalNetworkService'
import GlobalNetworkDesignDetailsModal from '../global-network-design-details-modal'

const GlobalNetworkDesignList = ({ data, onEditData, onDeleteData, viewMode }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const [carrierName, setCarrierName] = useState('')
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user } = useContext(AppContext)
  const [openRow, setOpenRow] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)
  const client = localStorage.getItem('client_id')
  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index)
  }

  const handleEditClick = (network) => {
    onEditData(network)
  }

  const handleDeleteClick = (network) => {
    setSelectedNetwork(network)
    setOpenConfirmation(true)
  }
  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await globalNetworkService.delete(selectedNetwork._id)
      setAnchorEl(null)
      setOpenConfirmation(false)
      onDeleteData && onDeleteData()
    } catch (error) {
      console.error('Delete failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleViewClick = (network) => {
    setSelectedNetwork(network)
    setOpenViewModal(true)
  }
  const renderGridView = () => (
    <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
      {data?.map((network) => (
        <Grid item xs={12} sm={6} md={3} key={network.id}>
          <Card sx={{ boxShadow: 3, cursor: 'pointer' }}>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    alignItems: 'flex-start',
                    width: '60%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 15
                  }}
                >
                  <Tooltip title={network?.supplier_location} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography style={{ fontWeight: '500' }} variant="h7">
                        {network?.supplier_location}
                      </Typography>
                    </div>
                  </Tooltip>
                  <Tooltip title={network?.shipping_service} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography style={{ fontWeight: '500' }} variant="h7">
                        {network?.shipping_service}
                      </Typography>
                    </div>
                  </Tooltip>
                  <Tooltip title={network?.fulfillment_location} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography style={{ fontWeight: '500' }} variant="h7">
                        {network?.fulfillment_location}
                      </Typography>
                    </div>
                  </Tooltip>
                  <Tooltip title={network?.package_type} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography style={{ fontWeight: '500' }} variant="h7">
                        {network?.package_type}
                      </Typography>
                    </div>
                  </Tooltip>
                  <Tooltip title={network?.weight} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography style={{ fontWeight: '500' }} variant="h7">
                        {network?.weight}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>

                <div style={{ display: 'flex' }}>
                  <IconButton
                    style={{
                      color: '#FEC50F',
                      padding: 0,
                      background: '#FEC50F33',
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      marginLeft: 15,
                      boxShadow: '0px 0px 4px 0px #00000040',
                      marginTop: 5
                    }}
                    aria-label="edit"
                    onClick={() => handleEditClick(network)}
                  >
                    <BorderColorIcon sx={{ height: 20, width: 20 }} />
                  </IconButton>

                  <IconButton
                    style={{
                      color: '#D13438',
                      padding: 0,
                      background: '#D1343833',
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      marginLeft: 10,
                      boxShadow: '0px 0px 4px 0px #00000040',
                      marginTop: 5
                    }}
                    aria-label="delete"
                    onClick={() => handleDeleteClick(network)}
                  >
                    <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                  </IconButton>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: '100%', padding: 0.5 }}>
        {data?.map((network, index) => (
          <Card key={index} sx={{ marginBottom: 2 }}>
            <CardContent>
              <div style={{ width: '100%' }}>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Supplier Location: {network?.supplier_location}</Typography>
                    <Typography>Shipping Service: {network?.shipping_service}</Typography>
                    <Typography>Package Type: {network?.package_type}</Typography>
                    <Typography>Fulfillment Location: {network?.fulfillment_location}</Typography>
                    <Typography>Weight: {network?.weight}</Typography>
                  </Box>
                </Box>

                <Box mt={2} justifyContent={'flex-end'} display="flex">
                  <IconButton
                    style={{
                      color: '#007DFF',
                      padding: 0,
                      background: '#C3E1FF',
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      marginLeft: 10,
                      boxShadow: '0px 0px 4px 0px #00000040'
                    }}
                    aria-label="view"
                    onClick={() => {
                      handleViewClick(network)
                    }}
                  >
                    <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png"></img>
                  </IconButton>

                  <>
                    <IconButton
                      style={{
                        color: '#FEC50F',
                        padding: 0,
                        background: '#FEC50F33',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 15,
                        boxShadow: '0px 0px 4px 0px #00000040'
                        // marginTop: 5,
                      }}
                      aria-label="edit"
                      onClick={() => handleEditClick(network)}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton
                      style={{
                        color: '#D13438',
                        padding: 0,
                        background: '#D1343833',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040'
                        // marginTop: 5,
                      }}
                      aria-label="delete"
                      onClick={() => handleDeleteClick(network)}
                    >
                      <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                    </IconButton>
                  </>
                </Box>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>
    )
  }

  const renderListView = () => (
    <div
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        paddingBottom: 100
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          sx={{
            marginTop: 1,
            maxHeight: '70vh',
            overflowY: 'auto',
            paddingInline: 0.4
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: 'separate',
              borderSpacing: '0 5px',
              paddingBottom: 7
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  marginBottom: 10,
                  backgroundColor: '#fff',
                  borderRadius: 8,
                  boxShadow: '0px 0px 4px 0px #00000040'
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: 8,
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 10
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Supplier Location
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 5
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Fulfillment Location
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 5
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Shipping Service
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 5
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Package Type
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 5
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Weight
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 4,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    alignItems: 'center'
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Actions
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((network, index) => (
                <React.Fragment>
                  <TableRow
                    onClick={() => handleRowClick(index)}
                    key={index}
                    style={{
                      marginBottom: 10,
                      backgroundColor: '#fff',
                      borderRadius: 8,
                      boxShadow: '0px 0px 4px 0px #00000040'
                    }}
                  >
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        padding: 4
                      }}
                    >
                      <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                        {network.supplier_location}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                        {network.fulfillment_location}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                        {network.shipping_service}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                        {network.package_type}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                        {network.weight}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 4,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        alignItems: 'center'
                      }}
                    >
                      {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  > */}
                      <IconButton
                        style={{
                          color: '#007DFF',
                          padding: 0,
                          background: '#C3E1FF',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: '0px 0px 4px 0px #00000040'
                        }}
                        aria-label="view"
                        onClick={() => {
                          handleViewClick(network)
                        }}
                      >
                        <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png"></img>
                      </IconButton>

                      <>
                        <IconButton
                          style={{
                            color: '#FEC50F',
                            padding: 0,
                            background: '#FEC50F33',
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 15,
                            boxShadow: '0px 0px 4px 0px #00000040',
                            marginTop: 5
                          }}
                          aria-label="edit"
                          onClick={() => handleEditClick(network)}
                        >
                          <BorderColorIcon sx={{ height: 20, width: 20 }} />
                        </IconButton>
                        <IconButton
                          style={{
                            color: '#D13438',
                            padding: 0,
                            background: '#D1343833',
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 10,
                            boxShadow: '0px 0px 4px 0px #00000040',
                            marginTop: 5
                          }}
                          aria-label="delete"
                          onClick={() => handleDeleteClick(network)}
                        >
                          <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                        </IconButton>
                      </>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )

  return (
    <div sx={{ minWidth: '100%' }}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div
          style={{
            overflowY: 'scroll',
            display: 'flex',
            maxHeight: '70vh',
            width: '100%',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': { width: 0 }
          }}
        >
          {viewMode === 'grid' ? renderGridView() : renderListView()}
        </div>
      )}

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete this item ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          confirmDelete(e)
        }}
      />

      <GlobalNetworkDesignDetailsModal
        network={selectedNetwork}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
    </div>
  )
}

export default GlobalNetworkDesignList
