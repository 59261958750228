// api.js

import axiosInstance from '../axiosInstance'

export const eventService = {
  create: async (obj) => {
    try {
      let response = await axiosInstance.post('/rl_event', obj)
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/rl_event?&$limit=${limit || 15}&$skip=${skip || 0}&${clientId ? `client_id=${clientId}` : ''}&${
          searchValue ? `&code[$search]=${searchValue}` : ''
        }`
      )
      return response.data
    } catch (error) {
      throw error
    }
  },
  edit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(`/rl_event/${id}`, obj)
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/rl_event/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  },
  getEventLogs: async (orderId) => {
    try {
      let response = await axiosInstance.get(`/event-log?event_id=${orderId}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
