import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { eventService } from "../../utils/services/eventService";

const EventModal = ({ open, handleClose, onAddData, selectedEvent }) => {
  const [eventDetails, setEventDetails] = useState({
    leg: "",
    code: "",
    description: "",
  });

  const [optional, setOptional] = useState(null);
  const [systemGenerated, setSystemGenerated] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!eventDetails.leg) {
      errors.leg = "Leg is required";
    }
    if (!eventDetails.code) {
      errors.code = "Code is required";
    } else if (eventDetails.code < 0) {
      errors.code = "Code should be greater than 0";
    }
    if (!eventDetails.description) {
      errors.description = "Description is required";
    }
    if (!optional) {
      errors.optional = "Select the Optional message";
    }
    if (!systemGenerated) {
      errors.system_generated = "Select the Sysytem Generated Message";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (selectedEvent) {
      setEventDetails({
        leg: selectedEvent.leg || "",
        code: selectedEvent.code || "",
        description: selectedEvent.description || "",
      });
      setOptional(selectedEvent.optional ? "Yes" : "No");
      setSystemGenerated(selectedEvent.system_generated ? "Yes" : "No");
    } else {
      resetData();
    }
  }, [selectedEvent]);

  const resetData = () => {
    setEventDetails({ leg: "", code: "", description: "" });
    setOptional(null);
    setSystemGenerated(null);
    setFormErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleOptionalChange = (e) => {
    const value = e.target.value;
    setOptional(value);
  };

  const handleSystemGeneratedChange = (e) => {
    const value = e.target.value;
    setSystemGenerated(value);
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await eventService.edit(selectedEvent._id, {
        ...eventDetails,
        optional: optional === "Yes",
        system_generated: systemGenerated === "Yes",
      });
      onAddData && onAddData();
      toast.success("Event edited successfully!");
      resetData();
    } catch (error) {
      toast.error("Failed to edit event.");
      console.error(error);
    }
    handleClose();
  };
  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await eventService.create({
        ...eventDetails,
        optional: optional === "Yes",
        system_generated: systemGenerated === "Yes",
      });
      onAddData && onAddData();
      toast.success("Event created successfully!");
      resetData();
      handleClose();
    } catch (error) {
      toast.error("Failed to create event.");
      console.error(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        resetData();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle id="form-dialog-title" style={{ fontSize: 24 }}>
          {selectedEvent ? "Edit" : "Create"} Event
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography>
              Leg <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              size="small"
              name="leg"
              value={eventDetails.leg}
              onChange={handleInputChange}
              fullWidth
              placeholder="Enter Leg"
              margin="dense"
              error={!!formErrors.leg}
              helperText={formErrors.leg}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>
              Code <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              // type="number"
              size="small"
              name="code"
              value={eventDetails.code}
              onChange={handleInputChange}
              fullWidth
              placeholder="Enter Code"
              margin="dense"
              error={!!formErrors.code}
              helperText={formErrors.code}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <CustomTypography>
                Optional <span style={{ color: "red" }}>*</span>
              </CustomTypography>
              <Select
                margin="dense"
                size="small"
                value={optional || ""}
                onChange={handleOptionalChange}
                displayEmpty
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {formErrors.optional}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <CustomTypography>
                System Generated <span style={{ color: "red" }}>*</span>
              </CustomTypography>
              <Select
                margin="dense"
                size="small"
                value={systemGenerated || ""}
                onChange={handleSystemGeneratedChange}
                displayEmpty
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {formErrors.system_generated}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomTypography>
              Description <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              size="small"
              name="description"
              value={eventDetails.description}
              onChange={handleInputChange}
              fullWidth
              placeholder="Enter Description"
              margin="dense"
              error={!!formErrors.description}
              helperText={formErrors.description}
              multiline
              rows={6}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          onClick={() => {
            handleClose();
            resetData();
          }}
          color="primary"
          variant="outlined"
          className="cancel-button-style"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => (selectedEvent ? handleEditData(e) : handleSave(e))}
          color="primary"
          variant="contained"
          className="add-button-style"
          style={{ textTransform: "none" }}
        >
          {selectedEvent ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventModal;
