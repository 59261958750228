import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  styled,
  tableCellClasses,
  Button,
  Grid,
  CardContent,
  Card,
} from "@mui/material";
import { formatKey } from "../../utils";
import { isMobile } from "../../globalStyles/mobile-design";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    padding: "6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },
  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const MappingColumns = ({
  title = "Mapping Columns",
  description = "Use the dropdowns to adjust and ensure everything lines up.",
  rows = [],
  dropdownOptions = [],
  onCancel,
  onProceed,
  onSaveAndProceed,
  previewData,
  mappingColumns,
  setMappingColumns,
}) => {
  const addToMappingColumns = (key, column) => {
    const existingIndex = mappingColumns.findIndex((item) => item.key === key);

    if (existingIndex >= 0) {
      const updatedMappings = [...mappingColumns];
      updatedMappings[existingIndex] = {
        key: key,
        column: column,
      };
      setMappingColumns(updatedMappings);
    } else {
      setMappingColumns([
        ...mappingColumns,
        {
          key: key,
          column: column,
        },
      ]);
    }
  };

  const MobileCardView = ({ data }) => {
    return (
      <Box
        sx={{
          width: "100%",
          padding: 0.5,
          maxHeight: "45vh",
          overflowY: "scroll",
        }}
      >
        {data?.map((row, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: 2,
              backgroundColor: "#ffff",
              borderRadius: 4,
              position: "relative",
            }}
          >
            <CardContent>
              <Box display="flex" flexDirection="column">
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Import to RL
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      : {formatKey(row)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Column Heading
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      size="small"
                      fullWidth
                      defaultValue={row.defaultOption || ""}
                      onChange={(e) => addToMappingColumns(row, e.target.value)}
                      value={
                        mappingColumns.find((x) => x.key === row)?.column || ""
                      }
                    >
                      <MenuItem value="">--</MenuItem>
                      {dropdownOptions.map((option, idx) => (
                        <MenuItem key={option + " " + idx} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="poppins"
                      color="#333333"
                      fontWeight={600}
                      textAlign="left"
                    >
                      Preview
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={14}
                      fontFamily="Poppins"
                      color="#606060"
                      fontWeight={500}
                      noWrap
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      :{" "}
                      {previewData[
                        mappingColumns.find((x) => x.key === row)?.column
                      ] || "--"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: 2,
      }}
    >
      <Box>
        <Typography
          fontFamily="poppins"
          fontWeight={500}
          fontSize={"16px"}
          color="#333333"
        >
          {title}
        </Typography>
        <Typography
          fontFamily="poppins"
          fontWeight={500}
          fontSize="14px"
          color="#757575"
        >
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          // overflowY: "scroll",
        }}
      >
        {isMobile() ? (
          <MobileCardView data={rows} />
        ) : (
          <TableContainer sx={{ maxHeight: "60vh", overflowY: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Import to RL</StyledTableCell>
                  <StyledTableCell>Column Heading</StyledTableCell>
                  <StyledTableCell>Preview</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{formatKey(row)}</StyledTableCell>
                    <StyledTableCell>
                      <Select
                        fullWidth
                        size="small"
                        value={
                          mappingColumns.find((x) => x.key === row)?.column ||
                          ""
                        }
                        onChange={(e) =>
                          addToMappingColumns(row, e.target.value)
                        }
                      >
                        <MenuItem value="">--</MenuItem>
                        {dropdownOptions.map((option, idx) => (
                          <MenuItem key={idx} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledTableCell>
                    <StyledTableCell>
                      {previewData[
                        mappingColumns.find((x) => x.key === row)?.column
                      ] || "--"}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{ textTransform: "none", border: "1px solid #A0AEC0" }}
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          sx={{ textTransform: "none", backgroundColor: "#DC585C" }}
          onClick={onSaveAndProceed}
        >
          Save & Proceed
        </Button>
      </Box>
    </Box>
  );
};

export default MappingColumns;
