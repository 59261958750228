import { SvgIcon } from "@mui/material";
import React, { useState } from "react";

const PackageMappingIcon = ({ fill }) => {
  return (
    <SvgIcon>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="512.000000pt"
        height="512.000000pt"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill={fill}
          stroke="none"
        >
          <path
            d="M604 4955 c-101 -22 -191 -72 -269 -150 -115 -114 -165 -234 -165
-395 0 -149 44 -260 142 -365 115 -124 238 -178 403 -179 115 0 172 15 275 70
l68 37 203 -183 c112 -101 215 -193 227 -205 l24 -22 -64 -76 c-173 -209 -276
-470 -293 -747 -4 -52 -2 -126 3 -165 5 -41 6 -72 0 -76 -5 -3 -79 -27 -164
-54 l-156 -49 -21 29 c-97 138 -251 194 -403 146 -218 -67 -322 -300 -226
-505 112 -237 419 -285 598 -94 64 69 97 145 98 229 l1 67 149 47 c81 26 152
43 156 38 3 -4 12 -28 19 -53 44 -151 163 -355 287 -489 124 -133 306 -258
469 -322 33 -13 63 -26 67 -29 4 -3 -2 -79 -14 -168 l-22 -162 -50 0 c-296 -1
-531 -272 -487 -561 44 -282 326 -473 596 -404 186 48 328 193 366 373 16 77
6 203 -21 274 -39 104 -131 206 -244 273 -16 9 -16 20 5 168 l22 158 86 -8
c425 -39 789 86 1071 367 199 200 323 449 367 740 9 58 17 106 18 108 2 3 257
-25 311 -33 27 -5 33 -11 39 -42 21 -113 122 -233 244 -292 74 -35 79 -36 192
-36 113 0 117 1 190 37 93 46 168 122 212 215 30 64 32 74 32 183 0 111 -1
118 -34 185 -45 92 -128 175 -218 218 -64 30 -74 32 -184 32 -113 0 -117 -1
-190 -37 -117 -58 -209 -167 -252 -299 -4 -13 -21 -13 -163 3 -88 9 -166 17
-175 18 -12 0 -18 20 -27 89 -29 214 -122 435 -256 609 -67 88 -212 222 -303
282 -71 46 -81 55 -70 69 6 9 65 80 131 159 87 104 122 140 130 132 23 -18
140 -57 193 -64 161 -20 347 70 436 211 90 142 91 346 4 495 -14 24 -50 67
-81 96 -136 129 -329 156 -502 71 -112 -55 -212 -178 -244 -299 -28 -105 -3
-268 55 -352 14 -20 25 -39 25 -42 0 -5 -247 -314 -274 -343 -9 -10 -26 -7
-78 12 -89 32 -240 62 -355 71 -89 7 -92 6 -117 -19 -32 -32 -33 -68 -3 -100
19 -21 36 -25 142 -35 261 -25 466 -112 663 -281 153 -131 291 -356 349 -566
26 -93 28 -113 27 -285 0 -168 -2 -194 -27 -283 -91 -338 -337 -625 -648 -758
-406 -174 -863 -104 -1199 183 -194 167 -322 383 -376 638 -23 111 -23 334 0
438 78 353 278 618 597 795 37 20 70 44 73 53 21 53 -12 104 -67 104 -35 0
-126 -49 -233 -125 -34 -25 -65 -45 -68 -45 -5 0 -347 304 -438 389 -17 15
-16 21 22 92 70 135 86 254 50 393 -26 103 -69 177 -149 256 -134 132 -320
186 -507 145z m198 -146 c319 -68 427 -467 186 -685 -22 -20 -70 -50 -107 -68
-60 -28 -75 -31 -166 -31 -92 0 -106 3 -167 32 -84 39 -155 109 -194 188 -27
55 -29 69 -29 170 0 101 2 115 29 170 34 69 114 153 174 184 91 46 181 59 274
40z m2923 -23 c108 -53 169 -153 170 -276 0 -62 -5 -85 -28 -132 -54 -110
-154 -172 -277 -172 -95 -1 -168 32 -234 105 -101 112 -103 281 -5 393 99 113
245 145 374 82z m920 -1870 c61 -29 117 -84 147 -145 33 -68 31 -178 -5 -247
-56 -110 -164 -171 -287 -162 -157 12 -272 133 -273 288 0 116 57 205 165 260
80 40 177 42 253 6z m-4061 -487 c60 -17 123 -81 142 -147 61 -210 -186 -366
-350 -221 -174 153 -17 431 208 368z m1504 -1479 c34 -15 71 -43 107 -82 94
-101 119 -243 66 -364 -28 -62 -104 -139 -170 -171 -48 -24 -68 -28 -141 -28
-72 1 -94 5 -141 28 -67 32 -141 108 -171 174 -30 68 -31 199 -1 265 25 54 90
128 141 160 85 52 213 60 310 18z"
          />
          <path
            d="M2073 3436 c-189 -88 -351 -169 -360 -179 -17 -16 -18 -58 -21 -592
-2 -490 0 -579 12 -604 13 -24 43 -39 248 -116 128 -48 288 -109 356 -136 97
-37 129 -46 150 -39 15 5 176 65 357 134 214 82 336 133 348 147 16 20 17 63
17 595 0 392 -3 581 -11 597 -12 27 -2 22 -415 213 -171 79 -318 143 -325 143
-8 -1 -168 -74 -356 -163z m603 -112 c126 -58 232 -109 236 -112 4 -4 -101
-58 -233 -119 l-241 -112 -239 111 c-132 61 -236 115 -231 120 10 10 454 216
468 217 6 1 114 -47 240 -105z m-570 -347 l249 -113 3 -456 2 -456 -257 97
-258 97 -3 472 c-1 263 2 472 6 472 5 0 121 -51 258 -113z m924 -360 l0 -473
-256 -96 c-166 -63 -257 -92 -260 -85 -2 7 -3 211 -2 455 l3 442 250 115 c138
63 253 114 258 115 4 0 7 -213 7 -473z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default PackageMappingIcon;
