import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Modal, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LocationList from "../../components/location-list";
import { locationService } from "../../services/location-service";

const Location = () => {
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchLocations = async () => {
    try {
      const response = await locationService.fetch(
        rowsPerPage,
        rowsPerPage * page
      );
      setLocations(response.data);
      setCount(response.total);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [rowsPerPage, page]);

  const AddLocationModal = () => {
    const [name, setName] = useState("");

    const handleSubmit = async () => {
      try {
        await locationService.create({ name });
        fetchLocations();
        setOpen(false);
        setName("");
      } catch (error) {
        console.error(error);
      }
    };

    const handleClose = () => {
      setOpen(false);
      setName("");
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-location-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Add Location
          </Typography>
          <TextField
            fullWidth
            label="Location Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
          />
          <Box
            sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{ backgroundColor: "#DC585C" }}
              disabled={!name.trim()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{ textTransform: "none", backgroundColor: "#DC585C" }}
          onClick={() => setOpen(true)}
        >
          <Typography
            fontFamily={"poppins"}
            fontSize={"14px"}
            fontWeight={500}
            color="#fff"
          >
            Add
          </Typography>
        </Button>
      </div>
      <LocationList
        data={locations}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <AddLocationModal />
    </>
  );
};

export default Location;
