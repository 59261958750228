// productService.js

import axiosInstance from "../utils/axios-instance";

export const orderService = {
  create: async (productData) => {
    try {
      let response = await axiosInstance.post("/order", productData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetch: async (limit, skip) => {
    try {
      const response = await axiosInstance.get(
        `/order?$sort[createdAt]=-1&$limit=${limit || 10}&$skip=${
          skip || 0
        }&$populate=client_id`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/order/${id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/order/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  bulkUpload: async (data) => {
    try {
      let response = await axiosInstance.post("/order-bulk-upload", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getUploadConfig: async (limit, skip) => {
    try {
      let response = await axiosInstance.get(
        `/bulk-upload-config?$limit=${limit || 15}&$skip=${
          skip || 0
        }&$populate=upload_status`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  findConfigById: async (id) => {
    try {
      let response = await axiosInstance.get(`/bulk-upload-config/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  uploadConfig: async (data, id) => {
    try {
      let response = await axiosInstance.patch(
        `/bulk-upload-config/${id}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  bulkUploadResult: async (id) => {
    try {
      let response = await axiosInstance.get(
        `/bulk-upload-results?status_id=${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  downloadSample: async () => {
    try {
      let response = await axiosInstance.post(`/sample-template?type=order`, {
        dataType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
