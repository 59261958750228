import React from 'react'
import { Container, Typography, TextField, Button } from '@mui/material'

const UserProfile = () => {
  const user = {
    name: 'John Doe',
    email: 'john.doe@example.com',
    phone: '123-456-7890'
  }

  const handleSave = () => {
    alert('Profile saved!')
  }

  return (
    <Container>
      <Typography variant="h4">User Profile</Typography>
      <TextField label="Name" fullWidth margin="normal" defaultValue={user.name} />
      <TextField label="Email" fullWidth margin="normal" defaultValue={user.email} />
      <TextField label="Phone" fullWidth margin="normal" defaultValue={user.phone} />
      <Button variant="contained" onClick={handleSave}>
        Save
      </Button>
    </Container>
  )
}

export default UserProfile
