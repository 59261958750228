import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography
} from '@mui/material'

const AddEditCustomer = ({ open, handleClose, customerData, setCustomerData, handleSubmit, isEdit }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isEdit ? 'Edit Customer' : 'Add Customer'}</DialogTitle>
      <DialogContent sx={{ maxHeight: 400, overflowY: 'auto' }}>
        <TextField
          size="small"
          fullWidth
          label="First Name"
          value={customerData.first_name}
          onChange={(e) => setCustomerData({ ...customerData, first_name: e.target.value })}
          margin="normal"
        />
        <TextField
          size="small"
          fullWidth
          label="Last Name"
          value={customerData.last_name}
          onChange={(e) => setCustomerData({ ...customerData, last_name: e.target.value })}
          margin="normal"
        />
        <TextField
          size="small"
          fullWidth
          label="Email"
          value={customerData.email}
          onChange={(e) => setCustomerData({ ...customerData, email: e.target.value })}
          margin="normal"
        />
        <TextField
          size="small"
          fullWidth
          label="Phone"
          value={customerData.phone}
          onChange={(e) => setCustomerData({ ...customerData, phone: e.target.value })}
          margin="normal"
        />
        <Typography variant="subtitle2" component="div" sx={{ mt: 2 }}>
          Address
        </Typography>
        <TextField
          size="small"
          fullWidth
          label="Address Line 1"
          value={customerData.address.address1}
          onChange={(e) =>
            setCustomerData({
              ...customerData,
              address: { ...customerData.address, address1: e.target.value }
            })
          }
          margin="normal"
        />
        <TextField
          size="small"
          fullWidth
          label="Address Line 2"
          value={customerData.address.address2}
          onChange={(e) =>
            setCustomerData({
              ...customerData,
              address: { ...customerData.address, address2: e.target.value }
            })
          }
          margin="normal"
        />
        <TextField
          size="small"
          fullWidth
          label="City"
          value={customerData.address.city}
          onChange={(e) =>
            setCustomerData({ ...customerData, address: { ...customerData.address, city: e.target.value } })
          }
          margin="normal"
        />
        <TextField
          size="small"
          fullWidth
          label="Zip"
          value={customerData.address.zip}
          onChange={(e) =>
            setCustomerData({ ...customerData, address: { ...customerData.address, zip: e.target.value } })
          }
          margin="normal"
        />
        <TextField
          size="small"
          fullWidth
          label="Province"
          value={customerData.address.province}
          onChange={(e) =>
            setCustomerData({
              ...customerData,
              address: { ...customerData.address, province: e.target.value }
            })
          }
          margin="normal"
        />
        <TextField
          size="small"
          fullWidth
          label="Country"
          value={customerData.address.country}
          onChange={(e) =>
            setCustomerData({
              ...customerData,
              address: { ...customerData.address, country: e.target.value }
            })
          }
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ backgroundColor: '#DC585C' }}
          disabled={
            !customerData.first_name.trim() || !customerData.last_name.trim() || !customerData.email.trim()
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddEditCustomer
