import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from '@mui/material'
import { overPackService } from '../../utils/services/overPackService'
import { manifestService } from '../../utils/services/manifestService'

const OverpackGroupModal = ({ open, setOpen, allOverpacks, onSuccessManifestCreation }) => {
  const [selectedOverPack, setSelectedOverpacks] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [overpacks, setOverpacks] = useState([])
  const [selectedOverpacksForManifest, setSelectedOverPacksForManifest] = useState([])
  const createManifest = async (data) => {
    const manifestData = {
      manifest: {
        carrier: '',
        overpacks: selectedOverpacksForManifest.map((manifest) => manifest?.id)
      }
    }
    try {
      const response = await manifestService.create(manifestData)
      console.log(response)
      if (response.id) {
        onSuccessManifestCreation && onSuccessManifestCreation(response.id)
      }
    } catch (error) {
      console.error('fetch orders failed:', error)
    }
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target
    setSelectedOverpacks((prev) =>
      checked ? [...prev, value] : prev.filter((shipment) => shipment !== value)
    )

    let selectedData = filteredOverpacks?.find((x) => x.id == value)
    setSelectedOverPacksForManifest(
      selectedOverpacksForManifest?.find((x) => x.id.toString() === value.toString())
        ? selectedOverpacksForManifest?.filter((z) => z.id != value)
        : [...selectedOverpacksForManifest, selectedData]
    )
  }

  const filteredOverpacks = overpacks.filter((shipment) =>
    shipment.name.toString().includes(searchQuery.toLowerCase())
  )
  useEffect(() => {
    if (allOverpacks?.length) {
      setOverpacks(
        allOverpacks.map((x) => ({
          ...x,
          name: x.id
        }))
      )
    }
  }, [allOverpacks])
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Overpack</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            label="Search Overpack"
            variant="outlined"
            fullWidth
            margin="dense"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FormControl component="fieldset" fullWidth>
            <List
              className="custom-scrollbar"
              style={{ maxHeight: '350px', overflowY: 'auto', minWidth: 350 }}
            >
              {filteredOverpacks?.map((pack) => (
                <div key={pack.id}>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={pack.name}
                          checked={selectedOverPack.indexOf(pack.id.toString()) > -1}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={<ListItemText primary={pack.name} />}
                    />
                  </ListItem>
                  <List component="div" disablePadding>
                    <Typography sx={{ marginLeft: 5 }}>Total Shipment : {pack?.total_shipments}</Typography>
                  </List>
                </div>
              ))}
            </List>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: 'red', margin: 1 }} onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: '#007DFF', color: '#FFF', margin: 1 }}
            onClick={createManifest}
            color="primary"
          >
            Create Manifest
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default OverpackGroupModal
