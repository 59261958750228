// api.js

import axiosInstance from "../axiosInstance";

export const zoneService = {
  create: async (name, zoneNumber, clientId) => {
    try {
      let response = await axiosInstance.post("/zone", {
        name: name,
        zone_number: Number(zoneNumber),
        client_id: clientId,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, clientId, text) => {
    try {
      let response = await axiosInstance.get(
        `/zone?$limit=${limit || 15}&$skip=${skip || 0}&${
          clientId ? `client_id=${clientId}` : ""
        }&${text ? `&name[$search]=${text}` : ""}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (name, zoneNumber,  clientId,id) => {
    try {
      let response = await axiosInstance.patch(`/zone/${id}`, {
        name: name,
        zone_number: Number(zoneNumber),
        client_id: clientId,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/zone/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
