import React from 'react'
import './cart.css'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'

const CartScreen = ({ onCheckout, onContinueShopping }) => {
  // Demo cart items
  const navigate = useNavigate()

  const [cartItems, setCartItems] = React.useState([
    {
      id: 'SKU001',
      name: 'Premium Coffee Maker',
      price: 129.99,
      count: 1,
      image: '/api/placeholder/150/150',
      description: 'Programmable 12-cup brewing system'
    },
    {
      id: 'SKU002',
      name: 'Wireless Earbuds',
      price: 89.99,
      count: 2,
      image: '/api/placeholder/150/150',
      description: 'Active noise cancellation, 24hr battery life'
    },
    ,
    {
      id: 'SKU003',
      name: 'Smart Watch Series 5',
      price: 299.99,
      count: 1,
      image: '/api/placeholder/150/150',
      description: 'Health monitoring, GPS, waterproof'
    },
    {
      id: 'SKU004',
      name: 'Smart Watch Series 5',
      price: 299.99,
      count: 1,
      image: '/api/placeholder/150/150',
      description: 'Health monitoring, GPS, waterproof'
    }
  ])

  const updateItemCount = (itemId, increment) => {
    setCartItems((items) =>
      items
        .map((item) => (item.id === itemId ? { ...item, count: Math.max(0, item.count + increment) } : item))
        .filter((item) => item.count > 0)
    )
  }

  const removeItem = (itemId) => {
    setCartItems((items) => items.filter((item) => item.id !== itemId))
  }

  const totalItems = cartItems.reduce((total, item) => total + item.count, 0)
  const subtotal = cartItems.reduce((total, item) => total + item.price * item.count, 0)
  const shipping = subtotal > 100 ? 0 : 10
  const total = subtotal + shipping
  const cartContainerStyle = {
    padding: '20px',
    fontFamily: 'Arial, sans-serif'
  }

  const cartItemsStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '82vh',
    overflowY: 'auto',
    border: '1px solid #ddd',
    paddingLeft: '10px',
    paddingTop: '10px',
    borderRadius: '5px'
  }

  const cartItemStyle = {
    display: 'flex',
    marginBottom: '15px',
    borderBottom: '1px solid #ccc',
    paddingBottom: '10px'
  }

  return (
    <div className="cart-container">
      <div className="cart-title">Shopping Cart</div>

      {cartItems.length === 0 ? (
        <div className="empty-cart">
          <p>Your cart is empty</p>
          <button onClick={onContinueShopping} className="continue-shopping-btn">
            Continue Shopping
          </button>
        </div>
      ) : (
        <div className="cart-grid">
          <div style={cartItemsStyle}>
            {cartItems.map((item) => (
              <div key={item.id} style={cartItemStyle}>
                <img src={item.image} alt={item.name} style={{ width: '100px', marginRight: '15px' }} />
                <div style={{ flex: 1 }}>
                  <h4>{item.name}</h4>
                  <p>{item.description}</p>
                  <p>Price: ${item.price.toFixed(2)}</p>
                  <div>
                    <button onClick={() => updateItemCount(item.id, -1)}>-</button>
                    <span style={{ margin: '0 10px' }}>{item.count}</span>
                    <button onClick={() => updateItemCount(item.id, 1)}>+</button>
                  </div>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <button
                      onClick={() => removeItem(item.id)}
                      style={{ marginTop: '10px', color: 'red', marginRight: 15 }}
                    >
                      <DeleteIcon></DeleteIcon>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="order-summary">
            <div className="summary-card">
              <h3 className="summary-title">Order Summary</h3>
              <div className="summary-content">
                <div className="summary-row">
                  <span>Subtotal ({totalItems} items)</span>
                  <span>${subtotal.toFixed(2)}</span>
                </div>
                <div className="summary-row">
                  <span>Shipping</span>
                  <span>{shipping === 0 ? 'Free' : `$${shipping.toFixed(2)}`}</span>
                </div>
                <div className="summary-row total">
                  <span>Total</span>
                  <span>${total.toFixed(2)}</span>
                </div>
                {shipping > 0 && (
                  <p className="shipping-notice">Add ${(100 - subtotal).toFixed(2)} more for free shipping</p>
                )}
                <button
                  className="checkout-btn"
                  onClick={() => navigate('/checkout', { state: { cartItems, total } })}
                  disabled={totalItems === 0}
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CartScreen
