import React from 'react'
import { Card, CardContent, Typography, Grid, Box } from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'

const GradientCard = ({ backgroundColor, smallRoundBoxBg, minWidth, cardTitle, cardTitleCount }) => {
  return (
    <Card
      sx={{
        minWidth: minWidth || 300,
        padding: 1,
        backgroundColor: backgroundColor || '#FFF7E6',
        borderRadius: '16px',
        position: 'relative',
        minHeight: 170,
        margin: 1
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: -30,
          right: -10,
          width: '80px',
          height: '80px',
          backgroundColor: smallRoundBoxBg || '#FFE58F',
          borderRadius: '100%'
        }}
      />
      <CardContent>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <CustomTypography fontSize={19} sx={{ fontWeight: '600', color: '#151D48', textAlign: 'left' }}>
            {cardTitleCount ?? 1200}
          </CustomTypography>
          <CustomTypography sx={{ color: '#151D48', textAlign: 'left', fontSize: 16, fontWeight: 500 }}>
            {cardTitle ?? 'Total Orders (this month)'}
          </CustomTypography>
        </div>

        <Grid container spacing={2} sx={{ marginTop: 3 }}>
          <Grid item xs={4} sx={{ textAlign: 'left' }}>
            <CustomTypography fontSize={14} sx={{ fontWeight: '500', color: '#212121' }}>
              200
            </CustomTypography>
            <CustomTypography fontSize={13} sx={{ color: '#666' }}>
              Today order
            </CustomTypography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'left' }}>
            <CustomTypography fontSize={14} sx={{ fontWeight: '500', color: '#212121' }}>
              140
            </CustomTypography>
            <CustomTypography fontSize={13} sx={{ color: '#666' }}>
              Accept
            </CustomTypography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'left' }}>
            <CustomTypography fontSize={14} sx={{ fontWeight: '500', color: '#212121' }}>
              Various
            </CustomTypography>
            <CustomTypography fontSize={13} sx={{ color: '#666' }}>
              Location
            </CustomTypography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default GradientCard
