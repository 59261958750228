import React from "react";
import { Box, Button, Typography, Card, CardContent } from "@mui/material";
import { isMobile } from "../../globalStyles/mobile-design";

const AddImportCard = ({
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  onClickAdd,
  onClickImport,
  showImages = false,
  images = [],
  PrimaryIcon,
  SecondaryIcon,
}) => {
  return (
    <>
      <Card
        sx={{
          border: "1px solid #D9D9D9",
          backgroundColor: "#ffff",
          padding: 1,
          borderRadius: "8px",
          minHeight: 200,
        }}
      >
        <CardContent>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {showImages && isMobile() && (
              <Box
                sx={{
                  display: "flex",
                  //flexWrap: "wrap",
                  gap: 2,
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {images[0] && (
                  <Box
                    component="img"
                    src={images[0]}
                    alt="Image 1"
                    sx={{
                      width: { xs: "110px", sm: "120px", md: "230px" }, // Smaller width on mobile
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {images.slice(1).map((img, index) => (
                    <Box
                      key={index}
                      component="img"
                      src={img}
                      alt={`Image ${index + 2}`}
                      sx={{
                        width: { xs: "50px", sm: "100px", md: "100px" }, // Even smaller for additional images on mobile
                        height: "auto",
                        objectFit: "contain",
                        borderRadius: "8px",
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: 500,
                  fontSize: { sm: "18px", md: "24px", xs: "15px" },
                  color: "#333333",
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: 400,
                  fontSize: { sm: "14px", md: "16px", xs: "12px" },
                  color: "#333333",
                }}
              >
                {description}
              </Typography>
              <Box sx={{ display: "flex", gap: 2, mt: isMobile() ? 1 : 5 }}>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#DC585C",
                  }}
                  onClick={onClickAdd}
                >
                  {PrimaryIcon && <PrimaryIcon />}
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: 500,
                      fontSize: { sm: "12px", md: "14px", xs: "12px" },
                      color: "#ffff",
                    }}
                  >
                    {primaryButtonText}
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "#DC585C",
                    border: "1px solid #DC585C",
                  }}
                  onClick={onClickImport}
                >
                  {SecondaryIcon && <SecondaryIcon />}
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: 500,
                      fontSize: { sm: "12px", md: "14px", xs: "12px" },
                      color: "#DC585C",
                    }}
                  >
                    {secondaryButtonText}
                  </Typography>
                </Button>
              </Box>
            </Box>
            {showImages && !isMobile() && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                {images[0] && (
                  <Box
                    component="img"
                    src={images[0]}
                    alt="Image 1"
                    sx={{
                      width: { xs: "90px", sm: "120px", md: "230px" }, // Smaller width on mobile
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {images.slice(1).map((img, index) => (
                    <Box
                      key={index}
                      component="img"
                      src={img}
                      alt={`Image ${index + 2}`}
                      sx={{
                        width: { xs: "50px", sm: "100px", md: "100px" }, // Even smaller for additional images on mobile
                        height: "auto",
                        objectFit: "contain",
                        borderRadius: "8px",
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default AddImportCard;
