import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { webhookService } from "../../services/webhook-service";
import WebhookList from "../../components/webhook-list";

const Webhook = () => {
  const [webhooks, setWebhooks] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchWebhooks = async () => {
    try {
      const response = await webhookService.fetch(
        rowsPerPage,
        rowsPerPage * page
      );
      setWebhooks(response.data);
      setCount(response.total);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchWebhooks();
  }, [rowsPerPage, page]);
  return (
    <WebhookList
      data={webhooks}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
};

export default Webhook;
