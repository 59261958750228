// api.js

import axios from "axios";
import config from "../../config";
export const overPackService = {
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axios.get(`${config.tmsBaseURL}/overpacks`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  create: async (data, limit, skip, clientId, searchValue) => {
    try {
      let response = await axios.post(`${config.tmsBaseURL}/overpacks`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
