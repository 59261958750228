import { SvgIcon } from "@mui/material";
import React, { useState } from "react";

const OrderFooterIcon = ({ fill }) => {
  return (
    <SvgIcon>
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.3698 2.92383H8.61572V0.923828H12.6157H13.6157V1.92383V5.92383H11.6157V4.50638L8.31796 7.80414L7.61086 8.51124L6.90375 7.80414L4.79451 5.6949L1.41422 9.07521L0 7.661L4.0874 3.57358L4.79451 2.86647L5.50162 3.57358L7.61086 5.68282L10.3698 2.92383Z"
          fill="#28A745"
        />
      </svg>
    </SvgIcon>
  );
};

export default OrderFooterIcon;
