import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Box } from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'

const ShipmentDetails = ({ Shipment }) => {
  if (!Shipment) return null

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 1,
        p: 2,
        m: 'auto',
        border: '1px solid #E0E0E0',
        borderBottom: 'none' // Example adjustment to Box component
      }}
    >
      <CustomTypography
        variant="h6"
        sx={{
          backgroundColor: '#E0F7FA',
          padding: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <CustomTypography>Shipment ID: #{Shipment.shipmentId}</CustomTypography>
        <CustomTypography>SHIPMENT STATUS</CustomTypography>
        <CustomTypography></CustomTypography>
      </CustomTypography>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#F5F5F5', fontWeight: 'bold', borderButtom: 'none' }}>
                <CustomTypography fontSize={13}>Carrier Name:</CustomTypography>
              </TableCell>
              {/* Example adjustment to TableCell */}
              <TableCell sx={{ backgroundColor: '#F5F5F5', fontWeight: 'bold', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Shipment.carrierName}</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Date:</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Shipment.date}</CustomTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Origin:</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Shipment.origin}</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Destination:</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Shipment.destination}</CustomTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Status:</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Shipment.status}</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Current Location:</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Shipment.currentLocation}</CustomTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Last Update:</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Shipment.lastUpdate}</CustomTypography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ShipmentDetails
