import React, { useEffect, useState } from 'react'
import { Modal, Box, Typography, TextField, Button } from '@mui/material'
import CountryAutocomplete from '../country-autocomplete'

const AddEditProvince = ({ open, setOpen, handleSubmit, selectedData, handleCloseModal }) => {
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    country: ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const onSubmit = () => {
    handleSubmit(selectedData ? { ...formData, selectedData: selectedData?._id } : formData)
    setFormData({ name: '', code: '', country: '' }) // Reset form after submission
  }

  const handleClose = () => {
    setOpen(false)
    setFormData({ name: '', code: '', country: '' })
    handleCloseModal && handleCloseModal()
  }
  useEffect(() => {
    if (selectedData) {
      setFormData({
        name: selectedData.name,
        code: selectedData.code,
        country: selectedData.country
      })
    }
  }, [selectedData])
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="add-country-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Add Province
        </Typography>

        <TextField
          size="small"
          fullWidth
          label="Province Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          size="small"
          fullWidth
          label="Code"
          name="code"
          value={formData.code}
          onChange={handleChange}
          margin="normal"
        />
        <CountryAutocomplete
          selectedCountry={formData.country}
          style={{ marginTop: 10 }}
          onCountryChange={(e) => setFormData({ ...formData, country: e })}
        ></CountryAutocomplete>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            sx={{ backgroundColor: '#DC585C' }}
            disabled={!formData?.name?.trim() || !formData?.code?.trim()}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddEditProvince
