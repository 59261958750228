export const weightUnits = [
  { name: 'milligram', abbreviation: 'mg', factor: 0.001 },
  { name: 'centigram', abbreviation: 'cg', factor: 0.01 },
  { name: 'decigram', abbreviation: 'dg', factor: 0.1 },
  { name: 'gram', abbreviation: 'g', factor: 1 },
  { name: 'dekagram', abbreviation: 'dag', factor: 10 },
  { name: 'hectogram', abbreviation: 'hg', factor: 100 },
  { name: 'kilogram', abbreviation: 'kg', factor: 1000 },
  { name: 'metric ton', abbreviation: 't', factor: 1000000 },
  { name: 'ounce', abbreviation: 'oz', factor: 28.3495 },
  { name: 'pound', abbreviation: 'lb', factor: 453.592 },
  { name: 'stone', abbreviation: 'st', factor: 6350.29 },
  { name: 'short ton', abbreviation: 'ton', factor: 907184.74 }, // US ton
  { name: 'long ton', abbreviation: 'ton', factor: 1016046.91 } // UK ton
]

export const convertWeight = (fromUnit, toUnit, value) => {
  // Find the factors for the two units
  const fromUnitData = weightUnits.find((unit) => unit.abbreviation === fromUnit)
  const toUnitData = weightUnits.find((unit) => unit.abbreviation === toUnit)

  if (!fromUnitData || !toUnitData) {
    throw new Error('Invalid unit abbreviation')
  }

  // Convert the value to grams first, then convert to the target unit
  const valueInGrams = value * fromUnitData.factor
  const convertedValue = valueInGrams / toUnitData.factor

  return convertedValue
}

// Example usage:
// const result = convertWeight('hg', 'kg', 10) // Converts 10 hectograms to kilograms
// console.log(result) // Output: 1
