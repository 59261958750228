// api.js

import axiosInstance from "../axiosInstance";

export const simulationService = {
  fetchHeaders: async (data) => {
    try {
      let response = await axiosInstance.patch("/simulation-upload", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addSimulationData: async (data) => {
    try {
      let response = await axiosInstance.post("/simulation-upload", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getSimulationData: async (limit, skip, clientId, searchQuery) => {
    try {
      let response = await axiosInstance.get(
        `/simulation?$limit=${limit || 15}&$skip=${skip || 0}${
          clientId ? `&client_id=${clientId}` : ""
        }${searchQuery ? `&zipcode=${searchQuery}` : ""}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchUploadStatusData: async (clientId) => {
    try {
      let response = await axiosInstance.get(
        `/upload_status?type=simulation${
          clientId ? `&client_id=${clientId}` : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteUploadStatus: async (id) => {
    try {
      let response = await axiosInstance.delete(`/upload_status/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
