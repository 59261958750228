import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { portService } from "../../utils/services/portService"; 
import debounce from "lodash.debounce";

const PortAutocomplete = ({
  selectedPort,
  onPortChange,
  clientId,
  width = 200,
  label = "Select Port",
  errorMessage,
  hideLabel
}) => {
  const [allPorts, setAllPorts] = useState([]);
  const [portSearchValue, setPortSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const fetchPortData = useCallback(
    debounce(async (searchQuery, pageNumber, isLoadMore = false) => {
      if (isLoadMore) {
        setLoadMoreLoading(true);
      } else {
        setLoading(true);
      }

      try {
        const response = await portService.fetch(
          10,
          pageNumber * 10,
          clientId,
          searchQuery
        );
        if (pageNumber === 0) {
          setAllPorts(response.data);
        } else {
          setAllPorts((prevData) => [...prevData, ...response.data]);
        }
        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 300),
    [clientId]
  );

  useEffect(() => {
    fetchPortData(portSearchValue, page);
  }, [portSearchValue, page, fetchPortData]);

  useEffect(() => {
    if (portSearchValue === "") {
      fetchPortData("", 0); // Fetch all ports when search value is empty
    }
  }, [portSearchValue, fetchPortData]);

  const handlePortChange = (event, value) => {
    if (value && value.name === "Load More") {
      return; 
    }
    if (onPortChange) {
      onPortChange(value);
    }
    setPortSearchValue("");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setPortSearchValue(value);
    setPage(0);
    setAllPorts([]);
  };

  const handleLoadMoreClick = () => {
    if (allPorts.length < total && !loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Add "Load More" option every 10 items if more data is available
  const optionsWithLoadMore = [...allPorts];
  if (allPorts.length < total && allPorts.length % 10 === 0) {
    optionsWithLoadMore.push({ name: "Load More" });
  }

  return (
    <Autocomplete
      size="small"
      id="port-select"
      options={optionsWithLoadMore}
      getOptionLabel={(option) => option.name || ""}
      value={selectedPort || null}
      onChange={handlePortChange}
      renderOption={(props, option) =>
        option.name === "Load More" ? (
          <li
            {...props}
            onClick={handleLoadMoreClick}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <Typography
              color="primary"
              variant="body2"
              style={{ marginRight: 8 }}
            >
              Load More...
            </Typography>
            {loadMoreLoading && <CircularProgress color="inherit" size={16} />}
          </li>
        ) : (
          <li {...props}>{option.name}</li>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errorMessage}
          helperText={errorMessage}
          placeholder="Select Port"
          margin="dense"
          label={hideLabel?"":label}
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      style={{ minWidth: width, marginRight: 10 }}
    />
  );
};

export default PortAutocomplete;
