import React from "react";
import { SvgIcon } from "@mui/material";

const ShipmentCostIcon = ({ fill }) => {
  return (
    <>
      <SvgIcon>
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="512.000000pt"
          height="512.000000pt"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill={fill}
            stroke="none"
          >
            <path
              d="M1945 4796 c-392 -75 -725 -343 -871 -699 -89 -219 -113 -461 -68
-684 l7 -33 -253 0 -252 0 -29 -29 c-36 -36 -37 -73 -4 -106 l24 -25 283 0
284 0 27 -64 c16 -35 50 -98 78 -139 27 -41 49 -78 49 -81 0 -3 -247 -6 -549
-6 l-550 0 -20 -26 c-28 -35 -26 -67 3 -101 l24 -28 626 -3 627 -3 81 -60
c131 -97 292 -168 467 -205 95 -20 309 -23 411 -5 366 64 678 295 846 625 l49
96 58 0 c67 0 108 -18 125 -55 9 -20 12 -310 12 -1195 l0 -1170 -590 0 c-492
0 -590 2 -590 14 0 7 -16 45 -35 83 -128 257 -459 310 -662 106 -43 -43 -103
-151 -103 -185 0 -17 -18 -18 -235 -18 l-236 0 -24 25 -25 24 0 571 0 572 -29
29 -29 29 -372 0 -371 0 -24 -25 c-33 -32 -33 -78 0 -110 l24 -25 321 0 320 0
0 -532 c0 -595 -2 -575 71 -646 67 -66 69 -67 348 -72 l254 -5 18 -52 c69
-203 298 -320 506 -258 117 35 234 148 270 261 l17 54 788 0 788 0 6 -27 c25
-103 128 -223 231 -271 77 -35 205 -43 284 -17 133 43 237 146 274 270 l12 40
135 5 c125 5 137 7 175 32 27 18 50 45 67 81 l27 53 -3 530 -3 529 -180 424
c-119 278 -191 436 -211 460 -17 20 -53 47 -80 61 l-49 25 -462 3 -461 3 -4
177 c-3 157 -6 181 -25 217 -45 85 -118 133 -219 142 -51 5 -64 9 -59 20 35
93 31 385 -8 534 -111 426 -422 735 -857 851 -97 26 -370 34 -475 14z m460
-176 c313 -85 575 -324 684 -622 47 -128 55 -178 56 -348 0 -196 -17 -274 -94
-435 -59 -121 -98 -177 -194 -273 -297 -302 -749 -382 -1133 -203 -112 53
-196 112 -285 200 -130 131 -218 285 -266 469 -32 124 -36 322 -9 447 85 388
388 692 776 776 119 26 350 21 465 -11z m2104 -1967 c23 -21 72 -126 201 -430
l170 -403 0 -184 0 -184 -89 -4 c-101 -3 -133 -19 -163 -79 -27 -51 -26 -309
0 -359 28 -53 89 -80 179 -80 l73 0 0 -54 c0 -34 -5 -58 -14 -65 -9 -8 -51
-11 -122 -9 l-107 3 -15 45 c-36 109 -130 208 -239 251 -46 19 -78 23 -153 24
-86 0 -101 -3 -162 -32 -111 -52 -186 -135 -224 -247 l-16 -46 -119 0 -119 0
0 940 0 940 445 0 445 0 29 -27z m371 -1463 l0 -100 -55 0 -55 0 0 100 0 100
55 0 55 0 0 -100z m-2947 -236 c54 -18 115 -74 140 -127 79 -167 -41 -357
-226 -357 -71 0 -120 20 -171 68 -51 49 -76 104 -76 171 0 73 17 124 58 171
69 82 172 109 275 74z m2413 -12 c48 -24 100 -81 121 -130 20 -49 18 -142 -5
-194 -10 -24 -34 -58 -53 -77 -131 -131 -362 -73 -414 103 -18 60 -18 99 -1
149 28 77 83 134 160 164 44 18 144 10 192 -15z"
            />
            <path
              d="M1998 4500 c-207 -37 -407 -159 -532 -323 -218 -287 -240 -663 -58
-972 61 -104 191 -234 298 -297 243 -144 552 -161 801 -43 313 147 503 444
503 786 0 382 -260 724 -630 830 -84 23 -299 34 -382 19z m365 -183 c214 -70
382 -238 455 -454 23 -68 26 -95 27 -213 0 -115 -3 -145 -23 -204 -66 -197
-184 -337 -357 -422 -117 -57 -197 -77 -315 -77 -120 1 -212 22 -320 75 -69
34 -102 59 -175 133 -74 73 -99 106 -132 175 -55 113 -75 194 -76 310 -1 156
34 272 120 402 91 138 263 257 423 293 101 23 275 14 373 -18z"
            />
            <path
              d="M2092 4197 c-14 -15 -22 -36 -22 -58 0 -32 -3 -35 -51 -53 -33 -11
-69 -35 -100 -66 -130 -130 -88 -336 85 -410 23 -9 83 -27 135 -39 133 -30
181 -66 181 -132 0 -36 -51 -76 -114 -89 -105 -22 -197 17 -229 98 -20 50 -41
66 -84 66 -64 0 -91 -62 -59 -139 33 -79 111 -147 199 -175 31 -10 37 -16 37
-40 0 -16 3 -36 6 -45 7 -18 55 -45 79 -45 28 0 75 55 75 89 0 26 5 31 38 40
68 19 144 72 177 125 26 43 30 59 30 120 -1 85 -22 133 -85 190 -51 46 -103
69 -206 91 -106 23 -159 49 -174 85 -30 72 39 140 143 140 65 0 106 -24 152
-90 26 -37 38 -46 68 -48 28 -2 42 3 62 22 32 33 31 59 -5 122 -36 63 -89 109
-152 132 -43 15 -48 20 -48 48 0 17 -9 43 -21 58 -27 35 -86 36 -117 3z"
            />
            <path
              d="M3719 2577 c-37 -28 -39 -49 -39 -397 l0 -342 29 -29 29 -29 472 0
c394 0 476 2 499 15 30 15 41 33 41 67 0 39 -278 681 -306 706 -25 22 -26 22
-367 22 -253 -1 -346 -4 -358 -13z m726 -383 c55 -130 102 -240 103 -245 2 -5
-153 -9 -352 -9 l-356 0 0 245 0 245 253 0 252 0 100 -236z"
            />
            <path
              d="M1774 796 c-29 -29 -34 -41 -34 -81 0 -38 5 -52 29 -76 24 -24 38
-29 76 -29 40 0 52 5 81 34 40 40 45 83 14 134 -38 62 -114 70 -166 18z"
            />
            <path
              d="M4174 811 c-94 -57 -52 -201 60 -201 51 0 83 21 102 67 42 100 -68
191 -162 134z"
            />
            <path
              d="M332 2467 c-29 -31 -29 -87 -1 -113 20 -18 45 -19 486 -22 l464 -3
29 30 c37 36 39 73 5 106 l-24 25 -469 0 -469 0 -21 -23z"
            />
          </g>
        </svg>
      </SvgIcon>
    </>
  );
};
export default ShipmentCostIcon;
