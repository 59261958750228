import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton, // Import IconButton from Material-UI
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { isMobile } from "../../globalStyles/mobile";

const UploadStatusModal = ({
  data,
  open,
  handleClose,
  onDeleteStatus,
  simulation,
}) => {
  const groupedData = data?.reduce((acc, obj) => {
    let index = acc.findIndex((x) => x.key === obj.filename);
    return index > -1
      ? acc.map((x, i) =>
          i === index ? { ...x, values: [...x.values, obj] } : x
        )
      : [...acc, { key: obj.filename, values: [obj] }];
  }, []);

  return (
    <Dialog
      sx={{
        minWidth: 300,
        maxHeight: "90vh", // Set maximum height for the dialog
      }}
      open={open}
      onClose={handleClose}
    >
      {groupedData?.map((card) => (
        <div key={card.key}>
          <DialogTitle sx={{ color: "blue", fontWeight: "600" }}>
            {card.key}
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              flexWrap: "wrap",
              overflowY: isMobile() ? "auto" : "visible", // Enable vertical scroll on mobile
              maxHeight: isMobile() ? "400px" : "auto", // Set fixed height for scrollable area
            }}
          >
            <Grid container spacing={2}>
              {card.values.map((item) => (
                <Grid
                  item
                  xs={isMobile() ? 12 : 6} // Full width on mobile, half width otherwise
                  key={item.id}
                >
                  <Card
                    style={{
                      margin: 5,
                      position: "relative",
                      borderWidth: 1,
                      minWidth: 200,
                    }}
                  >
                    <CardContent>
                      {item.inserted === item.verified && !simulation && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            onDeleteStatus && onDeleteStatus(item.id);
                          }}
                          style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                          }}
                        >
                          <CancelIcon sx={{ color: "red" }} />
                        </IconButton>
                      )}
                      {simulation ? (
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            onDeleteStatus && onDeleteStatus(item._id);
                          }}
                          style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                          }}
                        >
                          <CancelIcon sx={{ color: "red" }} />
                        </IconButton>
                      ) : null}
                      <Typography
                        sx={{
                          color: "#6877C1",
                          paddingBottom: 1,
                          fontWeight: "600",
                        }}
                        variant="subtitle1"
                        component="div"
                        noWrap
                      >
                        {item.sheet}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Type: {item.type}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Error: {item.error}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Total: {item.total}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Inserted: {item.inserted}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Verified: {item.verified}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </div>
      ))}
      {groupedData?.length ? null : (
        <div style={{ padding: 40 }}>
          <p style={{ color: "red" }}>No data available...</p>
        </div>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadStatusModal;
