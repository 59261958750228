import { Data } from "../components/sidebar/sidebarData";

export const getObjectByPath = (path) => {
  for (const item of Data) {
    if (item.path === path) {
      return { title: item.title, subNav: null };
    }
    if (item.subNav) {
      for (const subItem of item.subNav) {
        if (subItem.path === path) {
          return { title: item.title, subNav: subItem };
        }
      }
    }
  }
  return null;
};
