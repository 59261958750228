import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  TablePagination
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import MappingModal from '../../components/file-column-and-schema-map'
import { orderService } from '../../services/order-service'
import UploadStatusDataOverView from '../../components/upload-stattus-data-overview'

const UploadStatus = () => {
  const [uploadConfigData, setUploadConfigData] = useState([])
  const [viewModal, setViewModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [mapping, setMapping] = useState({ fileColumn: '', modelField: '' })
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleViewClick = (order) => {
    setSelectedOrder(order)
    setViewModal(true)
  }

  const handleEditClick = (order) => {
    setSelectedOrder(order)
    setEditModal(true)
  }

  const handleClose = () => {
    setViewModal(false)
    setEditModal(false)
    setSelectedOrder(null)
  }

  const handleSaveMapping = async (data) => {
    try {
      const uploadConfigResponse = await orderService.uploadConfig(data, selectedOrder?._id)
      handleClose()
      console.log(uploadConfigResponse)
    } catch (error) {
      console.error(error)
      throw error
    }

    // console.log('Saving mapping:', mapping)
    // handleClose()
  }

  const fetchUploadConfig = async () => {
    try {
      const response = await orderService.getUploadConfig(rowsPerPage, rowsPerPage * page)
      setUploadConfigData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchUploadConfig()
  }, [rowsPerPage, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <TableContainer
        sx={{
          maxHeight: '80vh',
          overflowY: 'auto'
        }}
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '600', fontSize: 16 }}>Start Process</TableCell>
              <TableCell sx={{ fontWeight: '600', fontSize: 16 }}>Mapped Columns</TableCell>
              <TableCell sx={{ fontWeight: '600', fontSize: 16 }}>Total Records</TableCell>
              <TableCell sx={{ fontWeight: '600', fontSize: 16 }}>Success</TableCell>
              <TableCell sx={{ fontWeight: '600', fontSize: 16 }}>Error</TableCell>
              <TableCell sx={{ fontWeight: '600', fontSize: 16 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadConfigData.map((order) => (
              <TableRow key={order._id}>
                <TableCell>
                  {order.start_process ? (
                    <Typography sx={{ color: '#007D14' }}>Processed</Typography>
                  ) : (
                    <Typography sx={{ color: '#FF8A00' }}>Not Processed</Typography>
                  )}
                </TableCell>
                <TableCell>
                  {order.mapped_column.filter((m) => m.column).length > 0 ? (
                    <Typography sx={{ color: '#007D14' }}>Done</Typography>
                  ) : (
                    <Typography sx={{ color: '#FF8A00' }}>Pending</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Typography sx={{ ml: 1 }}>{order?.upload_status?.total_records}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ ml: 1 }}>{order?.upload_status?.success}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ ml: 1 }}>{order?.upload_status?.error}</Typography>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleViewClick(order)} color="primary">
                    <VisibilityIcon />
                  </IconButton>
                  {order.mapped_column.filter((m) => m.column).length > 0 ? (
                    <></>
                  ) : (
                    <IconButton onClick={() => handleEditClick(order)} color="secondary">
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#ffffff',
            padding: '2px',
            borderTop: '1px solid #dddddd'
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={uploadConfigData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <UploadStatusDataOverView data={selectedOrder} onClose={handleClose} open={viewModal} />
      <MappingModal
        onSubmit={(data) => handleSaveMapping(data)}
        modelSchema={selectedOrder?.model_schema}
        fileColumn={selectedOrder?.file_column}
        onCloseModal={handleClose}
        open={editModal}
      />
    </Box>
  )
}

export default UploadStatus
