import axios from "axios";

export const countryService = {
  fetchAll: async () => {
    try {
      let response = await axios.get("https://restcountries.com/v3.1/all");
      const countries = response.data
        .map((country) => ({
          code: country.cca2, 
          name: country.name.common,
          contactCode: country.idd.root
            ? `${country.idd.root}${
                country.idd.suffixes ? country.idd.suffixes[0] : ""
              }`
            : "",
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      return countries;
    } catch (error) {
      throw error;
    }
  },
};
