import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/system";
import { loginService } from "../../services/login-service";
import { toast } from "react-toastify";
import AppContext from "../../components/context";

const StyledContainer = styled(Box)({
  display: "flex",
  minHeight: "100vh",
  backgroundColor: "#f8f9fa",
});

const FormContainer = styled(Box)({
  maxWidth: "400px",
  margin: "0 auto",
  padding: "2rem",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
});

const ImageContainer = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#e9ecef",
  padding: "2rem",
});

const Login = ({ setToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const navigate = useNavigate();
  const { setIsAuthenticated, setUser } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(true);
  const path = localStorage.getItem("path");
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  React.useEffect(() => {
    if (token && user) {
      setIsAuthenticated(true);
      setUser(JSON.parse(user));
      navigate(path);
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false);
  }, [user, token, path]);

  // React.useEffect(() => {
  // }, [path]);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        toast.error("Please enter email and password", { autoClose: 3000 });
        return;
      }
      const response = await loginService.login(email, password);
      if (response.accessToken) {
        setUser(response.user);
        setIsAuthenticated(true);
        localStorage.setItem("token", response.accessToken);
        localStorage.setItem("user", JSON.stringify(response.user));
        navigate("/products");
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("Login failed. Please check your credentials.");
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  if (loading) {
    return (
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#151D48",
        }}
      >
        <Typography color="white" variant="h5">
          Loading...
        </Typography>
      </main>
    );
  }
  return (
    <StyledContainer>
      <Grid container sx={{ minHeight: "100vh" }}>
        {/* Left Section: Image */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#e9ecef",
          }}
        >
          <ImageContainer>
            <img
              src="./mobile.png"
              alt="Design illustration"
              style={{ maxWidth: "80%", height: "auto" }}
            />
          </ImageContainer>
        </Grid>

        {/* Right Section: Login Form */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "2rem",
          }}
        >
          <FormContainer>
            <Typography variant="h4" gutterBottom align="center">
              Welcome to Dropskip
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              align="center"
              sx={{ marginBottom: "2rem", color: "#6c757d" }}
            >
              Please sign in to continue
            </Typography>
            <Typography fontFamily="Poppins" fontWeight={500} fontSize="14px">
              Email{" "}
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>

            <TextField
              size="small"
              placeholder=" Enter Your Email"
              fullWidth
              margin="dense"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Typography fontFamily="Poppins" fontWeight={500} fontSize="14px">
              Password{" "}
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>

            <TextField
              size="small"
              placeholder="Enter Your Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              margin="dense"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              size="small"
              variant="contained"
              fullWidth
              onClick={handleLogin}
              sx={{
                marginTop: "1.5rem",
                padding: "0.60rem",
                backgroundColor: "#007bff",
                ":hover": {
                  backgroundColor: "#0056b3",
                },
              }}
            >
              <Typography
                fontFamily={"poppins"}
                fontWeight={500}
                fontSize={"14px"}
                color="#fff"
              >
                Login
              </Typography>
            </Button>
          </FormContainer>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Login;
