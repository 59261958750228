import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  Drawer,
  Popover,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AppContext from "../context/context";
import Sidebar from "../sidebar/sidebar";
import Breadcrumbs from "../breadcrumbs";
import NotificationIcon from "../../globalStyles/icons/notification-icon";
import CustomTypography from "../../globalStyles/fonts";
import NotificationModal from "../notificationModal";
import SearchBar from "../headerSearchbar";
import { styled } from "@mui/material/styles";
import AccountSettings from "../account-setting";
import AccountSettingsModal from "../account-setting";
import { clientService } from "../../utils/services/clientService";

const ResponsiveImage = styled("img")({
  width: "100px",
  height: "50px",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function Layout({ children, title }) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const [showDrawer, setShowDrawer] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(230);
  const [anchorNotificationEl, setAnchorNotificationEl] = useState(null);
  const [openAccountSettingModal, setOpenAccountSettingModal] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  const navigate = useNavigate();
  const { user, setIsAuthenticated } = React.useContext(AppContext);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    const adminData = localStorage.getItem("adminUser");
    const adminToken = localStorage.getItem("adminToken");

    if (adminData) {
      const token = localStorage.getItem("token");
      localStorage.clear();
      localStorage.setItem("user", adminData);
      localStorage.setItem("token", adminToken);
      window.location.reload();
    } else {
      setIsAuthenticated(false);
      navigate("/login", { replace: true });
      localStorage.clear();
    }
  };

  const handleNotificationClick = (event) => {
    setAnchorNotificationEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorNotificationEl(null);
  };
  const fetchClientById = async () => {
    let client = await clientService.get(localStorage.getItem("client_id"));
    setClientDetails(client);
  };

  useEffect(() => {
    if (localStorage.getItem("client_id")) {
      fetchClientById();
    }
  }, [user]);
  const isMobile = useMediaQuery("(max-width:700px)");

  const handleSearchData = (searchTerm) => {
    console.log(searchTerm);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <AppBar
          sx={{
            height: 60,
            justifyContent: "center",
            backgroundColor: "#FFFFFF",
            color: "gray",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          position="fixed"
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              sx={{
                mr: 2,
                backgroundColor: isMobile ? "#FFF" : "#BF83FF",
                height: 35,
                width: 35,
              }}
              onClick={() => {
                setDrawerWidth(drawerWidth === 230 ? 100 : 230);
                setOpen(!open);
                setShowDrawer(!showDrawer);
              }}
            >
              <MenuIcon
                sx={{
                  color: isMobile ? "#BF83FF" : "#FFF",
                  height: 20,
                  width: 20,
                }}
              />
            </IconButton>

            {isMobile ? (
              <></>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  gap: 15,
                }}
              >
                {user.role === "admin" ? (
                  <ResponsiveImage src="/DropSkipLogo.png" alt="Logo" />
                ) : clientDetails?.logo ? (
                  <ResponsiveImage
                    style={{ borderRadius: 10, objectFit: "contain" }}
                    src={`${clientDetails?.logo}`}
                    alt="Logo"
                  />
                ) : (
                  <strong>{clientDetails?.business_name}</strong>
                )}
                <SearchBar
                  onSearchData={handleSearchData}
                  searchIconColor="#5D5FEF"
                  backgroundColor="#F7FAFC"
                  borderColor="#EEEEEE"
                />
              </Box>
            )}
            <Box sx={{ marginRight: 10 }}>
              <IconButton
                size="large"
                edge="end"
                color="primary"
                aria-label="notifications"
                onClick={handleNotificationClick}
              >
                <Badge badgeContent={notifications.length} color="error">
                  <NotificationIcon />
                </Badge>
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Avatar
                src={user?.image || "user.png"}
                alt="Profile Image"
                sx={{ width: 48, height: 38, borderRadius: 12 }}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CustomTypography
                  color="#333333"
                  fontSize={"15px"}
                  fontWeight={500}
                >
                  {user?.name}
                </CustomTypography>
                {/* <CustomTypography color="#333333" fontSize={'12px'} fontWeight={400}>
                  {user?.role}
                </CustomTypography> */}
              </Box>
            </Box>
            <Box>
              <IconButton
                size="large"
                edge="end"
                color="primary"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuClick}
              >
                <ArrowDropDownIcon color="#151D48" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {JSON.parse(localStorage.getItem("user")).role === "admin" ? (
                  <></>
                ) : (
                  <MenuItem
                    onClick={() => {
                      localStorage.setItem("path", `/account-setting`);
                      navigate(`/account-setting`);
                      setAnchorEl(null);
                    }}
                  >
                    Account Setting
                  </MenuItem>
                )}
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Box sx={{ display: "flex", flexGrow: 1, marginTop: "60px" }}>
          <Drawer
            variant={isMobile ? "temporary" : "permanent"}
            open={open}
            onClose={toggleDrawer}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              width: isMobile ? 200 : drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: isMobile ? 200 : drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <Box
              sx={{
                height: "100%",
                overflowY: "auto",
              }}
            >
              <Sidebar
                hideSideBar={isMobile ? false : !showDrawer}
                onClickMenu={toggleDrawer}
                onClickSubMenu={toggleDrawer}
              />
            </Box>
          </Drawer>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              padding: 2,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              backgroundColor: "#F7F8F9",
              height: "90vh",
              overflow: "hidden",
              overflowX: "hidden",
            }}
          >
            <div>
              <Breadcrumbs />
            </div>
            {children}
          </Box>
        </Box>
        <Popover
          open={Boolean(anchorNotificationEl)}
          anchorEl={anchorNotificationEl}
          onClose={handleNotificationClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <NotificationModal onClose={handleNotificationClose} />
        </Popover>
        <AccountSettingsModal
          user={user}
          handleClose={() => setOpenAccountSettingModal(false)}
          open={openAccountSettingModal}
        ></AccountSettingsModal>
      </Box>
    </>
  );
}
