import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  FormHelperText,
  Checkbox,
  Typography,
} from "@mui/material";
import { manifestIdService } from "../../utils/services/manifestIdService";
import debounce from "lodash.debounce";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";

const ManifestAutocomplete = ({
  manifestId,
  setManifestId,
  formErrors,
  width = 200,
  label = "Select Manifest IDs",
  hideLabel,
}) => {
  const [manifestData, setManifestData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const fetchManifestData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      if (pageNumber > 0) {
        setLoadMoreLoading(true);
      } else {
        setLoading(true);
      }

      try {
        const response = await manifestIdService.fetch(
          10,
          pageNumber * 10,
          searchQuery
        );
        if (pageNumber === 0) {
          setManifestData(response.data);
        } else {
          setManifestData((prevData) => [...prevData, ...response.data]);
        }
        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        if (pageNumber > 0) {
          setLoadMoreLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 300),
    []
  );

  useEffect(() => {
    fetchManifestData(searchValue, page);
  }, [searchValue, page, fetchManifestData]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setPage(0);
    setManifestData([]);
  };

  const handleLoadMoreClick = () => {
    if (manifestData.length < total && !loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Add "Load More" option every 15 items if more data is available
  const optionsWithLoadMore = [...manifestData];
  if (manifestData.length < total && manifestData.length % 10 === 0) {
    optionsWithLoadMore.push({ _id: "load-more", name: "Load More" });
  }

  return (
    <Autocomplete
      multiple
      size="small"
      value={manifestId}
      onChange={(event, newValue) => {
        setManifestId(newValue);
      }}
      options={optionsWithLoadMore}
      getOptionLabel={(option) => option?._id?.toString() || ""}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => {
        if (option._id === "load-more") {
          return (
            <li
              {...props}
              onClick={handleLoadMoreClick}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                color="primary"
                variant="body2"
                style={{ marginRight: 8 }}
              >
                Load More...
              </Typography>
              {loadMoreLoading && (
                <CircularProgress color="inherit" size={16} />
              )}
            </li>
          );
        }
        return (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option?._id?.toString()}
          </li>
        );
      }}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            placeholder="Select Manifest IDs"
            margin="dense"
            error={!!formErrors.manifestId}
            label={hideLabel ? "" : label}
            onChange={handleSearchChange}
          />
          {!!formErrors.manifestId && (
            <FormHelperText error>{formErrors.manifestId}</FormHelperText>
          )}
        </>
      )}
      style={{ minWidth: width, marginRight: 10 }}
    />
  );
};

export default ManifestAutocomplete;
