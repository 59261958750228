import { SvgIcon } from '@mui/material'
import React, { useState } from 'react'

const AlertIcon = ({ fill }) => {
  return (
    <SvgIcon sx={{ height: 20, width: 20 }}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9997 12.0001V17.0001M3.59537 21.5009C2.44152 23.5009 3.88495 26.0001 6.19393 26.0001H25.8055C28.1144 26.0001 29.5579 23.5009 28.404 21.5009L18.5983 4.50425C17.4438 2.50314 14.5556 2.50314 13.4011 4.50425L3.59537 21.5009ZM15.9997 21.0001H16.0097V21.0101H15.9997V21.0001Z"
          stroke={fill || '#0F172A'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default AlertIcon
