import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography/Typography'
import 'react-toastify/dist/ReactToastify.css'

import { Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import 'react-quill/dist/quill.snow.css'
import { styled } from '@mui/material/styles'
import { ActionTableCellComponent } from '../action-component'
import DeleteConfirmationModal from '../delete-confirmation-modal'
import { provinceService } from '../../services/province-service'
import { toast } from 'react-toastify'
import CountryViewModal from '../country-details-modal'
import AddEditProvince from '../add-edit-province'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    color: theme.palette.common.white,
    padding: '6px 6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '6px 6px'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
    border: '1px solid #EAECF0'
  },

  '&:lastChild td, &:lastChild th': {
    border: 0
  }
}))

const ProvinceList = ({
  data,
  rowsPerPage,
  page,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
  onSuccess
}) => {
  const [provinces, setProvinces] = useState([])
  const [selectedProvince, setSelectedProvince] = useState(null)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: 'cover', height: '300px' }}
      />
    </div>
  )
  const handleViewClick = (province) => {
    setSelectedProvince(province)
    setOpenViewModal(true)
  }
  const handleEditClick = (province) => {
    console.log(province)
    setSelectedProvince(province)
    setOpenEditModal(true)
  }

  const handleDeleteClick = (province) => {
    setOpenConfirmation(true)
    setSelectedProvince(province)
  }
  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      const response = await provinceService.delete(selectedProvince._id)
      setSelectedProvince(null)
      onSuccess && onSuccess()
      toast.success('Province Deleted successfully!')
      setOpenConfirmation(false)
    } catch (error) {
      console.error('Delete failed:', error)
    }
  }
  const handleEditCountry = (data) => {
    try {
      const response = provinceService.edit(
        {
          ...data
        },
        selectedProvince._id
      )
      setOpenEditModal(false)
      setProvinces(
        provinces.map((province) =>
          province._id == data.selectedData
            ? {
                ...data,
                _id: data.selectedData,
                action: (
                  <ActionTableCellComponent
                    selectedData={{ ...data, _id: data.selectedData }}
                    handleViewClick={handleViewClick}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )
              }
            : province
        )
      )
      setSelectedProvince(null)
      //   onSuccess && onSuccess()
      toast.success('Province Updated successfully!')
    } catch (error) {
      console.error(error)
    }
  }
  console.log(provinces)
  useEffect(() => {
    setProvinces(
      data.map((province) => ({
        ...province,
        action: (
          <ActionTableCellComponent
            selectedData={province}
            handleViewClick={handleViewClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        )
      }))
    )
  }, [data])
  return (
    <div
      style={{
        overflowY: 'scroll',
        display: 'flex',
        maxHeight: `calc(100vh - 170px)`,
        width: '100%',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::WebkitScrollbar': { width: 0 }
      }}
    >
      <TableContainer
        sx={{
          mt: 3,
          overflowY: 'auto',
          paddingInline: 0.4,
          maxHeight: '100%'
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            border: '1px solid #EAECF0'
          }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                  fontFamily={'poppins'}
                >
                  NAME
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                  fontFamily={'poppins'}
                >
                  CODE
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                  fontFamily={'poppins'}
                >
                  COUNTRY
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" title={true} color="#333333" fontWeight={500}>
                  ACTION
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {provinces.length === 0 ? (
              <StyledTableRow>
                <TableCell colSpan={8} align="center">
                  <NoDataImage />
                </TableCell>
              </StyledTableRow>
            ) : (
              provinces?.map((province, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {province.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {province.code}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {province.country?.name}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell sx={{ width: '3%', textAlign: 'center' }}>
                    {province?.action}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffffff',
          padding: '2px',
          borderTop: '1px solid #dddddd'
        }}
        rowsPerPageOptions={[10, 15, 25, 45, 75, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DeleteConfirmationModal
        title={`Are you sure you want to delete  ${selectedProvince?.name} ?`}
        open={openConfirmation}
        handleClose={() => {
          setOpenConfirmation(false)
          setSelectedProvince(null)
        }}
        handleDelete={(e) => {
          confirmDelete(e)
        }}
      />
      <AddEditProvince
        handleCloseModal={() => setSelectedProvince(null)}
        setOpen={setOpenEditModal}
        handleSubmit={handleEditCountry}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        selectedData={selectedProvince}
      ></AddEditProvince>
      <CountryViewModal
        province
        handleCloseModal={() => {
          setOpenViewModal(false)
          setSelectedProvince(null)
        }}
        openModal={openViewModal}
        country={selectedProvince}
      ></CountryViewModal>
    </div>
  )
}

export default ProvinceList
