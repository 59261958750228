import React, { useEffect, useState } from 'react'
import DashboardContainer from '../../components/dashboard-container'
import EventModal from '../../components/EventModal';
import { eventService } from '../../utils/services/eventService';
import { Box, CircularProgress } from "@mui/material";
import EventList from '../../components/eventList';
const Event = () => {
     const [selectedEvent, setSelectedEvent] = useState(null);
     const [openModal, setOpenModal] = useState(false);
     const [eventList, setEventList] = useState([]);
     const [viewMode, setViewMode] = useState("list");
     const [searchValue, setSearchValue] = useState("");
     const [clientFilter, setClientFilter] = useState(null);
     const [page, setPage] = React.useState(0);
     const [rowsPerPage, setRowsPerPage] = React.useState(15);
     const [dataCount, setDataCount] = useState(rowsPerPage);
     const [loading, setLoading] = useState(false);
     
     const fetchEvent = async () => {
       setLoading(true);
       try {
         const response = await eventService.fetch(
           10,
           0,
           clientFilter?._id,
           searchValue
         );
         setEventList(response?.data);
         setLoading(false);
         setDataCount(response?.total);
       } catch (error) {
         console.error("Fetch failed:", error);
         setLoading(false);
       }
     };
     useEffect(() => {
       fetchEvent();
     }, [page, rowsPerPage, clientFilter, searchValue]);
  return (
    <>
      <DashboardContainer
        header={"Event List"}
        hideUploadButton
        title={"+ Add Event"}
        onClickAddButton={() => setOpenModal(true)}
        setViewMode={setViewMode}
        onSearchData={(x) => {
          if (x.trim()) {
            setSearchValue(x);
            setPage(0);
          } else {
            setSearchValue("");
          }
        }}
        hideFilterClient
      >
        <EventModal
          selectedEvent={selectedEvent}
          handleClose={() => {
            setOpenModal(false);
            setSelectedEvent(null);
          }}
          open={openModal}
          onAddData={fetchEvent}
        ></EventModal>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <EventList
            dataCount={dataCount}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            onDeleteData={fetchEvent}
            onEditData={(e) => {
              setSelectedEvent(e);
              setOpenModal(true);
            }}
            data={eventList}
            viewMode={viewMode}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
          />
        )}
      </DashboardContainer>
    </>
  );
}

export default Event
