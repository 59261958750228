import React, { useState, useEffect, useContext, useRef } from 'react'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import IconButton from '@mui/material/IconButton/IconButton'
import Button from '@mui/material/Button/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { clientService } from '../../utils/services/clientService'
import AppContext from '../context/context'
import { productService } from '../../utils/services/productService'
import config from '../../config'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import RvHookupIcon from '@mui/icons-material/RvHookup'
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TablePagination
} from '@mui/material'
import 'react-quill/dist/quill.snow.css'
import Checkbox from '@mui/material/Checkbox'
import CustomTypography from '../../globalStyles/fonts'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import ProductDetailsModal from '../productViewDetailsModal'
import { isMobile } from '../../globalStyles/mobile'
import { supplierService } from '../../utils/services/supplierService'
import ASNDataModal from '../ASN-data-modal'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import NotStarted from '../../globalStyles/icons/not-started-icon'
import Started from '../../globalStyles/icons/started-icon'
import InProgress from '../../globalStyles/icons/in-progress-icon'
import Compliant from '../../globalStyles/icons/compliant-icon'
import CustomPagination from '../pagination'
const defaultImage = '/product.png'

const ProductList = ({
  client,
  data,
  onEditData,
  onDeleteData,
  handleEditClient,
  handleDeleteProduct,
  onSelectClient,
  viewMode,
  filter,
  setFilter,
  deleteTitle,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const fileInputRef = useRef(null)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [clientData, setClientData] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [openViewModal, setOpenViewModal] = useState(false)
  const [vendors, setVendors] = useState([])
  const [openASNDataModal, setOpenASNDataModal] = useState(false)
  const { user } = useContext(AppContext)
  const modalRef = useRef(null)
  const navigate = useNavigate()
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch()
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }
  const fetchSupplierData = async () => {
    try {
      const response = await supplierService.fetch()
      setVendors(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }
  useEffect(() => {
    fetchClientData()
    fetchSupplierData()
  }, [])

  const handleEditClick = (product) => {
    localStorage.setItem('path', `/manage-product/${product?._id}`)
    navigate(`/manage-product/${product?._id}`)
  }

  const handleDeleteClick = (product) => {
    setSelectedProduct(product)
    setOpenConfirmation(true)
  }

  const clientId = localStorage.getItem('client_id')

  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await productService.delete(selectedProduct._id)
      onDeleteData && onDeleteData()
      toast.success('Product Deleted successfully!')
      setAnchorEl(null)
      setOpenConfirmation(false)
      setLoading(false)
    } catch (error) {
      console.error('Delete failed:', error)
    } finally {
      setLoading(false)
    }
  }

  // const handleCheckCoverImage = (index) => {
  //   const updatedFeatureImages = featureImages.map((image, idx) => (idx === index ? !image : false))
  //   setFeatureImages(updatedFeatureImages)
  //   const selectedIndex = selectedImages.findIndex((_, idx) => idx === index)
  //   const updatedImages = selectedImages.map((image, idx) => ({
  //     ...image,
  //     feature_image: idx === selectedIndex
  //   }))

  //   setSelectedImages(updatedImages)
  //   setImageData(updatedImages)
  // }

  const fetchImage = (image) => {
    if (image && image.image) {
      return `${config.baseURL}/fetch-file/${image.image}`
    }
    return defaultImage
  }
  const fetchProductImage = (images) => {
    if (images.length) {
      const featureImage = images?.find((img) => img.feature_image)
      if (featureImage && featureImage.image) {
        return `${config.baseURL}/fetch-file/${featureImage.image}`
      } else {
        return `${config.baseURL}/fetch-file/${images[0].image}`
      }
    }
    return defaultImage
  }
  function stripHtmlTags(html) {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    const plainText = tempDiv.textContent || tempDiv.innerText || ''
    return plainText.replace(/\s\s+/g, ' ').trim()
  }

  const handleFilterChange = (newFilter) => {
    setPage(0)
    if (newFilter === 'All') {
      setFilter(newFilter)
    } else {
      setFilter(newFilter.toLowerCase())
      setSelectedItems([])
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedItems(data.map((product) => product._id))
    } else {
      setSelectedItems([])
    }
  }

  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedItems.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1))
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1))
    } else {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      )
    }

    setSelectedItems(newSelected)
  }
  const handleDelete = async () => {
    try {
      const response = await productService.deleteMultipleProduct({
        ids: selectedItems
      })
      if (response) {
        onDeleteData(selectedItems)
        setSelectedItems([])
      }
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }

  const filteredData = data.filter((product) => (filter === 'All' ? true : product.status === filter))
  const handleViewClick = (product) => {
    setSelectedProduct(product)
    setOpenViewModal(true)
  }

  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: 'cover', height: '300px' }}
      />
    </div>
  )
  const renderGridView = () => (
    <Grid
      sx={{
        marginTop: 1,
        maxHeight: '90vh',
        paddingBottom: 10,
        overflow: 'auto'
      }}
      container
      spacing={2}
    >
      {data?.map((product, index) => (
        <Grid sx={{ paddingBottom: 1 }} item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              boxShadow: 3,
              cursor: 'pointer',
              width: '100%',
              height: '100%'
            }}
          >
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '50%',
                    overflow: 'hidden'
                  }}
                >
                  <img
                    src={fetchImage(product.images.find((img) => img.feature_image) || {})}
                    alt={product.name}
                    style={{
                      width: !product.image ? 65 : 60,
                      height: !product.image ? 65 : 60,
                      objectFit: 'cover',
                      marginRight: 10,
                      borderRadius: '60%',
                      border: !product.image ? '' : '2px solid #18E7CA',
                      padding: !product.image ? 0 : 1
                    }}
                  />
                </div>
                <div
                  style={{
                    alignItems: 'flex-start',
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 15
                  }}
                >
                  <Tooltip title={product?.name} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '50%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: '500'
                        }}
                        variant="h7"
                      >
                        {product?.name}
                      </Typography>
                    </div>
                  </Tooltip>

                  <Tooltip title="Variants" arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '50%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: '500'
                        }}
                        variant="h7"
                      >
                        {/* Price: ${product?.variants[0]?.price} */}
                      </Typography>
                    </div>
                  </Tooltip>
                  <Tooltip title={stripHtmlTags(product?.description)} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '80%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: '500'
                        }}
                        variant="h7"
                      >
                        Description: {stripHtmlTags(product?.description)}
                      </Typography>
                    </div>
                  </Tooltip>

                  <Tooltip title={product?.client_id?.name} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}
                    >
                      {user.role === 'admin' ? (
                        <Typography
                          style={{
                            fontWeight: '500'
                          }}
                          variant="h7"
                        >
                          Client : {product?.client_id?.name}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <IconButton
                    style={{
                      color: '#FEC50F',
                      padding: 0,
                      background: '#FEC50F33',
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      marginLeft: 15,
                      boxShadow: '0px 0px 4px 0px #00000040',
                      marginTop: 5
                    }}
                    aria-label="edit"
                    onClick={() => handleEditClick(JSON.parse(JSON.stringify(product)))}
                  >
                    <BorderColorIcon sx={{ height: 20, width: 20 }} />
                  </IconButton>

                  <IconButton
                    style={{
                      color: '#D13438',
                      padding: 0,
                      background: '#D1343833',
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      marginLeft: 10,
                      boxShadow: '0px 0px 4px 0px #00000040',
                      marginTop: 5
                    }}
                    aria-label="delete"
                    onClick={() => {
                      handleDeleteClick(product)
                    }}
                  >
                    <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                  </IconButton>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: '100%', padding: 0.5 }}>
        {data.length === 0 ? (
          <NoDataImage />
        ) : (
          data?.map((product, index) => (
            <Card key={index} sx={{ marginBottom: 2 }}>
              <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Checkbox
                    checked={selectedItems.indexOf(product._id) !== -1}
                    onChange={(event) => handleCheckboxClick(event, product._id)}
                  />
                </Box>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <img
                    src={fetchProductImage(product.images)}
                    alt={product.name}
                    style={{
                      width: 80,
                      height: 90,
                      objectFit: 'contain',
                      marginBottom: 20
                    }}
                  />
                  <div style={{ width: '70%' }}>
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                      <Box
                        display="flex"
                        alignItems="center"
                        mb={1}
                        sx={{
                          maxWidth: '90%'
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                          variant="h6"
                        >
                          {product?.name}
                        </Typography>
                      </Box>
                      {/* <Box display="flex" alignItems="center" mb={1}>
                        <Typography>Price : ${product.variants[0]?.price}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>Weight : {product.variants[0]?.weight}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>SKU: {product.variants[0]?.sku}</Typography>
                      </Box> */}
                      {/* <Box display="flex" alignItems="center" mb={1}>
                        <Typography>
                          Category: {product.category || ""}
                        </Typography>
                      </Box> */}
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>
                          Status :{' '}
                          <Chip
                            label={product.status}
                            sx={{
                              padding: '2px 4px',
                              borderRadius: '4px',
                              backgroundColor:
                                product.status.toLowerCase() === 'active' ? '#CBFFD3' : '#E1E1E1'
                            }}
                          />
                        </Typography>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        mb={1}
                        sx={{
                          maxWidth: '80%'
                        }}
                      >
                        <Typography
                          noWrap
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          Supplier : {product.vendor?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box mt={2}>
                      {/* <Tooltip title="ASN request list">
                        <IconButton
                          style={{
                            color: "#007DFF",
                            padding: 0,
                            background: "#C3E1FF",
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 10,
                            boxShadow: "0px 0px 4px 0px #00000040",
                          }}
                          aria-label="view"
                          onClick={() => {
                            setOpenASNDataModal(true);
                          }}
                        >
                          <RvHookupIcon sx={{ height: 20, width: 20 }} />
                        </IconButton>
                      </Tooltip> */}
                      <IconButton
                        style={{
                          color: '#007DFF',
                          padding: 0,
                          background: '#C3E1FF',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: '0px 0px 4px 0px #00000040'
                        }}
                        aria-label="view"
                        onClick={() => handleViewClick(JSON.parse(JSON.stringify(product)))}
                      >
                        <img style={{ height: 14, width: 20 }} alt="delete" src="/eye.png"></img>
                      </IconButton>
                      <IconButton
                        style={{
                          color: '#FEC50F',
                          padding: 0,
                          background: '#FEC50F33',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 15,
                          boxShadow: '0px 0px 4px 0px #00000040',
                          marginTop: 5
                        }}
                        aria-label="edit"
                        onClick={() => handleEditClick(JSON.parse(JSON.stringify(product)))}
                      >
                        <BorderColorIcon sx={{ height: 20, width: 20 }} />
                      </IconButton>

                      <IconButton
                        style={{
                          color: '#D13438',
                          padding: 0,
                          background: '#D1343833',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: '0px 0px 4px 0px #00000040',
                          marginTop: 5
                        }}
                        aria-label="delete"
                        onClick={() => {
                          handleDeleteClick(product)
                        }}
                      >
                        <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                      </IconButton>
                    </Box>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    )
  }
  const renderListView = () => (
    <div
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        paddingBottom: 100
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isMobile() ? 'column' : 'row'
        }}
      >
        <div style={{ width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 2, mb: 2, mt: 2 }}>
            <Button
              onClick={() => handleFilterChange('All')}
              style={{
                backgroundColor: filter === 'All' ? '#151D48' : '#FFFFFF',
                color: filter === 'All' ? '#ffff' : '#000',
                padding: 10,
                width: 100,
                textTransform: 'none'
              }}
            >
              <CustomTypography fontWeight={filter === 'All' ? 600 : 400}>All</CustomTypography>
            </Button>
            <Button
              onClick={() => handleFilterChange('active')}
              style={{
                backgroundColor: filter === 'active' ? '#151D48' : '#FFFFFF',
                color: filter === 'active' ? '#ffff' : '#000',
                padding: 10,
                width: 100,
                textTransform: 'none'
              }}
            >
              <CustomTypography fontWeight={filter === 'active' ? 600 : 400}>Active</CustomTypography>
            </Button>
            <Button
              onClick={() => handleFilterChange('draft')}
              style={{
                backgroundColor: filter === 'draft' ? '#151D48' : '#FFFFFF',
                color: filter === 'draft' ? '#ffff' : '#000',
                padding: 10,
                width: 100,
                textTransform: 'none'
              }}
            >
              <CustomTypography fontWeight={filter === 'draft' ? 600 : 400}>Draft</CustomTypography>
            </Button>
          </Box>
        </div>
        <Box sx={{ mt: 1 }}>
          {selectedItems.length > 0 && (
            <Box sx={{ display: 'flex', gap: 2, mb: 2, mr: 1 }}>
              <Button
                onClick={() => setSelectedItems([])}
                style={{
                  backgroundColor: '#FFFFFF',
                  borderWidth: 1,
                  borderColor: '#333333',
                  width: 100,
                  color: '#333333',
                  textTransform: 'none'
                }}
              >
                <CustomTypography fontSize={14} fontWeight={600}>
                  Cancel
                </CustomTypography>
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                style={{ textTransform: 'none', width: 100 }}
              >
                <CustomTypography fontSize={14} fontWeight={600}>
                  Delete
                </CustomTypography>
              </Button>
            </Box>
          )}
        </Box>
      </div>

      {isMobile() ? (
        <MobileCardView data={data}></MobileCardView>
      ) : (
        <TableContainer
          sx={{
            maxHeight: '60vh',
            overflowY: 'auto',
            paddingInline: 0.4
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: 'separate',
              borderSpacing: '0 10px',
              paddingBottom: 10
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: '#EEEEEE',
                  borderRadius: '8px',
                  boxShadow: '0px 0px 4px 0px #00000040'
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    borderBottom: 'none',
                    padding: 5
                  }}
                >
                  <Checkbox
                    indeterminate={selectedItems.length > 0 && selectedItems.length < filteredData.length}
                    checked={filteredData.length > 0 && selectedItems.length === filteredData.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    padding: 0
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Name
                  </CustomTypography>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    padding: 0
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Category
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    padding: 0
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Status
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    padding: 0
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Supplier
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    padding: 0
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    No of SKU's
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    padding: 0
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Compliance Status
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderBottom: 'none',
                    padding: 0,
                    textAlign: 'center'
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Actions
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} style={{ borderBottom: 'none' }}>
                    <NoDataImage />
                  </TableCell>
                </TableRow>
              ) : (
                data?.map((product, index) => (
                  <TableRow
                    key={index}
                    style={{
                      marginBottom: 10,
                      backgroundColor: product.variants.find((x) => !x.sku) ? '#a61c1c' : '#fff',
                      borderRadius: 8,
                      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                      border: 'none',
                      padding: 0,
                      '&:last-child td, &:last-child th': {
                        border: 'none'
                      }
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: 'none',
                        borderTopLeftRadius: '8px',
                        borderBottomLeftRadius: '8px',
                        padding: 5
                      }}
                    >
                      <Checkbox
                        checked={selectedItems.indexOf(product._id) !== -1}
                        onChange={(event) => handleCheckboxClick(event, product._id)}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottom: 'none',
                        padding: 0,
                        // width: 200,
                        maxWidth: '150px'
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={fetchProductImage(product.images)}
                          alt={product.name}
                          style={{
                            width: 30,
                            height: 30,
                            objectFit: 'cover',
                            marginRight: 10,
                            marginTop: 5
                          }}
                        />
                        <CustomTypography
                          style={{ marginTop: 1 }}
                          fontSize="13px"
                          color="#333333"
                          fontWeight={400}
                        >
                          {product.name}
                        </CustomTypography>
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row" style={{ borderBottom: 'none', padding: 0 }}>
                      <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                        {product.product_type || ''}
                      </CustomTypography>
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ borderBottom: 'none', padding: 0 }}>
                      {product.status === 'active' || product.status === 'Active' ? (
                        <CustomTypography
                          fontSize="12px"
                          // color="#007613"
                          fontWeight={400}
                        >
                          <span
                            style={{
                              // backgroundColor: "#CBFFD3",
                              padding: '5px 10px',
                              // borderColor: "#007613",
                              display: 'inline-block'
                              // borderWidth: 2,
                            }}
                          >
                            {product.status}
                          </span>
                        </CustomTypography>
                      ) : (
                        <CustomTypography
                          fontSize="12px"
                          // color="#626262"
                          fontWeight={400}
                        >
                          <span
                            style={{
                              // backgroundColor: "#E1E1E1",
                              padding: '5px 10px',
                              // borderColor: "#747474",
                              display: 'inline-block'
                              // borderWidth: 2,
                            }}
                          >
                            {product.status}
                          </span>
                        </CustomTypography>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ borderBottom: 'none', padding: 0 }}>
                      <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                        {product.vendor?.name}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: 'none',
                        padding: 0
                        // display: "flex",
                        // justifyContent: "center",
                      }}
                    >
                      <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                        {product.variants?.length}
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: 'none',
                        padding: 0,
                        textAlign: 'start',
                        display: 'flex',
                        justifyContent: 'start'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginLeft: 20,
                          marginTop: 7
                        }}
                      >
                        {product.compliance_status.toLowerCase() === 'not started' ? (
                          <NotStarted></NotStarted>
                        ) : product.compliance_status.toLowerCase() === 'started' ? (
                          <Started></Started>
                        ) : product.compliance_status.toLowerCase() === 'in progress' ? (
                          <InProgress></InProgress>
                        ) : product.compliance_status.toLowerCase() === 'compliant' ? (
                          <Compliant></Compliant>
                        ) : (
                          <></>
                        )}
                        <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                          {product.compliance_status}
                        </CustomTypography>
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        borderBottom: 'none',
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        padding: 0
                      }}
                    >
                      {/* <Tooltip title="ASN request list">
                        <IconButton
                          style={{
                            color: "#007DFF",
                            padding: 0,
                            background: "#C3E1FF",
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 10,
                            boxShadow: "0px 0px 4px 0px #00000040",
                          }}
                          aria-label="view"
                          onClick={() => {
                            setOpenASNDataModal(true);
                            setSelectedProduct(product);
                          }}
                        >
                          <RvHookupIcon sx={{ height: 20, width: 20 }} />
                        </IconButton>
                      </Tooltip> */}

                      <IconButton
                        style={{
                          color: '#007DFF',
                          padding: 0,
                          background: '#C3E1FF',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: '0px 0px 4px 0px #00000040'
                        }}
                        aria-label="view"
                        onClick={() => handleViewClick(JSON.parse(JSON.stringify(product)))}
                      >
                        <img style={{ height: 14, width: 20 }} alt="delete" src="/eye.png"></img>
                      </IconButton>
                      <IconButton
                        style={{
                          color: '#FEC50F',
                          padding: 0,
                          background: '#FEC50F33',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 15,
                          boxShadow: '0px 0px 4px 0px #00000040',
                          marginTop: 5
                        }}
                        aria-label="edit"
                        onClick={() => handleEditClick(JSON.parse(JSON.stringify(product)))}
                      >
                        <BorderColorIcon sx={{ height: 20, width: 20 }} />
                      </IconButton>
                      <IconButton
                        style={{
                          color: '#D13438',
                          padding: 0,
                          background: '#D1343833',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: '0px 0px 4px 0px #00000040',
                          marginTop: 5
                        }}
                        aria-label="delete"
                        onClick={() => {
                          handleDeleteClick(product)
                        }}
                      >
                        <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
  useEffect(() => {
    if (openModal && modalRef.current) {
      modalRef.current.scrollTop = 0
    }
  }, [openModal])
  return (
    <div sx={{ minWidth: '100%' }}>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}

      <div
        style={{
          overflowY: 'scroll',
          display: 'flex',
          maxHeight: isMobile() ? '60vh' : '70vh',
          width: '100%',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::WebkitScrollbar': { width: 0 }
        }}
      >
        {viewMode === 'grid' ? renderGridView() : renderListView()}
      </div>
      {/* <TablePagination
        slotProps={{
          actions: {
            nextButton: {
              disabled: dataCount < rowsPerPage ? true : false
            }
          }
        }}
        nextIconButton={
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        }
        backIconButton={
          <IconButton>
            <KeyboardArrowLeft />
          </IconButton>
        }
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffffff',
          padding: '2px',
          borderTop: '1px solid #dddddd'
        }}
      /> */}
      <CustomPagination
        dataCount={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete  ${selectedProduct?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          // if (client) {
          //   handleDeleteProduct && handleDeleteProduct(selectedProduct);
          //   setOpenConfirmation(false);
          // } else {
          confirmDelete(e)
          // }
        }}
      />
      <ProductDetailsModal
        product={selectedProduct}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
      <ASNDataModal
        selectedProduct={selectedProduct}
        handleClose={() => setOpenASNDataModal(false)}
        open={openASNDataModal}
      ></ASNDataModal>
    </div>
  )
}

export default ProductList
