// productService.js

import axiosInstance from "../utils/axios-instance";

export const mappingTemplateService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post("/mapping-template", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetch: async (type) => {
    try {
      const response = await axiosInstance.get(
        `/mapping-template?type=${type}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  patch: async (id, data) => {
    try {
      let response = await axiosInstance.patch(id, "/mapping-template", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
