export const currentStatus = (key) => {
  if (Array.isArray(key) && key?.length === 0) {
    return 'New'
  } else {
    if (Array.isArray(key)) {
      return key[key.length - 1]
    }
  }

  switch (key) {
    case 'Accepted':
      return 'Accepted'
    case 'Shipment created':
      return 'Shipment created'
    default:
      return 'New'
  }
}
