import React, { useState, useEffect, useContext } from 'react'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import IconButton from '@mui/material/IconButton/IconButton'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import Button from '@mui/material/Button/Button'
import TextField from '@mui/material/TextField/TextField'
import { supplierService } from '../../utils/services/supplierService'
import BorderColorIcon from '@mui/icons-material/BorderColor'

import {
  Box,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider
} from '@mui/material'
import { clientService } from '../../utils/services/clientService'
import AppContext from '../context/context'
import CustomTypography from '../../globalStyles/fonts'
import CloseIcon from '@mui/icons-material/Close'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import { isMobile } from '../../globalStyles/mobile'
import SupplierDetailsModal from '../supplier-details-modal'
import ClientAutocomplete from '../clientAutocomplete'

const SupplierList = ({
  client,
  data,
  onEditData,
  onDeleteData,
  handleEditClient,
  handleDeleteClient,
  onSelectClient,
  viewMode,
  deleteTitle
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedSupplier, setSelectedSupplier] = useState(null)
  const [supplierData, setSupplierData] = useState({
    name: '',
    client: '',
    email: '',
    min_order_qty: '',
    max_order_qty: ''
  })
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [clientData, setClientData] = useState([])
  const [selectedClient, setSelectedClient] = useState({ name: '' })
  const clientId = localStorage.getItem('client_id')
  const { user } = useContext(AppContext)
  const [openRow, setOpenRow] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [openViewModal, setOpenViewModal] = useState(false)

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index)
  }
  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch()
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
    }
  }

  useEffect(() => {
    fetchClientData()
  }, [])

  const handleEditClick = (supplier) => {
    setSelectedSupplier(supplier)
    setSupplierData({
      name: supplier.name,
      email: supplier.email,
      min_order_qty: supplier.min_order_qty,
      max_order_qty: supplier.max_order_qty,
      client: supplier.client
    })
    setSelectedClient({
      name: supplier?.client?.name,
      _id: supplier.client?._id,
      business_name:supplier.client.business_name
    })
    setOpenModal(true)
  }

  const handleDeleteClick = (supplier) => {
    setSelectedSupplier(supplier)
    setOpenConfirmation(true)
  }

  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await supplierService.delete(selectedSupplier._id)
      onDeleteData && onDeleteData()
      setAnchorEl(null)
      setOpenConfirmation(false)
    } catch (error) {
      console.error('Delete failed:', error)
    } finally {
      setLoading(false)
    }
  }

  const validateForm = () => {
    const errors = {}
    if (!supplierData.name) {
      errors.name = 'Supplier name is required'
    }
     if (!supplierData.min_order_qty) {
       errors.min_order_qty = "Min Order Quantity is required";
     } else if (supplierData.min_order_qty < 0) {
       errors.min_order_qty = "Min Order Quantity must be greater than 0";
     }
     if (!supplierData.max_order_qty) {
       errors.max_order_qty = "Max Order Quantity is required";
     } else if (supplierData.max_order_qty < 0) {
       errors.max_order_qty = "Max Order Quantity must be greater than 0";
     }
     if (
       supplierData.min_order_qty >= 0 &&
       supplierData.max_order_qty >= 0 &&
       supplierData.min_order_qty > supplierData.max_order_qty
     ) {
        errors.min_order_qty = "Min Order Quantity must be less than Max Order Quantity";
        errors.max_order_qty = "Max Order Quantity must be greater than Min Order Quantity ";
     }
    if (!clientId && !selectedClient?.name) {
      errors.selectedClient = 'Client is requred'
    }
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleEditCarrier = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      setLoading(true)
      const client_id = clientId ? clientId : selectedClient?._id
      const response = await supplierService.edit(selectedSupplier._id, supplierData)
      onEditData && onEditData()
      setFormErrors({})
    } catch (error) {
      console.error('Edit failed:', error)
    } finally {
      setOpenModal(false)
      setAnchorEl(null)
      setLoading(false)
    }
  }
  const handleClientAssignClick = () => {
    setModalOpen(true)
  }
  const handleViewClick = (supplier) => {
    setSelectedSupplier(supplier)
    setOpenViewModal(true)
  }
  const renderGridView = () => (
    <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
      {data?.map((supplier) => (
        <Grid item xs={12} sm={6} md={3} key={supplier.id}>
          <Card sx={{ boxShadow: 3, cursor: 'pointer' }}>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    alignItems: 'flex-start',
                    width: '60%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 15
                  }}
                >
                  <Tooltip title={supplier?.name} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography style={{ fontWeight: '500' }} variant="h7">
                        {supplier?.name}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                {user.role == 'admin' ? (
                  <div style={{ display: 'flex' }}>
                    <IconButton
                      style={{
                        color: '#FEC50F',
                        padding: 0,
                        background: '#FEC50F33',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 15,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 5
                      }}
                      aria-label="edit"
                      onClick={() => handleEditClick(supplier)}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>

                    <IconButton
                      style={{
                        color: '#D13438',
                        padding: 0,
                        background: '#D1343833',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 5
                      }}
                      aria-label="delete"
                      onClick={() => handleDeleteClick(supplier)}
                    >
                      <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                    </IconButton>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: '100%', padding: 0.5 }}>
        {data?.map((supplier, index) => (
          <Card key={index} sx={{ marginBottom: 2 }}>
            <CardContent>
              <div style={{ width: '100%' }}>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Supplier Name: {supplier?.name}</Typography>
                  </Box>
                </Box>

                <Box mt={2} justifyContent={'flex-end'} display="flex">
                  <IconButton
                    style={{
                      color: '#007DFF',
                      padding: 0,
                      background: '#C3E1FF',
                      height: 30,
                      width: 30,
                      borderRadius: 5,
                      marginLeft: 10,
                      boxShadow: '0px 0px 4px 0px #00000040'
                    }}
                    aria-label="view"
                    onClick={() => {
                      handleViewClick(supplier)
                    }}
                  >
                    <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png"></img>
                  </IconButton>
                  {user.role == 'admin' ? (
                    <>
                      {/* <IconButton
                        style={{
                          color: '#007613',
                          padding: 0,
                          background: '#00761333',
                          borderRadius: 5,
                          marginLeft: 10,
                          height: 30,
                          width: 30,
                          padding: 0,
                          boxShadow: '0px 0px 4px 0px #00000040'
                        }}
                        aria-label="view"
                        onClick={() => {
                          handleClientAssignClick()
                          setSelectedSupplier(supplier)
                        }}
                      >
                        <img style={{ height: 14, width: 20 }} alt="client" src="/client-assign.png"></img>
                      </IconButton> */}
                      <IconButton
                        style={{
                          color: '#FEC50F',
                          padding: 0,
                          background: '#FEC50F33',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 15,
                          boxShadow: '0px 0px 4px 0px #00000040'
                          // marginTop: 5,
                        }}
                        aria-label="edit"
                        onClick={() => handleEditClick(supplier)}
                      >
                        <BorderColorIcon sx={{ height: 20, width: 20 }} />
                      </IconButton>
                      <IconButton
                        style={{
                          color: '#D13438',
                          padding: 0,
                          background: '#D1343833',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: '0px 0px 4px 0px #00000040'
                          // marginTop: 5,
                        }}
                        aria-label="delete"
                        onClick={() => handleDeleteClick(supplier)}
                      >
                        <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                      </IconButton>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>
    )
  }

  const renderListView = () => (
    <div
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        height: window.innerHeight < 650 ? '80vh' : 'auto'
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          className="custom-container"
          sx={{
            marginTop: 1,
            overflowY: 'auto',
            paddingInline: 0.4
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: 'separate',
              borderSpacing: '0 5px',
              paddingBottom: 7
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  marginBottom: 10,
                  backgroundColor: '#fff',
                  borderRadius: 8,
                  boxShadow: '0px 0px 4px 0px #00000040'
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: 8,
                    borderBottom: 'none',
                    textAlign: 'left',
                    padding: 10
                  }}
                >
                  <CustomTypography
                    style={{ marginLeft: 8 }}
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Supplier Name
                  </CustomTypography>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderBottom: 'none',
                    textAlign: 'center',
                    padding: 4,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    alignItems: 'center'
                  }}
                >
                  <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                    Actions
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((supplier, index) => (
                <React.Fragment>
                  <TableRow
                    onClick={() => handleRowClick(index)}
                    key={index}
                    style={{
                      marginBottom: 10,
                      backgroundColor: '#fff',
                      borderRadius: 8,
                      boxShadow: '0px 0px 4px 0px #00000040'
                    }}
                  >
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'left',
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        padding: 4
                      }}
                    >
                      <CustomTypography
                        style={{ marginLeft: 10 }}
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {supplier.name}
                      </CustomTypography>
                    </TableCell>

                    <TableCell
                      style={{
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 4,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        alignItems: 'center'
                      }}
                    >
                      {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  > */}
                      <IconButton
                        style={{
                          color: '#007DFF',
                          padding: 0,
                          background: '#C3E1FF',
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginLeft: 10,
                          boxShadow: '0px 0px 4px 0px #00000040'
                        }}
                        aria-label="view"
                        onClick={() => {
                          handleViewClick(supplier)
                        }}
                      >
                        <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png"></img>
                      </IconButton>
                      {user.role == 'admin' ? (
                        <>
                          {/* <IconButton
                            style={{
                              color: '#007613',
                              padding: 0,
                              background: '#00761333',
                              borderRadius: 5,
                              marginLeft: 10,
                              height: 30,
                              width: 30,
                              padding: 0,
                              boxShadow: '0px 0px 4px 0px #00000040'
                            }}
                            aria-label="view"
                            onClick={() => {
                              handleClientAssignClick()
                              setSelectedSupplier(supplier)
                            }}
                          >
                            <img
                              style={{ height: 14, width: 20 }}
                              alt="client"
                              src="/client-assign.png"
                            ></img>
                          </IconButton> */}
                          <IconButton
                            style={{
                              color: '#FEC50F',
                              padding: 0,
                              background: '#FEC50F33',
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              marginLeft: 15,
                              boxShadow: '0px 0px 4px 0px #00000040',
                              marginTop: 5
                            }}
                            aria-label="edit"
                            onClick={() => handleEditClick(supplier)}
                          >
                            <BorderColorIcon sx={{ height: 20, width: 20 }} />
                          </IconButton>
                          <IconButton
                            style={{
                              color: '#D13438',
                              padding: 0,
                              background: '#D1343833',
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              marginLeft: 10,
                              boxShadow: '0px 0px 4px 0px #00000040',
                              marginTop: 5
                            }}
                            aria-label="delete"
                            onClick={() => handleDeleteClick(supplier)}
                          >
                            <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                          </IconButton>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )

  return (
    <div sx={{ minWidth: "100%" }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <div
          style={{
            overflowY: "scroll",
            display: "flex",
            maxHeight: "calc(100vh - 80px)",
            width: "100%",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": { width: 0 },
          }}
        >
          {viewMode === "grid" ? renderGridView() : renderListView()}
        </div>
      )}
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setFormErrors({});
        }}
        maxWidth="md"
        // fullWidth
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>{"Edit Supplier"}</DialogTitle>
          <IconButton onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
                fontFamily={"poppins"}
              >
                Supplier Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={{ minWidth: 300 }}
                autoFocus
                margin="dense"
                size="small"
                placeholder="Enter Supplier Name"
                type="text"
                fullWidth
                value={supplierData.name}
                onChange={(e) =>
                  setSupplierData({
                    ...supplierData,
                    name: e.target.value,
                  })
                }
                error={formErrors?.name}
                helperText={formErrors?.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
                fontFamily={"poppins"}
              >
                Choose Client <span style={{ color: "red" }}>*</span>
              </Typography>
              <ClientAutocomplete
                hideLabel={true}
                selectedClient={selectedClient}
                onClientChange={(e) => {
                  setSupplierData({ ...supplierData, client: e });
                }}
                errorMessage={formErrors.selectedClient}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
                fontFamily={"poppins"}
              >
                Min Order Quantity <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={{ marginTop: 1, fontSize: 13, fontStyle: "Poppins" }}
                size="small"
                type="number"
                inputProps={{ min: 0 }}
                placeholder={`Enter Min order quantity`}
                //label="Name"
                variant="outlined"
                value={supplierData.min_order_qty}
                onChange={(e) =>
                  setSupplierData({
                    ...supplierData,
                    min_order_qty: e.target.value,
                  })
                }
                fullWidth
                autoFocus
                error={!!formErrors.min_order_qty}
                helperText={formErrors.min_order_qty}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
                fontFamily={"poppins"}
              >
                Min Order Quantity <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={{ marginTop: 1, fontSize: 13, fontStyle: "Poppins" }}
                size="small"
                type="number"
                placeholder={`Enter Max order quantity`}
                //label="Name"
                variant="outlined"
                value={supplierData.max_order_qty}
                onChange={(e) =>
                  setSupplierData({
                    ...supplierData,
                    max_order_qty: e.target.value,
                  })
                }
                fullWidth
                autoFocus
                error={!!formErrors.max_order_qty}
                helperText={formErrors.max_order_qty}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
                fontFamily={"poppins"}
              >
                Email
              </Typography>
              <TextField
                sx={{ marginTop: 1 }}
                type="email"
                size="small"
                label="Email"
                variant="outlined"
                value={supplierData.email}
                onChange={(e) =>
                  setSupplierData({ ...supplierData, email: e.target.value })
                }
                fullWidth
                autoFocus
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={() => {
              setOpenModal(false);
              setFormErrors({});
            }}
            className="cancel-button-style"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className="add-button-style"
            variant="contained"
            color="primary"
            onClick={(e) => handleEditCarrier(e)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmationModal
        DeleteConfirmationModal
        title={`Are you sure you want to delete ${selectedSupplier?.name} ?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          if (client) {
            handleDeleteClient && handleDeleteClient(selectedSupplier);
            setOpenConfirmation(false);
          } else {
            confirmDelete(e);
          }
        }}
      />
      {/* <AssignClient
        selectedData={selectedSupplier}
        mapKey="supplier"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      /> */}
      <SupplierDetailsModal
        supplier={selectedSupplier}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
    </div>
  );
}

export default SupplierList
