// api.js

import axiosInstance from "../axiosInstance";

export const shippingCostService = {
  create: async (obj) => {
    try {
      let response = await axiosInstance.post("/shipping-cost", obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let query = `/shipping-cost?$populate=client_id&$populate=shipment_service`;

      if (limit) query += `&$limit=${limit}`;
      if (skip) query += `&$skip=${skip}`;
      if (clientId) query += `&client_id=${clientId}`;
      //if (searchValue) query += `&type[$search]=${searchValue}`;

      let response = await axiosInstance.get(query);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  edit: async (id, obj) => {
    try {
      let response = await axiosInstance.patch(`/shipping-cost/${id}`, obj);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/shipping-cost/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
