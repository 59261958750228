import React, { useState } from 'react'
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Button,
  Tooltip
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { currentStatus } from '../../utils/currentOrderStatus'
import CustomTypography from '../../globalStyles/fonts'
export default function OrderTable({
  allOrders,
  onAcceptOrder,
  onCreateShipment,
  onCreateOverpack,
  onCreateManifest
}) {
  const [openRow, setOpenRow] = useState(null)

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index)
  }
  function convertUTCtoIST(utcTime) {
    // Convert the UTC time to a Date object
    const utcDate = new Date(utcTime)

    // Get the year
    const year = utcDate.getFullYear()

    // Get the month
    const month = utcDate.getMonth() + 1

    // Get the date
    const date = utcDate.getDate()

    // Format the date, month, and year
    const formattedDateTime = `${year}-${month.toString().padStart(2, '0')}-${date
      .toString()
      .padStart(2, '0')}`

    return formattedDateTime
  }
  return (
    <TableContainer
      sx={{ maxHeight: '65vh', paddingLeft: '10px', paddingBottom: '10px', paddingRight: '10px' }}
    >
      <Table
        sx={{ borderSpacing: '0 5px', borderCollapse: 'separate' }}
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead>
          <TableRow
            style={{
              backgroundColor: '#EEEEEE',
              borderRadius: 8,
              boxShadow: '0px 0px 4px 0px #00000040'
            }}
          >
            <TableCell
              style={{
                backgroundColor: '#EEEEEE',
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                padding: 10
              }}
            >
              <CustomTypography fontSize={14} fontWeight={600}>
                Order No
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#EEEEEE',
                borderBottom: 'none',
                padding: 7
              }}
            >
              <CustomTypography fontSize={14} fontWeight={600}>
                Customer Name
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#EEEEEE',
                borderBottom: 'none',
                padding: 7
              }}
            >
              <CustomTypography fontSize={14} fontWeight={600}>
                Customer Email
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#EEEEEE',
                borderBottom: 'none',
                padding: 7
              }}
            >
              <CustomTypography fontSize={14} fontWeight={600}>
                Date
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#EEEEEE',
                borderBottom: 'none',
                padding: 7
              }}
            >
              <CustomTypography fontSize={14} fontWeight={600}>
                Destination
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#EEEEEE',
                borderBottom: 'none',
                padding: 7
              }}
            >
              <CustomTypography fontSize={14} fontWeight={600}>
                Status
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: '#EEEEEE',
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                padding: 7
              }}
            >
              <CustomTypography fontSize={14} fontWeight={600}>
                Action
              </CustomTypography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ paddingBottom: 5 }}>
          {allOrders?.map((row, index) => (
            <React.Fragment key={index}>
              <TableRow
                sx={{
                  backgroundColor: '#FFFFFFEE',
                  cursor: 'pointer',
                  marginBottom: 10,
                  borderRadius: 2,
                  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                  border: 'none',
                  '&:last-child td, &:last-child th': {
                    border: 'none'
                  }
                }}
                onClick={() => handleRowClick(index)}
                hover
              >
                <TableCell
                  sx={{
                    borderBottom: 'none',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    padding: 1
                  }}
                >
                  <CustomTypography fontSize={13}>{row.order_number}</CustomTypography>
                </TableCell>
                <TableCell style={{ borderBottom: 'none', padding: 1 }}>
                  {' '}
                  <CustomTypography
                    fontSize={13}
                  >{`${row.billing_address?.first_name} ${row.billing_address?.last_name}`}</CustomTypography>
                </TableCell>
                <TableCell style={{ borderBottom: 'none', padding: 1 }}>
                  <CustomTypography fontSize={13}>{row.customer?.email || ''}</CustomTypography>
                </TableCell>
                <TableCell sx={{ width: '200px', borderBottom: 'none', padding: 1 }}>
                  <CustomTypography fontSize={13}>{row.order_date}</CustomTypography>
                </TableCell>
                <Tooltip
                  title={`${row.shipping_address?.address1} ,${row.shipping_address?.city} , ${row.shipping_address?.country}`}
                >
                  <TableCell sx={{ maxWidth: '200px', borderBottom: 'none', padding: 1 }}>
                    <CustomTypography
                      fontSize={13}
                    >{`${row.shipping_address?.address1} ,${row.shipping_address?.city} , ${row.shipping_address?.country}`}</CustomTypography>
                  </TableCell>
                </Tooltip>

                <TableCell sx={{ color: 'green', borderBottom: 'none', padding: 1 }}>
                  <CustomTypography fontSize={13}>{currentStatus(row.shipment_status)}</CustomTypography>
                </TableCell>
                {row.shipment_status?.length > 0 ? (
                  currentStatus(row.shipment_status) === 'Manifest created' ? (
                    <TableCell
                      sx={{
                        borderBottom: 'none',
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        padding: 1
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 1,
                          backgroundColor: '#71B179',
                          padding: 0.5,
                          minWidth: '70%',
                          maxWidth: 150,
                          border: '1px solid #007D14'
                        }}
                      >
                        <CheckIcon sx={{ color: 'white', mr: 1 }} />
                        <CustomTypography fontSize={13} style={{ color: '#FFF' }}>
                          Done
                        </CustomTypography>
                      </Box>
                    </TableCell>
                  ) : (
                    <TableCell
                      sx={{
                        borderBottom: 'none',
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        padding: 1
                      }}
                    >
                      <Button
                        onClick={() => {
                          if (currentStatus(row.shipment_status) === 'Shipment created') {
                            onCreateOverpack && onCreateOverpack(row)
                          } else if (currentStatus(row.shipment_status) === 'Overpack created') {
                            onCreateManifest && onCreateManifest(row)
                          } else if (currentStatus(row.shipment_status) === 'Manifest created') {
                            console.log('Done')
                          } else if (currentStatus(row.shipment_status) === 'Accepted') {
                            onCreateShipment && onCreateShipment(row)
                          }
                        }}
                        sx={{
                          backgroundColor: '#007DFF',
                          color: '#FFF',
                          textTransform: 'none',
                          fontFamily: 'Poppins'
                        }}
                      >
                        {currentStatus(row.shipment_status) === 'Accepted'
                          ? `Create Shipment`
                          : currentStatus(row.shipment_status) === 'Shipment created'
                          ? 'Create Overpack'
                          : currentStatus(row.shipment_status) === 'Overpack created'
                          ? 'Create Manifest'
                          : ''}
                      </Button>
                    </TableCell>
                  )
                ) : (
                  <TableCell
                    sx={{
                      borderBottom: 'none',
                      borderTopRightRadius: 8,
                      borderBottomRightRadius: 8,
                      padding: 1
                    }}
                  >
                    <Box
                      onClick={() => onAcceptOrder && onAcceptOrder(row, 'Accepted')}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        backgroundColor: '#00761333',
                        padding: 0.5,
                        minWidth: '70%',
                        maxWidth: 150,
                        border: '1px solid #007D14'
                      }}
                    >
                      <CheckIcon sx={{ color: 'green', mr: 1 }} />
                      <CustomTypography style={{ fontSize: 12 }}>Accept Order</CustomTypography>
                    </Box>
                    <Box
                      onClick={() => console.log('cancel')}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '70%',
                        maxWidth: 150,
                        background: '#FF000033',
                        padding: 0.5,
                        border: '1px solid #FF0000'
                      }}
                    >
                      <CloseIcon sx={{ color: 'red', mr: 1 }} />
                      <CustomTypography style={{ fontSize: 12 }}>Cancel Order</CustomTypography>
                    </Box>
                  </TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={8}>
                  <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <TableContainer component={Paper} style={{ border: '1px solid #cccccc' }}>
                        <Table>
                          <TableHead>
                            <TableRow style={{ backgroundColor: '#d3e5ff' }}>
                              <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                                {/* <CustomTypography>Order No : {row.orderNo}</CustomTypography> */}
                              </TableCell>
                              <TableCell colSpan={2} align="left" style={{ borderBottom: 'none' }}>
                                <CustomTypography align="left" style={{ fontWeight: '600' }}>
                                  STATUS
                                </CustomTypography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Shipment Tracking Number :</CustomTypography>
                              </TableCell>
                              <TableCell>
                                <CustomTypography>-------</CustomTypography>
                              </TableCell>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Status :</CustomTypography>
                              </TableCell>
                              <TableCell colSpan={2}>
                                <CustomTypography fontSize={13}>Order Accepted</CustomTypography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>Over-Pack ID :</TableCell>
                              <TableCell>
                                <CustomTypography>-----</CustomTypography>
                              </TableCell>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Over-Pack Tracking No :</CustomTypography>
                              </TableCell>
                              <TableCell colSpan={2}>
                                <CustomTypography>-----</CustomTypography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Manifest ID :</CustomTypography>
                              </TableCell>
                              <TableCell>
                                <CustomTypography>----</CustomTypography>
                              </TableCell>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Manifest Tracking No :</CustomTypography>
                              </TableCell>
                              <TableCell colSpan={2}>----</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Carrier :</CustomTypography>
                              </TableCell>
                              <TableCell>
                                <CustomTypography fontSize={13}>Carrier ABC</CustomTypography>
                              </TableCell>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Date :</CustomTypography>
                              </TableCell>
                              <TableCell colSpan={2}>
                                <CustomTypography fontSize={13}>
                                  {convertUTCtoIST(row.createdAt)}
                                </CustomTypography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Origin :</CustomTypography>
                              </TableCell>
                              <TableCell>
                                <CustomTypography fontSize={13}>Los Angeles, CA</CustomTypography>
                              </TableCell>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Destination :</CustomTypography>
                              </TableCell>
                              <TableCell colSpan={2}>
                                <CustomTypography
                                  fontSize={13}
                                >{`${row.shipping_address?.address1} , ${row.shipping_address?.city} ,${row.shipping_address?.country}`}</CustomTypography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Current Location :</CustomTypography>
                              </TableCell>
                              <TableCell>
                                <CustomTypography fontSize={13}>Kansas City, MO</CustomTypography>
                              </TableCell>
                              <TableCell style={{ backgroundColor: '#f5f5f5' }}>
                                <CustomTypography fontSize={13}>Last Update :</CustomTypography>
                              </TableCell>
                              <TableCell colSpan={2}>
                                <CustomTypography fontSize={13}>2024-06-02 14:00</CustomTypography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
