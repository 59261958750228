import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  Box,
  Badge,
  Popover,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NotificationModal from "./components/notification-modal";
import AppContext from "./components/context";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/material/styles";
import Sidebar from "./components/sidebar";
const ResponsiveImage = styled("img")({
  width: "100px",
  height: "50px",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Layout = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [selectedTab, setSelectedTab] = useState("");
  const [showDrawer, setShowDrawer] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState(180);
  const [notifications, setNotifications] = React.useState([]);
  const [anchorNotificationEl, setAnchorNotificationEl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:700px)");
  const { setIsSidebarOpen, isSidebarOpen } = React.useContext(AppContext);
  // Update the title and the selected tab based on the current URL
  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setSelectedTab(
      localStorage.getItem("current_tab")
        ? JSON.parse(localStorage.getItem("current_tab"))?.navigateTo
        : path || "Dashboard"
    ); // Default to 'Dashboard' if no path is found
  }, [location]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleNotificationClick = (event) => {
    setAnchorNotificationEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorNotificationEl(null);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    handleMenuClose();
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavigation = (menuItem) => {
    localStorage.setItem("current_tab", JSON.stringify(menuItem));
    setSelectedTab(menuItem.navigateTo);
    navigate(menuItem.navigateTo);
  };
  const userData = JSON.parse(localStorage.getItem("user"));
  React.useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <AppBar
          sx={{
            display: "flex",
            width: isMobile
              ? "100vw"
              : drawerWidth === 180
              ? `calc(100% - 180px)`
              : `calc(100% - 80px)`,
            height: 60,
            justifyContent: "center",
            backgroundColor: "#f0f0f0",
            color: "gray",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            boxShadow: "none", // Removes shadow
            borderLeft: "1px solid #E0E0E0",
            borderBottom: "1px solid #E0E0E0",
            flexDirection: "row",
          }}
          position="fixed"
        >
          {isMobile ? (
            <IconButton
              onClick={() => {
                setDrawerOpen(!drawerOpen);
                setShowDrawer(!showDrawer);
                setIsSidebarOpen(!isSidebarOpen);
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <></>
          )}

          <Toolbar
            sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <Box sx={{ marginRight: 10 }}>
              <IconButton
                size="large"
                edge="end"
                color="primary"
                aria-label="notifications"
                onClick={handleNotificationClick}
              >
                <Badge badgeContent={notifications.length} color="error">
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Box
                onClick={handleMenuClick}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography color="#333333" fontSize={"15px"} fontWeight={500}>
                  {userData?.name}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                size="large"
                edge="end"
                color="primary"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuClick}
              >
                <ArrowDropDownIcon color="#151D48" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  sx={{ color: "#000" }}
                  onClick={() => {
                    navigate("/profile");
                    handleMenuClose();
                  }}
                >
                  UserProfile
                </MenuItem>

                <MenuItem
                  sx={{ color: "#000" }}
                  onClick={() => {
                    handleLogout();
                    handleMenuClose();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            marginTop: "60px",
            //  borderRight: "1px solid #E0E0E0",
          }}
        >
          <Drawer
            variant={isMobile ? "temporary" : "permanent"}
            open={drawerOpen}
            onClose={toggleDrawer}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              width: isMobile ? 150 : drawerWidth,
              // borderRight: isMobile ? "" : "1px solid #E0E0E0",
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: isMobile ? 150 : drawerWidth,
                // boxSizing: "border-box",
                // borderRight: "1px solid #E0E0E0",
              },
            }}
          >
            {isMobile ? (
              <></>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  marginLeft: isSidebarOpen ? 2 : 0,
                  padding: 1,
                  justifyContent: "space-between",
                }}
              >
                {!isSidebarOpen ? (
                  <ResponsiveImage
                    style={{ borderRadius: 10, objectFit: "contain" }}
                    src={"./order.png"}
                    alt="Logo"
                  />
                ) : (
                  <ResponsiveImage src="/DropSkipLogo.png" alt="Logo" />
                )}
                {isSidebarOpen ? (
                  <div
                    style={{
                      // border: "1px solid #eaeaea",
                      height: 35,
                      width: 35,
                      borderRadius: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setDrawerWidth(drawerWidth === 180 ? 80 : 180);
                      setDrawerOpen(!drawerOpen);
                      setShowDrawer(!showDrawer);
                      setIsSidebarOpen(!isSidebarOpen);
                    }}
                  >
                    <ChevronLeftIcon
                      sx={{
                        height: 25,
                        width: 25,
                        color: "#000",
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {!isSidebarOpen ? (
                  <div
                    style={{
                      height: 10,
                      width: 10,
                      borderRadius: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      right: 10,
                    }}
                    onClick={() => {
                      setDrawerWidth(drawerWidth === 180 ? 80 : 180);
                      setDrawerOpen(!drawerOpen);
                      setShowDrawer(!showDrawer);
                      setIsSidebarOpen(!isSidebarOpen);
                    }}
                  >
                    <ChevronRightIcon
                      sx={{
                        height: 25,
                        width: 25,
                        color: "#000",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </Box>
            )}
            <Box
              sx={{
                height: "100%",
                overflowY: "auto",
              }}
            >
              <Sidebar
                hideSideBar={isMobile ? false : !showDrawer}
                onClickMenu={toggleDrawer}
                onClickSubMenu={toggleDrawer}
              />
            </Box>
          </Drawer>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              padding: 2,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              backgroundColor: "#F7F8F9",
              height: `calc(100vh - 114px)`,
              overflow: "hidden",
              overflowX: "hidden",
            }}
          >
            {children}
          </Box>
        </Box>
        <Popover
          open={Boolean(anchorNotificationEl)}
          anchorEl={anchorNotificationEl}
          onClose={handleNotificationClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <NotificationModal onClose={handleNotificationClose} />
        </Popover>
      </Box>
    </>
  );
};

export default Layout;
