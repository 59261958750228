import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  Box,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { styled } from "@mui/system";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DimensionsAndWeightInputWithUnit from "../../components/dimension-and-weight-unit";
import { productService } from "../../services/product-service";
import config from "../../config";
import { imageService } from "../../services/image-service";
import { currencyTypes } from "../../utils/currency";
const defaultImage = "/product.png";
const ManageProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);

  const initialData = {
    name: "",
    url: "",
    short_description: "",
    pga_flag: "",
    description: "",
    images: [],
    status: "",
    product_type: "",
    variants: [
      {
        option: [
          {
            option_type: "General",
            value: "",
          },
        ],
        price: "",
        weight: "",
        weight_unit: "",
        height: "",
        height_unit: "",
        width: "",
        width_unit: "",
        length: "",
        length_unit: "",
        sku: "",
        inventory: 0,
        hs_code: "",
        country_of_origin: "",
        hts_code: "",
      },
    ],
    currency: "",
    vendor: null,
  };

  // If editing, pre-fill the form with the passed data
  const [productData, setProductData] = useState(initialData);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [coverImageIndex, setCoverImageIndex] = useState(-1);
  const [imageUrls, setImageUrls] = useState([]);
  const [editingImageIndex, setEditingImageIndex] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [featureImages, setFeatureImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const productId = location.state?.product;
  const fetchProductDetails = async (productId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await productService.fetchById(productId);
      let product = JSON.parse(JSON.stringify(response));
      setProductData(product);
      setSelectedProduct(product);
      const fetchedImages = product.images.map(fetchImage);
      setSelectedImages(
        product.images.map((img) => ({
          image: img.image,
          feature_image: img.feature_image,
        }))
      );
      setImageUrls(fetchedImages);
      const coverIndex = product.images.findIndex((img) => img.feature_image);
      setCoverImageIndex(coverIndex);
      setFeatureImages(product.images.map((img) => img.feature_image));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchImage = (image) => {
    if (image && image.image) {
      return `${config.baseURL}/fetch-file/${image.image}`;
    }
    return defaultImage;
  };
  useEffect(() => {
    if (location.state?.product) {
      if (location.state.product) {
        // Fetch fresh data for editing
        fetchProductDetails(location.state.product);
      } else {
        // Use the passed product data directly
        setProductData(location.state.product);
      }
    }
  }, [location.state]);

  // Simulating supplier data load
  useEffect(() => {
    setLoadingSuppliers(true);
    setTimeout(() => {
      setSuppliers([
        { label: "Supplier 1", id: 1 },
        { label: "Supplier 2", id: 2 },
        { label: "Supplier 3", id: 3 },
      ]);
      setLoadingSuppliers(false);
    }, 1000);
  }, []);

  const handleInputChange = (field, value) => {
    setProductData((prev) => ({ ...prev, [field]: value }));
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...productData.variants];
    updatedVariants[index][field] = value;
    setProductData((prev) => ({ ...prev, variants: updatedVariants }));
  };

  const handleAddVariant = () => {
    setProductData((prev) => ({
      ...prev,
      variants: [...prev.variants, { ...initialData.variants[0] }],
    }));
  };

  const handleRemoveVariant = (index) => {
    const updatedVariants = [...productData.variants];
    updatedVariants.splice(index, 1);
    setProductData((prev) => ({ ...prev, variants: updatedVariants }));
  };

  const handleSubmit = async () => {
    console.log(productData, productId, location.state?.isEditing);
    try {
      if (location.state?.isEditing) {
        const imageField =
          selectedImages.length > 0
            ? selectedImages.map((image) => ({
                image: image.image,
                feature_image: image.feature_image,
              }))
            : selectedProduct.images;

        const response = await productService.edit(
          { ...productData, images: imageField },
          productId
        );
        console.log(response);
      } else {
        const response = await productService.create({
          ...productData,
          images: imageUrl,
        });
        console.log(response);
      }

      navigate("/products");
    } catch (error) {
      console.error("Error saving product:", error);
      // Handle error (show error message to user)
    }
  };
  const handleCancel = () => {
    navigate("/products"); // Redirect to the product list screen
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      const file = files[0];
      try {
        const imageData = await imageService.upload(file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedImages((prevImages) => {
          const newImages = [...prevImages];
          if (editingImageIndex !== null) {
            newImages[editingImageIndex] = {
              image: imageData.key,
              feature_image: editingImageIndex === coverImageIndex,
            };
          } else {
            newImages.push({
              image: imageData.key,
              feature_image: false,
            });
          }
          setImageUrl(newImages);
          return newImages;
        });
        setImageUrls((prevUrls) => {
          const newUrls = [...prevUrls];
          if (editingImageIndex !== null) {
            newUrls[editingImageIndex] = imageUrl;
          } else {
            newUrls.push(imageUrl);
          }
          return newUrls;
        });
        setEditingImageIndex(null);
      } catch (error) {
        console.error("Image upload failed:", error);
      } finally {
        e.target.value = null;
      }
    }
  };

  console.log(imageUrl);
  console.log(imageUrls);
  console.log(selectedImages);
  const handleChooseImage = (index = null) => {
    setEditingImageIndex(index);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      fileInputRef.current.click();
    }
  };
  const handleCheckCoverImage = (index) => {
    setCoverImageIndex(index === coverImageIndex ? -1 : index);
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.forEach((image, i) => {
        image.feature_image = i === index;
      });
      return updatedImages;
    });
  };
  const removeSelectedImage = (indexToRemove) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
    setImageUrls((prevUrls) =>
      prevUrls.filter((_, index) => index !== indexToRemove)
    );

    const updatedImages = [...(selectedProduct?.images || [])];
    updatedImages.splice(indexToRemove, 1);

    const updatedSelectedProduct = {
      ...selectedProduct,
      images: updatedImages,
    };

    setSelectedProduct(updatedSelectedProduct);

    if (coverImageIndex === indexToRemove) {
      setCoverImageIndex(-1);
      setFeatureImages((prevFeatureImages) => {
        const updatedFeatureImages = [...prevFeatureImages];
        updatedFeatureImages[indexToRemove] = false;
        return updatedFeatureImages;
      });
    }
  };
  const handleOptionChange = (index, optionIndex, field, value) => {
    const updatedVariants = [...productData.variants];
    updatedVariants[index].option[optionIndex][field] = value;
    setProductData((prev) => ({
      ...prev,
      variants: updatedVariants,
    }));
  };

  const handleAddOption = (index) => {
    const updatedVariants = [...productData.variants];
    updatedVariants[index].option.push({
      option_type: "General",
      value: "",
    });
    setProductData((prev) => ({
      ...prev,
      variants: updatedVariants,
    }));
  };

  const handleRemoveOption = (index, optionIndex) => {
    const updatedVariants = [...productData.variants];
    updatedVariants[index].option.splice(optionIndex, 1);
    setProductData((prev) => ({
      ...prev,
      variants: updatedVariants,
    }));
  };
  const onChangeDimensionAndWight = (index, field, value) => {
    if (
      ["weight", "height", "width", "length", "weight_unit"].includes(field)
    ) {
      handleVariantChange(index, field, value);
    } else if (field === "dimension_unit") {
      const updatedVariants = [...productData.variants];
      if (updatedVariants[index]) {
        updatedVariants[index]["height_unit"] = value;
        updatedVariants[index]["width_unit"] = value;
        updatedVariants[index]["length_unit"] = value;

        setProductData({ ...productData, variants: updatedVariants });
      }
    }
  };

  return (
    <Box>
      <Typography sx={{ padding: 1 }} variant="h5">
        {location.state?.product ? "Edit Product" : "Add Product"}
      </Typography>
      <Box
        sx={{
          maxHeight: "calc(100vh - 150px)",
          overflowY: "auto",
          scrollBehavior: "smooth",
          padding: 1,
          "&::-webkit-scrollbar": {
            width: "7px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#D9D9D9",
            borderRadius: "14px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#273894",
            borderRadius: "6px",
            "&:hover": {
              backgroundColor: "#555",
            },
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ backgroundColor: "#FFFFFF" }}
              size="small"
              fullWidth
              label="Name"
              value={productData.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ backgroundColor: "#FFFFFF" }}
              size="small"
              fullWidth
              label="Category"
              value={productData.product_type}
              onChange={(e) =>
                handleInputChange("product_type", e.target.value)
              }
            />
          </Grid>

          {/* Row for Short Description and PGA Flag */}
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ backgroundColor: "#FFFFFF" }}
              size="small"
              fullWidth
              label="Short Description"
              value={productData.short_description}
              onChange={(e) =>
                handleInputChange("short_description", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{ backgroundColor: "#FFFFFF" }}
              size="small"
              fullWidth
              label="PGA Flag"
              value={productData.pga_flag}
              onChange={(e) => handleInputChange("pga_flag", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              sx={{ backgroundColor: "#FFFFFF" }}
              size="small"
              fullWidth
              label="URL"
              value={productData.url}
              onChange={(e) => handleInputChange("url", e.target.value)}
            />
          </Grid>

          {/* Description with Quill Editor */}
          <Grid item xs={12}>
            <Grid item xs={12} sm={12}>
              <div style={{ marginBottom: "30px" }}>
                <ReactQuill
                  theme="snow"
                  value={productData.description}
                  onChange={(e) => handleInputChange("description", e)}
                  placeholder="Enter product description"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#FFFFFF",
                    height: "120px",
                  }}
                />
                <style>
                  {`
          .ql-container {
            background-color: #FFFFFF !important;
            height: 100%; 
          }
          .ql-editor {
            min-height: 100%; 
            background-color: #FFFFFF !important; 
            padding: 10px; 
            border-radius: 8px;
          }
            .ql-tooltip {
        margin-left: 100px !important; /* Add margin to the modal */
      }
        .ql-editor a {
        cursor: pointer; /* Change cursor to pointer for links */
        text-decoration: underline; /* Optional: underline the link for better visibility */
      }
        `}
                </style>
              </div>
            </Grid>
          </Grid>

          {/* Image Upload */}
          <Grid item xs={12} sx={{ marginTop: 2 }} sm={12}>
            <Box
              sx={{
                width: "97%",
                border: 1,
                borderColor: "#D1D1D1",
                borderRadius: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "150px",
                p: 2,
                backgroundColor: "#FFFFFF",
                marginTop: 1,
              }}
            >
              <input
                id="productImageInput"
                type="file"
                accept="image/*"
                ref={fileInputRef}
                multiple
                style={{ display: "none" }}
                onChange={handleImageChange}
              />

              {selectedImages.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "97%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      gap: 2,
                      mt: 2,
                      maxHeight: 250,
                      overflowY: "auto",
                      border: "1px solid #000",
                      borderRadius: 1,
                      p: 2,
                    }}
                  >
                    {selectedImages?.map((image, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          p: 1,
                          border: 1,
                          borderRadius: 1,
                          maxWidth: { xs: "100%", sm: "auto" },
                        }}
                      >
                        <img
                          src={imageUrls[index]}
                          alt={`image ${index}`}
                          style={{
                            maxWidth: 100,
                            maxHeight: 100,
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <IconButton onClick={() => handleChooseImage(index)}>
                            <EditIcon color="primary" />
                          </IconButton>
                          <input
                            type="checkbox"
                            checked={index === coverImageIndex}
                            onChange={() => handleCheckCoverImage(index)}
                          />
                          <IconButton
                            onClick={() => removeSelectedImage(index)}
                          >
                            <DeleteIcon style={{ color: "red" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <IconButton onClick={() => handleChooseImage(null)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              ) : (
                <Button
                  sx={{
                    minWidth: { xs: 150, sm: 200 },
                    color: "#707070",
                    textTransform: "none",
                  }}
                  color="primary"
                  onClick={() => handleChooseImage(null)}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <IconButton aria-label="upload" sx={{ mb: 1, p: 0 }}>
                      <InsertPhotoIcon />
                    </IconButton>
                    <Typography variant="body2">Upload</Typography>
                  </Box>
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              value={productData.currency}
              disableClearable={!productData.currency}
              onChange={(e, newValue) =>
                setProductData({ ...productData, currency: newValue })
              }
              size="small"
              options={currencyTypes}
              renderInput={(params) => (
                <TextField
                  placeholder={"Currency"}
                  {...params}
                  //   label="$ 00.00"
                  variant="outlined"
                  sx={{ backgroundColor: "#FFFFFF" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              sx={{ backgroundColor: "#FFFFFF" }}
              value={productData.status}
              onChange={(event, newValue) =>
                handleInputChange("status", newValue)
              }
              options={["active", "draft"]}
              renderInput={(params) => (
                <TextField {...params} label="Status" size="small" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              sx={{ backgroundColor: "#FFFFFF" }}
              size="small"
              fullWidth
              label="Supplier"
              value={productData.vendor}
              onChange={(e) => handleInputChange("vendor", e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Variants</Typography>
            {productData.variants.map((variant, index) => (
              <Box
                sx={{ borderColor: "#d2d2d2" }}
                key={index}
                border={2}
                borderRadius={2}
                p={2}
                mb={2}
                mt={1}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                      Options
                    </Typography>
                    {variant.option.map((option, optionIndex) => (
                      <Grid
                        container
                        spacing={2}
                        key={optionIndex}
                        sx={{ mb: 2 }}
                      >
                        <Grid item xs={5}>
                          <TextField
                            sx={{ backgroundColor: "#FFFFFF" }}
                            size="small"
                            fullWidth
                            label="Option Type"
                            value={option.option_type}
                            onChange={(e) =>
                              handleOptionChange(
                                index,
                                optionIndex,
                                "option_type",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            sx={{ backgroundColor: "#FFFFFF" }}
                            size="small"
                            fullWidth
                            label="Value"
                            value={option.value}
                            onChange={(e) =>
                              handleOptionChange(
                                index,
                                optionIndex,
                                "value",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() =>
                              handleRemoveOption(index, optionIndex)
                            }
                            disabled={variant.option.length === 1}
                          >
                            <DeleteIcon
                              color={
                                variant.option.length === 1
                                  ? "disabled"
                                  : "error"
                              }
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                    <Button
                      size="small"
                      onClick={() => handleAddOption(index)}
                      startIcon={<AddIcon />}
                      sx={{ mb: 1 }}
                    >
                      Add Option
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                      size="small"
                      fullWidth
                      label="Price"
                      value={variant.price}
                      onChange={(e) =>
                        handleVariantChange(index, "price", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                      // type="number"
                      size="small"
                      variant="outlined"
                      label="Enter HS Code"
                      value={variant.hs_code}
                      onChange={(e) =>
                        handleVariantChange(index, "hs_code", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                      // type="number"
                      size="small"
                      variant="outlined"
                      label="Enter HTS Code"
                      value={variant.hts_code}
                      onChange={(e) =>
                        handleVariantChange(index, "hts_code", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                      // type="number"
                      size="small"
                      variant="outlined"
                      label="Enter Country of Origin"
                      value={variant.country_of_origin}
                      onChange={(e) =>
                        handleVariantChange(
                          index,
                          "country_of_origin",
                          e.target.value
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                      type="number"
                      size="small"
                      variant="outlined"
                      label="Enter Inventory"
                      value={variant.inventory}
                      onChange={(e) =>
                        handleVariantChange(index, "inventory", e.target.value)
                      }
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      sx={{ marginTop: 0.5, backgroundColor: "#FFFFFF" }}
                      size="small"
                      fullWidth
                      label="SKU"
                      value={variant.sku}
                      onChange={(e) =>
                        handleVariantChange(index, "sku", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <DimensionsAndWeightInputWithUnit
                      dimensions={{
                        height: variant.height,
                        length: variant.length,
                        width: variant.width,
                      }}
                      weight={variant.weight}
                      onChange={(e) =>
                        onChangeDimensionAndWight(index, e.field, e.value)
                      }
                      dimensionUnitValue={variant.height_unit}
                      weightUnitValue={variant.weight_unit}
                      requiredField={true}
                    ></DimensionsAndWeightInputWithUnit>
                  </Grid>

                  <Grid item xs={6}>
                    <IconButton
                      disabled={productData.variants.length === 1}
                      onClick={() => handleRemoveVariant(index)}
                    >
                      <DeleteIcon
                        color={
                          productData.variants.length === 1
                            ? "disabled"
                            : "error"
                        }
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Button onClick={handleAddVariant} startIcon={<AddIcon />}>
              Add Variant
            </Button>
          </Grid>

          {/* Submit Button */}
          <Grid
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              paddingBottom: 2,
              gap: 2,
            }}
            item
          >
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{ textTransform: "none", border: "1px solid #DC585C" }}
            >
              <Typography
                fontFamily={"poppins"}
                fontSize={"14px"}
                fontWeight={500}
                color="#DC585C"
              >
                Cancel
              </Typography>
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                backgroundColor: "#DC585C",
              }}
            >
              <Typography
                fontFamily={"poppins"}
                fontSize={"14px"}
                fontWeight={500}
                color="#ffff"
              >
                {" "}
                {location.state?.product ? "Save" : "Add"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ManageProduct;
