// api.js

import axios from 'axios'
import axiosInstance from '../axiosInstance'
import config from '../../config'
export const orderService = {
  fetch: async (limit, skip, clientId, searchValue, statusFilter, startDate, endDate, signal) => {
    try {
      let response = await axiosInstance.get(
        `/order?${clientId ? 'client_id=' + clientId : ''}&$populate=client_id&$limit=${limit || 15}&$skip=${
          skip || 0
        }&$sort[createdAt]=-1&${searchValue ? `order_id[$search]=${searchValue}` : ''}${
          statusFilter ? `&status=${statusFilter}` : ''
        }${startDate ? `&order_date[$gte]=${startDate}` : ''}${
          endDate ? `&order_date[$lte]=${endDate}` : ''
        }`,
        signal
      )
      return response.data
    } catch (error) {
      throw error
    }
  },
  get: async (id) => {
    try {
      let response = await axiosInstance.get(`/order/${id}?$populate=client_id`)
      return response.data
    } catch (error) {
      throw error
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/order/${id}`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  trackOrder: async (orderId) => {
    try {
      const response = await axiosInstance.get(`/track-order/${orderId}`)
      return response.data
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Error fetching tracking details: Page not found')
        return { error: 'Tracking details not found' }
      } else {
        console.error('Error fetching tracking details:', error)
        return { error: 'An unexpected error occurred' }
      }
    }
  },

  fetchOrderTrail: async (orderId) => {
    try {
      const response = await axiosInstance.get(`/order-trail-custom/${orderId}`)
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetchOrderTrailWithoutAuth: async (orderId) => {
    try {
      const response = await axios.get(`${config.baseURL}/order-trail-custom/${orderId}`)
      return response.data
    } catch (error) {
      throw error
    }
  },
  exportOrders: async (clientId) => {
    try {
      let response = await axiosInstance.get(`/export-orders?client_id=${clientId}`)
      return response.data
    } catch (error) {
      throw error
    }
  },
  cancel: async (obj) => {
    try {
      let response = await axiosInstance.post('/cancel-order', obj)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
