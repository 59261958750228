// api.js

import axiosInstance from "../axiosInstance";

export const rateCardService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post(`/rate_card`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  bulkUpload: async (data) => {
    try {
      let response = await axiosInstance.post("/rate-card-upload", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  bulkUploadStatus: async (clientId) => {
    try {
      let response = await axiosInstance.get(
        `/upload_status?type=rate-card${clientId ? `&client_id=${clientId}` : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteStatus: async (id) => {
    try {
      let response = await axiosInstance.delete(`/upload_status/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (
    limit,
    skip,
    service,
    zone,
    carrier,
    client,
    weight,
    weightSortOrder,
    priceSortOrder,

  ) => {
    try {
      let weightSortParam = weightSortOrder === "asc" ? 1 : -1;
      let priceSortParam = priceSortOrder === "asc" ? 1 : -1;
      let response = await axiosInstance.get(
        `/rate_card?$limit=${limit || 15}&$skip=${skip || 0}&${service?._id ? `service_type=${service?._id}` : ""
        }&${zone?._id ? `zone=${zone?._id}` : ""}&${carrier?._id ? `carrier=${carrier?._id}` : ""
        }&${client?._id ? `client_id=${client?._id}` : ""}
      &$populate=service_type&$populate=zone&$populate=client_id&${weight ? `weight=${weight}` : ""
        }&$sort[weight]=${weightSortParam}&$sort[price]=${priceSortParam}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/rate_card/${id}`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/rate_card/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
