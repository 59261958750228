import { SvgIcon } from '@mui/material'
import React, { useState } from 'react'

const GridIcon = ({ fill }) => {
  return (
    <SvgIcon sx={{ height: 20, width: 20 }}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <path
            d="M13.875 1.5H22.875C23.4963 1.5 24 2.00367 24 2.625V10.125C24 10.7463 23.4963 11.25 22.875 11.25H13.875C13.2537 11.25 12.75 10.7463 12.75 10.125V2.625C12.75 2.00367 13.2537 1.5 13.875 1.5ZM10.125 1.5H1.125C0.503672 1.5 0 2.00367 0 2.625V10.125C0 10.7463 0.503672 11.25 1.125 11.25H10.125C10.7463 11.25 11.25 10.7463 11.25 10.125V2.625C11.25 2.00367 10.7463 1.5 10.125 1.5ZM0 13.875V21.375C0 21.9963 0.503672 22.5 1.125 22.5H10.125C10.7463 22.5 11.25 21.9963 11.25 21.375V13.875C11.25 13.2537 10.7463 12.75 10.125 12.75H1.125C0.503672 12.75 0 13.2537 0 13.875ZM13.875 22.5H22.875C23.4963 22.5 24 21.9963 24 21.375V13.875C24 13.2537 23.4963 12.75 22.875 12.75H13.875C13.2537 12.75 12.75 13.2537 12.75 13.875V21.375C12.75 21.9963 13.2537 22.5 13.875 22.5Z"
            fill={fill || 'black'}
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default GridIcon
