// api.js

import axiosInstance from "../axiosInstance";

export const carrierService = {
  create: async (name, id) => {
    try {
      let response = await axiosInstance.post("/carrier", {
        name: name,
        client_id: id,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/carrier?$limit=${limit || 15}&$skip=${skip || 0}&${
          clientId ? `client_id=${clientId}&` : ""
        }&${searchValue ? `&name[$search]=${searchValue}` : ""}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  edit: async (name, id, clientId) => {
    try {
      let response = await axiosInstance.patch(`/carrier/${id}`, {
        name: name,
        client_id: clientId,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/carrier/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
