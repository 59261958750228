import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'

const ForeCastCard = ({
  backgroundColor,
  cardContent,
  cardCount,
  cardFooter,
  cardImage,
  IconComponent,
  footerColor,
  borderColor
}) => (
  <Card
    sx={{
      // flex: 1,
      width: '100%',
      height: 180,
      padding: 1,
      backgroundColor: backgroundColor || '#FFE2E5',
      borderRadius: '16px',
      position: 'relative',
      borderWidth: 1,
      borderColor: borderColor,
      marginTop: 2
    }}
  >
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 10
        }}
      >
        <img src={cardImage} width={80} height={80} alt="Card Icon" />
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '100%',
              flexWrap: 'wrap',
              overflowWrap: 'break-word' // Ensures content wraps within its container
            }}
          >
            <CustomTypography
              fontSize={27}
              fontWeight={600}
              color={'#151D48'}
              sx={{
                textAlign: 'start',
                whiteSpace: 'normal', // Allows text to wrap
                overflow: 'hidden', // Hide overflow text
                textOverflow: 'ellipsis', // Show ellipsis for overflowed text
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1, // Limits text to one line
                '@media (min-width: 1200px)': {
                  WebkitLineClamp: 'unset' // Remove line clamp on larger screens
                }
              }}
            >
              {cardCount}
            </CustomTypography>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '100%',
              flexWrap: 'wrap',
              overflowWrap: 'break-word' // Ensures content wraps within its container
            }}
          >
            <CustomTypography
              ontSize={18}
              fontWeight={500}
              color={'#425166'}
              sx={{
                textAlign: 'start',
                whiteSpace: 'normal', // Allows text to wrap
                overflow: 'hidden', // Hide overflow text
                textOverflow: 'ellipsis', // Show ellipsis for overflowed text
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1, // Limits text to one line
                '@media (min-width: 1200px)': {
                  WebkitLineClamp: 'unset' // Remove line clamp on larger screens
                }
              }}
            >
              {cardContent}
            </CustomTypography>
          </div>
          {/* <CustomTypography fontSize={18} fontWeight={500} color={'#425166'} sx={{ textAlign: 'center' }}>
            {cardContent}
          </CustomTypography> */}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 10,
          marginTop: 20,
          width: '100%'
        }}
      >
        {IconComponent && <IconComponent style={{ fontSize: 24, color: footerColor }} />}
        <Typography fontSize={13} sx={{ fontWeight: '600', color: footerColor, textAlign: 'center' }}>
          7.2%
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
            flexWrap: 'wrap',
            overflowWrap: 'break-word' // Ensures content wraps within its container
          }}
        >
          <CustomTypography
            fontSize={10}
            fontWeight={500}
            color={'#425166'}
            sx={{
              textAlign: 'center',
              whiteSpace: 'normal', // Allows text to wrap
              overflow: 'hidden', // Hide overflow text
              textOverflow: 'ellipsis', // Show ellipsis for overflowed text
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1, // Limits text to one line
              '@media (min-width: 1200px)': {
                WebkitLineClamp: 'unset' // Remove line clamp on larger screens
              }
            }}
          >
            {cardFooter}
          </CustomTypography>
        </div>
      </div>
    </CardContent>
  </Card>
)

export default ForeCastCard
