import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomTypography from "../../globalStyles/fonts";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { asnRequestService } from "../../utils/services/asnRequestService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import config from "../../config";
import { getRequestDetailsForOrder } from "../../utils/getRequestDetailsForAsn";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import { warehouseOrderService } from "../../utils/services/warehouseOrderService";

const WarehouseList = ({
  openModal,
  handleClose,
  handleEdit,
  selectedOrder,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [editData, setEditData] = useState({});
  const [responseData, setResponseData] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [warehouseOrderData, setWarehouseOrderData] = useState([]);
  const [itemModalOpen, setItemModalOpen] = useState(false);

  const handleItemModalOpen = () => {
    setItemModalOpen(true);
  };

  const handleItemModalClose = () => {
    setItemModalOpen(false);
  };
  const fetchWarehouseOrders = async () => {
    try {
      const response = await warehouseOrderService.fetch(
        100,
        selectedOrder?.order_id
      );
      setWarehouseOrderData(
        response.data.map((x) => ({
          ...x,
          request: getRequestDetailsForOrder(x),
        }))
      );
    } catch (error) {
      console.error("Error canceling order:", error);
    }
  };
  console.log(warehouseOrderData);

  useEffect(() => {
    if (selectedOrder) {
      fetchWarehouseOrders();
    }
  }, []);
  const editAsnData = async (item) => {
    try {
      const response = await axios.patch(
        `${config.warehouseBaseURL}/stock-in`,
        {
          stockData: editData,
          client_id: selectedOrder?.client_id?._id,
          provider: editData.provider.provider,
        }
      );
      console.log(response);
      fetchWarehouseOrders();
      setEditModalOpen(false);
    } catch (error) {
      console.error("Edit failed:", error);
      // Handle fetch failure
    }
  };
  const deleteAsnData = async () => {
    try {
      const response = await axios.delete(
        `${config.warehouseBaseURL}/stock-in?client_id=${selectedOrder?.client_id?._id}&provider=${selectedData.provider.provider}&ItemNumber=${selectedData.request.ItemNumber}`
      );
      fetchWarehouseOrders();
      setOpenDeleteModal(false);
    } catch (error) {
      console.error("Edit failed:", error);
      // Handle fetch failure
    }
  };
  useEffect(() => {
    if (openModal && selectedOrder?._id) {
      fetchWarehouseOrders();
    }
  }, [openModal]);

  // Handler for opening the edit modal with pre-filled data
  const onEditClick = (item) => {
    setEditData(item); // Set the current item data in state
    setEditModalOpen(true); // Open the edit modal
  };

  // Handler for closing the edit modal
  const handleEditClose = () => {
    setEditModalOpen(false);
  };
  const onResponseClick = (response) => {
    setResponseData(response);
    setResponseModalOpen(true);
  };

  // Handler for editing the input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle the form submission for editing
  const onEditSubmit = () => {
    editAsnData();
    // handleEdit(editData) // Pass the edited data to the parent handler
    // setEditModalOpen(false) // Close the modal after submission
  };
  const handleResponseClose = () => setResponseModalOpen(false);
  const renderData = (key, value) => {
    let cleanValue;

    // Clean HTML if the value is a string
    if (typeof value === "string") {
      cleanValue = value.replace(/<[^>]+>/g, "");
    } else if (Array.isArray(value)) {
      // Return the count of items in the array and make it clickable
      const itemCount = value.length;
      cleanValue = (
        <span
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => handleItemModalOpen(value)} // Implement handleArrayClick to show modal
        >
          {itemCount} item{itemCount > 1 ? "s" : ""} available
        </span>
      );
    } else if (typeof value === "object" && value !== null) {
      return Object.entries(value).map(([subKey, subValue]) => (
        <React.Fragment key={subKey}>
          {renderData(subKey, subValue)}
        </React.Fragment>
      ));
    } else {
      cleanValue = value;
    }

    // Only render if value is not null, undefined, or an empty string
    if (cleanValue !== null && cleanValue !== undefined && cleanValue !== "") {
      return (
        <React.Fragment key={key}>
          <Grid item xs={6}>
            <CustomTypography style={{ fontSize: 15 }}>
              <strong>{`${key}`}</strong>:
            </CustomTypography>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title={cleanValue.length > 30 ? cleanValue : ""}>
              <CustomTypography style={{ fontSize: 15 }}>
                {cleanValue}
              </CustomTypography>
            </Tooltip>
          </Grid>
        </React.Fragment>
      );
    }
    return null;
  };
  const renderItem = (item) => {
    return (
      <Grid container spacing={2}>
        {Object.entries(item)
          .filter(([key]) => key !== "_id") // Exclude the _id field
          .map(([key, value], index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {key}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {typeof value === "object" && value !== null ? (
                  // If the value is an object, render it recursively
                  <Box pl={2}>{renderItem(value)}</Box>
                ) : (
                  <Typography variant="body1">{value}</Typography>
                )}
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    );
  };
  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: isMobile ? "90%" : "70%",
            maxHeight: "60vh",
            overflow: "auto",
          }}
        >
          <CustomTypography
            style={{ fontWeight: "500", fontSize: 23 }}
            id="modal-title"
            variant="h6"
            component="h2"
            mb={2}
          >
            Warehouse Order List
          </CustomTypography>
          {/* {asnData?.length ? <CustomTypography>No data found</CustomTypography> : <></>} */}
          {isMobile ? (
            warehouseOrderData?.map((item) => (
              <Card key={item._id}>
                <CardContent>
                  <CustomTypography variant="h6">
                    Order No: {item.orderNo}
                  </CustomTypography>
                  <CustomTypography variant="h6">
                    Status: {item.status}
                  </CustomTypography>
                  <CustomTypography>
                    Est. Pick Up Date:{" "}
                    {new Date(item.request.estimatePickupDate).toLocaleString()}
                  </CustomTypography>

                  <CustomTypography>
                    Pickup By: {item.request.pickupBy}
                  </CustomTypography>
                  <CustomTypography>
                    Email: {item.request.email}
                  </CustomTypography>
                  <CustomTypography>
                    Sold To: {item.request.soldTo}
                  </CustomTypography>
                  <CustomTypography>
                    Ship To: {item.request.shipTo}
                  </CustomTypography>

                  <CustomTypography>
                    Carrier: {item.request.carrier}
                  </CustomTypography>

                  {/* Displaying Item Details */}
                  {item.request.Items.map((subItem) => (
                    <Box
                      key={subItem.ItemNumber}
                      sx={{
                        minWidth: 100,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CustomTypography>
                        Item Number: {subItem.ItemNumber}
                      </CustomTypography>
                      <CustomTypography>
                        Package: {subItem.PKG}
                      </CustomTypography>
                      <CustomTypography>Unit: {subItem.Unit}</CustomTypography>
                      <CustomTypography>
                        Inner Package: {subItem.InnerPKG}
                      </CustomTypography>
                      <CustomTypography>
                        Inner Unit: {subItem.InnerUnit}
                      </CustomTypography>
                      <CustomTypography>
                        Unit Price: {subItem.UnitPrice} {subItem.UnitCurrency}
                      </CustomTypography>
                      <CustomTypography>
                        Description: {subItem.Description}
                      </CustomTypography>
                    </Box>
                  ))}

                  <Box
                    sx={{
                      minWidth: 100,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Tooltip title="Response">
                      <IconButton
                        style={{
                          color: "#007DFF",
                          padding: 0,
                          background: "#C3E1FF",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginTop: 5,
                          boxShadow: "0px 0px 4px 0px #00000040",
                        }}
                        aria-label="view"
                        onClick={() => {
                          onResponseClick(item.response);
                        }}
                      >
                        <img
                          style={{ height: 14, width: 20 }}
                          alt="view"
                          src="/eye.png"
                        ></img>
                      </IconButton>
                    </Tooltip>
                    {/*  <IconButton
                      style={{
                        color: '#FEC50F',
                        padding: 0,
                        background: '#FEC50F33',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 5,
                        marginLeft: 10
                      }}
                      aria-label="edit"
                      onClick={() => {
                        // onEditClick(item.request)
                        // setSelectedData(item)
                      }}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                   <IconButton
                      style={{
                        color: '#D13438',
                        padding: 0,
                        background: '#D1343833',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 5
                      }}
                      aria-label="delete"
                      onClick={() => {
                        // setOpenDeleteModal(true)
                        // setSelectedData(item)
                      }}
                    >
                      <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                    </IconButton> */}
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Order No
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Status
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Est. Pick Up Date
                      </CustomTypography>
                    </TableCell>

                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Pickup By
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Fulfiller
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Sold To
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Ship To
                      </CustomTypography>
                    </TableCell>

                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Carrier
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Action
                      </CustomTypography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {warehouseOrderData?.map((item) => (
                    <TableRow key={item._id}>
                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.orderNo}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "600", fontSize: 15 }}
                        >
                          {item.status}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {new Date(
                            item.request.estimatePickupDate
                          ).toLocaleString()}
                        </CustomTypography>
                      </TableCell>

                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.request.pickupBy}
                        </CustomTypography>
                      </TableCell>

                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.request.warehouse}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography
                          style={{
                            fontWeight: "400",
                            fontSize: 13,
                            maxWidth: 100,
                          }}
                        >
                          {item.request.soldTo}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography
                          style={{
                            fontWeight: "400",
                            fontSize: 13,
                            maxWidth: 100,
                          }}
                        >
                          {item.request.shipTo}
                        </CustomTypography>
                      </TableCell>

                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.request.carrier || item.request.pickupBy}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            minWidth: 100,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Tooltip title="Response">
                            <IconButton
                              style={{
                                color: "#007DFF",
                                padding: 0,
                                background: "#C3E1FF",
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                marginTop: 5,
                                boxShadow: "0px 0px 4px 0px #00000040",
                              }}
                              aria-label="view"
                              onClick={() => {
                                onResponseClick(item.response);
                              }}
                            >
                              <img
                                style={{ height: 14, width: 20 }}
                                alt="view"
                                src="/eye.png"
                              ></img>
                            </IconButton>
                          </Tooltip>
                          {/* <IconButton
                            style={{
                              color: "#FEC50F",
                              padding: 0,
                              background: "#FEC50F33",
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              boxShadow: "0px 0px 4px 0px #00000040",
                              marginTop: 5,
                              marginLeft: 10,
                            }}
                            aria-label="edit"
                            onClick={() => {
                              onEditClick(item.request);
                              setSelectedData(item);
                            }}
                          >
                            <BorderColorIcon sx={{ height: 20, width: 20 }} />
                          </IconButton>
                          <IconButton
                            style={{
                              color: "#D13438",
                              padding: 0,
                              background: "#D1343833",
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              marginLeft: 10,
                              boxShadow: "0px 0px 4px 0px #00000040",
                              marginTop: 5,
                            }}
                            aria-label="delete"
                            onClick={() => {
                              setOpenDeleteModal(true);
                              setSelectedData(item);
                            }}
                          >
                            <img
                              style={{ height: 15, width: 15 }}
                              alt="delete"
                              src="/delete.png"
                            ></img>
                          </IconButton> */}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{ mt: 2, backgroundColor: "#D13438" }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal
        open={editModalOpen}
        onClose={handleEditClose}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: isMobile ? "90%" : "60%",
            overflow: "auto",
            maxHeight: "70vh",
          }}
        >
          <Typography variant="h6" id="edit-modal-title" mb={2}>
            Edit Item
          </Typography>

          <TextField
            size="small"
            fullWidth
            label="Item Number"
            name="ItemNumber"
            value={editData?.ItemNumber}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            size="small"
            fullWidth
            label="Package"
            name="PKG"
            value={editData?.PKG}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            size="small"
            fullWidth
            label="Unit"
            name="Unit"
            value={editData?.Unit}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            size="small"
            fullWidth
            label="Inner Package"
            name="InnerPKG"
            value={editData?.InnerPKG}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            size="small"
            fullWidth
            label="Inner Unit"
            name="InnerUnit"
            value={editData?.InnerUnit}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            size="small"
            fullWidth
            label="Currency"
            name="UnitCurrency"
            value={editData?.UnitCurrency}
            onChange={handleInputChange}
            margin="normal"
          />
          <ReactQuill
            theme="snow"
            value={editData?.Description}
            onChange={(e) => setEditData({ ...editData, Description: e })}
            placeholder="Enter product description"
            style={{
              height: isMobile ? 70 : "100px",
              marginBottom: "50px",
            }}
          />
          {/* <TextField
            size="small"
            fullWidth
            label="Description"
            name="Description"
            value={editData?.request?.Description}
            onChange={handleInputChange}
            margin="normal"
          /> */}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              onClick={() => setEditModalOpen(false)}
              variant="contained"
              sx={{ mt: 2, backgroundColor: "#D13438", marginRight: 1 }}
            >
              Cancel
            </Button>
            <Button onClick={onEditSubmit} variant="contained" sx={{ mt: 2 }}>
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <>
        <Modal open={responseModalOpen} onClose={handleResponseClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              width: isMobile ? "90%" : "60%",
              overflow: "auto",
              maxHeight: "70vh",
            }}
          >
            <CustomTypography style={{ fontSize: 20, fontWeight: "600" }}>
              Response Details
            </CustomTypography>
            <Grid style={{ marginTop: 5 }} container spacing={2}>
              {responseData ? (
                Object.entries(responseData).map(([key, value]) => {
                  // Exclude specified fields
                  if (["_id", "createdAt", "updatedAt", "__v"].includes(key))
                    return null;

                  // Check for Items field and render item length
                  //   if (key === 'Items' && Array.isArray(value)) {
                  //     const itemCount = value.length
                  //     return (
                  //       <Grid item xs={12} key={key}>
                  //         <CustomTypography variant="h6">
                  //           Items:{' '}
                  //           {itemCount > 0 ? (
                  //             <span style={{ cursor: 'pointer', color: 'blue' }} onClick={handleItemModalOpen}>
                  //               {itemCount} item{itemCount > 1 ? 's' : ''} available
                  //             </span>
                  //           ) : (
                  //             'No items available'
                  //           )}
                  //         </CustomTypography>
                  //       </Grid>
                  //     )
                  //   }

                  return renderData(key, value);
                })
              ) : (
                <Grid item xs={12}>
                  <CustomTypography>No data available</CustomTypography>
                </Grid>
              )}
            </Grid>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleResponseClose}
                variant="contained"
                sx={{ mt: 2, backgroundColor: "#D13438" }}
              >
                Close
              </Button>
            </div>
          </Box>
        </Modal>

        {/* Item List Modal */}
        <Modal open={itemModalOpen} onClose={handleItemModalClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              width: "50%",
              overflow: "auto",
              maxHeight: "60vh",
            }}
          >
            <Typography variant="h6" mb={2}>
              Item List
            </Typography>
            {Array.isArray(responseData?.Items) &&
            responseData.Items.length > 0 ? (
              responseData.Items.map((item, index) => (
                <Box key={index} mb={1}>
                  {renderItem(item)}
                </Box>
              ))
            ) : (
              <CustomTypography>No items available</CustomTypography>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleItemModalClose}
                variant="contained"
                sx={{ mt: 2, backgroundColor: "#D13438" }}
              >
                Close
              </Button>
            </div>
          </Box>
        </Modal>
      </>

      <DeleteConfirmationModal
        title={`Are you sure you want to delete the ASN request for SKU ${selectedData?.request.ItemNumber}? This action cannot be undone.`}
        handleDelete={() => deleteAsnData()}
        handleClose={() => {
          setOpenDeleteModal(false);
          setSelectedData(null);
        }}
        open={openDeleteModal}
      ></DeleteConfirmationModal>
    </>
  );
};

export default WarehouseList;
