import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  CircularProgress
} from '@mui/material'
import debounce from 'lodash.debounce'
import React, { useCallback, useEffect, useState } from 'react'
import AddParameterModal from '../../components/add-parameter-modal'
import DateRangePicker from '../../components/date-range-picker'
import ForeCastCard from '../../components/forecastCard'
import SalesChart from '../../components/forecastGraph'
import CustomTypography from '../../globalStyles/fonts'
import OrderFooterIcon from '../../globalStyles/icons/orderFooter'
import { productService } from '../../utils/services/productService'
import { isMobile } from '../../globalStyles/mobile'
import ProductAutocomplete from '../../components/productAutocomplete'

const ForeCast = () => {
  const changeDateFormat = (data) => {
    const dateStr = data
    const date = new Date(dateStr)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const currentDate = new Date()

  const [dateRange, setDateRange] = useState([
    changeDateFormat(new Date(currentDate.setMonth(currentDate.getMonth() - 2))),
    changeDateFormat(new Date(currentDate.setDate(currentDate.getDate() + 2)))
  ])

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(currentDate.setMonth(currentDate.getMonth() - 2)),
    endDate: new Date(new Date().setDate(new Date().getDate() + 2)),
    key: 'selection'
  })

  const [selectedProduct, setSelectedProduct] = useState(null)
  const [allProducts, setAllProducts] = useState([])
  const [productSearchValue, setProductSearchValue] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showParameters, setShowParameters] = useState(false)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  // const fetchProductData = useCallback(
  //   debounce(async (searchQuery, pageNumber) => {
  //     setLoading(true)
  //     try {
  //       const clientId = localStorage.getItem('client_id')
  //       const response = await productService.fetch(10, pageNumber * 10, clientId, searchQuery)
  //       if (pageNumber === 0) {
  //         setAllProducts(response.data)
  //       } else {
  //         setAllProducts((prevProducts) => [...prevProducts, ...response.data])
  //       }
  //       setTotal(response.total)
  //     } catch (error) {
  //       console.error('Fetch failed:', error)
  //     } finally {
  //       setLoading(false)
  //     }
  //   }, 300),
  //   []
  // )
  // useEffect(() => {
  //   fetchProductData(productSearchValue, page)
  // }, [productSearchValue, page, fetchProductData])

  // useEffect(() => {
  //   if (productSearchValue === '') {
  //     fetchProductData('', 0)
  //   }
  // }, [productSearchValue, fetchProductData])

  // const handleScroll = (event) => {
  //   const bottom =
  //     event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) ||
  //     event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) - 1 ||
  //     event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) + 1
  //   if (bottom && allProducts.length < total && !loading) {
  //     setPage((prevPage) => prevPage + 1)
  //   }
  // }

  // const handleClientChange = (event, value) => {
  //   setProductSearchValue('')
  // }

  const handleProductChange = (value) => {
    setSelectedProduct(value)
  }
  // const handleSearchChange = (event) => {
  //   const value = event.target.value
  //   setProductSearchValue(value)
  //   setPage(0)
  //   setAllProducts([])
  // }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleModalSubmit = (data) => {
    console.log(data)
    // Handle form submission here
  }

  return (
    <div
      style={{
        width: "100%",
        marginTop: 20,
        display: "flex",
        gap: 10,
        flexDirection: isMobile() ? "column" : "row",
        overflow: "auto",
        height: "90%",
        padding: 2,
        paddingBottom: 50,
      }}
    >
      <div style={{ width: isMobile() ? "100%" : "25%" }}>
        <Card
          style={{
            padding: 10,
            borderWidth: 1,
            borderRadius: 14,
            borderColor: "#F8F9FA",
            minHeight: 550,
            overflow: "unset",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // gap: 10,
              marginTop: 20,
              width: "100%",
            }}
          >
            <Grid sx={{ width: "100%" }} container gap={1}>
              <Grid sx={{ width: "100%" }}>
                {/* <Autocomplete
                  ListboxProps={{
                    onScroll: handleScroll
                  }}
                  sx={{ width: '100%' }}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        backgroundColor: '#f5f5f5',
                        borderBottom: '1px solid #ddd',
                        padding: '10px',
                        position: 'relative'
                      }}
                      title={option.name}
                    >
                      <p
                        style={{
                          display: 'block',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          margin: 0,
                          width: '100%'
                        }}
                      >
                        {option.name}
                      </p>
                    </li>
                  )}
                  size="small"
                  id="product-select"
                  options={allProducts}
                  getOptionLabel={(option) => option.name}
                  value={selectedProduct}
                  onChange={handleProductChange}
                  style={{ minWidth: 200 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Select Product"
                      variant="outlined"
                      onChange={handleSearchChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                /> */}
                <ProductAutocomplete
                  selectedProduct={selectedProduct}
                  onProductChange={handleProductChange}
                  label="Select Product"
                  errorMessage={null}
                  hideLabel={false}
                  width={"100%"}
                />
              </Grid>
              <Grid sx={{ width: "100%" }}>
                <DateRangePicker
                  style={{ minWidth: "100%" }}
                  selectionRange={selectionRange}
                  setSelectionRange={setSelectionRange}
                />
              </Grid>
            </Grid>
          </div>
          <Grid container sx={{ marginTop: 1 }}>
            <Grid item sx={{ width: "100%" }}>
              <ForeCastCard
                cardCount={2500}
                cardContent={"Total Orders"}
                cardFooter={"Last Year same duration orders 2330"}
                cardImage="/order.png"
                IconComponent={OrderFooterIcon}
                footerColor={"green"}
                borderColor={"#FA5A7D"}
              />
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <ForeCastCard
                cardCount={2500}
                cardContent={"Total Shipments"}
                cardFooter={"Last Year same duration shipments 2300"}
                cardImage="/shipment.png"
                IconComponent={OrderFooterIcon}
                backgroundColor={"#FFF4DE"}
                footerColor={"green"}
                borderColor={"#FF947A"}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
      <div style={{ width: isMobile() ? "100%" : "75%" }}>
        <Card
          style={{
            padding: 10,
            borderWidth: 1,
            borderRadius: 14,
            borderColor: "#F8F9FA",
            minHeight: 550,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 10,
              flexDirection: isMobile() ? "column" : "row",
            }}
          >
            <CustomTypography
              fontSize={isMobile() ? 20 : 30}
              fontWeight={600}
              color={"#05004E"}
            >
              Inventory Forecast
            </CustomTypography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showParameters}
                    onChange={() => setShowParameters(!showParameters)}
                    color="primary"
                  />
                }
                label={
                  <CustomTypography
                    fontSize={14}
                    fontWeight={400}
                    color={"#05004E"}
                  >
                    Show Parameters
                  </CustomTypography>
                }
              />
              <Button
                variant="contained"
                style={{
                  textTransform: "none",
                  backgroundColor: "#007DFF",
                  height: 40,
                }}
                onClick={handleOpenModal}
              >
                <CustomTypography fontSize={14} fontWeight={500}>
                  + Add Parameter
                </CustomTypography>
              </Button>
            </div>
          </div>
          <SalesChart hideFilter={true} showParameter={showParameters} />
        </Card>
      </div>
      <AddParameterModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleModalSubmit}
      />
    </div>
  );
}

export default ForeCast
