import React, { useEffect, useState } from "react";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { productService } from "../../services/product-service";
import ProductSelector from "../../components/product-selector";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomerAutocomplete from "../../components/customer-autocomplete";
import { orderService } from "../../services/order-service";

const initialAddress = {
  first_name: "",
  last_name: "",
  address1: "",
  address2: "",
  company: "",
  city: "",
  zip: "",
  province: "",
  province_code: "",
  country: "",
  country_code: "",
  phone: "",
};

const requiredFields = [
  "first_name",
  "last_name",
  "address1",
  "city",
  "zip",
  "province",
  "country",
];

const initialOrderData = {
  order_id: "",
  shipping_method: "",
  shipping_address: { ...initialAddress },
  billing_address: { ...initialAddress },
  customer: {
    name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: { ...initialAddress },
  },
  line_items: [
    {
      name: "",
      price: "",
      quantity: 1,
      sku: "",
      title: "",
      product_id: "",
      variant_id: "",
    },
  ],
};

const ManageOrder = () => {
  const [order, setOrder] = useState(initialOrderData);
  const [sameAsShipping, setSameAsShipping] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleFieldChange = (type, field, value) => {
    setOrder((prevOrder) => {
      const updatedOrder = { ...prevOrder };
      updatedOrder[`${type}_address`][field] = value;
      if (sameAsShipping && type === "shipping") {
        updatedOrder.billing_address[field] = value;
      }
      return updatedOrder;
    });
  };

  const handleCheckboxChange = () => {
    setSameAsShipping(!sameAsShipping);
    setOrder((prevOrder) => ({
      ...prevOrder,
      billing_address: !sameAsShipping
        ? { ...prevOrder.shipping_address }
        : { ...initialAddress },
    }));
  };
  console.log(order);
  const validateForm = () => {
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!order.shipping_address[field]) {
        newErrors[`shipping_${field}`] = "This field is required";
      }
      if (!sameAsShipping && !order.billing_address[field]) {
        newErrors[`billing_${field}`] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const orderData = {
        ...order,
        shipping_address: {
          ...order.shipping_address,
          name: `${order.shipping_address.first_name} ${order.shipping_address.last_name}`,
        },
        billing_address: {
          ...order.billing_address,
          name: `${order.billing_address.first_name} ${order.billing_address.last_name}`,
        },
        line_items: order.line_items.map((item) => ({
          name: item.product?.name,
          price: item.variant.price,
          quantity: item.quantity,
          sku: item.variant.sku,
          title: item.product?.name,
          product_id: item.product?._id,
          variant_id: item.variant?._id,
        })),
        order_date: new Date().toISOString(),
      };
      try {
        const response = await orderService.create(orderData);
        console.log(response);
        navigate("/orders");
      } catch (error) {
        console.error(error);
      }
      console.log("Order Data:", orderData);

      // Here you would typically send this data to your backend
    }
  };

  const updateOrder = (value, field) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      [field]: value,
    }));
  };

  const updateQuantity = (value, index) => {
    setOrder((prevOrder) => {
      const updatedItems = [...prevOrder.line_items];
      updatedItems[index].quantity = value;
      return { ...prevOrder, line_items: updatedItems };
    });
  };
  const handleCustomerChange = (e) => {
    if (e) {
      setOrder((prevOrder) => ({
        ...prevOrder,
        customer: e,
        shipping_address: {
          first_name: e.first_name || "",
          last_name: e.last_name || "",
          address1: e.address.address1 || "",
          address2: e.address.address2 || "",
          company: e.address.company || "",
          city: e.address.city || "",
          zip: e.address.zip || "",
          province: e.address.province || "",
          province_code: e.address.province_code || "",
          country: e.address.country || "",
          country_code: e.address.country_code || "",
          phone: e.phone || "",
        },
      }));
    } else {
      setOrder((prevOrder) => ({
        ...prevOrder,
        customer: null,
        shipping_address: { ...initialAddress },
      }));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        maxHeight: "98%",
      }}
    >
      <Typography sx={{ paddingBottom: 1 }} variant="h5" className="pb-4">
        Create Order
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            size="small"
            required={true}
            label={"Order ID"}
            fullWidth
            value={order.order_id}
            onChange={(e) => updateOrder(e.target.value, "order_id")}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomerAutocomplete
            selectedCustomer={order.customer}
            onCustomerChange={(e) => handleCustomerChange(e)}
          ></CustomerAutocomplete>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            size="small"
            required={true}
            label={"Shipping Method"}
            fullWidth
            value={order.shipping_method}
            onChange={(e) => updateOrder(e.target.value, "shipping_method")}
          />
        </Grid>
      </Grid>

      <Grid
        spacing={2}
        mt={2}
        sx={{
          borderWidth: 2,
          borderColor: "#D9D9D9",
          borderStyle: "solid",
          padding: "10px",
          borderRadius: 1,
        }}
      >
        {order.line_items.map((item, index) => (
          <Grid sx={{ padding: 1 }} container key={index}>
            <Grid item xs={12} sm={4}>
              <ProductSelector
                selectedProduct={item.product}
                onProductSelect={(product) => {
                  const updatedItems = [...order.line_items];
                  updatedItems[index] = {
                    ...updatedItems[index],
                    product,
                    variant: null,
                    quantity: 1, // Reset quantity to 1 when a new product is selected
                  };
                  setOrder({ ...order, line_items: updatedItems });
                }}
              />
            </Grid>
            <Grid sx={{ ml: 1 }} item xs={12} sm={4}>
              <FormControl size="small" fullWidth disabled={!item.product}>
                <InputLabel>Select Variant</InputLabel>
                <Select
                  label="Select Variant"
                  value={item.variant?._id || ""}
                  onChange={(event) => {
                    const variant = item.product.variants.find(
                      (v) => v._id === event.target.value
                    );
                    const updatedItems = [...order.line_items];
                    updatedItems[index] = { ...updatedItems[index], variant };
                    setOrder({ ...order, line_items: updatedItems });
                  }}
                >
                  {item.product?.variants.map((variant) => (
                    <MenuItem key={variant._id} value={variant._id}>
                      {variant.option.map((opt) => opt.value).join(", ")}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2} display="flex" alignItems="center">
              <TextField
                disabled={!item.variant}
                sx={{ ml: 1 }}
                size="small"
                value={item.quantity}
                onChange={(e) => updateQuantity(e.target.value, index)}
                type="number"
                label="Quantity"
                fullWidth
                inputProps={{ min: 1 }}
              />
              <IconButton
                disabled={order.line_items?.length === 1}
                onClick={() => {
                  setOrder({
                    ...order,
                    line_items: order.line_items.filter((_, i) => i !== index),
                  });
                }}
                size="small"
              >
                <Remove />
              </IconButton>
              <IconButton
                onClick={() => {
                  setOrder({
                    ...order,
                    line_items: [
                      ...order.line_items,
                      {
                        name: "",
                        price: "",
                        quantity: 1,
                        sku: "",
                        product: null,
                        variant: null,
                      },
                    ],
                  });
                }}
                size="small"
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Typography mt={1} variant="h6">
        Shipping Address
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: 0.5 }}>
        {Object.keys(initialAddress).map((field) => (
          <Grid item xs={12} md={4} key={field}>
            <TextField
              size="small"
              required={requiredFields.includes(field)}
              label={field.replace(/([A-Z])/g, " $1").trim()}
              fullWidth
              value={
                order?.shipping_address[field]
                  ? order?.shipping_address[field]
                  : ""
              }
              onChange={(e) =>
                handleFieldChange("shipping", field, e.target.value)
              }
              error={!!errors[`shipping_${field}`]}
              helperText={errors[`shipping_${field}`]}
            />
          </Grid>
        ))}
      </Grid>

      <FormControlLabel
        sx={{ marginTop: 1 }}
        control={
          <Checkbox checked={sameAsShipping} onChange={handleCheckboxChange} />
        }
        label="Billing Address is same as Shipping Address"
      />

      {!sameAsShipping && (
        <>
          <Typography variant="h6" className="mt-4">
            Billing Address
          </Typography>
          <Grid sx={{ marginTop: 1 }} container spacing={2} className="mt-2">
            {Object.keys(initialAddress).map((field) => (
              <Grid item xs={12} md={4} key={field}>
                <TextField
                  size="small"
                  required={requiredFields.includes(field)}
                  label={field.replace(/([A-Z])/g, " $1").trim()}
                  fullWidth
                  value={order.billing_address[field]}
                  onChange={(e) =>
                    handleFieldChange("billing", field, e.target.value)
                  }
                  error={!!errors[`billing_${field}`]}
                  helperText={errors[`billing_${field}`]}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <div style={{ display: "flex", justifyContent: "end" }}>
        {order.line_items.length > 0 && (
          <Typography variant="h6" sx={{ marginTop: 2, fontWeight: "bold" }}>
            Order Total: $
            {order.line_items.reduce(
              (total, item) => total + (item.variant?.price || 0),
              0
            )}
          </Typography>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={() => navigate("/order-import")}
          variant="contained"
          color="primary"
          sx={{ marginTop: 2, backgroundColor: "red", marginRight: 1 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
        >
          Place Order
        </Button>
      </div>
    </div>
  );
};

export default ManageOrder;
