import React, { useState } from 'react'
import { Modal, Box, Typography, Grid, Button, TextField, Autocomplete } from '@mui/material'

const MappingModal = ({ fileColumn, modelSchema, open, onCloseModal, onSubmit }) => {
  const [mappedColumns, setMappedColumns] = useState([]) // Stores mappings as { key, column }

  const handleMappingChange = (modelKey, fileKey) => {
    setMappedColumns((prev) => {
      const existingMappingIndex = prev.findIndex((mapping) => mapping.key === modelKey)

      if (existingMappingIndex !== -1) {
        const updatedMappings = [...prev]
        updatedMappings[existingMappingIndex] = { key: modelKey, column: fileKey }
        return updatedMappings
      }

      return [...prev, { key: modelKey, column: fileKey }]
    })
  }

  const onClose = () => {
    onCloseModal && onCloseModal()
    setMappedColumns([])
  }

  const handleSubmit = () => {
    const transformedData = {
      start_process: true,
      mapped_column: modelSchema.map((key) => {
        const mappedItem = mappedColumns.find((mapping) => mapping.key === key)
        return {
          key, // Represents modelSchema key
          column: mappedItem ? mappedItem.column : '' // Maps to selected fileColumn
        }
      })
    }

    onSubmit && onSubmit(transformedData)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '60%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '80vh'
        }}
      >
        <Typography sx={{ fontWeight: '600' }} gutterBottom>
          Map File Columns to Model Schema
        </Typography>
        <div
          style={{
            display: 'flex',
            maxHeight: '70vh',
            overflow: 'auto',
            flexDirection: 'column'
          }}
        >
          {modelSchema?.map((modelKey, index) => (
            <Grid container spacing={2} alignItems="center" key={index} sx={{ marginTop: 1 }}>
              <Grid item xs={6}>
                <Typography>{modelKey}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  size="small"
                  options={fileColumn}
                  getOptionLabel={(option) => option}
                  value={
                    fileColumn.find(
                      (fileKey) =>
                        fileKey === mappedColumns.find((mapping) => mapping.key === modelKey)?.column
                    ) || null
                  }
                  onChange={(e, value) => handleMappingChange(modelKey, value || '')}
                  renderInput={(params) => <TextField {...params} label="Select File Column" />}
                />
              </Grid>
            </Grid>
          ))}
        </div>
        <Box display="flex" justifyContent="end" mt={2}>
          <Button sx={{ mr: 2 }} variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default MappingModal
