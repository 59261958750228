// Filename - components/SidebarData.js

import React from 'react'
import * as RiIcons from 'react-icons/ri'
import MasterIcon from '../../globalStyles/icons/master-icon'
import ClientIcon from '../../globalStyles/icons/client-icon'
import ProductIcon from '../../globalStyles/icons/product-icon'
import PortIcon from '../../globalStyles/icons/port-icon'
import ZoneIcon from '../../globalStyles/icons/zone-icon'
import CarrierIcon from '../../globalStyles/icons/carrier-icon'
import ServiceIcon from '../../globalStyles/icons/service-icon'
import ZipZoneIcon from '../../globalStyles/icons/zipZone-icon'
import RateCardIcon from '../../globalStyles/icons/rateCard-icon'
import ToolIcon from '../../globalStyles/icons/tools-icon'
import PortLookUpIcon from '../../globalStyles/icons/portLookup-icon'
import RateLookUpIcon from '../../globalStyles/icons/rateLookup-icon'
import SimulationIcon from '../../globalStyles/icons/simulation-icon'
import DashboardIcon from '../../globalStyles/icons/dashboard-icon'
import WarehouseIcon from '../../globalStyles/icons/warehouse-icon'
import OrderIcon from '../../globalStyles/icons/order-icon'
import ShipmentIcon from '../../globalStyles/icons/shipment-icon'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import StormIcon from '@mui/icons-material/Storm'
import TransactionIcon from '../../globalStyles/icons/transaction-icon'
import PartnerIcon from '../../globalStyles/icons/partner-icon'
import CustomClearanceIcon from '../../globalStyles/icons/custom-clearance-icon'
import FreightBrokerIcon from '../../globalStyles/icons/freightbrokerIcon'
import CustomProviderIcon from '../../globalStyles/icons/CustomProviderIcon'
import ShipmentCostIcon from '../../globalStyles/icons/shipmentCostIcon'
import PackageMappingIcon from '../../globalStyles/icons/packageMappingIcon'
import SupplierIcon from '../../globalStyles/icons/supplier-icon'
import BillingIcon from '../../globalStyles/icons/billing-icon'
import AlertIcon from '../../globalStyles/icons/alert-icon'

export const getIcon = (iconName, fill) => {
  switch (iconName) {
    case 'MasterIcon':
      return <MasterIcon fill={fill} />
    case 'ClientIcon':
      return <ClientIcon fill={fill} />
    case 'ProductIcon':
      return <ProductIcon fill={fill} />
    case 'PortIcon':
      return <PortIcon fill={fill} />
    case 'ZoneIcon':
      return <ZoneIcon fill={fill} />
    case 'CarrierIcon':
      return <CarrierIcon fill={fill} />
    case 'ServiceIcon':
      return <ServiceIcon fill={fill} />
    case 'ZipZoneIcon':
      return <ZipZoneIcon fill={fill} />
    case 'RateCardIcon':
      return <RateCardIcon fill={fill} />
    case 'ToolIcon':
      return <ToolIcon fill={fill} />
    case 'PortLookUpIcon':
      return <PortLookUpIcon fill={fill} />
    case 'RateLookUpIcon':
      return <RateLookUpIcon fill={fill} />
    case 'SimulationIcon':
      return <SimulationIcon fill={fill} />
    case 'DashboardIcon':
      return <DashboardIcon fill={fill} />
    case 'WarehouseIcon':
      return <WarehouseIcon fill={fill} />
    case 'OrderIcon':
      return <OrderIcon fill={fill} />
    case 'ShipmentIcon':
      return <ShipmentIcon fill={fill} />
    case 'CatalogueIcon':
      return <DesignServicesIcon fill={fill} />
    case 'ForecastIcon':
      return <StormIcon fill={fill} />
    case 'TransactionIcon':
      return <TransactionIcon fill={fill} />
    case 'PartnerIcon':
      return <PartnerIcon fill={fill} />
    case 'CustomClearanceIcon':
      return <CustomClearanceIcon fill={fill} />
    case 'CustomProviderIcon':
      return <CustomProviderIcon fill={fill} />
    case 'FreightBrokerIcon':
      return <FreightBrokerIcon fill={fill} />
    case 'ShipmentCostIcon':
      return <ShipmentCostIcon fill={fill} />
    case 'PackageMappingIcon':
      return <PackageMappingIcon fill={fill} />
    case 'SupplierIcon':
      return <SupplierIcon fill={fill} />
    case 'BillingIcon':
      return <BillingIcon fill={fill} />
    case 'AlertIcon':
      return <AlertIcon fill={fill} />
    default:
      return null
  }
}

export const Data = [
  {
    title: 'Dashboard',
    path: '/',
    icon: 'DashboardIcon',
    roles: ['admin', 'client']
  },
  {
    title: 'Product',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    icon: 'ProductIcon',
    roles: ['admin', 'client'],
    subNav: [
      {
        title: 'Catalog',
        path: '/product',
        icon: 'CatalogueIcon',
        roles: ['admin', 'client']
      },

      {
        title: 'Inventory Forecast',
        path: '/forecast',
        icon: 'ForecastIcon',
        roles: ['admin', 'client']
      },
      {
        title: 'Supplier',
        path: '/supplier',
        icon: 'SupplierIcon',
        roles: ['admin', 'client']
      }
      // {
      //   title: "Manage PO",
      //   path: "/manage-po",
      //   icon: "BillingIcon",
      //   roles: ["admin"],
      // },
    ]
  },
  {
    title: 'Order Management',
    path: '/order-management',
    icon: 'OrderIcon',
    roles: ['admin', 'client']
  },
  {
    title: 'Fulfilment',
    icon: 'MasterIcon',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    roles: ['client'],
    subNav: [
      // {
      //   title: 'Shipping Options',
      //   path: '/shipment-options',
      //   icon: 'ShipmentIcon',
      //   roles: ['admin', 'client']
      // },
      {
        title: 'Global Network Design',
        path: '/global-network-design',
        icon: 'ShipmentIcon',
        roles: ['admin', 'client']
      }
    ]
  },
  {
    title: 'Settings',
    icon: 'MasterIcon',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    roles: ['admin'],
    subNav: [
      {
        title: 'Client',
        path: '/client',
        icon: 'ClientIcon',
        roles: ['admin']
      },

      {
        title: 'Port',
        path: '/port',
        icon: 'PortIcon',
        roles: ['admin']
      },
      {
        title: 'Zone',
        path: '/zone',
        icon: 'ZoneIcon',
        roles: ['admin']
      },
      {
        title: 'Carrier Management',
        path: '/carrier',
        icon: 'CarrierIcon',
        roles: ['admin']
      },
      {
        title: 'Service Management',
        path: '/service-management',
        icon: 'ServiceIcon',
        roles: ['admin']
      },
      {
        title: 'Zip Zone Mapping',
        path: '/zip-zone',
        icon: 'ZipZoneIcon',
        roles: ['admin']
      },
      {
        title: 'Rate Card Management',
        path: '/rate-card-management',
        icon: 'RateCardIcon',
        roles: ['admin']
      },
      {
        title: 'Shipment Options',
        path: '/shipment-options',
        icon: 'ShipmentIcon',
        roles: ['admin']
      },
      {
        title: 'Shipment Cost',
        path: '/shipment-cost',
        icon: 'ShipmentCostIcon',
        roles: ['admin']
      },
      {
        title: 'Transaction',
        path: '/shipment-transaction',
        icon: 'TransactionIcon',
        roles: ['admin']
      },
      {
        title: 'Partner',
        path: '/partner',
        icon: 'PartnerIcon',
        roles: ['admin']
      },
      {
        title: 'Email Template',
        path: '/email-template',
        icon: 'PartnerIcon',
        roles: ['admin']
      }
    ]
  },
  {
    title: 'Custom Clearance',
    path: '/custom-clearance',
    icon: 'CustomClearanceIcon',
    roles: ['admin']
  },
  {
    title: 'Custom Provider',
    path: '/custom-provider',
    icon: 'CustomProviderIcon',
    roles: ['admin']
  },
  {
    title: 'Freight Broker',
    path: '/freight-broker',
    icon: 'FreightBrokerIcon',
    roles: ['admin']
  },
  {
    title: 'Billing',
    path: '/billing',
    icon: 'BillingIcon',
    roles: ['admin', 'client']
  },
  {
    title: 'Alert & Notification',
    path: '/alert-and-notification',
    icon: 'AlertIcon',
    roles: ['admin', 'client']
  },
  // {
  //   title: 'Shipment',
  //   path: '/shipment',
  //   icon: 'ShipmentIcon',
  //   roles: ['admin', 'client']
  // },

  {
    title: 'Tools',
    icon: 'ToolIcon',
    roles: ['admin'],
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Port Lookup',
        path: '/port-lookup',
        icon: 'PortLookUpIcon',
        cName: 'sub-nav',
        roles: ['admin']
      },
      {
        title: 'Rate Lookup',
        path: '/rate-lookup',
        icon: 'RateLookUpIcon',
        cName: 'sub-nav',
        roles: ['admin']
      }
    ]
  },
  {
    title: 'Simulation',
    path: '/simulation',
    icon: 'SimulationIcon',
    roles: ['admin']
  },
  // {
  //   title: "Warehouse",
  //   path: "/warehouse-management",
  //   icon: "WarehouseIcon",
  //   roles: ["admin"],
  // },

  {
    title: 'Package Maping',
    path: '/package-maping',
    icon: 'PackageMappingIcon',
    roles: ['admin']
  }
]

export const Tabs = [
  {
    title: 'Setting',
    path: '/setting',
    icon: <img src="/settings.png" alt="icon" />
  }
]
