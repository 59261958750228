// api.js

import axiosInstance from '../axiosInstance'

export const omsService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post('/oms-config', data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetch: async (id, limit, skip) => {
    try {
      let response = await axiosInstance.get(
        `/oms-config?$limit=${limit || 15}&$skip=${skip || 0}&client_id=${id}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/oms-config/${id}`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/oms-config/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
