import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import 'react-toastify/dist/ReactToastify.css'

import { Box, Button, IconButton, Popover, TableRow } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import 'react-quill/dist/quill.snow.css'
import { styled } from '@mui/material/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

export const ActionTableCellComponent = React.memo(
  ({ selectedData, handleViewClick, handleEditClick, handleDeleteClick }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
      setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    const handleActionClick = (action) => {
      handlePopoverClose() // Close the popover before executing the action
      switch (action) {
        case 'view':
          handleViewClick(selectedData) // Pass `selectedData` directly
          break
        case 'edit':
          handleEditClick(selectedData)
          break
        case 'delete':
          handleDeleteClick(selectedData)
          break
        default:
          break
      }
    }

    return (
      <Box>
        <IconButton
          aria-label="more actions"
          onClick={handlePopoverOpen}
          sx={{
            color: open ? 'primary.main' : 'inherit'
          }}
        >
          <MoreVertIcon />
        </IconButton>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          disableRestoreFocus={true}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
            <Button
              onClick={() => handleActionClick('view')}
              sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
            >
              <Box display={'flex'} gap={1} alignItems={'center'}>
                <VisibilityIcon />
                <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                  View
                </Typography>
              </Box>
            </Button>

            <Button
              onClick={() => handleActionClick('edit')}
              sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
            >
              <Box display={'flex'} gap={1} alignItems={'center'}>
                <EditIcon style={{ color: 'orange' }} />
                <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                  Edit
                </Typography>
              </Box>
            </Button>

            <Button
              onClick={() => handleActionClick('delete')}
              sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
            >
              <Box display={'flex'} gap={1} alignItems={'center'}>
                <DeleteIcon style={{ color: 'red' }} />
                <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                  Delete
                </Typography>
              </Box>
            </Button>
          </Box>
        </Popover>
      </Box>
    )
  }
)
