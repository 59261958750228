import React, { useState, useEffect } from 'react'
import { Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material'

const User = ({ userData, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: 'client',
    password: ''
  })

  useEffect(() => {
    if (userData) {
      setFormData({
        name: userData.name || '',
        email: userData.email || '',
        role: userData.role || 'client',
        password: '' // Password is intentionally left blank for editing
      })
    }
  }, [userData])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSave(formData)
  }

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400, mx: 'auto', mt: 4 }}
      onSubmit={handleSubmit}
    >
      <Typography variant="h6" textAlign="center">
        {userData ? 'Edit User' : 'Add User'}
      </Typography>
      <TextField label="Name" name="name" value={formData.name} onChange={handleChange} required />
      <TextField
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <FormControl fullWidth>
        <InputLabel id="role-label">Role</InputLabel>
        <Select labelId="role-label" name="role" value={formData.role} onChange={handleChange} required>
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="client">Client</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        required={!userData} // Password is required only for adding a new user
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </Box>
    </Box>
  )
}

export default User
