import React from "react";
import Typography from "@mui/material/Typography/Typography";
import config from "../../config";
import "react-toastify/dist/ReactToastify.css";

import {
  Box,
  Card,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { isMobile } from "../../globalStyles/mobile-design";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EEEEEE",
    border: "1px solid #EAECF0",
    color: theme.palette.common.white,
    padding: "6px 6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "6px 6px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EAECF0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F6F6F6",
    border: "1px solid #EAECF0",
  },

  "&:lastChild td, &:lastChild th": {
    border: 0,
  },
}));

const WebhookList = ({
  data,
  rowsPerPage,
  page,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "300px" }}
      />
    </div>
  );

  return (
    <div
      style={{
        overflowY: "scroll",
        display: "flex",
        maxHeight: `calc(100vh - 170px)`,
        width: "100%",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::WebkitScrollbar": { width: 0 },
      }}
    >
      <TableContainer
        sx={{
          mt: 3,
          overflowY: "auto",
          paddingInline: 0.4,
          maxHeight: "100%",
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            border: "1px solid #EAECF0",
          }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontSize="14px"
                    title={true}
                    color="#333333"
                    fontWeight={500}
                    fontFamily={"poppins"}
                  >
                    Topic
                  </Typography>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                  fontFamily={"poppins"}
                >
                  Endpoint
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  fontSize="14px"
                  title={true}
                  color="#333333"
                  fontWeight={500}
                ></Typography>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <StyledTableRow>
                <TableCell colSpan={8} align="center">
                  <NoDataImage />
                </TableCell>
              </StyledTableRow>
            ) : (
              data?.map((location, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        maxWidth: 250,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#667085",
                          fontWeight: 400,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontFamily: "poppins",
                        }}
                      >
                        {location.topic}
                      </Typography>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        maxWidth: 250,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#667085",
                          fontWeight: 400,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontFamily: "poppins",
                        }}
                      >
                        {location.endpoint}
                      </Typography>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell sx={{ width: "3%", textAlign: "center" }}>
                    {location?.action}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff",
          padding: "2px",
          borderTop: "1px solid #dddddd",
        }}
        rowsPerPageOptions={[10, 15, 25, 45, 75, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default WebhookList;
