import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { productService } from "../../services/product-service";
import ProductList from "../../components/product-list";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchProducts = async () => {
    try {
      const response = await productService.fetch(
        rowsPerPage,
        rowsPerPage * page
      );
      setProducts(response.data);
      setCount(response.total);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [rowsPerPage, page]);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{ textTransform: "none", backgroundColor: "#DC585C" }}
          onClick={() => navigate("/product-import")}
        >
          <Typography
            fontFamily={"poppins"}
            fontSize={"14px"}
            fontWeight={500}
            color="#fff"
          >
            Add/Import
          </Typography>
        </Button>
      </div>
      <ProductList
        onDeleteData={fetchProducts}
        data={products}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Products;
