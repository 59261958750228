import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Products from "./pages/products/index";
import Orders from "./pages/orders/index";
import Location from "./pages/location/index";
import Webhook from "./pages/webhook/index";
import UserProfile from "./pages/profile/index";
import ProductDetails from "./pages/product-details/index";
import Login from "./pages/login/index";
import Layout from "./layout";
import ManageProduct from "./pages/manage-product";
import CartScreen from "./pages/cart";
import CheckoutScreen from "./pages/checkout";
import { AppProvider } from "./components/context";
import ProtectedRoute from "./protected-route";
import ManageOrder from "./pages/manage-order";
import User from "./pages/user";
import UploadStatus from "./pages/upload-status";
import OrderImport from "./components/order-import";
import ProductImport from "./pages/product-add-import";
import Customer from "./pages/customer";
import Country from "./pages/country";
import Province from "./pages/province";

const App = () => {
  const [token, setToken] = useState("");

  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route element={<ProtectedRoute></ProtectedRoute>}>
            <Route path="/products" element={<Products />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/manage-product" element={<ManageProduct />} />
            <Route path="/cart" element={<CartScreen />} />
            <Route path="/checkout" element={<CheckoutScreen />} />
            <Route path="/manage-order" element={<ManageOrder />} />
            <Route path="/user" element={<User />} />
            <Route path="/upload-status" element={<UploadStatus />} />
            <Route path="/order-import" element={<OrderImport />} />
            <Route path="/product-import" element={<ProductImport />} />
            <Route path="/location" element={<Location />} />
            <Route path="/webhook" element={<Webhook />} />
            <Route path="/customer" element={<Customer />} />
            <Route path="/country" element={<Country />} />
            <Route path="/province" element={<Province />} />
          </Route>
          <Route path="/login" element={<Login setToken={setToken} />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </AppProvider>
  );
};

export default App;
