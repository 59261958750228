import React, { useState, useEffect, useCallback } from 'react'
import CanvasJSReact from '@canvasjs/react-charts'
import { TextField, Autocomplete, CircularProgress } from '@mui/material'
import { productService } from '../../utils/services/productService'
import debounce from 'lodash.debounce'
import ClientAutocomplete from '../clientAutocomplete'
import { isMobile } from '../../globalStyles/mobile'
import ProductAutocomplete from '../productAutocomplete'
// import { roRO } from '@mui/x-date-pickers/locales'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

const initData = {
  ProductA: [
    {
      name: 'Quarter 1',
      dataPoints: [
        { label: 'Jan', y: 40 },
        { label: 'Feb', y: 51 },
        { label: 'Mar', y: 61 },
        { label: 'Apr', y: 71 },
        { label: 'May', y: 75 },
        { label: 'Jun', y: 73 },
        { label: 'Jul', y: 68 },
        { label: 'Aug', y: null },
        { label: 'Sep', y: null },
        { label: 'Oct', y: null },
        { label: 'Nov', y: null },
        { label: 'Dec', y: null }
      ]
    },
    {
      name: 'Forecast',
      lineDashType: 'dash',
      dataPoints: [
        { label: 'Jan', y: null },
        { label: 'Feb', y: null },
        { label: 'Mar', y: null },
        { label: 'Apr', y: null },
        { label: 'May', y: null },
        { label: 'Jun', y: null },
        { label: 'Jul', y: 68 },
        { label: 'Aug', y: 51 },
        { label: 'Sep', y: 54 },
        { label: 'Oct', y: 65 },
        { label: 'Nov', y: 78 },
        { label: 'Dec', y: 93 }
      ]
    },
    {
      name: 'Last Year',
      dataPoints: [
        { label: 'Jan', y: 30 },
        { label: 'Feb', y: 35 },
        { label: 'Mar', y: 32 },
        { label: 'Apr', y: 43 },
        { label: 'May', y: 53 },
        { label: 'Jun', y: 53 },
        { label: 'Jul', y: 43 },
        { label: 'Aug', y: 49 },
        { label: 'Sep', y: 46 },
        { label: 'Oct', y: 42 },
        { label: 'Nov', y: 51 },
        { label: 'Dec', y: 58 }
      ]
    }
  ]
}
const paramsData = {
  ProductA: [
    {
      name: 'Google Trends',
      dataPoints: [
        { label: 'Jan', y: 45 },
        { label: 'Feb', y: 47 },
        { label: 'Mar', y: 55 },
        { label: 'Apr', y: 67 },
        { label: 'May', y: 63 },
        { label: 'Jun', y: 62 },
        { label: 'Jul', y: 59 },
        { label: 'Aug', y: 56 },
        { label: 'Sep', y: 54 },
        { label: 'Oct', y: 52 },
        { label: 'Nov', y: 78 },
        { label: 'Dec', y: 85 }
      ]
    },
    {
      name: 'Product Wise Marketing Spend',
      lineDashType: 'dash',
      dataPoints: [
        { label: 'Jan', y: 10 },
        { label: 'Feb', y: 12 },
        { label: 'Mar', y: 18 },
        { label: 'Apr', y: 16 },
        { label: 'May', y: 14 },
        { label: 'Jun', y: 21 },
        { label: 'Jul', y: 23 },
        { label: 'Aug', y: 19 },
        { label: 'Sep', y: 18 },
        { label: 'Oct', y: 25 },
        { label: 'Nov', y: 29 },
        { label: 'Dec', y: 32 }
      ]
    },
    {
      name: 'Catgory wise marketing spend',
      dataPoints: [
        { label: 'Jan', y: 12 },
        { label: 'Feb', y: 23 },
        { label: 'Mar', y: 32 },
        { label: 'Apr', y: 21 },
        { label: 'May', y: 33 },
        { label: 'Jun', y: 23 },
        { label: 'Jul', y: 13 },
        { label: 'Aug', y: 19 },
        { label: 'Sep', y: 16 },
        { label: 'Oct', y: 12 },
        { label: 'Nov', y: 21 },
        { label: 'Dec', y: 28 }
      ]
    },
    {
      name: 'Brand wise marketing spend',
      dataPoints: [
        { label: 'Jan', y: 10 },
        { label: 'Feb', y: 15 },
        { label: 'Mar', y: 12 },
        { label: 'Apr', y: 23 },
        { label: 'May', y: 33 },
        { label: 'Jun', y: 33 },
        { label: 'Jul', y: 23 },
        { label: 'Aug', y: 29 },
        { label: 'Sep', y: 26 },
        { label: 'Oct', y: 22 },
        { label: 'Nov', y: 31 },
        { label: 'Dec', y: 38 }
      ]
    }
  ]
}

const SalesChart = ({ hideFilter, showParameter = false, onClientFilterChange }) => {
  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [allProducts, setAllProducts] = useState([])
  const [productSearchValue, setProductSearchValue] = useState('')
  const [allClients, setAllClients] = useState([])
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  // const fetchProductData = useCallback(
  //   debounce(async (clientID, searchQuery, pageNumber) => {
  //     setLoading(true);
  //     try {
  //       const response = await productService.fetch(
  //         10,
  //         pageNumber * 10,
  //         clientID,
  //         searchQuery
  //       );
  //       if (pageNumber === 0) {
  //         setAllProducts(response.data);
  //       } else {
  //         setAllProducts((prevProducts) => [...prevProducts, ...response.data]);
  //       }
  //       setTotal(response.total);
  //     } catch (error) {
  //       console.error("Fetch failed:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }, 300),
  //   []
  // );

  // useEffect(() => {
  //   fetchProductData(
  //     selectedClient ? selectedClient?._id : "",
  //     productSearchValue,
  //     page
  //   );
  // }, [productSearchValue, selectedClient, page, fetchProductData]);

  // useEffect(() => {
  //   if (productSearchValue === "") {
  //     fetchProductData("", "", 0);
  //   }
  // }, [productSearchValue, fetchProductData]);

  // const handleScroll = (event) => {
  //   const bottom =
  //     event.target.scrollHeight ===
  //       Math.ceil(event.target.scrollTop + event.target.clientHeight) ||
  //     event.target.scrollHeight ===
  //       Math.ceil(event.target.scrollTop + event.target.clientHeight) - 1 ||
  //     event.target.scrollHeight ===
  //       Math.ceil(event.target.scrollTop + event.target.clientHeight) + 1;
  //   if (bottom && allProducts.length < total && !loading) {
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // };

  useEffect(() => {
    if (onClientFilterChange) {
      onClientFilterChange(selectedClient)
    }
  }, [selectedClient, onClientFilterChange])

  const handleClientChange = (event, value) => {
    setSelectedClient(value)
    setProductSearchValue('') // Clear search value when client changes
  }

  const handleProductChange = (value) => {
    setSelectedProduct(value)
  }

  // const handleSearchChange = (event) => {
  //   const value = event.target.value;
  //   setProductSearchValue(value);
  //   setPage(0);
  //   setAllProducts([]);
  // };

  const [productData, setProductData] = useState(initData)

  useEffect(() => {
    if (showParameter) {
      setProductData({
        ProductA: [...initData.ProductA, ...paramsData.ProductA]
      })
    } else {
      setProductData(initData)
    }
  }, [showParameter])

  const options = {
    title: {
      text: `Monthly Trend ${selectedProduct?.name ? `for ${selectedProduct?.name}` : ''}`
    },
    axisX: {
      title: 'Month',
      interval: 1
    },
    axisY: {
      title: 'Sales'
    },
    data: productData.ProductA?.map((series) => ({
      type: 'spline',
      showInLegend: true,
      name: series.name,
      lineDashType: series.lineDashType,
      dataPoints: series.dataPoints
    }))
  }

  return (
    <div>
      {!hideFilter ? (
        <div
          style={{
            marginBottom: '15px',
            display: 'flex',
            justifyContent: isMobile() ? 'center' : 'flex-end',
            flexDirection: isMobile() ? 'column' : 'row'
          }}
        >
          <ClientAutocomplete
            selectedClient={selectedClient}
            onClientChange={(client) => {
              setSelectedClient(client)
            }}
          />
          {/* <Autocomplete
            ListboxProps={{
              onScroll: handleScroll,
            }}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  backgroundColor: "#f5f5f5",
                  borderBottom: "1px solid #ddd",
                  padding: "10px",
                  position: "relative",
                }}
                title={option.name} // This will show the full name on hover
              >
                <p
                  style={{
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    margin: 0, // Reset margin for p element
                    width: "100%", // Ensure p element takes full width of li
                  }}
                >
                  {option.name}
                </p>
              </li>
            )}
            size="small"
            style={{
              minWidth: 250,
              marginRight: 10,
              marginTop: isMobile() ? 5 : 0,
            }}
            id="product-select"
            options={allProducts}
            getOptionLabel={(option) => option.name}
            value={selectedProduct}
            onChange={handleProductChange}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Select Product"
                variant="outlined"
                onChange={handleSearchChange}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          /> */}
          <ProductAutocomplete
            selectedProduct={selectedProduct}
            onProductChange={handleProductChange}
            selectedClient={selectedClient}
            width={250}
            label="Select Product"
            errorMessage={null}
            hideLabel={false}
          />
        </div>
      ) : (
        <></>
      )}

      <CanvasJSChart options={options} />
    </div>
  )
}

export default SalesChart
