import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { providerEventMappingService } from "../../utils/services/providerEventMappingService";
import ProviderAutocomplete from "../providerAutocomplete";
import EventAutocomplete from "../rl_eventAutocomplete";

const ProviderEventMappingModal = ({
  open,
  handleClose,
  onAddData,
  selectedEventMapping,
}) => {
  const [selectedRlEventCode, setSelectedRlEventCode] = useState({code:""}); 
  const [selectedProvider, setSelectedProvider] = useState({name:""});
  const [providerEventCode, setProviderEventCode] = useState(""); 
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!selectedRlEventCode) {
      errors.rl_event_code = "RL Event Code is required";
    }
    if (!selectedProvider) {
      errors.provider = "Provider is required";
    }
    if (!providerEventCode) {
      errors.provider_event_code = "Provider Event Code is required";
    }else if(providerEventCode<0){
      errors.provider_event_code = "Provider Event Code should be greater than 0";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (selectedEventMapping) {
      setSelectedRlEventCode({
        code: selectedEventMapping?.rl_event_code?.code || "", 
      });
      setSelectedProvider({
        name: selectedEventMapping?.provider?.name || "", 
      });
      setProviderEventCode(selectedEventMapping?.provider_event_code || "");
    } else {
      resetData();
    }
  }, [selectedEventMapping]);

  const resetData = () => {
    setSelectedRlEventCode({code:""});
    setSelectedProvider({name:""});
    setProviderEventCode("");
    setFormErrors({});
  };


  const handleProviderEventCodeChange = (e) => {
    setProviderEventCode(e.target.value);
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await providerEventMappingService.edit(selectedEventMapping._id, {
        rl_event_code: selectedRlEventCode._id,
        provider: selectedProvider._id,
        provider_event_code: providerEventCode,
      });
      onAddData && onAddData();
      toast.success("Provider event mapping edited successfully!");
      resetData();
    } catch (error) {
      toast.error("Failed to edit event.");
      console.error(error);
    }
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await providerEventMappingService.create({
        rl_event_code: selectedRlEventCode._id,
        provider: selectedProvider._id,
        provider_event_code: providerEventCode,
      });
      onAddData && onAddData();
      toast.success("Provider event mapping created successfully!");
      resetData();
      handleClose();
    } catch (error) {
      toast.error("Failed to create event.");
      console.error(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        resetData();
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle id="form-dialog-title" style={{ fontSize: 24 }}>
          {selectedEventMapping ? "Edit" : "Create"} Provider Event Mapping
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography>
              RL Event Code <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <EventAutocomplete
              selectedEvent={selectedRlEventCode}
              onEventChange={(e) => setSelectedRlEventCode(e)}
              width="100%"
              errorMessage={formErrors.rl_event_code}
              hideLabel
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>
              Provider <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <ProviderAutocomplete
              selectedProvider={selectedProvider}
              onProviderChange={(e) => setSelectedProvider(e)}
              width="100%"
              errorMessage={formErrors.provider}
              hideLabel
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography>
              Provider Event Code <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              type="number"
              size="small"
              name="provider_event_code"
              value={providerEventCode}
              onChange={handleProviderEventCodeChange}
              fullWidth
              placeholder="Enter Provider Event Code"
              margin="dense"
              error={!!formErrors.provider_event_code}
              helperText={formErrors.provider_event_code}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          onClick={() => {
            handleClose();
            resetData();
          }}
          color="primary"
          variant="outlined"
          style={{textTransform:"none"}}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedEventMapping ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          style={{textTransform:"none"}}
        >
          {selectedEventMapping ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProviderEventMappingModal;
