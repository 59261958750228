// Filename - components/SidebarData.js

import React from 'react'
import ProductIcon from '../../globalStyles/icons/product-icon'
import OrderIcon from '../../globalStyles/icons/order-icon'
import { LocationCity, Webhook } from '@mui/icons-material'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag'
import PublicIcon from '@mui/icons-material/Public'
export const getIcon = (iconName, fill) => {
  switch (iconName) {
    case 'ProductIcon':
      return <ProductIcon fill={fill} />
    case 'OrderIcon':
      return <OrderIcon fill={fill} />
    case 'Location':
      return <LocationCity fill={'#000'} />
    case 'Webhook':
      return <Webhook fill={'#000'} />
    case 'Customer':
      return <SupervisorAccountIcon fill={'#000'} />
    case 'Country':
      return <OutlinedFlagIcon fill={'#000'} />
    case 'Province':
      return <PublicIcon fill={'#000'} />
    default:
      return null
  }
}

export const Data = [
  {
    title: 'Product',
    path: '/products',
    icon: 'ProductIcon',
    roles: ['admin', 'client']
  },
  {
    title: 'Order',
    path: '/orders',
    icon: 'OrderIcon',
    roles: ['admin', 'client']
  },
  {
    title: 'Location',
    path: '/location',
    icon: 'Location',
    roles: ['admin', 'client']
  },
  {
    title: 'Webhook',
    path: '/webhook',
    icon: 'Webhook',
    roles: ['admin', 'client']
  },
  {
    title: 'Customer',
    path: '/customer',
    icon: 'Customer',
    roles: ['admin', 'client']
  },
  {
    title: 'Country',
    path: '/country',
    icon: 'Country',
    roles: ['admin']
  },
  {
    title: 'Province',
    path: '/province',
    icon: 'Province',
    roles: ['admin']
  }
]
