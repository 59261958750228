import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import debounce from "lodash/debounce";
import { zoneService } from "../../utils/services/zoneService";

const ZoneAutocomplete = ({
  onZoneChange,
  selectedZone,
  clientId,
  width = 200,
  hideLabel,
  label = "Select Zone",
  errorMessage,
}) => {
  const [zones, setZones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [zoneSearchValue, setZoneSearchValue] = useState("");
  const [page, setPage] = useState(0);

  const fetchZoneData = useCallback(
    debounce(async (searchQuery, pageNumber, isLoadMore = false) => {
      if (isLoadMore) {
        setLoadMoreLoading(true);
      } else {
        setLoading(true);
      }

      try {
        const response = await zoneService.fetch(
          10,
          pageNumber * 10,
          clientId,
          searchQuery
        );

        if (pageNumber === 0) {
          setZones(response.data);
        } else {
          setZones((prevData) => [...prevData, ...response.data]);
        }
        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 300),
    [clientId]
  );

  useEffect(() => {
    fetchZoneData(zoneSearchValue, page);
  }, [zoneSearchValue, page, fetchZoneData]);

  useEffect(() => {
    if (zoneSearchValue === "") {
      fetchZoneData("", 0);
    }
  }, [zoneSearchValue, fetchZoneData]);

  const handleZoneSearchChange = (event, value) => {
    if (event && event.target) {
      const searchValue = event.target.value;
      setZoneSearchValue(searchValue);
      setPage(0);
      setZones([]);
    }
  };

  const handleZoneFilterValue = (event, value) => {
    if (value && value.name === "Load More") {
      return;
    }
    if (onZoneChange) {
      onZoneChange(value);
    }
    setZoneSearchValue("");
    setPage(0);
  };

  const handleLoadMoreClick = () => {
    if (zones.length < total && !loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Add "Load More" option if there is more data to load
  const optionsWithLoadMore = [...zones];
  if (zones.length < total && zones.length % 10 === 0) {
    optionsWithLoadMore.push({ name: "Load More" });
  }

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={optionsWithLoadMore}
      value={selectedZone?.name ? selectedZone : null}
      getOptionLabel={(option) => option.name || ""}
      onChange={handleZoneFilterValue}
      ListboxProps={{
        onScroll: (event) => {
          const bottom =
            event.target.scrollHeight ===
            Math.ceil(event.target.scrollTop + event.target.clientHeight);
          if (bottom && zones.length < total && !loading) {
            handleLoadMoreClick();
          }
        },
      }}
      renderOption={(props, option) =>
        option.name === "Load More" ? (
          <li
            {...props}
            onClick={handleLoadMoreClick}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <Typography
              color="primary"
              variant="body2"
              style={{ marginRight: 8 }}
            >
              Load More
            </Typography>
            {loadMoreLoading && <CircularProgress color="inherit" size={16} />}
          </li>
        ) : (
          <li {...props}>{option.name}</li>
        )
      }
      onInputChange={handleZoneSearchChange}
      onOpen={() => fetchZoneData("", 0)}
      isOptionEqualToValue={(option, value) => option.name === value?.name}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Zone"
          label={hideLabel ? "" : label}
          margin="dense"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          error={!!errorMessage}
          helperText={errorMessage}
          style={{ width }}
        />
      )}
      style={{ maxWidth: width }}
    />
  );
};

export default ZoneAutocomplete;
