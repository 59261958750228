// api.js

import axiosInstance from "../axiosInstance";

export const zipZoneService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post("/zip_zone", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetch: async (limit, skip, port, zone, clientId, destination) => {
    try {
      let response = await axiosInstance.get(
        `/zip_zone?$limit=${limit || 15}&$skip=${skip || 0}&${
          port?._id ? `port=${port?._id}` : ""
        }&${zone?._id ? `zone=${zone?._id}` : ""}&${
          clientId ? `client_id=${clientId}` : ""
        }&$populate=port&$populate=zone&${
          destination ? `destination=${destination}` : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  bulkUpload: async (data) => {
    try {
      let response = await axiosInstance.post("/zip-zone-upload", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  bulkUploadStatus: async () => {
    try {
      let response = await axiosInstance.get("/upload_status?type=zip-zone");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteStatus: async (id) => {
    try {
      let response = await axiosInstance.delete(`/upload_status/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/zip_zone/${id}`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/zip_zone/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
