import axiosInstance from "../utils/axios-instance";

export const webhookService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post("/webhook", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetch: async (limit, skip) => {
    try {
      const response = await axiosInstance.get(
        `/webhook?$limit=${limit || 10}&$skip=${skip || 0}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/webhook/${id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/webhook/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
