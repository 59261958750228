import React from "react";
import { DialogContent, DialogActions, Typography } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";

const NotificationModal = ({ onClose }) => {
  return (
    <div sx={{ p: 2 }}>
      <DialogContent>
        <Typography
          fontFamily={"poppins"}
          fontSize={"12px"}
          fontWeight={500}
          color="#333333"
        >
          There is no notification at the moment.
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: 10 }}></DialogActions>
    </div>
  );
};
export default NotificationModal;
