import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Box } from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'

const ManifestDetails = ({ Manifest }) => {
  if (!Manifest) return null

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
        m: 'auto',
        border: '1px solid #E0E0E0',
        borderBottom: 'none'
      }}
    >
      <CustomTypography
        variant="h6"
        gutterBottom
        sx={{
          padding: '8px',
          borderBottom: '1px solid #E0E0E0',
          backgroundColor: '#E0F7FA',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minWidth: '50%'
        }}
      >
        <CustomTypography>Manifest ID: #{Manifest.manifestId}</CustomTypography>
        <CustomTypography>MANIFEST STATUS</CustomTypography>
        <CustomTypography></CustomTypography>
      </CustomTypography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#F5F5F5', fontWeight: 'bold', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Carrier Name:</CustomTypography>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Manifest.carrierName}</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Date:</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Manifest.date}</CustomTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Origin:</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Manifest.origin}</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Destination:</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Manifest.destination}</CustomTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Status:</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Manifest.status}</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Current Location:</CustomTypography>
              </TableCell>
              <TableCell sx={{ backgroundColor: '#F5F5F5', borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Manifest.currentLocation}</CustomTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>Last Update:</CustomTypography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>
                <CustomTypography fontSize={13}>{Manifest.lastUpdate}</CustomTypography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ManifestDetails
