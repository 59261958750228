import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import FileUploadModal from "../../components/product-upload-modal";
import { useNavigate } from "react-router-dom";
import { orderService } from "../../services/order-service";
import AddImportCard from "../../components/add-import-card";
import TemplateCard from "../../components/template-card";
import MappingColumns from "../mapping-columns";
import StepperComponent from "../../components/stepper";
import { mappingTemplateService } from "../../services/mapping-template-service";
import { toast } from "react-toastify";
import UploadIcon from "@mui/icons-material/FileUploadOutlined";
import AddIcon from "@mui/icons-material/Add";
import ImportResult from "./import-result";

const OrderImport = () => {
  const [orders, setOrders] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [uploadResponseData, setUploadResponseData] = useState(null);
  const [uploadConfigData, setUploadConfigData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [mappingTemplates, setMappingTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [mappingColumns, setMappingColumns] = useState([]);

  useEffect(() => {
    if (selectedTemplate && selectedTemplate != "Other") {
      setMappingColumns(
        mappingTemplates.find((x) => x._id === selectedTemplate)
          ?.mapped_column || []
      );
    } else {
      setMappingColumns([]);
    }
  }, [selectedTemplate]);

  const steps = ["Add/Upload", "Select Template", "Mapping", "Verify/Update"];

  const handleStepChange = (newStep) => {
    setActiveStep(newStep);
  };
  const navigate = useNavigate();
  const fetchOrders = async () => {
    try {
      const response = await orderService.fetch();
      setOrders(response);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchUploadConfig = async () => {
    try {
      const response = await orderService.getUploadConfig();
      setUploadConfigData(response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchOrders();
    fetchUploadConfig();
    fetchMappingTemplates();
  }, []);

  const fetchMappingTemplates = async () => {
    try {
      const response = await mappingTemplateService.fetch("order");
      setMappingTemplates(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFile = async (data) => {
    try {
      const formData = new FormData();
      formData.append("file", data);
      const uploadResponse = await orderService.bulkUpload(formData);
      setUploadResponseData(uploadResponse);
      setOpenUploadModal(false);
      setActiveStep(1);
    } catch (error) {
      console.error(error);
    }
  };
  const patchUploadConfig = async () => {
    try {
      const uploadConfigResponse = await orderService.uploadConfig(
        { start_process: true, mapped_column: mappingColumns },
        uploadResponseData?._id
      );
      setActiveStep(3);
    } catch (error) {
      console.error(error);
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <AddImportCard
            title="Create Your Order List"
            description="Add the things your customers will want to buy."
            primaryButtonText="Order"
            secondaryButtonText="Upload"
            onClickAdd={() => navigate("/manage-order")}
            onClickImport={() => setOpenUploadModal(true)}
            showImages={true}
            images={["./order1.png"]}
            PrimaryIcon={AddIcon}
            SecondaryIcon={UploadIcon}
          />
        );
      case 1:
        return (
          <TemplateCard
            title="Select Template"
            description={`Start with a template that’s just right for you.`}
            templates={[...mappingTemplates, { _id: "Other", name: "Other" }]}
            onTemplateChange={(value) => setSelectedTemplate(value)}
            onNext={() => {
              if (!selectedTemplate) {
                toast.error("Select the template first!", { autoClose: 2000 });
              } else {
                setActiveStep(2);
              }
            }}
            image="./template.png"
            defaultTemplate="template1"
            displayKey={"name"}
            id={"_id"}
            selectedTemplate={selectedTemplate}
          />
        );
      case 2:
        return (
          <MappingColumns
            title="Product Mapping Columns"
            description="Use the dropdowns to adjust and ensure everything lines up for orders."
            rows={uploadResponseData?.model_schema}
            previewData={uploadResponseData?.preview_record}
            dropdownOptions={uploadResponseData.file_column}
            onCancel={() => navigate("/orders")}
            onProceed={() => patchUploadConfig()}
            onSaveAndProceed={() => patchUploadConfig()}
            mappingColumns={mappingColumns}
            setMappingColumns={setMappingColumns}
          />
        );
      case 3:
        return (
          <ImportResult
            bulkUploadConfig={uploadResponseData}
            onFinish={() => {
              navigate("/orders");
            }}
            type="order"
          />
        );
      default:
        return <Typography variant="h6">Unknown Step</Typography>;
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <StepperComponent
          activeStep={activeStep}
          steps={steps}
          handleStepClick={handleStepChange}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        {renderStepContent()}
      </div>
      <FileUploadModal
        onClickShowUpload={() => navigate("/upload-status")}
        product
        onSubmitUploadData={(e) => uploadFile(e)}
        onClose={() => setOpenUploadModal(false)}
        open={openUploadModal}
        title={"Product List"}
        type={"order"}
      ></FileUploadModal>
    </div>
  );
};

export default OrderImport;
