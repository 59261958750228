import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  TextField
} from '@mui/material'
import { overPackService } from '../../utils/services/overPackService'

const ShipmentGroupModal = ({ open, setOpen, allShipments, onSuccessOverpackCreation }) => {
  const [selectedShipments, setSelectedShipments] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [shipments, setShipments] = useState([])
  const [selectedShipmentsForOverPack, setSelectedShipmentsForOverPack] = useState([])
  const createOverPack = async (data) => {
    const overPackData = {
      overpack: {
        weight: 10,
        width: 20,
        height: 30,
        length: 10,
        shipments: selectedShipmentsForOverPack.map((item) => ({
          id: item.id,
          label_id:
            item?.label?.length > 0 ? item?.label?.find((x) => x.cancelled == false)?.id : item?.label?.id,
          tracking_number:
            item?.label?.length > 0
              ? item?.label?.find((x) => x.cancelled == false)?.tracking_number
              : item?.label.tracking_number
        }))
      }
    }
    try {
      const response = await overPackService.create(overPackData)
      if (response.id) {
        onSuccessOverpackCreation && onSuccessOverpackCreation(response.id)
      }
    } catch (error) {
      console.error('fetch orders failed:', error)
    }
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target
    setSelectedShipments((prev) =>
      checked ? [...prev, value] : prev.filter((shipment) => shipment !== value)
    )

    let selectedData = filteredShipments?.find((x) => x.id == value)
    setSelectedShipmentsForOverPack(
      selectedShipmentsForOverPack?.find((x) => x.id.toString() === value.toString())
        ? selectedShipmentsForOverPack?.filter((z) => z.id != value)
        : [...selectedShipmentsForOverPack, selectedData]
    )
  }

  const filteredShipments = shipments.filter((shipment) =>
    shipment.name.toString().includes(searchQuery.toLowerCase())
  )
  useEffect(() => {
    setShipments(
      allShipments.map((x) => ({
        ...x,
        name: x.id,
        products: x.line_items?.map((item) => item?.description)
      }))
    )
  }, [allShipments])
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Shipments</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            label="Search Shipments"
            variant="outlined"
            fullWidth
            margin="dense"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FormControl component="fieldset" fullWidth>
            <List
              className="custom-scrollbar"
              style={{ maxHeight: '350px', overflowY: 'auto', minWidth: 350 }}
            >
              {filteredShipments.map((shipment) => (
                <div key={shipment.id}>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={shipment.name}
                          checked={selectedShipments.indexOf(shipment.id.toString()) > -1}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={<ListItemText primary={shipment.name} />}
                    />
                  </ListItem>
                  <List component="div" disablePadding>
                    {shipment.products.map((product) => (
                      <ListItem key={product} dense sx={{ pl: 4 }}>
                        <ListItemText primary={product} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              ))}
            </List>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: 'red', margin: 1 }} onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: '#007DFF', color: '#FFF', margin: 1 }}
            onClick={createOverPack}
            color="primary"
          >
            Create Overpack
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ShipmentGroupModal
