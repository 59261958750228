import React, { useEffect, useState } from 'react'
import { Modal, Box, TextField, Button, Typography, IconButton, CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-toastify'
import { globalNetworkService } from '../../utils/services/globalNetworkService'

const GlobalNetworkDesignAddEditModal = ({ open, onClose, onSave, initialData = {} }) => {
  const [loading, setLoading] = useState(false)
  const clientId = localStorage.getItem('client_id')
  const [formData, setFormData] = useState({
    supplier_location: initialData?.supplier_location || '',
    fulfillment_location: initialData?.fulfillment_location || '',
    shipping_service: initialData?.shipping_service || '',
    package_type: initialData?.package_type || '',
    weight: initialData?.weight || ''
  })
  useEffect(() => {
    setFormData({
      supplier_location: initialData?.supplier_location || '',
      fulfillment_location: initialData?.fulfillment_location || '',
      shipping_service: initialData?.shipping_service || '',
      package_type: initialData?.package_type || '',
      weight: initialData?.weight || ''
    })
  }, [initialData])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const addData = async () => {
    if (initialData?._id) {
      try {
        setLoading(true)
        const response = await globalNetworkService.edit(initialData?._id, {
          ...formData,
          client_id: clientId
        })
        toast.success('Global network data updated successfully!')
        onSave()
        handleClose()
      } catch (error) {
        console.error('Create failed:', error)
      } finally {
        setLoading(false)
      }
    } else {
      try {
        setLoading(true)
        const response = await globalNetworkService.create({ ...formData, client_id: clientId })
        toast.success('Global network data created successfully!')
        onSave()
        handleClose()
      } catch (error) {
        console.error('Create failed:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleSave = () => {
    if (
      formData.supplier_location &&
      formData.fulfillment_location &&
      formData.shipping_service &&
      formData.package_type &&
      formData.weight
    ) {
      addData()
    } else {
      toast.warning('Please fill in all fields')
    }
  }

  const handleClose = () => {
    setFormData({
      supplier_location: '',
      fulfillment_location: '',
      shipping_service: '',
      package_type: '',
      weight: ''
    })
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit"></CircularProgress>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">
              {initialData?.supplier_location ? 'Edit Data' : 'Add Global Network Data'}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            size="small"
            fullWidth
            label="Supplier Location"
            name="supplier_location"
            value={formData.supplier_location}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            size="small"
            fullWidth
            label="Fulfillment Location"
            name="fulfillment_location"
            value={formData.fulfillment_location}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            size="small"
            fullWidth
            label="Shipping Service"
            name="shipping_service"
            value={formData.shipping_service}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            size="small"
            fullWidth
            label="Package Type"
            name="package_type"
            value={formData.package_type}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            size="small"
            fullWidth
            label="Weight"
            name="weight"
            type="number"
            value={formData.weight}
            onChange={handleChange}
            margin="normal"
            required
          />
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button onClick={handleClose} sx={{ mr: 1, color: 'red' }}>
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  )
}

export default GlobalNetworkDesignAddEditModal
