// axiosInstance.js
import axios from 'axios'
import config from '../config'
import { toast } from 'react-toastify'

const axiosInstance = axios.create({
  baseURL: config.baseURL
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor to handle 401 Unauthorized errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const status = error.response ? error.response.status : null
    if (status === 401) {
      // Handle unauthorized access
      console.log('Unauthorized access', error)
      localStorage.clear()
      if (window?.location?.pathname !== '/login') {
        window.location.href = '/login'
      }
    } else {
      if (error.message !== 'canceled') {
        toast.error(error?.response?.data?.message || 'An error occurred')
      }

      // console.error("An error occurred:", error);
    }

    return Promise.reject(error)
  }
)
// Utility function to handle unauthorized access

export default axiosInstance
