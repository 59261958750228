import React, { useEffect, useState, useCallback } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CustomTypography from '../../globalStyles/fonts'
import { clientService } from '../../utils/services/clientService'
import { Divider } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { clientMapping } from '../../utils/services/clientMapping'
import debounce from 'lodash.debounce'
import { isMobile } from '../../globalStyles/mobile'

const AssignClient = ({ open, onClose, selectedData, mapKey = 'port' }) => {
  const [tab, setTab] = useState(0)
  const [clientData, setClientData] = useState([])
  const [mappingData, setMappingData] = useState([])
  const [selectedButton, setSelectedButton] = useState('Pending Assign')
  const [searchInput, setSearchInput] = useState('')
  const [pendingAssign, setPendingAssign] = useState([])
  const [clientAssigned, setClientAssigned] = useState([])
  const [renderData, setRenderData] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [isHeaderChecked, setIsHeaderChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [totalAssignedClient, setTotalAssignedClient] = useState(0)
  const [page, setPage] = useState(0)
  const [assignedPage, setAssignedPage] = useState(0)

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     fetchClientData(searchInput, 0);
  //   }, 500);

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [searchInput]);

  const handleSearchInputChange = (e) => {
    setPage(0)
    setClientData([])
    setSearchInput(e.target.value)
  }

  // const fetchClientData = async (input) => {
  //   try {
  //     const response = await clientService.fetch('', '', input)
  //     setClientData(response.data)
  //   } catch (error) {
  //     console.error('Fetch failed:', error)
  //   }
  // }
  const fetchClientData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true)
      const clientIds = mappingData?.map((x) => x.client?._id)
      try {
        const response = await clientService.fetch(15, pageNumber * 15, searchQuery)
        if (pageNumber === 0) {
          setClientData([...response.data].filter((data) => !clientIds?.includes(data?._id)))
        } else {
          setClientData((prevClients) =>
            [...prevClients, ...response.data].filter((data) => !clientIds?.includes(data?._id))
          )
        }
        setTotal(response.total)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        setLoading(false)
      }
    }, 300),
    []
  )
  const fetchMappingData = async (size) => {
    setLoading(true)
    try {
      const response = await clientMapping(mapKey).fetch(selectedData?._id, 15, assignedPage * 15)
      if (page === 0) {
        setMappingData(response.data)
      } else {
        setMappingData((prevClients) => [...prevClients, ...response.data])
      }
      setTotalAssignedClient(response.total)
    } catch (error) {
      console.error('Fetch failed:', error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (open && selectedButton === 'Pending Assign' && selectedData) {
      fetchClientData(searchInput, page)
    }
    if (selectedData) {
      fetchMappingData()
    }
  }, [searchInput, page, selectedData, selectedButton, assignedPage])

  // const fetchMappingData = async () => {
  //   try {
  //     const response = await clientMapping(mapKey).fetch(selectedData?._id)
  //     setMappingData(response.data)
  //   } catch (error) {
  //     console.error('Fetch failed:', error)
  //   }
  // }
  const assignClient = async (data) => {
    try {
      const response = await clientMapping(mapKey).create(data)
      setClientData(clientData.filter((x) => x._id !== data?._id))
      fetchClientData('', 0)
      fetchMappingData()
    } catch (error) {
      console.error('Fetch failed:', error)
    }
  }
  const unAssignClient = async (data) => {
    let selected = mappingData?.find((x) => x.client?._id === data?._id)

    try {
      const response = await clientMapping(mapKey).delete(selected?._id)
      setMappingData(mappingData.filter((x) => x._id !== selected?._id))
      fetchClientData('', 0)
      fetchMappingData()
    } catch (error) {
      console.error('Fetch failed:', error)
    }
  }
  const handleHeaderCheckboxChange = (event) => {
    const isChecked = event.target.checked
    setIsHeaderChecked(isChecked)
    if (isChecked) {
      setSelectedItems(renderData.map((client) => client?._id))
    } else {
      setSelectedItems([])
    }
  }
  const handleAssignAll = async () => {
    try {
      const data = selectedItems.map((clientId) => ({
        [mapKey]: selectedData?._id,
        client: clientId
      }))
      await Promise.all(data.map(assignClient))
      setSelectedItems([])
      setIsHeaderChecked(false)
      fetchClientData('', 0)
      fetchMappingData(0)
    } catch (error) {
      console.error('Assign All failed:', error)
    }
  }
  const handleUnassignAll = async () => {
    try {
      await Promise.all(
        selectedItems.map(async (clientId) => {
          let client = clientData.find((c) => c._id === clientId)
          if (client) {
            await unAssignClient(client)
          }
        })
      )
      setSelectedItems([])
      setIsHeaderChecked(false)
    } catch (error) {
      console.error('Unassign All failed:', error)
    }
  }
  const handleItemCheckboxChange = (id) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems?.includes(id)
        ? prevSelectedItems.filter((itemId) => itemId !== id)
        : [...prevSelectedItems, id]
    )
  }
  useEffect(() => {
    if (clientData?.length) {
      const clientIds = mappingData?.map((x) => x.client?._id)
      setPendingAssign(clientData?.filter((data) => !clientIds?.includes(data?._id)))
      setClientAssigned(mappingData?.map((data) => data.client))
      setRenderData(
        tab === 0
          ? clientData?.filter((data) => !clientIds?.includes(data?._id))
          : mappingData?.map((data) => data.client)
      )
    }
  }, [clientData, mappingData, selectedButton])

  const handleButtonClick = (buttonName) => {
    setPage(0)
    setSelectedButton(buttonName)
    setTab(buttonName === 'Pending Assign' ? 0 : buttonName === 'Client Assigned' ? 1 : 0)
    if (buttonName === 'Pending Assign') {
      fetchClientData('', 0)

      // setRenderData(pendingAssign)
    } else {
      fetchMappingData(0)
      // setRenderData(clientAssigned)
    }
  }
  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) ||
      event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) - 1 ||
      event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) + 1
    if (bottom && clientData.length < total) {
      setPage((prevPage) => prevPage + 1)
    } else if (bottom && mappingData.length < totalAssignedClient) {
      setAssignedPage((prevPage) => prevPage + 1)
    }
  }

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: '100%', padding: 0.5 }}>
        {[...new Map(renderData.map((item) => [item?._id, item])).values()].map((client, index) => (
          <Card key={index} sx={{ marginBottom: 2 }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
              }}
            >
              <Checkbox
                checked={selectedItems?.includes(client?._id)}
                onChange={() => handleItemCheckboxChange(client._id)}
              />
            </div>
            <CardContent>
              <div style={{ width: '100%' }}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Client: {client?.business_name || client?.name}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography>
                      Status:&nbsp;
                      <CustomTypography
                        fontSize="13px"
                        color={tab === 0 ? '#FF8A00' : '#007613'}
                        fontWeight={400}
                        component="span"
                      >
                        {tab === 0 ? 'Pending' : 'Assigned'}
                      </CustomTypography>
                    </Typography>
                  </Box>
                </Box>

                <Box mt={2} justifyContent="flex-end" display="flex">
                  <Button
                    disabled={selectedItems?.length > 1}
                    variant="contained"
                    style={{
                      backgroundColor: tab === 0 ? '#007613' : '#D92D20',
                      padding: 6,
                      textTransform: 'none'
                    }}
                    onClick={() => {
                      if (tab === 0) {
                        assignClient({
                          [mapKey]: selectedData?._id,
                          client: client?._id
                        })
                      } else {
                        unAssignClient(client)
                      }
                    }}
                  >
                    <CustomTypography fontSize={13} fontWeight={400}>
                      {tab === 0 ? 'Assign' : 'Unassign'}
                    </CustomTypography>
                  </Button>
                </Box>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>
    )
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <DialogTitle>
          <CustomTypography fontSize={20} fontWeight={500} color={'#000000'}>
            Assign Client
          </CustomTypography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: '#000000'
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <Divider style={{ marginTop: -8 }} />

      <DialogContent>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isMobile() ? 'column' : 'row',
            mb: 2,
            gap: isMobile() ? 2 : 0,
            justifyContent: isMobile() ? 'flex-start' : 'space-between'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: isMobile() ? '100%' : 'auto'
            }}
          >
            <Button
              onClick={() => handleButtonClick('Pending Assign')}
              style={{
                backgroundColor: selectedButton === 'Pending Assign' ? '#151D48' : 'white',
                color: selectedButton === 'Pending Assign' ? 'white' : '#000',
                padding: 10,
                textTransform: 'none'
              }}
            >
              <CustomTypography fontWeight={selectedButton === 'Pending Assign' ? 600 : 400}>
                Pending Assign
              </CustomTypography>
            </Button>
            <Button
              onClick={() => handleButtonClick('Client Assigned')}
              style={{
                backgroundColor: selectedButton === 'Client Assigned' ? '#151D48' : 'white',
                color: selectedButton === 'Client Assigned' ? 'white' : '#000',
                padding: 10,
                textTransform: 'none'
              }}
            >
              <CustomTypography fontWeight={selectedButton === 'Client Assigned' ? 600 : 400}>
                Client Assigned
              </CustomTypography>
            </Button>
          </Box>
          {selectedButton === 'Pending Assign' ? (
            <Box
              sx={{
                position: 'relative',
                mt: isMobile() ? 2 : 0,
                ml: isMobile() ? 0 : 2,
                width: isMobile() ? '100%' : 'auto'
              }}
            >
              <InputBase
                placeholder="Search here..."
                value={searchInput}
                onChange={handleSearchInputChange}
                style={{
                  padding: '8px 8px 8px 40px',
                  borderRadius: 8,
                  backgroundColor: '#F7FAFC',
                  maxWidth: isMobile() ? 250 : 300,
                  height: 40,
                  borderWidth: 1,
                  borderColor: '#D9D9D9',
                  paddingLeft: 50
                }}
              />
              <IconButton
                sx={{
                  position: 'absolute',
                  left: 10,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#979797'
                }}
              >
                <SearchIcon />
              </IconButton>
            </Box>
          ) : (
            <></>
          )}
          {isMobile() && (
            <Box
              sx={{
                mt: 1,
                alignSelf: 'flex-end',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Checkbox checked={isHeaderChecked} onChange={handleHeaderCheckboxChange} />
              <Typography fontSize={14} color="primary" fontFamily="poppins">
                Select All
              </Typography>
            </Box>
          )}
        </Box>
        {isMobile() ? (
          <div onScroll={handleScroll} style={{ maxHeight: '60vh', overflowY: 'auto' }}>
            <MobileCardView data={renderData} />
          </div>
        ) : (
          <TableContainer
            sx={{
              maxHeight: '60vh',
              overflowY: 'auto',
              paddingInline: 0.4
            }}
          >
            <div onScroll={handleScroll} style={{ maxHeight: '60vh', overflowY: 'auto' }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  borderCollapse: 'separate',
                  borderSpacing: '0 7px',
                  paddingBottom: 7
                }}
              >
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: '#EEEEEE',
                      boxShadow: '0px 0px 4px 0px #00000040',
                      borderWidth: 1
                    }}
                  >
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        padding: 4,
                        textAlign: 'center'
                      }}
                    >
                      <Checkbox checked={isHeaderChecked} onChange={handleHeaderCheckboxChange} />
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 4
                      }}
                    >
                      <CustomTypography fontSize="14px" color="#000" fontWeight={600}>
                        Client Name
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 4
                      }}
                    >
                      <CustomTypography fontSize="14px" color="#000" fontWeight={600}>
                        Status
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        padding: 4,
                        borderBottom: 'none',
                        textAlign: 'center'
                      }}
                    >
                      <CustomTypography fontSize="14px" color="#000" fontWeight={600}>
                        Actions
                      </CustomTypography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...new Map(renderData.map((item) => [item?._id, item])).values()].map((client, index) => (
                    <TableRow
                      key={index}
                      style={{
                        marginBottom: 10,
                        backgroundColor: '#fff',
                        borderRadius: 8,
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                        border: 'none'
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: 'none',
                          borderTopLeftRadius: '8px',
                          borderBottomLeftRadius: '8px',
                          padding: 5,
                          textAlign: 'center'
                        }}
                      >
                        <Checkbox
                          checked={selectedItems?.includes(client?._id)}
                          onChange={() => handleItemCheckboxChange(client._id)}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: 'none',
                          textAlign: 'center',
                          padding: 0
                        }}
                      >
                        <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                          {client?.business_name || client?.name}
                        </CustomTypography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: 'none',
                          textAlign: 'center',
                          padding: 0
                        }}
                      >
                        <CustomTypography
                          fontSize="13px"
                          color={tab === 0 ? '#FF8A00' : '#007613'}
                          fontWeight={400}
                        >
                          {tab === 0 ? 'Pending' : 'Assigned'}
                        </CustomTypography>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          borderBottom: 'none',
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          padding: 0
                        }}
                      >
                        <Button
                          disabled={selectedItems?.length > 1 ? true : false}
                          variant="contained"
                          style={{
                            backgroundColor: tab === 0 ? '#007613' : '#D92D20',
                            padding: 6,
                            textTransform: 'none'
                          }}
                          onClick={() => {
                            if (tab === 0) {
                              assignClient({
                                [mapKey]: selectedData?._id,
                                client: client?._id
                              })
                            } else {
                              unAssignClient(client)
                            }
                          }}
                        >
                          <CustomTypography fontSize={13} fontWeight={400}>
                            {tab === 0 ? 'Assign' : 'Unassign'}
                          </CustomTypography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        )}

        {selectedItems.length > 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 2,
              gap: 2
            }}
          >
            <Button
              sx={{ textTransform: 'none' }}
              variant="outlined"
              color="primary"
              onClick={() => {
                setSelectedItems([])
                setIsHeaderChecked(false)
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: tab === 0 ? '#007613' : '#D92D20',
                textTransform: 'none'
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                if (tab === 0) {
                  handleAssignAll()
                } else {
                  handleUnassignAll()
                }
              }}
            >
              {tab === 0 ? 'Assign All' : 'Unassign All'}
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AssignClient
