import React, { useState, useEffect, useRef, useContext } from 'react'
import { Box, Button, Divider, Grid, IconButton, Typography, TextField } from '@mui/material'
import dayjs from 'dayjs'
import SearchBar from '../headerSearchbar'
import GridIcon from '../../globalStyles/icons/grid-icon'
import ListIcon from '../../globalStyles/icons/list-icon'
import ExportIcon from '../../globalStyles/icons/export-icon'
import AppContext from '../context/context'
import { Autocomplete } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { clientService } from '../../utils/services/clientService'
import CustomTypography from '../../globalStyles/fonts'
import DateRangePickerComponent from '../date-range-picker'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import SyncIcon from '@mui/icons-material/Sync'
import { isMobile } from '../../globalStyles/mobile'
import ClientAutocomplete from '../clientAutocomplete'
import AddIcon from '@mui/icons-material/Add'
function Header({
  onClickUpload,
  hideUploadButton,
  hideAddButton,
  hideFilterClient,
  onClickAddButton,
  onHandleUploadButton,
  zipZone,
  simulation,
  onUploadSimulationData,
  rateCard,
  searchBarPlaceholder,
  onSearchData,
  title,
  onSelectClient,
  //selectedClient,
  client,
  product,
  setViewMode,
  order,
  onClickExport,
  header,
  onSelectDate,
  showDownloadIcon,
  onClickRefresh,
  shipmentCost,
  onClickEstimate,
  customClerance,
  showUploadStatus,
  onClickStatus,
  onClickSyncProduct,
  onClickCreateASN,
  importProduct,
  onClickDiscardAll,
  eventMapping,
  headerButtons
}) {
  const currentDate = new Date()
  const [file, setFile] = useState('')
  const [clientData, setClientData] = useState([])
  const [selectedClientData, setSelectedClientData] = useState({ name: '' })
  const location = useLocation()
  const fileUploadRef = useRef()
  const navigationUrl = location.pathname
  const { user } = useContext(AppContext)
  const [viewMode, setMode] = useState('list')
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs().add(7, 'day'))
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(currentDate.setMonth(currentDate.getMonth() - 2)),
    endDate: new Date(new Date().setDate(new Date().getDate() + 2)),
    key: 'selection'
  })
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await clientService.fetch()
        setClientData(response.data)
      } catch (error) {
        console.error('Fetch failed:', error)
      }
    }

    fetchClientData()
  }, [])
  const [selectedClient, setSelectedClient] = useState(null)

  const handleFileUpload = (event) => {
    event.preventDefault()
    fileUploadRef.current.click()
  }

  const uploadFile = () => {
    const uploadedFile = fileUploadRef.current.files[0]
    if (simulation) {
      onUploadSimulationData && onUploadSimulationData(uploadedFile)
    }
    const formData = new FormData()
    formData.append('file', uploadedFile)
    onClickUpload && onClickUpload(formData)
    fileUploadRef.current.value = null
  }

  const capitalize = (str) => str.replace(/\b\w/g, (char) => char.toUpperCase())

  const handleGridViewClick = () => {
    setViewMode('grid')
    setMode('grid')
  }

  const handleListViewClick = () => {
    setViewMode('list')
    setMode('list')
  }

  const handleClearStartDate = () => {
    setStartDate(null)
    setEndDate(null)
    onSelectDate(null)
  }
  useEffect(() => {
    onSelectDate && onSelectDate(selectionRange)
  }, [selectionRange])

  return (
    <Box sx={{ marginTop: isMobile() ? 0 : 2, width: '100%' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={isMobile() ? 6 : 12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomTypography color="#05004E" fontSize={isMobile() ? 14 : 17} fontWeight={600}>
              {header}
            </CustomTypography>
          </Box>
        </Grid>
        <Grid item xs={isMobile() ? 6 : 12} md={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <input
              onChange={uploadFile}
              type="file"
              id="excelFile"
              accept=".xls,.xlsx"
              ref={fileUploadRef}
              hidden
            />
            <div>{headerButtons}</div>

            {!hideUploadButton &&
              (isMobile() ? (
                <IconButton
                  style={{
                    backgroundColor: '#007DFF',
                    color: '#FFFFFF',
                    margin: 3
                  }}
                  onClick={(e) => (zipZone || rateCard ? onHandleUploadButton(e) : handleFileUpload(e))}
                >
                  <UploadFileIcon />
                </IconButton>
              ) : (
                <Button
                  size="small"
                  style={{
                    backgroundColor: '#007DFF',
                    margin: 3,
                    textTransform: 'none',
                    minWidth: 100
                  }}
                  variant="contained"
                  onClick={(e) => (zipZone || rateCard ? onHandleUploadButton(e) : handleFileUpload(e))}
                >
                  <UploadFileIcon style={{ marginRight: 5, height: 20 }} />
                  <CustomTypography fontSize="14px" fontWeight={500} color="#FFFFFF">
                    Upload
                  </CustomTypography>
                </Button>
              ))}

            {!hideAddButton &&
              (isMobile() ? (
                <IconButton
                  style={{
                    backgroundColor: '#007DFF',
                    color: '#FFFFFF',
                    margin: 3
                  }}
                  onClick={onClickAddButton}
                >
                  {showDownloadIcon ? <ExportIcon /> : <AddIcon />}
                </IconButton>
              ) : (
                <Button
                  size="small"
                  style={{
                    backgroundColor: '#007DFF',
                    margin: 3,
                    minWidth: hideUploadButton ? 50 : 70,
                    textTransform: 'none'
                  }}
                  variant="contained"
                  onClick={onClickAddButton}
                >
                  {showDownloadIcon && (
                    <IconButton sx={{ height: 30 }}>
                      <ExportIcon />
                    </IconButton>
                  )}
                  <CustomTypography fontSize="14px" fontWeight={500} color="#FFFFFF">
                    {title}
                  </CustomTypography>
                </Button>
              ))}

            {(order || product || client) &&
              (isMobile() ? (
                <div style={{ display: 'flex', gap: 5 }}>
                  <IconButton
                    style={{
                      backgroundColor: '#007DFF',
                      color: '#FFFFFF'
                    }}
                    onClick={onClickRefresh}
                  >
                    <SyncIcon />
                  </IconButton>

                  {order && (
                    <IconButton
                      style={{
                        backgroundColor: '#007DFF',
                        color: '#FFFFFF'
                      }}
                      onClick={onClickExport}
                    >
                      <ExportIcon />
                    </IconButton>
                  )}
                </div>
              ) : (
                <div style={{ display: 'flex', gap: 5 }}>
                  <Button
                    size="small"
                    style={{
                      margin: 3,
                      minWidth: hideUploadButton ? 120 : 70,
                      textTransform: 'none',
                      fontSize: 14,
                      height: 30,
                      borderColor: '#333333',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    variant="outlined"
                    onClick={onClickRefresh}
                  >
                    <IconButton style={{ color: '#333333' }}>
                      <SyncIcon />
                    </IconButton>
                    <CustomTypography fontSize="14px" fontWeight={500} color="#333333">
                      Refresh
                    </CustomTypography>
                  </Button>
                </div>
              ))}
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1, color: '#C0C3D7' }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          marginInline: 0.2,
          flexDirection: isMobile() ? 'column' : 'row'
        }}
      >
        {!eventMapping ? <SearchBar onSearchData={onSearchData} placeholder={searchBarPlaceholder} /> : <></>}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: isMobile() ? 'space-between' : 'end',
            width: '100%',
            overflowX: isMobile() ? 'auto' : 'visible',
            overflowY: 'visible',
            position: 'relative'
          }}
        >
          {order && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                width: isMobile() ? 'fit-content' : 'auto',
                marginTop: 1,
                overflow: 'visible',
                marginRight: 1
              }}
            >
              <DateRangePickerComponent
                setSelectionRange={setSelectionRange}
                selectionRange={selectionRange}
              />
            </Box>
          )}

          {!client && user?.role === 'admin' && !hideFilterClient && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                width: isMobile() ? 'fit-content' : 'auto',
                overflow: 'visible' // Ensure overflow for the autocomplete as well
              }}
            >
              <ClientAutocomplete
                selectedClient={selectedClient}
                onClientChange={(client) => {
                  setSelectedClient(client)
                  onSelectClient && onSelectClient(client)
                }}
                width={order ? 200 : 250}
              />
            </Box>
          )}

          {!order &&
          !zipZone &&
          !rateCard &&
          !simulation &&
          !customClerance &&
          !isMobile() &&
          !eventMapping &&
          !importProduct ? (
            <>
              <IconButton
                aria-label="grid-view"
                onClick={handleGridViewClick}
                style={{ color: viewMode === 'grid' ? '#007DFF' : '' }}
              >
                <GridIcon fill={viewMode === 'grid' ? '#007DFF' : 'black'} />
              </IconButton>
              <IconButton
                aria-label="list-view"
                onClick={handleListViewClick}
                style={{
                  color: viewMode === 'list' ? '#007DFF' : 'grey',
                  marginRight: 10
                }}
              >
                <ListIcon fill={viewMode === 'list' ? '#007DFF' : 'grey'} />
              </IconButton>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default Header
