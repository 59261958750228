import AddIcon from "@mui/icons-material/Add";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Box,
  CardContent,
  CircularProgress,
  Collapse,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
} from "@mui/material";
import Button from "@mui/material/Button/Button";
import Card from "@mui/material/Card/Card";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import React, { useContext, useEffect, useState } from "react";
import CustomTypography from "../../globalStyles/fonts";
import { carrierService } from "../../utils/services/carrierService";
import { clientService } from "../../utils/services/clientService";
import { omsService } from "../../utils/services/omsProvider";
import { tmsService } from "../../utils/services/tmsService";
import { wmsService } from "../../utils/services/wmsProvider";
import ClientProviderModal from "../client-provider-modal";
import AppContext from "../context/context";
import DeleteConfirmationModal from "../deleteConfirmationModal";
import WebhookManagement from "../webhook";
import { isMobile } from "../../globalStyles/mobile";
import ClientDetailsModal from "../clientViewDetailsModal";
import { omsLocationService } from "../../utils/services/omsLocationService";
import WarehouseSelectionModal from "../oms-location-modal";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import { userService } from "../../utils/services/userService";
import { useNavigate } from "react-router-dom";
const ClientList = ({
  client,
  data,
  onEditData,
  onDeleteData,
  handleEditClient,
  handleDeleteClient,
  onSelectClient,
  viewMode,
  onViewItem,
  onChangeStatus,
  onSuccess,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [carrierName, setCarrierName] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [clientData, setClientData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectClientId, setSelectClientId] = useState(null);
  const [wms, setWms] = useState(false);
  const [tms, setTms] = useState(false);
  const clientId = localStorage.getItem("client_id");
  const { user, setUser } = useContext(AppContext);
  const [openOmsModal, setOpenOmsModal] = useState(false);
  const [openRow, setOpenRow] = useState(null);
  const [providerData, setProviderData] = useState({
    oms: [],
    tms: [],
    wms: [],
  });
  const [openClientModal, setOpenClientModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [omsLocations, setOmsLocations] = useState([]);
  const [openWarehouseList, setOpenWarehouseList] = useState(false);
  const [warehouseLocation, setWarehouseLocation] = useState(null);
  const navigate = useNavigate();
  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index);
  };
  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch();
      setClientData(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };
  useEffect(() => {
    fetchClientData();
  }, []);
  const fetchProviderDetails = async (id) => {
    if (id) {
      setLoading(true);
      try {
        const omsResponse = await omsService.fetch(id);
        const tmsResponse = await tmsService.fetch(id);
        const wmsResponse = await wmsService.fetch(id);
        setProviderData({
          oms: omsResponse.data,
          tms: tmsResponse.data,
          wms: wmsResponse.data,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Fetch failed:", error);
        throw error;
      }
    }
  };
  const changeClientStatus = async (id, status) => {
    setLoading(true);
    try {
      const response = await clientService.edit(
        {
          status: status,
        },
        id
      );
      if (onChangeStatus) onChangeStatus();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch failed:", error);
      throw error;
    }
  };

  const handleDeleteClick = (data) => {
    setSelectedCarrier(data);
    setOpenConfirmation(true);
  };
  const handleEnterClientProfile = async (data) => {
    setLoading(true);
    try {
      const response = await userService.fetchUserByEmail(data.email);
      console.log(response);
      const adminData = localStorage.getItem("user");
      localStorage.setItem("adminUser", adminData);
      let admin_token = localStorage.getItem("token");
      localStorage.setItem("token", response.accessToken);
      localStorage.setItem("adminToken", admin_token);

      localStorage.setItem("user", JSON.stringify(response.user));
      setUser(response.user);
      localStorage.setItem("client_id", data._id);
      navigate("/", { replace: true });
      window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch failed:", error);
      throw error;
    }
  };
  const confirmDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await carrierService.delete(selectedCarrier._id);
      if (onDeleteData) onDeleteData();
      setAnchorEl(null);
      setOpenConfirmation(false);
    } catch (error) {
      console.error("Delete failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const validateForm = () => {
    const errors = {};

    if (!clientId && !selectedClient?.name) {
      errors.selectedClient = "Please select Client";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const fetchOmsLocations = async (data) => {
    setLoading(true);
    try {
      const response = await omsLocationService.fetch(data);
      setOmsLocations(response?.locations);
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchWarehouseLocation = async (data) => {
    setLoading(true);
    try {
      const response = await omsLocationService.fetchLocation(data._id);
      setWarehouseLocation(response.data[0]);
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (providerData.oms.length) {
      fetchOmsLocations(providerData.oms[0]);
      fetchWarehouseLocation(providerData.oms[0]);
    }
  }, [providerData]);
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 35,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "& .MuiSwitch-thumb": {
          border: `6px solid ${
            theme.palette.mode === "dark" ? "#fff" : "#fff"
          }`,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 15,
      height: 15,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const sendActivationKey = async (id, email) => {
    try {
      setLoading(true);
      const response = await clientService.sendActivationKey(id, email);
      setLoading(false);
    } catch (error) {
      console.error("Delete failed:", error);
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const handleStatusToggle = (clientId, status, e, email) => {
    e.preventDefault();
    e.stopPropagation();
    if (status === "Approved") {
      changeClientStatus(clientId, "Active");
      sendActivationKey(clientId, email);
    } else if (status === "Active") {
      changeClientStatus(clientId, "Inactive");
    } else {
      changeClientStatus(clientId, "Active");
    }
    // Implement the logic to handle the status toggle
    console.log(`Client ID: ${clientId}, Status: ${status}`);
  };
  const ActionButtons = ({
    client,
    view,
    gridView,
    index,
    status,
    showActiveKey,
  }) => {
    const isDisabled = ["Inactive", "Uninstalled"].includes(status);
    return (
      <React.Fragment>
        <div style={{ width: "100%" }}>
          <Button
            disabled={isDisabled}
            onClick={(e) => {
              e.stopPropagation();
              setOpenOmsModal(true);
              setSelectedClient(client);
            }}
            style={{
              backgroundColor: isDisabled ? "#F0F0F0" : "#007BFF",
              padding: 0,
              marginRight: 8,
              paddingLeft: 10,
              paddingRight: 10,
              minHeight: 30,
              marginTop: 1,
              marginBottom: 1,
            }}
            aria-label="oms"
            variant="contained"
            startIcon={<AddIcon />}
          >
            <CustomTypography
              fontSize="13px"
              color={isDisabled ? "#BDBDBD" : "#FFFFFF"}
              fontWeight={500}
            >
              OMS
            </CustomTypography>
          </Button>
          <Button
            disabled={isDisabled}
            onClick={(e) => {
              setTms(true);
              setOpenOmsModal(true);
              e.stopPropagation();
              setSelectedClient(client);
            }}
            style={{
              backgroundColor: isDisabled ? "#F0F0F0" : "#28A745",
              padding: 0,
              marginRight: 8,
              paddingLeft: 10,
              paddingRight: 10,
              minHeight: 30,
              marginTop: 1,
              marginBottom: 1,
            }}
            aria-label="oms"
            variant="contained"
            startIcon={<AddIcon />}
          >
            <CustomTypography
              fontSize="13px"
              color={isDisabled ? "#BDBDBD" : "#FFFFFF"}
              fontWeight={500}
            >
              TMS
            </CustomTypography>
          </Button>
          <Button
            disabled={isDisabled}
            onClick={(e) => {
              setWms(true);
              setOpenOmsModal(true);
              e.stopPropagation();
              setSelectedClient(client);
            }}
            style={{
              backgroundColor: isDisabled ? "#F0F0F0" : "#FD7E14",
              padding: 0,
              marginRight: 8,
              paddingLeft: 10,
              paddingRight: 10,
              minHeight: 30,
              marginTop: 2,
              marginBottom: 1,
            }}
            aria-label="oms"
            variant="contained"
            startIcon={<AddIcon />}
          >
            <CustomTypography
              fontSize="13px"
              color={isDisabled ? "#BDBDBD" : "#FFFFFF"}
              fontWeight={500}
            >
              WMS
            </CustomTypography>
          </Button>
          <IconButton
            disabled={isDisabled}
            style={{
              color: isDisabled ? "#FFFFFF" : "#FEC50F",
              padding: 0,
              background: isDisabled ? "rgba(224, 224, 224, 1)" : "#FEC50F33",
              height: 30,
              width: 30,
              borderRadius: 5,
              boxShadow: "0px 0px 4px 0px #00000040",
              marginTop: 3,
              marginLeft: view ? 0 : 15,
            }}
            aria-label="edit"
            onClick={(e) => {
              e.stopPropagation();
              if (handleEditClient) handleEditClient(client);
            }}
          >
            <BorderColorIcon sx={{ height: 20, width: 20 }} />
          </IconButton>
          <IconButton
            style={{
              color: "#D13438",
              padding: 0,
              background: "#D1343833",
              height: 30,
              width: 30,
              borderRadius: 5,
              marginLeft: 10,
              boxShadow: "0px 0px 4px 0px #00000040",
              marginTop: 3,
            }}
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              handleEnterClientProfile(client);
              // handleDeleteClick(client)
            }}
          >
            <RoomPreferencesIcon />
            {/* <img
              style={{ height: 15, width: 15 }}
              alt="delete"
              src="/delete.png"
            ></img> */}
          </IconButton>
          {!gridView ? (
            <IconButton
              style={{
                color: "#007DFF",
                padding: 0,
                background: "#C3E1FF",
                height: 30,
                width: 30,
                borderRadius: 5,
                marginLeft: 10,
                boxShadow: "0px 0px 4px 0px #00000040",
              }}
              aria-label="view"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedClient(client);
                setOpenViewModal(true);
              }}
            >
              <img
                style={{ height: 14, width: 20 }}
                alt="view"
                src="/eye.png"
              />
            </IconButton>
          ) : null}
          {gridView ? (
            <IconButton
              style={{
                color: "#007DFF",
                padding: 0,
                background: "#C3E1FF",
                height: 30,
                width: 30,
                borderRadius: 5,
                marginLeft: 10,
                boxShadow: "0px 0px 4px 0px #00000040",
                marginTop: 5,
              }}
              aria-label="view"
              onClick={(e) => {
                e.stopPropagation();
                setOpenClientModal(true);
                fetchProviderDetails(client?._id);
                if (onViewItem) onViewItem();
                setSelectClientId(client?._id);
                handleRowClick(index);
              }}
            >
              <img
                style={{ height: 14, width: 20 }}
                alt="delete"
                src="/eye.png"
              ></img>
            </IconButton>
          ) : (
            <></>
          )}
        </div>
      </React.Fragment>
    );
  };

  const ListItem = ({ header, value, jsx, gridView }) => {
    return (
      <div style={{ width: "100%", display: "flex" }}>
        {gridView ? (
          <></>
        ) : (
          <div
            style={{
              width: "40%",
              display: "flex",
              paddingInline: 10,
              paddingTop: 7,
              paddingBottom: 5,
            }}
          >
            <CustomTypography
              style={{ textAlign: "left", fontSize: 14 }}
              fontWeight={600}
              color={"#333333"}
            >
              {header}
            </CustomTypography>
          </div>
        )}
        <div
          style={{
            width: gridView && isMobile ? "100%" : "60%",
            display: "flex",
            paddingInline: 10,
            paddingTop: 7,
            paddingBottom: 5,
          }}
        >
          <Tooltip title={typeof value === "string" ? value : ""}>
            <p
              style={{
                textAlign: "left",
                maxWidth: "99%",
                display: "flex",
                flexWrap: typeof value === "string" ? "nowrap" : "wrap",
                overflow: typeof value === "string" ? "hidden" : "visible",
                textOverflow: "ellipsis",
                whiteSpace: typeof value === "string" ? "nowrap" : "normal",
                fontFamily: "Poppins",
                fontSize: 13,
                color: "#606060",
                fontWeight: 500,
              }}
            >
              {value}
            </p>
          </Tooltip>
        </div>
      </div>
    );
  };
  const renderGridView = () => (
    <Grid sx={{ padding: 1, marginTop: 1 }} container spacing={2}>
      {data?.map((client, index) => (
        <Grid
          sx={{ paddingBottom: 1 }}
          item
          xs={12}
          sm={6}
          md={4}
          key={client.id}
        >
          <Card sx={{ boxShadow: 3, minHeight: 50 }}>
            <div
              style={{
                display: "flex",
                padding: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <ListItem value={client.name} header={"Client Name"}></ListItem>
                <ListItem
                  value={client.contact}
                  header={"Mobile No"}
                ></ListItem>
                <ListItem value={client.email} header={"Email"}></ListItem>
                <ListItem
                  value={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        maxWidth: 120,
                      }}
                    >
                      <CustomTypography
                        style={{ marginRight: 2, minWidth: 50 }}
                        fontSize="13px"
                        color={
                          client?.status === "Rejected"
                            ? "red"
                            : client?.status === "Active"
                            ? "#007D14"
                            : client?.status === "Pending"
                            ? "#FF8A00"
                            : client?.status === "Approved"
                            ? "#FF8A00"
                            : "#757477"
                        }
                        fontWeight={500}
                      >
                        {client.status}
                      </CustomTypography>
                      {client.status === "Rejected" ? (
                        <></>
                      ) : (
                        <div
                          onClick={(e) => {
                            if (
                              !["Pending", "Uninstalled"].includes(
                                client.status
                              )
                            ) {
                              e.preventDefault();
                              e.stopPropagation();
                              handleStatusToggle(
                                client._id,
                                client.status,
                                e,
                                client.email
                              );
                            }
                          }}
                          style={{
                            pointerEvents: ["Pending", "Uninstalled"].includes(
                              client.status
                            )
                              ? "none"
                              : "auto",
                            opacity: ["Pending", "Uninstalled"].includes(
                              client.status
                            )
                              ? 0.5
                              : 1,
                          }}
                        >
                          {client.status === "Uninstalled" ? (
                            <></>
                          ) : (
                            <IOSSwitch
                              disabled={["Pending"].includes(client.status)}
                              checked={client.status == "Active"}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  }
                  header={"Status"}
                ></ListItem>
                <ListItem
                  gridView={true}
                  value={
                    <ActionButtons
                      index={index}
                      gridView={true}
                      view
                      client={client}
                    ></ActionButtons>
                  }
                  // header={'Action'}
                ></ListItem>
              </div>
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_client.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "300px" }}
      />
    </div>
  );

  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: "100%", padding: 0.5 }}>
        {data.length === 0 ? (
          <NoDataImage />
        ) : (
          data?.map((client, index) => (
            <Card
              key={index}
              sx={{
                marginBottom: 2,
                backgroundColor: "#ffff",
                borderRadius: 4,
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    padding: 10,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <ListItem
                      value={`: ${client.business_name || client.name}`}
                      header={"Name"}
                    ></ListItem>
                    <ListItem
                      value={`: ${client.contact}`}
                      header={"Mobile No"}
                    ></ListItem>
                    <ListItem
                      value={`: ${client.email}`}
                      header={"Email"}
                    ></ListItem>
                    <ListItem
                      value={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            maxWidth: 120,
                          }}
                        >
                          <CustomTypography
                            style={{ marginRight: 2, minWidth: 50 }}
                            fontSize="13px"
                            color={
                              client?.status === "Rejected"
                                ? "red"
                                : client?.status === "Active"
                                ? "#007D14"
                                : client?.status === "Pending"
                                ? "#FF8A00"
                                : client?.status === "Approved"
                                ? "#FF8A00"
                                : "#757477"
                            }
                            fontWeight={500}
                          >
                            : {client.status}
                          </CustomTypography>
                          {client.status === "Rejected" ? (
                            <></>
                          ) : (
                            <div
                              onClick={(e) => {
                                if (
                                  !["Pending", "Uninstalled"].includes(
                                    client.status
                                  )
                                ) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleStatusToggle(
                                    client._id,
                                    client.status,
                                    e,
                                    client.email
                                  );
                                }
                              }}
                              style={{
                                pointerEvents: [
                                  "Pending",
                                  "Uninstalled",
                                ].includes(client.status)
                                  ? "none"
                                  : "auto",
                                opacity: ["Pending", "Uninstalled"].includes(
                                  client.status
                                )
                                  ? 0.5
                                  : 1,
                              }}
                            >
                              {client.status === "Uninstalled" ? (
                                <></>
                              ) : (
                                <IOSSwitch
                                  disabled={["Pending"].includes(client.status)}
                                  checked={client.status == "Active"}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      }
                      header={"Status"}
                    ></ListItem>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListItem
                    gridView={true}
                    value={
                      <ActionButtons
                        index={index}
                        gridView={true}
                        view
                        client={client}
                      ></ActionButtons>
                    }
                    // header={'Action'}
                  ></ListItem>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    );
  };

  const renderListView = () => (
    <div
      style={{
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        height: window.innerHeight < 650 ? "80vh" : "auto",
      }}
    >
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile() ? "column" : "row",
        }}
      > */}
      {isMobile() ? (
        <MobileCardView data={data}></MobileCardView>
      ) : (
        <TableContainer
          className="custom-container"
          sx={{
            marginTop: 1,
            overflowY: "auto",
            paddingInline: 0.4,
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 5px",
              paddingBottom: 7,
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: 8,
                  boxShadow: "0px 0px 4px 0px #00000040",
                }}
              >
                {client ? (
                  <>
                    {/* <TableCell
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    SL No.
                  </CustomTypography>
                </TableCell> */}
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Business Name
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderBottom: "none",
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Mobile No.
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderBottom: "none",
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Email
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderBottom: "none",
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Status
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        textAlign: "center",
                      }}
                    >
                      <CustomTypography
                        fontSize="14px"
                        title={true}
                        color="#000"
                        fontWeight={600}
                      >
                        Actions
                      </CustomTypography>
                    </TableCell>
                  </>
                ) : (
                  <></>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((client, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    onClick={() => {
                      if (client.status !== "Uninstalled") {
                        handleRowClick(index);
                        if (openRow !== index)
                          fetchProviderDetails(client?._id);
                        setSelectClientId(client?._id);
                        setSelectedClient(client);
                      }
                    }}
                    style={{
                      cursor:
                        client.status === "Uninstalled"
                          ? "not-allowed"
                          : "pointer",
                      backgroundColor: "#fff",
                      borderRadius: 8,
                      boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                      border: "none",
                      padding: 0,
                      "&:last-child td, &:last-child th": {
                        border: "none",
                      },
                    }}
                  >
                    {client ? (
                      <>
                        {/* <TableCell
                      component="th"
                      scope="row"
                      style={{
                        borderBottom: "none",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        padding: 15,
                      }}
                    >
                      <CustomTypography
                        fontSize="13px"
                        color="#333333"
                        fontWeight={400}
                      >
                        {index + 1}
                      </CustomTypography>
                    </TableCell> */}
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: "none",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            padding: 15,
                          }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {client.business_name
                              ? client?.business_name
                              : client.name}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none", padding: 0 }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {client.contact}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none", padding: 0 }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {client.email}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none", padding: 0 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              maxWidth: 120,
                            }}
                          >
                            <CustomTypography
                              style={{ marginRight: 2, minWidth: 50 }}
                              fontSize="13px"
                              color={
                                client?.status === "Rejected"
                                  ? "red"
                                  : client?.status === "Active"
                                  ? "#007D14"
                                  : client?.status === "Pending"
                                  ? "#FF8A00"
                                  : client?.status === "Approved"
                                  ? "#FF8A00"
                                  : "#757477"
                              }
                              fontWeight={500}
                            >
                              {client.status}
                            </CustomTypography>
                            {client.status === "Rejected" ? (
                              <></>
                            ) : (
                              <div
                                onClick={(e) => {
                                  if (
                                    !["Pending", "Uninstalled"].includes(
                                      client.status
                                    )
                                  ) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleStatusToggle(
                                      client._id,
                                      client.status,
                                      e,
                                      client.email
                                    );
                                  }
                                }}
                                style={{
                                  pointerEvents: [
                                    "Pending",
                                    "Uninstalled",
                                  ].includes(client.status)
                                    ? "none"
                                    : "auto",
                                  opacity: ["Pending", "Uninstalled"].includes(
                                    client.status
                                  )
                                    ? 0.5
                                    : 1,
                                }}
                              >
                                {client.status === "Uninstalled" ? (
                                  <></>
                                ) : (
                                  <IOSSwitch
                                    disabled={["Pending"].includes(
                                      client.status
                                    )}
                                    checked={client.status == "Active"}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderBottom: "none",
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            padding: 0,
                          }}
                        >
                          <ActionButtons
                            status={client.status}
                            client={client}
                          ></ActionButtons>
                        </TableCell>
                      </>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        borderBottom: 0,
                      }}
                      colSpan={8}
                    >
                      <Collapse
                        in={openRow === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          {loading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "fixed",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                zIndex: 9999,
                                color: "white",
                              }}
                            >
                              <CircularProgress color="inherit" />
                            </Box>
                          ) : (
                            <TableContainer
                              sx={{ padding: 1 }}
                              component={Paper}
                            >
                              <WebhookManagement
                                warehouseLocation={warehouseLocation}
                                onClickLocation={() =>
                                  setOpenWarehouseList(true)
                                }
                                onEditWms={(data) => {
                                  fetchProviderDetails(client?._id);
                                  setSelectedData(data);
                                  setSelectedClient(client);
                                  setOpenOmsModal(true);
                                  setWms(true);
                                  setTms(false);
                                }}
                                onEditTms={(data) => {
                                  fetchProviderDetails(client?._id);
                                  setSelectedData(data);
                                  setSelectedClient(client);
                                  setOpenOmsModal(true);
                                  setWms(false);
                                  setTms(true);
                                }}
                                onEditOms={(data) => {
                                  fetchProviderDetails(client?._id);
                                  setSelectedData(data);
                                  setSelectedClient(client);
                                  setOpenOmsModal(true);
                                }}
                                providerData={providerData}
                                clientId={client?._id}
                              ></WebhookManagement>
                            </TableContainer>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* </div> */}
    </div>
  );
  return (
    <div sx={{ minWidth: "100%" }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <></>
      )}
      <div
        style={{
          overflowY: "scroll",
          display: "flex",
          maxHeight: isMobile() ? "60vh" : "70vh",
          width: "100%",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::WebkitScrollbar": { width: 0 },
        }}
      >
        {viewMode === "grid" ? renderGridView() : renderListView()}
      </div>
      {/* <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false)
          setFormErrors({})
        }}
      >
        <DialogTitle>{'Edit Client'}</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ minWidth: 300 }}
            autoFocus
            margin="dense"
            label="Carrier Name"
            type="text"
            fullWidth
            value={carrierName}
            onChange={(e) => setCarrierName(e.target.value)}
            error={formErrors?.name}
            helperText={formErrors?.name}
          />
          {user?.role === 'admin' ? (
            <Autocomplete
              sx={{ marginBottom: 2 }}
              size="small"
              value={selectedClient}
              onChange={(event, newValue) => {
                setSelectedClient(newValue)
                if (onSelectClient) onSelectClient(newValue)
              }}
              options={clientData}
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography variant="body1">{option.name}</Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Client"
                  margin="normal"
                  error={formErrors?.selectedClient}
                  helperText={formErrors?.selectedClient}
                />
              )}
            />
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={() => {
              setOpenModal(false)
              setFormErrors({})
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={(e) => handleClientEdit(e)}>
            Save
          </Button>
        </DialogActions>
      </Dialog> */}
      <DeleteConfirmationModal
        title={`Are you sure you want to delete ${selectedCarrier?.name}?`}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          if (client) {
            if (handleDeleteClient) handleDeleteClient(selectedCarrier);
            setOpenConfirmation(false);
          } else {
            confirmDelete(e);
          }
        }}
      />
      <Dialog
        open={openClientModal}
        onClose={() => setOpenClientModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent
        // sx={{
        //   minWidth: { xs: "100vw", sm: "60vw" },
        //   maxHeight: { xs: "100vh", sm: "50vh" },
        //   padding: { xs: "16px", sm: "24px" },
        // }}
        >
          <WebhookManagement
            warehouseLocation={warehouseLocation}
            onClickLocation={() => setOpenWarehouseList(true)}
            onEditWms={(data) => {
              fetchProviderDetails(client?._id);
              setSelectedData(data);
              setOpenOmsModal(true);
              setWms(true);
              setTms(false);
            }}
            onEditTms={(data) => {
              fetchProviderDetails(client?._id);
              setSelectedData(data);
              setOpenOmsModal(true);
              setWms(false);
              setTms(true);
            }}
            onEditOms={(data) => {
              fetchProviderDetails(client?._id);
              setSelectedData(data);
              setOpenOmsModal(true);
            }}
            providerData={providerData}
            clientId={client?._id}
          ></WebhookManagement>
        </DialogContent>
      </Dialog>
      <ClientProviderModal
        onDeleteSuccess={() => {
          fetchProviderDetails(selectClientId);
          setOpenOmsModal(false);
        }}
        loading={loading}
        setLoading={setLoading}
        onAddProviderData={() => fetchProviderDetails(selectClientId)}
        selectedData={selectedData}
        selectedClient={selectedClient}
        wms={wms}
        tms={tms}
        onClose={() => {
          setWms(false);
          setTms(false);
          setSelectedData([]);
        }}
        setOpen={setOpenOmsModal}
        open={openOmsModal}
      ></ClientProviderModal>
      <ClientDetailsModal
        onSuccess={onSuccess}
        client={selectedClient}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
      <WarehouseSelectionModal
        omsData={providerData.oms[0]}
        data={omsLocations}
        onClose={() => setOpenWarehouseList(false)}
        open={openWarehouseList}
      ></WarehouseSelectionModal>
    </div>
  );
};

export default ClientList;
