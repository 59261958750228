import React, { useEffect, useState } from 'react'
import { Grid, TextField, FormControl, Select, MenuItem, Typography } from '@mui/material'
import { distanceUnits } from '../../utils/distanceUnits'
import { weightUnits } from '../../utils/weightUnits'

const DimensionsAndWeightInputWithUnit = ({
  onChange,
  dimensions,
  weight,
  dimensionUnitValue,
  weightUnitValue,
  requiredField,
  disabled = false,
  showOnlyUnitInput,
  showOnlyWeightUnit,
  showOnlyDimensionUnit,
  sm
}) => {
  const [dimensionUnitData, setDimensionUnitData] = useState([])
  const [weightUnitData, setWeightUnitData] = useState([])

  useEffect(() => {
    setDimensionUnitData(distanceUnits)
    setWeightUnitData(weightUnits)
  }, [])

  const handleChange = (field, value) => {
    onChange({ field: field, value: value })
  }

  return (
    <div style={{ width: '100%' }}>
      {/* <Typography
        color="#11193F"
        fontSize="16px"
        fontWeight={500}
        textAlign={"left"}
        fontFamily="poppins"
        gutterBottom
      >
        Dimensions and Weight{" "}
        {requiredField && <span style={{ color: "red" }}>*</span>}
      </Typography> */}

      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        {/* Height, Width, Length Fields */}
        {showOnlyUnitInput ? (
          <></>
        ) : (
          ['Height', 'Width', 'Length'].map((dim) => (
            <Grid item xs={12} sm={2} key={dim}>
              <Typography
                color="#11193F"
                fontSize="14px"
                //   fontWeight={500}
                textAlign={'left'}
                fontFamily="poppins"
              >
                {dim} {requiredField && <span style={{ color: 'red' }}>*</span>}
              </Typography>
              <TextField
                sx={{ backgroundColor: '#FFF' }}
                size="small"
                placeholder={`Enter ${dim}`}
                type="number"
                value={dimensions[dim.toLowerCase()]}
                onChange={(e) => handleChange(dim.toLowerCase(), e.target.value)}
                fullWidth
                margin="dense"
                inputProps={{ min: 0 }}
                disabled={disabled}
                style={disabled ? { color: '#000', backgroundColor: '#f0f0f0' } : {}}
              />
            </Grid>
          ))
        )}

        {/* Dimension Unit */}
        {showOnlyWeightUnit ? (
          <></>
        ) : (
          <Grid item xs={12} sm={sm ? sm : 2}>
            <Typography
              color="#11193F"
              fontSize="14px"
              // fontWeight={500}
              textAlign={'left'}
              fontFamily="poppins"
            >
              Dimension Unit {requiredField && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <FormControl sx={{ backgroundColor: '#FFF' }} fullWidth size="small" margin="dense">
              <Select
                disabled={disabled}
                value={dimensionUnitValue}
                onChange={(e) => handleChange('dimension_unit', e.target.value)}
                style={disabled ? { color: '#000', backgroundColor: '#f0f0f0' } : {}}
              >
                {dimensionUnitData.map((x) => (
                  <MenuItem key={x.abbreviation} value={x.abbreviation}>
                    {x.abbreviation}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Weight Field */}
        {showOnlyUnitInput ? (
          <></>
        ) : (
          <Grid item xs={12} sm={sm ? sm : 2}>
            <Typography
              color="#11193F"
              fontSize="14px"
              // fontWeight={500}
              textAlign={'left'}
              fontFamily="poppins"
            >
              Weight {requiredField && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <TextField
              sx={{ backgroundColor: '#FFF' }}
              size="small"
              placeholder="Enter Weight"
              type="number"
              value={weight}
              onChange={(e) => handleChange('weight', e.target.value)}
              fullWidth
              margin="dense"
              inputProps={{ min: 0 }}
              disabled={disabled}
              style={disabled ? { color: '#000', backgroundColor: '#f0f0f0' } : {}}
            />
          </Grid>
        )}

        {/* Weight Unit */}
        {showOnlyDimensionUnit ? (
          <></>
        ) : (
          <Grid item xs={12} sm={sm ? sm : 2}>
            <Typography
              color="#11193F"
              fontSize="14px"
              // fontWeight={500}
              textAlign={'left'}
              fontFamily="poppins"
            >
              Weight Unit {requiredField && <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <FormControl sx={{ backgroundColor: '#FFF' }} fullWidth size="small" margin="dense">
              <Select
                disabled={disabled}
                value={weightUnitValue}
                onChange={(e) => handleChange('weight_unit', e.target.value)}
                style={disabled ? { color: '#000', backgroundColor: '#f0f0f0' } : {}}
              >
                {weightUnitData.map((x) => (
                  <MenuItem key={x.abbreviation} value={x.abbreviation}>
                    {x.abbreviation}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default DimensionsAndWeightInputWithUnit
