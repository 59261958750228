import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CustomerList from '../../components/customer-list'
import { customerService } from '../../services/customer-service'
import AddEditCustomer from '../../components/add-edit-customer'

const Customer = () => {
  const [customers, setCustomers] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState()
  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState(null)
  const [customerData, setCustomerData] = useState({
    name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      zip: '',
      province: '',
      country: ''
    }
  })

  const navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const fetchCustomers = async () => {
    try {
      const response = await customerService.fetch(rowsPerPage, rowsPerPage * page)
      setCustomers(response.data)
      setCount(response.total)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchCustomers()
  }, [rowsPerPage, page])

  const handleAddCustomer = () => {
    setIsEdit(false)
    setCurrentCustomer(null)
    setCustomerData({
      name: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        zip: '',
        province: '',
        country: ''
      }
    })
    setOpen(true)
  }

  const handleSubmit = async () => {
    try {
      await customerService.create({
        ...customerData,
        name: `${customerData.first_name} ${customerData.last_name}`
      })

      fetchCustomers()
      setOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={{ textTransform: 'none', backgroundColor: '#DC585C' }} onClick={handleAddCustomer}>
          <Typography fontFamily={'poppins'} fontSize={'14px'} fontWeight={500} color="#fff">
            Add Customer
          </Typography>
        </Button>
      </div>
      <CustomerList
        onSuccess={fetchCustomers}
        data={customers}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <AddEditCustomer
        open={open}
        handleClose={handleClose}
        customerData={customerData}
        setCustomerData={setCustomerData}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
      ></AddEditCustomer>
    </>
  )
}

export default Customer
