import axiosInstance from '../utils/axios-instance'

export const locationService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post('/location', data)
      return response.data
    } catch (error) {
      throw error
    }
  },

  fetch: async (limit, skip) => {
    try {
      const response = await axiosInstance.get(
        `/location?$limit=${limit || 10}&$skip=${skip || 0}&$populate=client_id`
      )
      return response.data
    } catch (error) {
      throw error
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/location/${id}`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },

  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/location/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
