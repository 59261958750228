import React from "react";
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog,
  Card,
  CardContent,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../globalStyles/fonts";

const CustomCleranceDetailsModal = ({ customClerance, open, onClose }) => {
    const manifestIdsString =
      customClerance?.manifest_ids?.map((id) => id._id).join("\n") || "";
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>{"Custom Clerance Details"}</DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: "10px" }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Air WayBill
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={customClerance?.air_waybill_number || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Email Date
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={customClerance?.email_date || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Flight Name
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={customClerance?.flight_name || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Depart Date
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={customClerance?.depart_date || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Manifest Ids
              </CustomTypography>
              <TextField
                size="small"
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                rows={4} 
                value={manifestIdsString}
                InputProps={{
                  readOnly: true,
                  style: {
                    color: "#000",
                    backgroundColor: "#f0f0f0",
                    overflow: "auto",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Routing
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={customClerance?.routing || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CustomCleranceDetailsModal;
