 import React from "react";
 import { SvgIcon } from "@mui/material";

 const FreightBrokerIcon = ({ fill}) => {
   return (
     <>
       <SvgIcon>
         <svg
           version="1.0"
           xmlns="http://www.w3.org/2000/svg"
           width="512.000000pt"
           height="512.000000pt"
           viewBox="0 0 512.000000 512.000000"
           preserveAspectRatio="xMidYMid meet"
         >
           <g
             transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
             fill={fill}
             stroke="none"
           >
             <path
               d="M870 5100 c-19 -19 -20 -33 -20 -405 l0 -385 -149 0 c-82 0 -161 -4
-175 -10 -34 -12 -60 -54 -52 -83 9 -39 708 -950 740 -965 21 -11 33 -11 54
-2 34 14 730 928 738 968 4 22 0 36 -19 58 l-25 29 -161 3 -160 3 -3 386 -3
385 -24 19 c-22 18 -44 19 -372 19 -336 0 -350 -1 -369 -20z m610 -519 c0
-358 1 -380 19 -402 18 -22 28 -24 146 -29 l127 -5 -256 -340 c-142 -187 -261
-341 -265 -343 -4 -1 -126 152 -270 340 l-263 343 122 5 c175 7 160 -34 160
431 l0 379 240 0 240 0 0 -379z"
             />
             <path
               d="M3305 5110 c-38 -15 -250 -142 -272 -163 -22 -20 -29 -50 -17 -80 3
-9 116 -120 250 -248 135 -128 241 -235 237 -240 -4 -4 -75 -46 -158 -95 -195
-113 -345 -207 -588 -367 l-198 -130 -207 57 c-114 31 -220 56 -236 56 -20 0
-51 -19 -107 -66 -149 -124 -144 -140 89 -284 67 -41 121 -79 122 -84 0 -4
-21 -31 -46 -59 -58 -64 -77 -101 -77 -150 0 -29 7 -46 27 -65 25 -25 33 -27
136 -30 61 -2 110 -7 110 -10 0 -4 -10 -80 -22 -170 -20 -147 -21 -166 -7
-190 23 -42 56 -48 170 -30 58 10 108 23 118 32 10 9 60 108 111 221 l92 205
42 11 c230 60 678 205 908 292 l117 45 5 -42 c3 -22 12 -147 21 -276 23 -351
25 -370 46 -391 12 -12 33 -19 54 -19 35 0 308 99 328 119 5 5 47 213 93 462
46 249 87 455 91 459 4 3 67 45 138 92 220 145 341 262 407 392 79 156 31 324
-114 395 -151 74 -361 60 -698 -45 l-115 -35 -397 215 c-219 118 -406 217
-415 220 -10 2 -27 1 -38 -4z m417 -375 c217 -118 405 -215 417 -215 13 0 82
20 154 44 187 62 313 88 432 90 113 1 159 -11 199 -53 113 -118 -62 -335 -460
-574 -38 -23 -66 -47 -73 -64 -6 -16 -48 -219 -94 -453 l-83 -425 -58 -28
c-32 -16 -60 -26 -63 -23 -3 3 -16 157 -29 343 -23 329 -25 338 -48 362 -15
14 -36 24 -53 24 -15 0 -98 -27 -183 -60 -286 -112 -760 -265 -970 -313 -102
-23 -99 -20 -201 -246 -81 -179 -110 -229 -123 -213 -2 2 6 80 18 173 l20 168
-29 26 c-28 23 -34 24 -117 19 -48 -3 -88 -3 -88 0 0 3 27 36 60 75 92 107 86
121 -104 248 -176 116 -163 123 87 53 277 -78 253 -79 371 7 191 139 501 334
816 512 96 54 181 107 188 116 7 9 12 32 10 51 -3 31 -30 60 -247 266 -135
127 -245 235 -245 241 -1 6 84 61 100 64 1 0 179 -97 396 -215z"
             />
             <path
               d="M1162 3150 c-502 -63 -918 -392 -1087 -860 -114 -317 -94 -680 54
-985 185 -380 549 -650 970 -720 137 -23 378 -16 499 14 118 30 112 32 112
-48 0 -63 2 -71 26 -90 25 -20 39 -21 244 -21 l217 0 12 -71 c39 -237 259
-401 487 -362 193 33 331 182 359 385 l6 48 418 0 417 0 13 -74 c16 -97 52
-168 120 -237 169 -168 426 -170 597 -4 60 60 110 152 119 224 13 99 -6 89
176 93 155 3 161 4 180 27 18 22 19 47 19 605 0 392 -4 594 -11 621 -17 62
-402 697 -446 735 -82 72 -94 74 -432 78 -313 4 -354 2 -387 -22 -18 -14 -19
-38 -24 -668 l-5 -653 -698 -3 c-384 -1 -697 1 -695 5 2 5 31 64 66 133 43 87
72 161 94 245 29 112 31 132 32 300 1 117 -4 205 -13 250 -34 171 -112 356
-214 504 -107 156 -310 332 -486 421 -205 103 -516 158 -739 130z m204 -171
c42 -25 102 -116 142 -211 31 -75 77 -222 83 -268 l4 -25 -282 -3 c-254 -2
-283 -1 -283 14 0 30 41 176 71 252 43 109 82 173 134 221 51 47 81 52 131 20z
m-368 -85 c-34 -68 -98 -254 -128 -371 l-11 -43 -254 0 c-140 0 -255 4 -255 9
0 16 98 137 156 192 125 118 266 203 430 258 49 17 92 31 95 31 3 0 -12 -34
-33 -76z m739 24 c186 -71 361 -200 484 -356 33 -40 59 -75 59 -78 0 -2 -118
-4 -263 -4 l-263 0 -18 78 c-27 115 -74 248 -115 327 -20 39 -37 74 -39 78 -6
12 44 -2 155 -45z m2814 -599 c23 -18 94 -123 216 -323 l182 -296 -490 0 -489
0 0 325 0 326 273 -3 274 -3 34 -26z m-3716 -21 c-12 -71 -25 -216 -25 -280
l0 -68 -325 0 -325 0 0 24 c0 57 56 269 86 329 l15 27 290 0 290 0 -6 -32z
m799 -10 c11 -72 25 -240 26 -290 l0 -48 -345 0 -345 0 0 53 c0 51 14 201 26
285 l6 42 313 0 313 0 6 -42z m757 -40 c28 -69 52 -166 64 -255 l7 -53 -325 0
-325 0 -7 93 c-3 50 -10 128 -16 172 -5 44 -9 88 -9 97 0 17 17 18 292 16
l291 -3 28 -67z m-1580 -545 c0 -43 6 -125 13 -183 7 -58 10 -108 6 -113 -3
-4 -134 -6 -291 -5 l-284 3 -23 59 c-29 72 -60 193 -69 263 l-6 53 327 0 326
0 1 -77z m839 18 c0 -62 -16 -246 -26 -293 l-6 -28 -308 0 c-170 0 -311 4
-314 8 -5 8 -18 136 -30 305 l-5 67 344 0 345 0 0 -59z m808 2 c-12 -80 -53
-234 -75 -280 l-18 -38 -289 -3 -289 -2 6 62 c4 35 9 115 13 178 3 63 7 121
10 128 2 9 77 12 327 12 l324 0 -9 -57z m2502 -373 l0 -190 -495 0 -495 0 0
190 0 190 495 0 495 0 0 -190z m-4066 -207 c30 -119 84 -266 122 -331 14 -24
24 -45 22 -47 -1 -2 -41 8 -88 22 -171 49 -338 151 -466 284 -66 69 -134 155
-134 170 0 5 107 9 259 9 l259 0 26 -107z m690 45 c-54 -231 -155 -421 -244
-458 -62 -26 -131 33 -199 167 -40 82 -96 243 -107 313 l-7 40 286 0 285 0
-14 -62z m696 45 c0 -15 -87 -117 -155 -182 -84 -79 -187 -152 -295 -206 -73
-37 -201 -85 -226 -85 -8 0 1 30 26 83 35 75 94 249 120 355 l11 42 259 0
c143 0 260 -3 260 -7z m2680 -428 l0 -205 -118 0 -117 0 -24 45 c-33 63 -109
131 -189 168 -63 30 -73 32 -182 32 -102 -1 -121 -4 -170 -26 -78 -37 -149
-97 -190 -162 l-35 -56 -455 -1 -455 0 -24 45 c-33 63 -110 131 -189 168 -137
64 -317 45 -436 -46 -52 -39 -136 -137 -136 -158 0 -5 -84 -9 -190 -9 l-190 0
0 45 c0 43 1 45 48 69 89 45 226 143 307 220 l80 75 1333 1 1332 0 0 -205z
m-2253 -120 c60 -18 135 -80 166 -139 17 -32 22 -58 22 -121 0 -91 -17 -137
-72 -190 -51 -49 -99 -69 -181 -73 -63 -4 -79 -1 -127 23 -104 50 -163 153
-152 265 6 73 35 127 93 179 74 65 157 84 251 56z m1703 -1 c70 -21 149 -98
173 -168 62 -181 -63 -356 -253 -356 -247 0 -362 291 -182 464 42 40 124 75
178 76 17 0 54 -7 84 -16z"
             />
             <path
               d="M2814 2496 c-17 -8 -34 -21 -38 -31 -3 -9 -6 -127 -6 -261 0 -231 1
-245 20 -264 19 -19 33 -20 449 -20 l430 0 20 26 c20 25 21 39 21 260 0 254
-4 276 -53 294 -48 16 -804 13 -843 -4z m736 -286 l0 -140 -312 2 -313 3 -3
138 -3 137 316 0 315 0 0 -140z"
             />
             <path
               d="M2795 1815 l-25 -24 0 -244 c0 -232 1 -246 21 -271 l20 -26 425 0
425 0 24 25 25 24 0 241 0 242 -29 29 -29 29 -417 0 -416 0 -24 -25z m755
-275 l0 -140 -312 2 -313 3 -3 138 -3 137 316 0 315 0 0 -140z"
             />
           </g>
         </svg>
       </SvgIcon>
     </>
   );
 };
 export default FreightBrokerIcon;
