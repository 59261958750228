import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import { Box, CircularProgress } from "@mui/material";
import { transactionService } from "../../utils/services/transactionService";
import TransactionList from "../../components/transaction-list";

const Transaction = () => {
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactionList, setTransactionList] = useState([]);
  const [clientFilter, setClientFilter] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [viewMode, setViewMode] = useState("list");

  const fetchTransaction = async () => {
    setLoading(true);
    try {
      const response = await transactionService.fetch(
        15,
        0,
        clientFilter?._id,
        searchValue
      );
      setTransactionList(response.data);
      setLoading(false);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch Failed:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTransaction();
  }, [page, rowsPerPage, clientFilter, searchValue]);

  console.log(transactionList);

  return (
    <>
      <DashboardContainer
        hideAddButton={true}
        hideUploadButton={true}
        onSelectClient={(e) => setClientFilter(e)}
        onSearchData={(x) => {
          if (x.trim()) {
            setSearchValue(x);
            setPage(0);
          } else {
            setSearchValue("");
          }
        }}
        setViewMode={setViewMode}
        header={"Transaction List"}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <TransactionList
          data={transactionList}
          selectedTransaction={selectedTransaction}
          setSelectedTransaction={setSelectedTransaction}
          dataCount={dataCount}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          viewMode={viewMode}
        />
      )}
    </>
  );
};

export default Transaction;
