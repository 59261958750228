import { SvgIcon } from '@mui/material'
import React, { useState } from 'react'

const DashboardIcon = ({ fill }) => {
  return (
    <SvgIcon>
      <svg width="30" height="30" viewBox="0 0 30 30" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.43555 0.0526819C0.908203 0.181588 0.427734 0.580026 0.175781 1.08393L0.0292969 1.3769V4.98042V8.58393L0.169922 8.89448C0.339844 9.26948 0.826172 9.73237 1.21289 9.87885C1.47656 9.98432 1.79297 9.99018 6.73242 10.0078C11.2324 10.0195 12.0176 10.0078 12.3105 9.93745C12.9258 9.77924 13.3008 9.45698 13.5879 8.8476L13.7402 8.52534V5.03901C13.7402 1.81049 13.7344 1.52924 13.6348 1.24214C13.4883 0.831979 13.043 0.35151 12.6445 0.169869L12.334 0.0292444L6.97266 0.0175257C4.02539 0.0175257 1.53516 0.0292444 1.43555 0.0526819ZM12.0938 1.31245C12.498 1.48237 12.4805 1.30073 12.4805 5.02729V8.39057L12.3105 8.56049L12.1406 8.73042H6.90234C2.15625 8.73042 1.65234 8.7187 1.5293 8.63667C1.23633 8.42573 1.23047 8.38471 1.23047 4.97456C1.23047 2.21479 1.24219 1.7812 1.32422 1.61128C1.51172 1.21284 1.24219 1.23042 6.84375 1.23042C11.0332 1.23042 11.9297 1.24214 12.0938 1.31245Z"
          fill={fill || 'black'}
        />
        <path
          d="M17.6078 0.0820332C17.4789 0.123049 17.2914 0.199221 17.1918 0.251955C16.9867 0.357424 16.5707 0.791018 16.4476 1.03125C16.2308 1.44727 16.225 1.74024 16.2426 8.96484L16.2601 16.0254L16.4125 16.3477C16.6293 16.8164 16.8871 17.0801 17.3324 17.2969L17.725 17.4902H23.0863C28.1371 17.4902 28.4652 17.4844 28.7582 17.3848C29.1683 17.2383 29.6488 16.793 29.8305 16.3945L29.9711 16.084V8.73047V1.37696L29.7719 1.00781C29.5492 0.597658 29.3676 0.421877 28.9223 0.187502L28.6234 0.0292988L23.2328 0.01758C18.9906 0.00586128 17.7894 0.0234394 17.6078 0.0820332ZM28.3891 1.32422C28.7933 1.51172 28.7699 1.05469 28.7699 8.72461C28.7699 16.1836 28.7816 15.9199 28.4711 16.1367C28.348 16.2188 27.8441 16.2305 23.098 16.2305H17.8598L17.6898 16.0605L17.5199 15.8906V8.77734C17.5199 2.30274 17.5258 1.65234 17.6137 1.5293C17.8246 1.23047 17.7074 1.23633 23.1332 1.23047C27.6273 1.23047 28.2191 1.24219 28.3891 1.32422Z"
          fill={fill || 'black'}
        />
        <path
          d="M1.42969 12.5625C0.867188 12.6973 0.345703 13.1543 0.140625 13.6875C0.0351562 13.9629 0.0292969 14.2383 0.0292969 21.2988V28.623L0.228516 28.9922C0.451172 29.4023 0.632812 29.5781 1.07812 29.8125L1.37695 29.9707H6.85547H12.334L12.6445 29.8301C13.043 29.6484 13.4883 29.168 13.6348 28.7578C13.7344 28.4648 13.7402 28.0781 13.7402 21.2109V13.9746L13.5879 13.6523C13.3008 13.0488 12.9258 12.7266 12.3223 12.5684C12.0352 12.4922 11.2207 12.4805 6.85547 12.4863C3 12.4863 1.65234 12.5098 1.42969 12.5625ZM12.3105 13.9395L12.4805 14.1094V21.2227C12.4805 27.7031 12.4746 28.3477 12.3867 28.4707C12.1699 28.7754 12.2812 28.7695 6.84961 28.7695C2.37891 28.7695 1.78125 28.7578 1.61133 28.6758C1.20703 28.4883 1.23047 28.9453 1.23047 21.2754C1.23047 13.8164 1.21875 14.0801 1.5293 13.8633C1.65234 13.7812 2.15625 13.7695 6.90234 13.7695H12.1406L12.3105 13.9395Z"
          fill={fill || 'black'}
        />
        <path
          d="M17.6895 20.0625C17.0919 20.2207 16.6876 20.5723 16.4122 21.1523L16.2598 21.4746L16.2422 24.7852C16.2247 28.3066 16.2422 28.5938 16.4942 29.0391C16.6876 29.373 17.0977 29.7305 17.4376 29.8594C17.7129 29.9648 17.9532 29.9707 23.1739 29.9707H28.6231L28.9219 29.8125C29.3672 29.5781 29.5489 29.4023 29.7715 28.9922L29.9708 28.623V25.0195V21.416L29.8301 21.1055C29.6602 20.7305 29.1739 20.2676 28.7872 20.1211C28.5235 20.0156 28.2129 20.0098 23.2325 19.998C19.1778 19.9922 17.8946 20.0039 17.6895 20.0625ZM28.4708 21.3633C28.7637 21.5742 28.7696 21.6152 28.7696 25.0254C28.7696 27.791 28.7579 28.2188 28.6758 28.3887C28.4883 28.7871 28.7462 28.7695 23.1504 28.7695C17.7188 28.7695 17.8301 28.7754 17.6133 28.4707C17.5313 28.3477 17.5196 27.9844 17.5196 24.9727V21.6094L17.6895 21.4395L17.8594 21.2695H23.0977C27.8438 21.2695 28.3477 21.2813 28.4708 21.3633Z"
          fill={fill || 'black'}
        />
      </svg>
    </SvgIcon>
  )
}

export default DashboardIcon
