import React, { useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import CustomTypography from "../../globalStyles/fonts/index.js";

import FilterIcon from "../../globalStyles/icons/filter-icon.js";
import ShipmentDetails from "../Shipment-DetailsTable/index.js";
import ManifestDetails from "../Shipment-DetailsTable/manifestStatusTable.js";
import OverPackDetails from "../Shipment-DetailsTable/OverPackStatusTable.js";

const ShipmentList = () => {
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [selectedOverpack, setSelectedOverpack] = useState(null);
  const [selectedManifest, setSelectedManifest] = useState(null);

  const customData = [
    {
      orderId: "ORD001",
      shipmentId: "SHP001",
      carrierName: "Carrier A",
      overPackId: "OP001",
      manifestId: "MAN001",
      date: "2024-06-20",
      origin: "New York",
      destination: "Chicago",
      currentLocation: "Kansas City, MO",
      lastUpdate: "2024-06-20 14:00",
      status: "Shipped",
    },
    {
      orderId: "ORD002",
      shipmentId: "SHP002",
      carrierName: "Carrier B",
      overPackId: "OP002",
      manifestId: "MAN002",
      date: "2024-06-21",
      origin: "Los Angeles",
      destination: "Houston",
      currentLocation: "Dallas, TX",
      lastUpdate: "2024-06-21 14:00",
      status: "In Transit",
    },
    {
      orderId: "ORD003",
      shipmentId: "SHP003",
      carrierName: "Carrier c",
      overPackId: "OP003",
      manifestId: "MAN003",
      date: "2024-06-20",
      origin: "New York",
      destination: "Chicago",
      currentLocation: "Kansas City, MO",
      lastUpdate: "2024-06-20 14:00",
      status: "Shipped",
    },
    {
      orderId: "ORD004",
      shipmentId: "SHP004",
      carrierName: "Carrier C",
      overPackId: "OP004",
      manifestId: "MAN004",
      date: "2024-06-21",
      origin: "Los Angeles",
      destination: "Houston",
      currentLocation: "Dallas, TX",
      lastUpdate: "2024-06-21 14:00",
      status: "In Transit",
    },

    {
      orderId: "ORD005",
      shipmentId: "SHP005",
      carrierName: "Carrier E",
      overPackId: "OP005",
      manifestId: "MAN005",
      date: "2024-06-21",
      origin: "Los Angeles",
      destination: "Houston",
      currentLocation: "Dallas, TX",
      lastUpdate: "2024-06-21 14:00",
      status: "In Transit",
    },
    {
      orderId: "ORD006",
      shipmentId: "SHP006",
      carrierName: "Carrier F",
      overPackId: "OP006",
      manifestId: "MAN006",
      date: "2024-06-20",
      origin: "New York",
      destination: "Chicago",
      currentLocation: "Kansas City, MO",
      lastUpdate: "2024-06-20 14:00",
      status: "Shipped",
    },
    {
      orderId: "ORD007",
      shipmentId: "SHP007",
      carrierName: "Carrier G",
      overPackId: "OP007",
      manifestId: "MAN007",
      date: "2024-06-21",
      origin: "Los Angeles",
      destination: "Houston",
      currentLocation: "Dallas, TX",
      lastUpdate: "2024-06-21 14:00",
      status: "In Transit",
    },
    {
      orderId: "ORD008",
      shipmentId: "SHP008",
      carrierName: "Carrier H",
      overPackId: "OP008",
      manifestId: "MAN008",
      date: "2024-06-20",
      origin: "New York",
      destination: "Chicago",
      currentLocation: "Kansas City, MO",
      lastUpdate: "2024-06-20 14:00",
      status: "Shipped",
    },
    // Add more data as needed
  ];
  const handleViewClick = (shipmentId) => {
    if (selectedShipment === shipmentId) {
      setSelectedShipment(null);
    } else {
      setSelectedShipment(shipmentId);
      setSelectedOverpack(null);
      setSelectedManifest(null);
    }
  };

  const handleOverpackClick = (overPackId) => {
    if (selectedOverpack === overPackId) {
      setSelectedOverpack(null);
    } else {
      setSelectedOverpack(overPackId);
      setSelectedShipment(null);
      setSelectedManifest(null);
    }
  };

  const handleManifestClick = (manifestId) => {
    if (selectedManifest === manifestId) {
      setSelectedManifest(null);
    } else {
      setSelectedManifest(manifestId);
      setSelectedShipment(null); // Collapse the shipment
      setSelectedOverpack(null); // Collapse the overpack
    }
  };
  const isSelected = (id, selectedId) => id === selectedId;

  return (
    <TableContainer
      style={{
        maxHeight: "80vh",
        overflowY: "auto",
        paddingRight: "4px",
        paddingLeft: "4px",
        // scrollbarWidth: 'none',
        // 'msOverflowStyle': 'none',
        // '&::WebkitScrollbar': {
        //   width: 0
        // }
      }}
    >
      <Table
        style={{ borderCollapse: "separate", borderSpacing: "0 4px" }}
        stickyHeader
      >
        <TableHead>
          <TableRow
            style={{
              backgroundColor: "#EEEEEE",
              borderRadius: "8px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
            }}
          >
            <TableCell
              style={{
                backgroundColor: "#EEEEEE",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                textAlign: "center",
                padding: 7,
              }}
            >
              <CustomTypography
                fontSize="14px"
                title={true}
                color="#000"
                fontWeight={600}
              >
                Order ID
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#EEEEEE",
                borderBottom: "none",
                textAlign: "center",
                padding: 7,
              }}
            >
              <CustomTypography
                fontSize="14px"
                title={true}
                color="#000"
                fontWeight={600}
              >
                Shipment ID
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#EEEEEE",
                borderBottom: "none",
                textAlign: "center",
                padding: 7,
              }}
            >
              <CustomTypography
                fontSize="14px"
                title={true}
                color="#000"
                fontWeight={600}
              >
                Carrier Name
                <IconButton aria-label="filter" size="small">
                  <FilterIcon />
                </IconButton>
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#EEEEEE",
                borderBottom: "none",
                textAlign: "center",
                padding: 7,
              }}
            >
              <CustomTypography
                fontSize="14px"
                title={true}
                color="#000"
                fontWeight={600}
              >
                Over Pack ID
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#EEEEEE",
                borderBottom: "none",
                textAlign: "center",
                padding: 7,
              }}
            >
              <CustomTypography
                fontSize="14px"
                title={true}
                color="#000"
                fontWeight={600}
              >
                Manifest ID
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#EEEEEE",
                borderBottom: "none",
                textAlign: "center",
                padding: 7,
              }}
            >
              <CustomTypography
                fontSize="14px"
                title={true}
                color="#000"
                fontWeight={600}
              >
                Date
                <IconButton aria-label="filter" size="small">
                  <FilterIcon />
                </IconButton>
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#EEEEEE",
                borderBottom: "none",
                textAlign: "center",
                padding: 7,
              }}
            >
              <CustomTypography
                fontSize="14px"
                title={true}
                color="#000"
                fontWeight={600}
              >
                Origin
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#EEEEEE",
                borderBottom: "none",
                textAlign: "center",
                padding: 7,
              }}
            >
              <CustomTypography
                fontSize="14px"
                title={true}
                color="#000"
                fontWeight={600}
              >
                Destination
              </CustomTypography>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "#EEEEEE",
                borderBottom: "none",
                textAlign: "center",
                padding: 7,
              }}
            >
              <CustomTypography
                fontSize="14px"
                title={true}
                color="#000"
                fontWeight={600}
              >
                Status
                <IconButton aria-label="filter" size="small">
                  <FilterIcon />
                </IconButton>
              </CustomTypography>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#EEEEEE",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                textAlign: "center",
                padding: 7,
              }}
            >
              <CustomTypography
                fontSize="14px"
                title={true}
                color="#000"
                fontWeight={600}
              >
                Actions
              </CustomTypography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customData.map((row, index) => (
            <React.Fragment key={row.orderId}>
              <TableRow
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                }}
              >
                <TableCell
                  style={{
                    borderBottom: "none",
                    borderRadius: "8px 0 0 8px",
                    cursor: "pointer",
                    textAlign: "center",
                    padding: 7,
                  }}
                  // onClick={() => handleRowClick(row)}
                >
                  <CustomTypography
                    fontSize="13px"
                    color="#333333"
                    fontWeight={400}
                  >
                    {row.orderId}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "none",
                    cursor: "pointer",
                    textAlign: "center",
                    padding: 7,
                  }}
                  onClick={() => handleViewClick(row.shipmentId)}
                >
                  <CustomTypography
                    fontSize="13px"
                    color={
                      isSelected(row.shipmentId, selectedShipment)
                        ? "#007BFF"
                        : "#0096C7"
                    }
                    fontWeight={
                      isSelected(row.shipmentId, selectedShipment) ? 600 : 400
                    }
                    style={{
                      textDecoration: isSelected(
                        row.shipmentId,
                        selectedShipment
                      )
                        ? "underline"
                        : "none",
                    }}
                  >
                    {row.shipmentId}
                  </CustomTypography>
                </TableCell>

                <TableCell
                  style={{
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 7,
                  }}
                >
                  <CustomTypography
                    fontSize="13px"
                    color="#333333"
                    fontWeight={400}
                  >
                    {row.carrierName}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "none",
                    textAlign: "center",
                    cursor: "pointer",
                    padding: 7,
                  }}
                  onClick={() => handleOverpackClick(row.overPackId)}
                >
                  <CustomTypography
                    fontSize="13px"
                    color={
                      isSelected(row.overPackId, selectedOverpack)
                        ? "#007BFF"
                        : "#0096C7"
                    }
                    fontWeight={
                      isSelected(row.overPackId, selectedOverpack) ? 600 : 400
                    }
                    style={{
                      textDecoration: isSelected(
                        row.overPackId,
                        selectedOverpack
                      )
                        ? "underline"
                        : "underline",
                    }}
                  >
                    {row.overPackId}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "none",
                    textAlign: "center",
                    cursor: "pointer",
                    padding: 7,
                  }}
                  onClick={() => handleManifestClick(row.manifestId)}
                >
                  <CustomTypography
                    fontSize="13px"
                    color={
                      isSelected(row.manifestId, selectedManifest)
                        ? "#007BFF"
                        : "#0096C7"
                    }
                    fontWeight={
                      isSelected(row.manifestId, selectedManifest) ? 600 : 400
                    }
                    style={{
                      textDecoration: isSelected(
                        row.manifestId,
                        selectedManifest
                      )
                        ? "underline"
                        : "underline",
                    }}
                  >
                    {row.manifestId}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 7,
                  }}
                >
                  <CustomTypography
                    fontSize="13px"
                    color="#333333"
                    fontWeight={400}
                  >
                    {row.date}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 7,
                  }}
                >
                  <CustomTypography
                    fontSize="13px"
                    color="#333333"
                    fontWeight={400}
                  >
                    {row.origin}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 7,
                  }}
                >
                  <CustomTypography
                    fontSize="13px"
                    color="#333333"
                    fontWeight={400}
                  >
                    {row.destination}
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "none",
                    textAlign: "center",
                    padding: 7,
                  }}
                >
                  <CustomTypography
                    fontSize="13px"
                    color="#007613"
                    fontWeight={400}
                  >
                    <span
                      style={{
                        backgroundColor: "#CBFFD3",
                        padding: "3px 5px",
                        borderColor: "#007613",
                        display: "inline-block",
                        borderWidth: 2,
                      }}
                    >
                      {row.status}
                    </span>
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: "none",
                    borderRadius: "0 8px 8px 0",
                    textAlign: "center",
                    padding: 7,
                  }}
                >
                  <IconButton
                    color="primary"
                    sx={{
                      backgroundColor: "#C3E1FF",
                      width: 35,
                      height: 35,
                      borderRadius: 2,
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={10}
                  style={{ padding: 0, borderBottom: "none" }}
                >
                  <Collapse
                    in={selectedShipment === row.shipmentId}
                    timeout="auto"
                    unmountOnExit
                  >
                    <ShipmentDetails Shipment={row} />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={10}
                  style={{ padding: 0, borderBottom: "none" }}
                >
                  <Collapse
                    in={selectedOverpack === row.overPackId}
                    timeout="auto"
                    unmountOnExit
                  >
                    <OverPackDetails OverPack={row} />
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={10}
                  style={{ padding: 0, borderBottom: "none" }}
                >
                  <Collapse
                    in={selectedManifest === row.manifestId}
                    timeout="auto"
                    unmountOnExit
                  >
                    {/* Collapse for Manifest Details */}
                    <ManifestDetails Manifest={row} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShipmentList;
