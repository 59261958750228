// api.js

import axiosInstance from '../axiosInstance'

export const emailTemplateService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post('/email-template', {
        ...data
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/email-template?$limit=${limit || 15}&$skip=${skip || 0}&${
          clientId ? `client_id=${clientId}&` : ''
        }&${searchValue ? `&name[$search]=${searchValue}` : ''}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  },

  edit: async (id, data) => {
    try {
      let response = await axiosInstance.patch(`/email-template/${id}`, {
        ...data
      })
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/email-template/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
