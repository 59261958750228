import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import GradientCard from "../../components/gradient-card";
import ShipmentList from "../../components/shipment-list";
const Shipment = () => {
  const [searchValue, setSearchValue] = useState("");
  const [viewMode, setViewMode] = useState("list");
  const [loading, setLoading] = useState(false);
  const hideScrollbarStyle = {
    "&::WebkitScrollbar": {
      display: "none",
    },
    msOverflowStyle: "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
  };
  return (
    <div
      style={{
        display: "flex",
        overflow: "auto",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          overflowY: "scroll",
          flexDirection: "column",
          marginTop: 10,
          width: "100%",
          ...hideScrollbarStyle,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            overflow: "auto",
            minHeight: 200,
            marginTop: 10,
            ...hideScrollbarStyle,
          }}
        >
          <GradientCard
            cardTitle={"Total Shipments"}
            cardTitleCount={150}
            minWidth={"30%"}
          ></GradientCard>
          <GradientCard
            cardTitleCount={60}
            cardTitle={"Total Over-Packs"}
            minWidth={"30%"}
            smallRoundBoxBg={"#FF95A0"}
            backgroundColor={"#FFE2E5"}
          ></GradientCard>
          <GradientCard
            cardTitle={"Total Manifests"}
            cardTitleCount={30}
            minWidth={"30%"}
            smallRoundBoxBg={"#BF82FF"}
            backgroundColor={"#F3E8FF"}
          ></GradientCard>
        </div>
        <div style={{ marginInline: 2 }}>
          <DashboardContainer
            showDownloadIcon
            header={"Total Shipments"}
            setViewMode={setViewMode}
            onSearchData={(x) => {
              setSearchValue(x);
            }}
            hideFilterClient={true}
            title={"Download Report"}
            hideUploadButton={true}
          />
        </div>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <div style={{ marginInline: 1, marginTop: 5 }}>
            <ShipmentList />
          </div>
        )}
      </div>
    </div>
  );
};

export default Shipment;
