import React, { useContext, useEffect, useState } from 'react'
import Container from '@mui/material/Container/Container'
import Typography from '@mui/material/Typography/Typography'
import Grid from '@mui/material/Grid/Grid'
import Card from '@mui/material/Card/Card'
import CardContent from '@mui/material/CardContent/CardContent'
import IconButton from '@mui/material/IconButton/IconButton'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogActions from '@mui/material/DialogActions/DialogActions'
import Button from '@mui/material/Button/Button'
import TextField from '@mui/material/TextField/TextField'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import { portTypes } from '../../utils/portTypes'
import { clientService } from '../../utils/services/clientService'
import AppContext from '../context/context'
import CloseIcon from '@mui/icons-material/Close'
import CustomTypography from '../../globalStyles/fonts'
import DeleteConfirmationModal from '../deleteConfirmationModal'
import AssignClient from '../client-assign'
import PortDetailsModal from '../portDetailsViewModal'
import ZoneDetailsModal from '../zoneViewDetailsModal'
import { isMobile } from '../../globalStyles/mobile'

const ListContainer = ({
  containerName,
  data,
  onDeleteData,
  handleEditClick,
  handleDeleteClick,
  value,
  setValue,
  handleAddData,
  selectedData,
  listName,
  inputLable,
  port,
  setCountryValue,
  setLocationValue,
  setTypeValue,
  countryValue,
  locationValue,
  typeValue,
  onChangeZoneNumber,
  zoneNum,
  handleClose,
  postalCode,
  setPostalCode,
  formErrors,
  zone,
  setFormErrors,
  openModal,
  setOpenModal,
  client,
  onSelectClient,
  selectedClient,
  viewMode,
  deleteTitle
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)
  const [zoneNumber, setZoneNumber] = useState('')
  const [clientData, setClientData] = useState([])
  const [selectedClientData, setSelectedClient] = useState({ name: '' })
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedPortOrZoneData, setSelectedPortOrZoneData] = useState(null)
  const [openViewModal, setOpenViewModal] = useState(false)
  const [openZoneViewModal, setOpenZoneViewModal] = useState(false)

  const { user } = useContext(AppContext)
  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch()
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }
  useEffect(() => {
    fetchClientData()
  }, [])

  const handleInputChange = (event, value) => {
    setSelectedValue(value)
    setTypeValue(value)
  }

  useEffect(() => {
    setSelectedValue(typeValue)
  }, [typeValue])

  useEffect(() => {
    setZoneNumber(zoneNum)
  }, [zoneNum])

  useEffect(() => {
    fetchClientData()
  }, [])
  const handleClientAssignClick = (client) => {
    setSelectedClient(client)
    setModalOpen(true)
  }
  const handlePortViewClick = (port) => {
    setSelectedPortOrZoneData(port)
    setOpenViewModal(true)
  }
  const handleZoneViewClick = (zone) => {
    setOpenZoneViewModal(true)
    setSelectedPortOrZoneData(zone)
  }
  const renderGridView = () => (
    <Grid container spacing={2}>
      {data?.map((x) => (
        <Grid sx={{ padding: 1, marginTop: 1 }} item xs={12} sm={6} md={3} key={x.id}>
          <Card sx={{ boxShadow: 3, cursor: 'pointer' }}>
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    alignItems: 'flex-start',
                    width: '60%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 15
                  }}
                >
                  <Tooltip title={x?.name} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '70%',
                        overflow: 'hidden'
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: '500'
                        }}
                        variant="h7"
                      >
                        {x?.name}
                      </Typography>
                    </div>
                  </Tooltip>

                  <Tooltip title={x?.client_id?.name} arrow>
                    <div
                      style={{
                        fontWeight: '500',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}
                    >
                      {user.role === 'admin' ? (
                        <Typography
                          style={{
                            fontWeight: '500'
                          }}
                          variant="h7"
                        >
                          Client : {x?.client_id?.name}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Tooltip>
                </div>
                {user.role === 'admin' && (
                  <div>
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        handleEditClick && handleEditClick(x)
                        setOpenModal(true)
                      }}
                      style={{
                        color: '#FEC50F',
                        padding: 0,
                        background: '#FEC50F33',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 15,
                        boxShadow: '0px 0px 4px 0px #00000040'
                      }}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        handleDeleteClick && handleDeleteClick(x)
                        setOpenConfirmation(true)
                      }}
                      style={{
                        color: '#D13438',
                        padding: 0,
                        background: '#D1343833',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040'
                      }}
                    >
                      <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                    </IconButton>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ width: '100%', padding: 0.5 }}>
        {data?.map((x, index) => (
          <Card key={index} sx={{ marginBottom: 2 }}>
            <CardContent>
              {port ? (
                <div style={{ width: '100%' }}>
                  <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>Port Name: {x?.name}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>Country Name: {x?.country}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>Location: {x?.location}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>Postal Code: {x?.postal_code}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>Port Type: {x?.type}</Typography>
                    </Box>
                  </Box>

                  <Box mt={2} justifyContent={'flex-end'} display="flex">
                    <IconButton
                      style={{
                        color: '#007DFF',
                        padding: 0,
                        background: '#C3E1FF',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040'
                      }}
                      aria-label="view"
                      onClick={() => {
                        handlePortViewClick(x)
                      }}
                    >
                      <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png" />
                    </IconButton>
                    {user.role === 'admin' && (
                      <>
                        <IconButton
                          style={{
                            color: '#007613',
                            padding: 0,
                            background: '#00761333',
                            borderRadius: 5,
                            marginLeft: 10,
                            height: 30,
                            width: 30,
                            boxShadow: '0px 0px 4px 0px #00000040'
                          }}
                          aria-label="client-assign"
                          onClick={() => {
                            handleClientAssignClick()
                            setSelectedPortOrZoneData(x)
                          }}
                        >
                          <img style={{ height: 14, width: 20 }} alt="client" src="/client-assign.png" />
                        </IconButton>
                        <IconButton
                          style={{
                            color: '#FEC50F',
                            padding: 0,
                            background: '#FEC50F33',
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 15,
                            boxShadow: '0px 0px 4px 0px #00000040'
                          }}
                          aria-label="edit"
                          onClick={() => {
                            handleEditClick && handleEditClick(x)
                            setOpenModal(true)
                          }}
                        >
                          <BorderColorIcon sx={{ height: 20, width: 20 }} />
                        </IconButton>
                        <IconButton
                          style={{
                            color: '#D13438',
                            padding: 0,
                            background: '#D1343833',
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 10,
                            boxShadow: '0px 0px 4px 0px #00000040'
                          }}
                          aria-label="delete"
                          onClick={() => {
                            handleDeleteClick && handleDeleteClick(x)
                            setOpenConfirmation(true)
                          }}
                        >
                          <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png" />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </div>
              ) : (
                <div style={{ width: '100%' }}>
                  <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>Zone Name: {x?.name}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography>Zone Number: {x?.zone_number}</Typography>
                    </Box>
                  </Box>

                  <Box mt={2} justifyContent={'flex-end'} display="flex">
                    <IconButton
                      style={{
                        color: '#007DFF',
                        padding: 0,
                        background: '#C3E1FF',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040'
                      }}
                      aria-label="view"
                      onClick={() => {
                        handlePortViewClick(x)
                      }}
                    >
                      <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png" />
                    </IconButton>
                    {user.role === 'admin' && (
                      <>
                        <IconButton
                          style={{
                            color: '#007613',
                            padding: 0,
                            background: '#00761333',
                            borderRadius: 5,
                            marginLeft: 10,
                            height: 30,
                            width: 30,
                            boxShadow: '0px 0px 4px 0px #00000040'
                          }}
                          aria-label="client-assign"
                          onClick={() => {
                            handleClientAssignClick()
                            setSelectedPortOrZoneData(x)
                          }}
                        >
                          <img style={{ height: 14, width: 20 }} alt="client" src="/client-assign.png" />
                        </IconButton>
                        <IconButton
                          style={{
                            color: '#FEC50F',
                            padding: 0,
                            background: '#FEC50F33',
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 15,
                            boxShadow: '0px 0px 4px 0px #00000040'
                          }}
                          aria-label="edit"
                          onClick={() => {
                            handleEditClick && handleEditClick(x)
                            setOpenModal(true)
                          }}
                        >
                          <BorderColorIcon sx={{ height: 20, width: 20 }} />
                        </IconButton>
                        <IconButton
                          style={{
                            color: '#D13438',
                            padding: 0,
                            background: '#D1343833',
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 10,
                            boxShadow: '0px 0px 4px 0px #00000040'
                          }}
                          aria-label="delete"
                          onClick={() => {
                            handleDeleteClick && handleDeleteClick(x)
                            setOpenConfirmation(true)
                          }}
                        >
                          <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png" />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </div>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
    )
  }
  const renderListView = () => (
    <div
      style={{
        width: '100%',
        overflow: isMobile() ? 'auto' : 'hidden',
        paddingBottom: 100
      }}
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          style={{
            maxHeight: '70vh',
            overflowY: 'auto',
            paddingInline: 0.4,
            marginInline: 0.5
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: 'separate',
              borderSpacing: '0 7px',
              paddingBottom: 7
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: '#EEEEEE',
                  borderRadius: 10,
                  boxShadow: '0px 0px 4px 0px #00000040'
                }}
              >
                {port && (
                  <>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        textAlign: 'center',
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                        Port Name
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                        country Name
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                        Location
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                        Postal Code
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                        Port type
                      </CustomTypography>
                    </TableCell>

                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        textAlign: 'center',
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                        Actions
                      </CustomTypography>
                    </TableCell>
                  </>
                )}

                {zone && (
                  <>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderTopLeftRadius: '8px',
                        borderBottom: 'none',
                        textAlign: 'center',
                        borderBottomLeftRadius: 8,
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                        Zone Name
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        textAlign: 'center',
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                        Zone Number
                      </CustomTypography>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: '#EEEEEE',
                        borderBottom: 'none',
                        textAlign: 'center',
                        borderBottomRightRadius: 8,
                        borderTopRightRadius: 8,
                        padding: 10
                      }}
                    >
                      <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                        Actions
                      </CustomTypography>
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((x, index) => (
                <TableRow
                  key={index}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: '#fff',
                    borderRadius: 8,
                    boxShadow: '0 4px 4px rgba(0,0,0,0.1)',
                    border: 'none',
                    padding: 0,
                    '&:last-child td, &:last-child th': {
                      border: 'none'
                    }
                  }}
                >
                  {port && (
                    <>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: 'none',
                          textAlign: 'center',
                          padding: 1,
                          borderBottom: 'none',
                          borderTopLeftRadius: '8px',
                          borderBottomLeftRadius: '8px'
                        }}
                      >
                        <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                          {x.name}
                        </CustomTypography>
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: 'none',
                          textAlign: 'center',
                          padding: 1
                        }}
                      >
                        <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                          {x.country}
                        </CustomTypography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: 'none',
                          textAlign: 'center',
                          padding: 1
                        }}
                      >
                        <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                          {x.location}
                        </CustomTypography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: 'none',
                          textAlign: 'center',
                          padding: 1
                        }}
                      >
                        <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                          {x.postal_code}
                        </CustomTypography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: 'none',
                          textAlign: 'center',
                          padding: 1
                        }}
                      >
                        <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                          {x.type}
                        </CustomTypography>
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: 'center',
                          borderBottom: 'none',
                          padding: 1,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8
                          // justifyContent: "center",
                        }}
                      >
                        <IconButton
                          style={{
                            color: '#007DFF',
                            padding: 0,
                            background: '#C3E1FF',
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 10,
                            boxShadow: '0px 0px 4px 0px #00000040'
                          }}
                          aria-label="view"
                          onClick={() => {
                            handlePortViewClick(x)
                          }}
                        >
                          <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png"></img>
                        </IconButton>
                        {user.role === 'admin' && (
                          <>
                            <IconButton
                              style={{
                                color: '#007613',
                                padding: 0,
                                background: '#00761333',
                                borderRadius: 5,
                                marginLeft: 10,
                                height: 30,
                                width: 30,
                                padding: 0,
                                boxShadow: '0px 0px 4px 0px #00000040'
                              }}
                              aria-label="view"
                              onClick={() => {
                                handleClientAssignClick()
                                setSelectedPortOrZoneData(x)
                              }}
                            >
                              <img
                                style={{ height: 14, width: 20 }}
                                alt="client"
                                src="/client-assign.png"
                              ></img>
                            </IconButton>
                            <IconButton
                              style={{
                                color: '#FEC50F',
                                padding: 0,
                                background: '#FEC50F33',
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                marginLeft: 15,
                                boxShadow: '0px 0px 4px 0px #00000040'
                              }}
                              aria-label="edit"
                              onClick={() => {
                                handleEditClick && handleEditClick(x)
                                setOpenModal(true)
                              }}
                            >
                              <BorderColorIcon sx={{ height: 20, width: 20 }} />
                            </IconButton>
                            <IconButton
                              style={{
                                color: '#D13438',
                                padding: 0,
                                background: '#D1343833',
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                marginLeft: 10,
                                boxShadow: '0px 0px 4px 0px #00000040'
                              }}
                              aria-label="delete"
                              onClick={() => {
                                handleDeleteClick && handleDeleteClick(x)
                                setOpenConfirmation(true)
                              }}
                            >
                              <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                              {/* <DeleteIcon sx={{ height: 20, width: 20 }} /> */}
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </>
                  )}

                  {zone && (
                    <>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: 'none',
                          borderTopLeftRadius: '8px',
                          borderBottomLeftRadius: '8px',
                          textAlign: 'center',
                          padding: 6
                        }}
                      >
                        <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                          {x.name}
                        </CustomTypography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: 'none',
                          padding: 6,
                          textAlign: 'center'
                        }}
                      >
                        <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                          {x.zone_number}
                        </CustomTypography>
                      </TableCell>

                      <TableCell
                        style={{
                          borderBottom: 'none',
                          textAlign: 'center',
                          padding: 6,
                          borderBottomRightRadius: 8,
                          borderTopRightRadius: 8
                        }}
                      >
                        <IconButton
                          style={{
                            color: '#007DFF',
                            padding: 0,
                            background: '#C3E1FF',
                            height: 30,
                            width: 30,
                            borderRadius: 5,
                            marginLeft: 10,
                            boxShadow: '0px 0px 4px 0px #00000040'
                          }}
                          aria-label="view"
                          onClick={() => {
                            handleZoneViewClick(x)
                          }}
                        >
                          <img style={{ height: 14, width: 20 }} alt="view" src="/eye.png"></img>
                        </IconButton>
                        {user.role === 'admin' && (
                          <>
                            <IconButton
                              style={{
                                color: '#007613',
                                padding: 0,
                                background: '#00761333',
                                borderRadius: 5,
                                marginLeft: 10,
                                height: 30,
                                width: 30,
                                padding: 0,
                                boxShadow: '0px 0px 4px 0px #00000040'
                              }}
                              aria-label="view"
                              onClick={() => {
                                handleClientAssignClick()
                                setSelectedPortOrZoneData(x)
                              }}
                            >
                              <img
                                style={{ height: 14, width: 20 }}
                                alt="client"
                                src="/client-assign.png"
                              ></img>
                            </IconButton>
                            <IconButton
                              style={{
                                color: '#FEC50F',
                                padding: 0,
                                background: '#FEC50F33',
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                marginLeft: 15,
                                boxShadow: '0px 0px 4px 0px #00000040'
                              }}
                              aria-label="edit"
                              onClick={() => {
                                handleEditClick && handleEditClick(x)
                                setOpenModal(true)
                              }}
                            >
                              <BorderColorIcon sx={{ height: 20, width: 20 }} />
                            </IconButton>
                            <IconButton
                              style={{
                                color: '#D13438',
                                padding: 0,
                                background: '#D1343833',
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                marginLeft: 10,
                                boxShadow: '0px 0px 4px 0px #00000040'
                              }}
                              aria-label="delete"
                              onClick={() => {
                                handleDeleteClick && handleDeleteClick(x)
                                setOpenConfirmation(true)
                              }}
                            >
                              <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png"></img>
                              {/* <DeleteIcon sx={{ height: 20, width: 20 }} /> */}
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
  return (
    <div style={{ minWidth: "100%" }}>
      <div
        style={{
          overflowY: "scroll",
          display: "flex",
          maxHeight: "70vh",
          // width: "100%",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::WebkitScrollbar": { width: 0 },
          marginTop: 5,
        }}
      >
        {viewMode === "grid" ? renderGridView() : renderListView()}
      </div>
      <Dialog
        open={openModal}
        onClose={() => {
          setFormErrors({});
          setOpenModal(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>{`Edit ${listName}`}</DialogTitle>
          <IconButton onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider style={{ marginBottom: 10 }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">
                {`${listName} Name`} <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                size="small"
                //sx={{ minWidth: 300 }}
                autoFocus
                margin="dense"
                // label={inputLable}
                placeholder="Enter Name"
                type="text"
                fullWidth
                value={value}
                onChange={(e) => setValue(e.target.value)}
                error={formErrors?.name}
                helperText={formErrors?.name}
              />
            </Grid>
            {zone ? (
              <Grid item xs={12} sm={6}>
                <Typography className="form-lable-style">
                  Zone Number <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  // sx={{ marginTop: 2 }}
                  margin="dense"
                  type="number"
                  size="small"
                  placeholder=" Enter Zone number"
                  variant="outlined"
                  value={zoneNumber}
                  inputProps={{ min: 0 }}
                  onChange={(e) => {
                    setZoneNumber(e.target.value);
                    onChangeZoneNumber && onChangeZoneNumber(e.target.value);
                  }}
                  fullWidth
                  error={formErrors?.zoneNumber}
                  helperText={formErrors?.zoneNumber}
                />
              </Grid>
            ) : (
              <></>
            )}
            {port ? (
              <>
                <Grid item xs={12} sm={6}>
                  <Typography className="form-lable-style">Country</Typography>
                  <TextField
                    value={countryValue}
                    size="small"
                    // sx={{ minWidth: 200 }}
                    margin="dense"
                    placeholder="Enter Country"
                    type="text"
                    fullWidth
                    onChange={(e) => setCountryValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className="form-lable-style">Location</Typography>
                  <TextField
                    value={locationValue}
                    size="small"
                    //sx={{ minWidth: 200 }}
                    margin="dense"
                    placeholder="Enter Location"
                    type="text"
                    fullWidth
                    onChange={(e) => setLocationValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className="form-lable-style">
                    Postal Code
                  </Typography>
                  <TextField
                    value={postalCode}
                    size="small"
                    //  sx={{ minWidth: 300 }}
                    margin="dense"
                    placeholder="Enter Postal Code"
                    type="text"
                    fullWidth
                    onChange={(e) => setPostalCode(e.target.value)}
                    error={!!formErrors.postal_code}
                    helperText={formErrors.postal_code}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className="form-lable-style">
                    Type <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Autocomplete
                    //label="Type"
                    size="small"
                    //sx={{ minWidth: 200 }}
                    value={selectedValue}
                    onChange={handleInputChange}
                    options={portTypes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Type"
                        variant="outlined"
                        error={!!formErrors.type}
                        helperText={formErrors.type}
                      />
                    )}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            {/* {!client && user?.role === 'admin' ? (
              <Grid item xs={12} sm={6}>
                <Typography className="form-lable-style">Choose Client</Typography>
                <Autocomplete
                  size="small"
                  value={
                    selectedClientData?._id
                      ? selectedClientData
                      : { name: selectedClient ? selectedClient : '' }
                  }
                  onChange={(event, newValue) => {
                    setSelectedClient(newValue)
                    onSelectClient && onSelectClient(newValue)
                  }}
                  options={clientData}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Typography variant="body1">{option.name}</Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Client"
                      // margin="dense"
                      error={!!formErrors.client}
                      helperText={formErrors.client}
                    />
                  )}
                />
              </Grid>
            ) : (
              <></>
            )} */}
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            className="cancel-button-style"
            onClick={() => {
              setOpenModal(false);
              handleClose && handleClose();
              setSelectedClient("");
              setFormErrors({});
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className="add-button-style"
            variant="contained"
            color="primary"
            onClick={(e) => {
              handleAddData && handleAddData(e);
              // setOpenModal(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmationModal
        title={deleteTitle}
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        handleDelete={(e) => {
          onDeleteData && onDeleteData(e);
          setOpenConfirmation(false);
        }}
      />
      <AssignClient
        mapKey={zone ? "zone" : "port"}
        selectedData={selectedPortOrZoneData}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <PortDetailsModal
        port={selectedPortOrZoneData}
        open={openViewModal}
        onClose={() => setOpenViewModal(false)}
      />
      <ZoneDetailsModal
        zone={selectedPortOrZoneData}
        open={openZoneViewModal}
        onClose={() => setOpenZoneViewModal(false)}
      />
    </div>
  );
}

export default ListContainer
