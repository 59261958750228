import React, { useState, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AddEditProvince from '../../components/add-edit-province'
import ProvinceList from '../../components/province-list'
import { provinceService } from '../../services/province-service'

const Province = () => {
  const [countries, setCountries] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()

  const fetchProvinces = async () => {
    try {
      const response = await provinceService.fetch(rowsPerPage, rowsPerPage * page)
      setCountries(response.data)
      setCount(response.total)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchProvinces()
  }, [rowsPerPage, page])

  const handleSubmit = async (data) => {
    try {
      await provinceService.create({ ...data, country: data.country._id })
      fetchProvinces()
      setOpen(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button sx={{ textTransform: 'none', backgroundColor: '#DC585C' }} onClick={() => setOpen(true)}>
          <Typography fontFamily={'poppins'} fontSize={'14px'} fontWeight={500} color="#fff">
            Add
          </Typography>
        </Button>
      </Box>

      <ProvinceList
        onSuccess={fetchProvinces}
        data={countries}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={(event, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10))
          setPage(0)
        }}
      />
      <AddEditProvince open={open} setOpen={setOpen} handleSubmit={handleSubmit} />
    </>
  )
}

export default Province
