import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Avatar,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { clientService } from "../../utils/services/clientService";
import CustomTypography from "../../globalStyles/fonts";
const ASPECT_RATIO = 2 / 3;
const AccountSettingsModal = ({ open, handleClose, user }) => {
  const [contactDetails, setContactDetails] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const [clientDetails, setClientDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [crop, setCrop] = useState({
    unit: "px",
    aspect: ASPECT_RATIO, // Fixed aspect ratio 2:3
  });
  const [imageRef, setImageRef] = useState(null);
  const [formData, setFormData] = useState({
    logo: "", // Replace with actual logo
    clientName: "",
    email: "",
    contact: "",
    businessName: "",
  });
  const onClose = () => {
    setFormData({
      logo: "", // Replace with actual logo
      clientName: "",
      email: "",
      contact: "",
      businessName: "",
    });
    setIsEditing(false);
    handleClose && handleClose();
  };

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target?.value });
  };

  // Toggle editing mode
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };
  const onImageLoaded = (image) => {
    setImageRef(image);
  };
  const checkAspectRatio = () => {
    const aspectRatio = crop.width / crop.height;
    console.log(crop.width / crop.height);
    console.log(2 / 3);
    if (aspectRatio < 2 / 3) {
      return false;
    }
    return true;
  };
  // Crop the image using the selected area
  const getCroppedImage = () => {
    console.log(imageRef, crop.width, crop.height);
    console.log(checkAspectRatio());
    // Check aspect ratio before cropping
    if (!checkAspectRatio()) {
      toast.warning("Give a proper aspect ratio of 2:3 ", { autoClose: 3000 });
      return;
    }
    if (imageRef && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg");
      console.log(base64Image);
      setBase64Image(base64Image);
      setCroppedImage(base64Image);
      setImagePreview(base64Image);
      setIsCropModalOpen(false);
    }
  };
  // Handle image upload and convert to base64
  const {
    getRootProps,
    getInputProps,
    open: triggerFileInput,
  } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(URL.createObjectURL(acceptedFiles[0]));
        setIsCropModalOpen(true); // Open crop modal after selecting the image
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    noClick: true,
    noKeyboard: true,
  });

  const fetchClientByEmail = async () => {
    try {
      let client = await clientService.fetch("", "", "", user.email);
      setClientDetails(client?.data[0]);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (clientDetails) {
      setFormData({
        ...formData,
        logo: clientDetails.logo,
        clientName: clientDetails.name,
        businessName: clientDetails?.businessName ?? clientDetails.name,
        contact: clientDetails?.contact ? clientDetails?.contact : "",
        email: clientDetails?.email,
      });
    }
  }, [clientDetails, open]);

  useEffect(() => {
    if (user) {
      fetchClientByEmail(user);
    }
  }, [user]);

  const handleSave = async () => {
    try {
      let response = await clientService.edit(
        base64Image
          ? {
              ...formData,
              logo: base64Image,
            }
          : {
              clientName: formData.name,
              businessName: formData?.businessName ?? formData.name,
              contact: formData?.contact ? formData?.contact : "",
              email: formData?.email,
            },
        localStorage.getItem("client_id")
      );
      if (response) {
        toast.success("Updated Successfully", { autoClose: 3000 });
        if (base64Image) {
          window.location.reload();
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }

    // Here, you'd usually send the data to the backend or handle state accordingly.
    onClose();
  };
  const handleImageLoad = (event) => {
    setImageRef(event.target); // Capturing the image element
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <CustomTypography style={{ fontWeight: "bold" }} variant="h6">
            ACCOUNT SETTINGS
          </CustomTypography>
          <IconButton onClick={toggleEdit}>
            {isEditing ? (
              <></>
            ) : (
              <DriveFileRenameOutlineIcon sx={{ color: "#FF5733" }} />
            )}
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            {/* Logo */}
            <Grid
              sx={{ minHeight: 50 }}
              item
              xs={12}
              container
              alignItems="center"
              spacing={2}
            >
              <Grid style={{ minWidth: 170 }} item xs={2}>
                <CustomTypography
                  style={{ fontWeight: "500", minHeight: 30 }}
                  variant="body1"
                >
                  Logo
                </CustomTypography>
              </Grid>
              <Grid item xs={4}>
                <div {...getRootProps()}>
                  <img
                    src={imagePreview || formData.logo}
                    alt="Logo"
                    style={{
                      width: "150px",
                      height: "80px",
                      objectFit: "contain",
                      cursor: isEditing ? "pointer" : "default",
                      borderRadius: 0,
                    }}
                    onClick={isEditing ? triggerFileInput : null}
                  />
                  <input {...getInputProps()} />
                </div>
              </Grid>
            </Grid>

            {/* Client Name */}
            <Grid item xs={12} container spacing={2}>
              <Grid style={{ minWidth: 170 }} item xs={4}>
                <CustomTypography style={{ fontWeight: "500" }} variant="body1">
                  Client Name
                </CustomTypography>
              </Grid>
              <Grid item xs={8}>
                {isEditing ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="clientName"
                    value={formData.clientName}
                    onChange={handleChange}
                  />
                ) : (
                  <CustomTypography
                    style={{ color: "#273894", fontWeight: "500" }}
                    variant="body1"
                  >
                    {formData.clientName}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>

            {/* Email */}
            <Grid item xs={12} container spacing={2}>
              <Grid style={{ minWidth: 170 }} item xs={4}>
                <CustomTypography style={{ fontWeight: "500" }} variant="body1">
                  Email
                </CustomTypography>
              </Grid>
              <Grid item xs={8}>
                <CustomTypography
                  style={{ color: "#273894", fontWeight: "500" }}
                  variant="body1"
                >
                  {formData.email}
                </CustomTypography>
              </Grid>
            </Grid>

            {/* Mobile Number */}
            <Grid item xs={12} container spacing={2}>
              <Grid style={{ minWidth: 170 }} item xs={4}>
                <CustomTypography style={{ fontWeight: "500" }} variant="body1">
                  Mobile No
                </CustomTypography>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                item
                xs={8}
              >
                <CustomTypography
                  style={{ marginRight: 1, color: "#273894", minWidth: 25 }}
                >{`+1`}</CustomTypography>
                {isEditing ? (
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                  />
                ) : (
                  <CustomTypography
                    style={{ color: "#273894", fontWeight: "500" }}
                    variant="body1"
                  >{` ${" "} ${formData.contact}`}</CustomTypography>
                )}
              </Grid>
            </Grid>

            {/* Business Name */}
            <Grid item xs={12} container spacing={2}>
              <Grid style={{ minWidth: 170 }} item xs={4}>
                <CustomTypography style={{ fontWeight: "500" }} variant="body1">
                  Business Name
                </CustomTypography>
              </Grid>
              <Grid item xs={8}>
                {isEditing ? (
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleChange}
                  />
                ) : (
                  <CustomTypography
                    style={{ color: "#273894", fontWeight: "500" }}
                    variant="body1"
                  >
                    {formData.businessName}
                  </CustomTypography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      {isEditing ? (
        <DialogActions style={{ margin: 5 }}>
          <Button variant="outlined" onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} color="primary">
            Update
          </Button>
        </DialogActions>
      ) : (
        <></>
      )}
      <Dialog
        open={isCropModalOpen}
        onClose={() => setIsCropModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent>
          <ReactCrop
            crop={crop}
            onChange={setCrop}
            onImageLoaded={onImageLoaded}
          >
            <img onLoad={handleImageLoad} src={imagePreview}></img>
          </ReactCrop>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCropModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={getCroppedImage} color="primary">
            Crop & Save
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default AccountSettingsModal;
