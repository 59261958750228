import axiosInstance from '../utils/axios-instance'

export const provinceService = {
  create: async (data) => {
    try {
      let response = await axiosInstance.post('/province', data)
      return response.data
    } catch (error) {
      throw error
    }
  },

  fetch: async (limit, skip) => {
    try {
      const response = await axiosInstance.get(
        `/province?$limit=${limit || 10}&$skip=${skip || 0}&$populate=country`
      )
      return response.data
    } catch (error) {
      throw error
    }
  },
  edit: async (data, id) => {
    try {
      let response = await axiosInstance.patch(`/province/${id}`, data)
      return response.data
    } catch (error) {
      throw error
    }
  },

  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/province/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }
}
