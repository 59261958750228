import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Network } from 'vis-network/standalone'
import 'vis-network/styles/vis-network.css'
import { eventService } from '../../utils/services/eventService'
import ReactJson from 'react-json-view'
import CustomTypography from '../../globalStyles/fonts'

const OrderWorkFlow = () => {
  const networkContainer = useRef(null)
  const { id } = useParams()
  const [nodes, setNodes] = useState([])
  const [edges, setEdges] = useState([])
  const [selectedNodeData, setSelectedNodeData] = useState(null)
  const [selectedNodeStatus, setSelectedNodeStatus] = useState(null)
  const [selectedNodeId, setSelectedNodeId] = useState(null)
  function formatString(str) {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }
  const options = {
    nodes: {
      shape: 'box',
      font: { size: 16, color: '#FFFFFF' },
      margin: 10
    },
    edges: {
      arrows: { to: { enabled: true, scaleFactor: 1.5 } },
      smooth: { type: 'continuous' }
    },
    physics: { enabled: false },
    interaction: {
      hover: true,
      dragNodes: true,
      dragView: true,
      zoomView: true
    }
  }

  const fetchEventLogs = async () => {
    try {
      const response = await eventService.getEventLogs(id)

      const processedIds = new Set()
      const nodeList = response.data.map((event, index) => {
        const { step, event_name, status, ...rest } = event

        // Generate a unique ID
        let uniqueId = step
        let counter = 1
        while (processedIds.has(uniqueId)) {
          uniqueId = `${step}_${counter}`
          counter++
        }

        processedIds.add(uniqueId)

        return {
          id: uniqueId,
          label: formatString(event_name),
          color: status === 'Success' ? '#018749' : '#FF6347',
          message: rest,
          status,
          originalStep: step
        }
      })

      const edgeList = response.data.slice(1).map((event, index) => ({
        from: response.data[index].step,
        to: event.step
      }))

      setNodes(nodeList)
      setEdges(edgeList)
    } catch (error) {
      console.error('Error fetching event logs:', error)
    }
  }
  console.log(nodes)
  useEffect(() => {
    if (id) {
      fetchEventLogs()
    }
  }, [id])

  useEffect(() => {
    if (nodes.length && edges.length) {
      const data = { nodes, edges }
      const network = new Network(networkContainer.current, data, options)

      // Add a click event listener to the network
      network.on('click', function (event) {
        const clickedNodeId = event.nodes[0]
        if (clickedNodeId) {
          const clickedNode = nodes.find((node) => node.id === clickedNodeId)
          if (clickedNode) {
            setSelectedNodeId(clickedNode.id)
            setSelectedNodeData(clickedNode)
            setSelectedNodeStatus(clickedNode.status)
          }
        }
      })
    }
  }, [nodes, edges])

  const handleRetrigger = async () => {
    if (!selectedNodeId) return

    try {
      await eventService.retriggerEvent(selectedNodeId)
      alert(`Event with ID ${selectedNodeId} retriggered successfully.`)
      // Optionally refresh the event logs after retriggering
      fetchEventLogs()
    } catch (error) {
      console.error(`Failed to retrigger event with ID ${selectedNodeId}:`, error)
      alert('Failed to retrigger event. Please try again.')
    }
  }

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div
        style={{
          height: '90%',
          width: '70%',
          border: '1px dotted',
          marginTop: 15
        }}
        ref={networkContainer}
      ></div>
      <div
        style={{
          width: '40%',
          borderRight: '1px solid #ccc',
          borderTop: '1px solid #ccc',
          marginTop: 15,
          padding: '10px'
        }}
      >
        <div>
          {selectedNodeData ? (
            <CustomTypography style={{ fontWeight: '500' }}>
              {`${selectedNodeData?.label} Data`}
            </CustomTypography>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            overflow: 'auto',
            height: '90%'
          }}
        >
          {selectedNodeData ? (
            <div>
              <ReactJson
                style={{ textAlign: 'left', display: 'inline-block', width: '100%' }}
                displayDataTypes={false}
                quotesOnKeys={false}
                displayObjectSize
                iconStyle="square"
                src={selectedNodeData.message}
                theme="bright"
                collapsed={2}
              />
              {selectedNodeStatus === 'Failed' && (
                <button
                  style={{
                    marginTop: '10px',
                    padding: '10px 15px',
                    backgroundColor: '#FF6347',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                  }}
                  onClick={handleRetrigger}
                >
                  Retrigger Event
                </button>
              )}
            </div>
          ) : (
            <CustomTypography style={{ fontWeight: '500' }}>
              Click on a node to see its details
            </CustomTypography>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderWorkFlow
