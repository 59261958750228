// api.js

import axiosInstance from "../axiosInstance";

export const loginService = {
  login: async (email, password) => {
    try {
      let response = await axiosInstance.post("/authentication", {
        email: email,
        password: password,
        strategy: "local",
      });
      return response.data; // Return the token
    } catch (error) {
      throw error; // Throw the error for handling in the calling component
    }
  },
};
export const signUpService = {
  signUp: async (name, email, password) => {
    try {
      let response = await axiosInstance.post("/users/create", {
        email: email,
        password: password,
        name: name,
      });
      return response.data; // Return the token
    } catch (error) {
      throw error; // Throw the error for handling in the calling component
    }
  },
};
export const removeSession = {
  logout: async (token) => {
    try {
      await axiosInstance.post("/remove-session", {
        token: token,
      });
      return "Session removed successfully"; // Return the token
    } catch (error) {
      throw error; // Throw the error for handling in the calling component
    }
  },
};

export const fileService = {
  saveData: async (data) => {
    try {
      const response = await axiosInstance.post("/upload", data);
      return response.data; // Return the token
    } catch (error) {
      throw error; // Throw the error for handling in the calling component
    }
  },
  download: async (key) => {
    const response = await axiosInstance.get(`/file/${key}`);
    return response;
  },
};

export const forgotPasswordService = {
  sendOTP: async (email, init = false) => {
    try {
      let response = await axiosInstance.post("/forgot-password", {
        email: email,
        init: init,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  validateOTP: async (email, otp) => {
    try {
      const response = await axiosInstance.patch(`/forgot-password/${email}`, {
        otp: otp,
        email: email,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createPassword: async (email, password) => {
    try {
      const response = await axiosInstance.post(`/change-password`, {
        email: email,
        newPassword: password,
        forgot: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
