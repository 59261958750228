// productService.js

import axiosInstance from "../utils/axios-instance";

export const bulkUploadConfigService = {
  fetchUploadStatus: async (id) => {
    try {
      let response = await axiosInstance.get(`/bulk-upload-status/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetchUploadResults: async (id, limit, skip) => {
    try {
      const response = await axiosInstance.get(
        `/bulk-upload-results?status_id=${id}&$limit=${limit}&$skip=${skip}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  patch: async (id, data) => {
    try {
      let response = await axiosInstance.patch(id, "/mapping-template", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateUploadResult: async (data) => {
    let response = await axiosInstance.post(`/update-verify-record`, data);
    return response.data;
  },

  startImport: async (data) => {
    let response = await axiosInstance.post("/start-import", data);
    return response.data;
  },
};
