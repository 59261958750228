import React from "react";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonIcon from "@mui/icons-material/Person";
import ProductIcon from "./globalStyles/icons/product-icon";
import OrderIcon from "./globalStyles/icons/order-icon";

export const getIcon = (iconName, fill) => {
  switch (iconName) {
    case "ProductIcon":
      return <ProductIcon fill={fill} />;
    case "OrderIcon":
      return <OrderIcon fill={fill} />;
    default:
      return null;
  }
};

export const sideMenuData = [
  // { name: "User", icon: <PersonIcon />, navigateTo: "user", role: ["admin"] },
  {
    name: "Products",
    icon: "ProductIcon",
    navigateTo: "products",
    role: ["admin", "client"],
  },
  {
    name: "Orders",
    icon: "OrderIcon",
    navigateTo: "orders",
    role: ["admin", "client"],
  },
  // { name: 'Cart', icon: <ShoppingCartIcon />, navigateTo: 'cart', role: ['admin', 'client'] }
];

export const formatKey = (key) => {
  const parts = key.split(".");

  const formattedParts = parts.map((part) => {
    return part
      .split("_")
      .map((word) => {
        // Custom handling for specific words
        if (word.toUpperCase() === "ID") return "ID";
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  });

  return formattedParts.join(" > ");
};
