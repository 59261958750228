import { SvgIcon } from '@mui/material'
import React, { useState } from 'react'

const OrderTrackingIcon = ({ fill }) => {
  return (
    <SvgIcon>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.7807 2.86451C12.8571 2.92466 12.9328 2.98572 13.0078 3.04762C13.0458 3.07879 13.0839 3.10996 13.1231 3.14207C14.0333 3.92954 14.5779 5.05651 14.6875 6.25074C14.7562 7.50289 14.4405 8.67531 13.6328 9.64918C13.6069 9.6805 13.5811 9.71182 13.5545 9.74409C13.1949 10.1604 12.772 10.5245 12.3623 10.8903C12.1809 11.0534 12.0023 11.2197 11.8233 11.3854C11.7161 11.4846 11.6088 11.5838 11.5014 11.6829C11.4804 11.7023 11.4593 11.7217 11.4377 11.7417C11.259 11.9066 11.0799 12.0708 10.9005 12.2349C10.8158 12.3126 10.7314 12.3905 10.6472 12.4687C10.6248 12.4895 10.6023 12.5103 10.5792 12.5318C10.5363 12.5716 10.4935 12.6114 10.4507 12.6513C10.3171 12.7752 10.1798 12.893 10.039 13.0086C9.68894 12.8469 9.41197 12.5103 9.1331 12.2491C8.92878 12.0579 8.7222 11.8692 8.51558 11.6804C8.43824 11.6097 8.36094 11.5389 8.28365 11.468C8.2644 11.4504 8.24516 11.4328 8.22533 11.4146C8.05944 11.2625 7.89409 11.1098 7.72878 10.9571C7.58685 10.826 7.44464 10.6953 7.3022 10.5647C7.28313 10.5472 7.26406 10.5297 7.24441 10.5116C7.14599 10.4213 7.0472 10.3315 6.94774 10.2423C6.02276 9.41054 5.40539 8.32948 5.31246 7.07106C5.24223 5.70533 5.64725 4.47577 6.56124 3.44954C8.13698 1.72076 10.9042 1.49803 12.7807 2.86451ZM8.50688 5.3462C8.15496 5.77315 7.98134 6.28464 8.00777 6.83668C8.02466 6.94129 8.04316 7.03965 8.0688 7.14186C8.07558 7.16887 8.08236 7.19588 8.08934 7.2237C8.15902 7.47827 8.27821 7.68123 8.43746 7.89137C8.46727 7.93326 8.49708 7.97516 8.52779 8.01832C8.8846 8.38343 9.37457 8.63253 9.88834 8.64498C10.4947 8.65013 10.9974 8.47032 11.4453 8.04762C11.821 7.59472 12.0077 7.11994 11.9921 6.52418C11.9248 5.92525 11.6529 5.46437 11.1947 5.07734C10.3531 4.44944 9.21878 4.58918 8.50688 5.3462Z"
          fill="#FD7E14"
        />
        <path
          d="M11.1722 14.3356C11.5056 14.5907 11.7911 14.8911 11.8753 15.3122C12.8421 15.3122 13.8089 15.3122 14.805 15.3122C14.8308 15.2477 14.8566 15.1833 14.8831 15.1169C15.1363 14.7101 15.4729 14.4753 15.9319 14.3425C16.3474 14.2547 16.8018 14.2953 17.1746 14.5074C17.4909 14.724 17.7233 14.9664 17.891 15.3122C17.9042 15.3384 17.9174 15.3647 17.9311 15.3917C18.0958 15.7856 18.0531 16.3057 17.9012 16.6959C17.8248 16.852 17.7332 16.9799 17.6175 17.1091C17.5979 17.1327 17.5783 17.1563 17.5582 17.1806C17.2699 17.5026 16.8552 17.6648 16.4318 17.7034C15.9351 17.7231 15.5292 17.5518 15.1526 17.2354C15.0009 17.092 14.8968 16.9448 14.805 16.7575C14.805 16.7317 14.805 16.7059 14.805 16.6794C13.8382 16.6794 12.8714 16.6794 11.8753 16.6794C11.8624 16.7438 11.8495 16.8083 11.8363 16.8747C11.5651 17.4005 11.1477 17.7798 10.5814 17.966C10.007 18.0988 9.42048 18.0275 8.9139 17.7243C8.55568 17.4784 8.31247 17.1913 8.12533 16.7966C8.12533 16.7579 8.12533 16.7192 8.12533 16.6794C7.15853 16.6794 6.19173 16.6794 5.19564 16.6794C5.16986 16.7567 5.14408 16.8341 5.11751 16.9137C4.83742 17.312 4.4577 17.5793 3.98089 17.6781C3.52884 17.7456 3.09623 17.665 2.71059 17.4133C2.32565 17.1243 2.10528 16.7956 1.99251 16.3278C1.93593 15.8231 2.01578 15.3843 2.31463 14.9657C2.65324 14.5546 3.03834 14.3569 3.56554 14.303C4.02668 14.2827 4.44979 14.436 4.81539 14.7159C4.97171 14.864 5.09944 15.0412 5.19564 15.2341C5.19564 15.2598 5.19564 15.2856 5.19564 15.3122C6.16244 15.3122 7.12923 15.3122 8.12533 15.3122C8.13822 15.2477 8.15111 15.1833 8.16439 15.1169C8.43945 14.5901 8.832 14.2468 9.39181 14.0529C9.99426 13.8749 10.6563 13.9748 11.1722 14.3356ZM9.48031 15.5929C9.3773 15.7646 9.31955 15.9365 9.34817 16.1383C9.41365 16.3493 9.54433 16.4813 9.72689 16.6012C9.92145 16.6661 10.0903 16.6637 10.2811 16.5964C10.4618 16.4759 10.5777 16.3455 10.6449 16.1398C10.6822 15.95 10.6413 15.7919 10.5472 15.6247C10.3888 15.4465 10.2515 15.3592 10.0101 15.339C9.78464 15.3424 9.64332 15.4387 9.48031 15.5929Z"
          fill="#FD7E14"
        />
      </svg>
    </SvgIcon>
  )
}

export default OrderTrackingIcon
