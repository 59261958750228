import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField, Button, Box } from '@mui/material'
import { productService } from '../../services/product-service'

const ProductSelector = ({ onProductSelect, selectedProduct }) => {
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const ITEMS_PER_PAGE = 10

  const fetchProducts = async (currentPage) => {
    try {
      setLoading(true)
      const response = await productService.fetch(ITEMS_PER_PAGE, ITEMS_PER_PAGE * currentPage)

      // If first page, replace products, otherwise append
      if (currentPage === 0) {
        setProducts(response.data)
      } else {
        setProducts((prev) => [...prev, ...response.data])
      }

      // Check if we have more items to load
      setHasMore(response.data.length === ITEMS_PER_PAGE)
    } catch (error) {
      console.error('Error fetching products:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProducts(0)
  }, [])

  const handleLoadMore = () => {
    const nextPage = page + 1
    setPage(nextPage)
    fetchProducts(nextPage)
  }

  return (
    <Box>
      <Autocomplete
        value={selectedProduct}
        onChange={(event, newValue) => {
          onProductSelect(newValue)
        }}
        isOptionEqualToValue={(option, value) => option._id === value?._id}
        getOptionLabel={(option) => option.name || ''}
        options={products}
        loading={loading}
        size="small"
        fullWidth
        renderInput={(params) => <TextField {...params} label="Select Product" variant="outlined" />}
        ListboxProps={{
          style: { maxHeight: '250px' },
          onScroll: (event) => {
            const listboxNode = event.currentTarget
            if (
              listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight &&
              hasMore &&
              !loading
            ) {
              handleLoadMore()
            }
          }
        }}
        renderOption={(props, option, state) => (
          <React.Fragment key={option._id}>
            <li {...props}>{option.name}</li>
            {state.index === products.length - 1 && hasMore && (
              <Button
                fullWidth
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleLoadMore()
                }}
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Load More'}
              </Button>
            )}
          </React.Fragment>
        )}
      />
    </Box>
  )
}

export default ProductSelector
