// imageService.js

import axiosInstance from '../utils/axios-instance'

export const imageService = {
  upload: async (imageData) => {
    try {
      const formData = new FormData()
      formData.append('file', imageData)

      const response = await axiosInstance.post('/file-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      return response.data
    } catch (error) {
      throw error
    }
  },

  fetch: async (image) => {
    try {
      return await axiosInstance.get(`/fetch-file/${image}`)
    } catch (error) {
      throw error
    }
  }
}
