import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  TableContainer,
  Tooltip,
  IconButton,
  Box,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CustomTypography from "../../globalStyles/fonts";
import { isMobile } from "../../globalStyles/mobile";
const SimulationDataTable = ({
  data,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  dataCount,
}) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const renderTooltipContent = (data) => (
    <div style={{ padding: 5 }}>
      <p style={{ fontSize: 15 }}>
        Carrier Name: {data?.ratecard?.carrier?.name}
      </p>
      <p style={{ fontSize: 15 }}>
        Service Name: {data.ratecard.service_type.name}
      </p>
      <p style={{ fontSize: 15 }}>Port Name: {data.port.name}</p>
      <p style={{ fontSize: 15 }}>Zone Name: {data.zone.name}</p>
      <p style={{ fontSize: 15 }}>Price: {data.ratecard.price}</p>
      <p style={{ fontSize: 15 }}>Service Day: {data.service_day}</p>
    </div>
  );

 const NoDataImage = () => (
   <div
     style={{
       width: "100%",
       justifyContent: "center",
       display: "flex",
     }}
   >
     <img
       src="no_data_rateCard.png"
       alt="No data available"
       style={{ objectFit: "fill", height: "300px" }}
     />
   </div>
 ); 

    const MobileCardView = ({ data }) => {
      return (
        <Box sx={{ width: "100%", padding: 0.5 }}>
          {data.length === 0 ? (
            <NoDataImage />
          ) : (
            data?.map((x, index) => (
              <Card key={index} sx={{ marginBottom: 2 }}>
                <CardContent>
                  <div style={{ width: "100%" }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>Zip Code: {x?.zipcode}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>Weight:{x?.weight}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>RL Price:{x?.rl_price}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>
                          Cheapest:{" "}
                          <Tooltip title={renderTooltipContent(x.cheapest)}>
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography>
                          Fastest:{" "}
                          <Tooltip title={renderTooltipContent(x.fastest)}>
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </CardContent>
                ;
              </Card>
            ))
          )}
        </Box>
      );
    };

  return (
    <div
      style={{
        height: "70vh",
        width: "100%",
        overflow: isMobile() ? "auto" : "hidden",
        paddingBottom: 100,
      }}
      className="table-container"
    >
      {isMobile() ? (
        <MobileCardView data={data} />
      ) : (
        <TableContainer
          sx={{
            maxHeight: "60vh",
            overflowY: "auto",
            paddingInline: 0.4,
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 5px",
              paddingBottom: 7,
            }}
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "#EEEEEE",
                  boxShadow: "0px 0px 4px 0px #00000040",
                  borderWidth: 1,
                  borderRadius: 8,
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEEEE",
                    textAlign: "center",
                    borderBottom: "none",
                    padding: 4,
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Zip Code
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEEEE",
                    padding: 5,
                    textAlign: "center",

                    borderBottom: "none",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Weight
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEEEE",
                    padding: 5,
                    textAlign: "center",

                    borderBottom: "none",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    RL Price
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEEEE",
                    padding: 5,
                    textAlign: "center",
                    borderBottom: "none",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Cheapest
                  </CustomTypography>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#EEEEEEEE",
                    padding: 5,
                    textAlign: "center",

                    borderBottom: "none",
                  }}
                >
                  <CustomTypography
                    fontSize="14px"
                    title={true}
                    color="#000"
                    fontWeight={600}
                  >
                    Fastest
                  </CustomTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  style={{
                    marginBottom: 10,
                    backgroundColor: "#fff",
                    borderRadius: 8,
                    boxShadow: "0px 0px 4px 0px #00000040",
                  }}
                  key={index}
                >
                  <TableCell
                    align="center"
                    style={{
                      borderBottom: "none",
                      borderRadius: "8px 0 0 8px",
                      cursor: "pointer",
                      textAlign: "center",
                      padding: 4,
                    }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {row.zipcode}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 4 }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {row?.weight}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 4 }}
                  >
                    <CustomTypography
                      fontSize="13px"
                      color="#333333"
                      fontWeight={400}
                    >
                      {row?.rl_price}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 4 }}
                  >
                    <Tooltip title={renderTooltipContent(row.cheapest)}>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderBottom: "none", padding: 4 }}
                  >
                    <Tooltip title={renderTooltipContent(row.fastest)}>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        slotProps={{
          actions: {
            nextButton: {
              disabled: dataCount < rowsPerPage ? true : false,
            },
          },
        }}
        nextIconButton={
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        }
        backIconButton={
          <IconButton>
            <KeyboardArrowLeft />
          </IconButton>
        }
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff",
          padding: "2px",
          borderTop: "1px solid #dddddd",
        }}
      />
    </div>
  );
};

export default SimulationDataTable;
