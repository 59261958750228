import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  TablePagination,
  Button,
  IconButton,
  Input,
  Modal,
} from "@mui/material";
import { orderService } from "../../services/order-service";
import { bulkUploadConfigService } from "../../services/bulk-upload-config-service";
import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";

const ImportResult = ({ bulkUploadConfig, onFinish, type }) => {
  const [uploadConfigData, setUploadConfigData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [bulkUploadResults, setBulkUploadResults] = useState([]);
  const [bulkUploadStatus, setBulkUploadStatus] = useState({});
  const [open, setOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  useEffect(() => {
    if (bulkUploadConfig && bulkUploadConfig._id) {
      fetchUploadConfig();
      fetchStatus();
      // if (
      //   bulkUploadStatus.total_records !=
      //   bulkUploadStatus.processed
      // ) {
      // } else {
      //   fetchStatusAndResults();
      // }
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [bulkUploadConfig]);
  let interval;

  const fetchStatusAndResultsWithInterval = async () => {
    interval = setInterval(() => {
      fetchStatus();
    }, 5000);
  };

  const fetchStatusAndResults = async () => {
    fetchStatus();
  };

  const fetchStatus = async () => {
    let response = await bulkUploadConfigService.fetchUploadStatus(
      bulkUploadConfig.upload_status
    );
    fetchResults(response);
    setBulkUploadStatus(response);
  };

  const fetchResults = async (status) => {
    let response = await bulkUploadConfigService.fetchUploadResults(
      bulkUploadConfig.upload_status,
      rowsPerPage,
      page * rowsPerPage
    );
    if (status?.total_records == response?.total) {
      console.log("End");
      if (interval) clearInterval(interval);
    }
    setBulkUploadResults(response.data);
  };

  const fetchUploadConfig = async () => {
    try {
      const response = await orderService.findConfigById(bulkUploadConfig._id);
      setUploadConfigData(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    maxHeight: "80vh",
  };

  const onClose = () => {
    setOpen(false);
    setSelectedOrder({});
  };

  const updateOrder = async () => {
    let response = await bulkUploadConfigService.updateUploadResult({
      _id: selectedOrder._id,
      item: selectedOrder.item,
      mapped_column: uploadConfigData.mapped_column,
      type: type,
    });
    console.log(response);
    setBulkUploadResults(
      bulkUploadResults.map((x) => (x._id == response._id ? response : x))
    );
    setTimeout(() => {
      fetchStatus();
    }, 1000);
    onClose();
  };

  const onProceed = async () => {
    let response = await bulkUploadConfigService.startImport({
      mapped_column: uploadConfigData.mapped_column,
      upload_status: uploadConfigData.upload_status,
      user_id: uploadConfigData.user_id,
      type: uploadConfigData.type,
    });
    console.log(response);
    toast.success(
      `Import started in the background. ${response.count}/${bulkUploadStatus.processed} records will be uploaded.`,
      { autoClose: 3000 }
    );
    onFinish();
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 2,
          }}
        >
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#DC585C",
              marginRight: 3,
            }}
            onClick={() => onProceed()}
          >
            <Typography
              fontFamily={"poppins"}
              fontSize={"14px"}
              fontWeight={500}
              color="#fff"
            >
              Proceed and Import
            </Typography>
          </Button>
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "#DC585C",
            }}
            onClick={() => fetchStatus()}
          >
            <Typography
              fontFamily={"poppins"}
              fontSize={"14px"}
              fontWeight={500}
              color="#fff"
            >
              Refresh
            </Typography>
          </Button>
        </Box>
        <TableContainer
          sx={{
            maxHeight: "80vh",
            overflowY: "auto",
            overflowX: "auto",
          }}
          component={Paper}
        >
          <Table stickyHeader sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {bulkUploadConfig.file_column.map((x) => (
                  <TableCell sx={{ fontWeight: "600", fontSize: 12 }}>
                    {x}
                  </TableCell>
                ))}
                <TableCell sx={{ fontWeight: "600", fontSize: 12 }}>
                  Status
                </TableCell>
                <TableCell sx={{ fontWeight: "600", fontSize: 12 }}>
                  Message
                </TableCell>
                <TableCell sx={{ fontWeight: "600", fontSize: 12 }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(bulkUploadResults || []).map((order, index) => (
                <TableRow key={order._id} style={{}}>
                  {bulkUploadConfig.file_column.map((x) => (
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: order.error ? "#a0452f" : "#27ab86",
                        }}
                      >
                        {order.item[x]}
                      </Typography>
                    </TableCell>
                  ))}
                  <TableCell>
                    {order.error ? (
                      <Typography sx={{ color: "#a0452f", fontSize: 12 }}>
                        Failed
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "#27ab86", fontSize: 12 }}>
                        Success
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: order.error ? "#a0452f" : "#27ab86",
                      }}
                    >
                      {order.message}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ fontWeight: "600", fontSize: 12 }}>
                    <IconButton
                      onClick={() => {
                        setSelectedOrder(order);
                        setOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "#ffffff",
              padding: "2px",
              borderTop: "1px solid #dddddd",
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={bulkUploadStatus.processed}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            align="center"
            variant="h5"
            component="h2"
          >
            Edit Order Details
          </Typography>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "16px",
              marginTop: 10,
            }}
          >
            {bulkUploadConfig.file_column.map(
              (x, index) =>
                index % 2 === 0 && (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginRight: 15,
                      }}
                    >
                      <Typography
                        style={{
                          width: "200px",
                          textAlign: "left",
                          color: (selectedOrder.columns || []).includes(x)
                            ? "red"
                            : "inherit",
                        }}
                      >
                        {x}
                      </Typography>
                      <Input
                        style={{
                          flex: "1",
                          border: (selectedOrder.columns || []).includes(x)
                            ? "1px solid red"
                            : "0px solid #ccc",
                          color: (selectedOrder.columns || []).includes(x)
                            ? "red"
                            : "black",
                        }}
                        value={selectedOrder?.item?.[x]}
                        onChange={(e) =>
                          setSelectedOrder({
                            ...selectedOrder,
                            item: {
                              ...selectedOrder.item,
                              [x]: e.target.value,
                            },
                          })
                        }
                      />
                    </div>

                    {bulkUploadConfig.file_column[index + 1] && (
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "200px",
                            textAlign: "left",
                            color: (selectedOrder.columns || []).includes(
                              bulkUploadConfig.file_column[index + 1]
                            )
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {bulkUploadConfig.file_column[index + 1]}
                        </Typography>
                        <Input
                          style={{
                            flex: "1",
                            border: (selectedOrder.columns || []).includes(
                              bulkUploadConfig.file_column[index + 1]
                            )
                              ? "1px solid red"
                              : "0px solid #ccc",
                            color: (selectedOrder.columns || []).includes(
                              bulkUploadConfig.file_column[index + 1]
                            )
                              ? "red"
                              : "black",
                          }}
                          value={
                            selectedOrder?.item?.[
                              bulkUploadConfig.file_column[index + 1]
                            ]
                          }
                          onChange={(e) =>
                            setSelectedOrder({
                              ...selectedOrder,
                              item: {
                                ...selectedOrder.item,
                                [bulkUploadConfig.file_column[index + 1]]:
                                  e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                )
            )}
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 2,
            }}
          >
            <Button
              sx={{
                textTransform: "none",
                backgroundColor: "#fefeee",
                marginRight: 3,
              }}
              onClick={onClose}
            >
              <Typography
                fontFamily={"poppins"}
                fontSize={"14px"}
                fontWeight={500}
                color="#000"
              >
                Close
              </Typography>
            </Button>
            <Button
              sx={{
                textTransform: "none",
                backgroundColor: "#DC585C",
              }}
              onClick={() => updateOrder()}
            >
              <Typography
                fontFamily={"poppins"}
                fontSize={"14px"}
                fontWeight={500}
                color="#fff"
              >
                Update
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ImportResult;
