import React, { useEffect, useState } from 'react'
import DashboardContainer from '../../components/dashboard-container'
import ZipZoneDataTable from '../../components/zip-zone-data-table'
import ZipZoneModal from '../../components/zip-zone-fields'
import { zipZoneService } from '../../utils/services/zipZoneService'
import ZipZoneFileUploadModal from '../../components/file-upload-modal'
import UploadStatusModal from '../../components/upload-status-cards'
import { Box, CircularProgress } from '@mui/material'

export default function ZipZone() {
  const [zipZoneData, setZipZoneData] = useState([])
  const [selectedZipZoneData, setSelectedZipZoneData] = useState(null)
  const [selectedPort, setSelectedPort] = useState(null)
  const [selectedZone, setSelectedZone] = useState(null)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [uploadStatus, setUploadStatus] = useState([])
  const [showUploadStatusModal, setShowUploadStatusModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataCount, setDataCount] = useState(rowsPerPage)
  const clientId = localStorage.getItem('client_id')
  const [searchValue, setSearchValue] = useState('')
  const [clientFilter, setClientFilter] = useState(null)

  const fetchUploadStatus = async () => {
    setLoading(true)
    try {
      const response = await zipZoneService.bulkUploadStatus(clientId)
      setUploadStatus(response.data)
    } catch (error) {
      console.error('Delete failed:', error)
    } finally {
      setLoading(false)
    }
  }
  const fetchZipZoneData = async () => {
    setLoading(true)
    try {
      const skip = page * rowsPerPage
      const response = await zipZoneService.fetch(
        rowsPerPage,
        skip,
        selectedPort,
        selectedZone,
        clientId ? clientId : clientFilter?._id,
        searchValue
      )
      // setTotal(response.total);

      setDataCount(response.total)
      setZipZoneData(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Fetch error:', error)
    }
  }
  useEffect(() => {
    fetchUploadStatus()
  }, [])
  useEffect(() => {
    fetchZipZoneData()
  }, [, selectedPort, selectedZone, clientFilter, page, rowsPerPage, searchValue])
  const deleteStaus = async (id) => {
    setLoading(true)
    try {
      fetchUploadStatus()
    } catch (error) {
      console.error('Delete failed:', error)
      setLoading(false)
    }
  }
  return (
    <DashboardContainer
      hideFilterClient
      onSearchData={(x) => {
        setSearchValue(x)
        setPage(0)
      }}
      searchBarPlaceholder={'Search by destination ...'}
      zipZone
      onHandleUploadButton={() => setOpenUploadModal(true)}
      onClickAddButton={() => {
        setSelectedZipZoneData(null)
        setOpenModal(true)
      }}
      title={'+ Add Zip Zone'}
      onSelectClient={(e) => {
        setClientFilter(e)
      }}
      // selectedClient={selectedClient?.name}
      header={'Zip Zone List'}
    >
      <ZipZoneModal
        selectedZipZoneData={selectedZipZoneData}
        onAddSuccess={fetchZipZoneData}
        handleClose={() => {
          setOpenModal(false)
          setSelectedZipZoneData(null)
        }}
        open={openModal}
      />
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <div style={{ height: '100%', overflow: 'auto' }}>
        <ZipZoneDataTable
          dataCount={dataCount}
          onClickUploadStatusButton={() => {
            setShowUploadStatusModal(true)
            fetchUploadStatus()
          }}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          onFilterZone={(e) => setSelectedZone(e)}
          onFilterPort={(e) => setSelectedPort(e)}
          onEditData={(e) => {
            setSelectedZipZoneData(e)
            setOpenModal(true)
          }}
          onDeleteZipZoneData={fetchZipZoneData}
          data={zipZoneData}
          onFilterClient={(e) => setClientFilter(e)}
          deleteTitle={'Delete Zip Zone'}
          // onFilterCarrier={(e) => setSelectedCarrier(e)}
        />
      </div>
      <ZipZoneFileUploadModal
        onSubmitUploadData={() => setOpenUploadModal(false)}
        onClose={() => setOpenUploadModal(false)}
        open={openUploadModal}
        title={'Zip Zone'}
      ></ZipZoneFileUploadModal>

      <UploadStatusModal
        onDeleteStatus={(id) => deleteStaus(id)}
        handleClose={() => {
          setShowUploadStatusModal(false)
          setSelectedZipZoneData(null)
        }}
        data={uploadStatus}
        open={showUploadStatusModal}
      ></UploadStatusModal>
    </DashboardContainer>
  )
}
