import React from "react";
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog,
  Card,
  CardContent,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../globalStyles/fonts";

const ShippingPriceDetailsModal = ({ shippingPrice, open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>{"Shipping Options Details"}</DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: "10px" }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Type
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={shippingPrice?.type || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ display: "flex" }}>
                <CustomTypography
                  color="#11193F"
                  fontSize="14px"
                  fontWeight={400}
                  style={{ marginTop: 1 }}
                >
                  Price
                </CustomTypography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              {shippingPrice?.pricing?.map((price, index) => (
                <Card
                  key={index}
                  style={{ marginBottom: 16, backgroundColor: "#EEEEEE" }}
                >
                  <CardContent>
                    <Grid
                      container
                      spacing={2}
                      gap={3}
                      justifyContent={"center"}
                    >
                      <Grid item xs={12} sm={2}>
                        <CustomTypography
                          color="#11193F"
                          fontSize="14px"
                          fontWeight={400}
                        >
                          Min Weight
                        </CustomTypography>
                        <TextField
                          size="small"
                          autoFocus
                          margin="dense"
                          variant="outlined"
                          type="text"
                          fullWidth
                          value={price?.min_weight || ""}
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: "#000",
                              backgroundColor: "#f0f0f0",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <CustomTypography
                          color="#11193F"
                          fontSize="14px"
                          fontWeight={400}
                        >
                          Max Weight
                        </CustomTypography>
                        <TextField
                          size="small"
                          autoFocus
                          margin="dense"
                          variant="outlined"
                          type="text"
                          fullWidth
                          value={price?.max_weight || ""}
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: "#000",
                              backgroundColor: "#f0f0f0",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <CustomTypography
                          color="#11193F"
                          fontSize="14px"
                          fontWeight={400}
                        >
                          Min Days
                        </CustomTypography>
                        <TextField
                          size="small"
                          autoFocus
                          margin="dense"
                          variant="outlined"
                          type="text"
                          fullWidth
                          value={price?.min_days || ""}
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: "#000",
                              backgroundColor: "#f0f0f0",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <CustomTypography
                          color="#11193F"
                          fontSize="14px"
                          fontWeight={400}
                        >
                          Max Days
                        </CustomTypography>
                        <TextField
                          size="small"
                          autoFocus
                          margin="dense"
                          variant="outlined"
                          type="text"
                          fullWidth
                          value={price?.max_days || ""}
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: "#000",
                              backgroundColor: "#f0f0f0",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <CustomTypography
                          color="#11193F"
                          fontSize="14px"
                          fontWeight={400}
                        >
                          Price
                        </CustomTypography>
                        <TextField
                          size="small"
                          autoFocus
                          margin="dense"
                          variant="outlined"
                          type="text"
                          fullWidth
                          value={price?.price || ""}
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: "#000",
                              backgroundColor: "#f0f0f0",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Grid>
            <Grid item xs={12} sm={12}>
              <CustomTypography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
              >
                Client
              </CustomTypography>
              <TextField
                type="text"
                size="small"
                margin="dense"
                placeholder="Client Name"
                value={
                  shippingPrice?.client_id?.business_name ||
                  shippingPrice?.client_id?.name
                }
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ShippingPriceDetailsModal;
