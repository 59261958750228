import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Typography } from '@mui/material'

const ProductDetails = () => {
  const { id } = useParams()
  // Replace with actual API fetch logic
  const product = { name: `Product ${id}`, description: 'Detailed description here.' }

  return (
    <Container>
      <Typography variant="h4">{product.name}</Typography>
      <Typography>{product.description}</Typography>
    </Container>
  )
}

export default ProductDetails
