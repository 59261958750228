import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Button,
  IconButton,
  Grid,
  Paper,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem
} from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import { omsService } from '../../utils/services/omsProvider'
import { tmsService } from '../../utils/services/tmsService'
import { wmsService } from '../../utils/services/wmsProvider'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { toast } from 'react-toastify'
const OrderManagementForm = ({
  tms,
  wms,
  selectedClient,
  handleClose,
  selectedData,
  onAddProviderData,
  onDeleteSuccess,
  setLoading,
  loading
}) => {
  const InitialData = wms
    ? [
        {
          provider: '',
          address: '',
          country: '',
          priority: '',
          consignor_name: '',
          email: '',
          contact_number: '',
          address1: '',
          address2: '',
          city: '',
          province: '',
          postal: '',
          endpoint: '',
          authDetails: [{ key: '', value: '' }]
        }
      ]
    : tms
    ? [
        {
          provider: '',
          endpoint: '',
          authDetails: [{ key: '', value: '' }]
        }
      ]
    : [{ provider: '', shop_name: '' }]
  const [providers, setProviders] = useState(InitialData)

  const handleAddProvider = () => {
    setProviders((prevProviders) => [
      ...prevProviders,
      wms
        ? {
            provider: '',
            address: '',
            country: '',
            priority: '',
            consignor_name: '',
            email: '',
            contact_number: '',
            address1: '',
            address2: '',
            city: '',
            province: '',
            postal: '',
            endpoint: '',
            authDetails: [{ key: '', value: '' }]
          }
        : tms
        ? {
            provider: '',
            endpoint: '',
            authDetails: [{ key: '', value: '' }]
          }
        : { provider: '', shop_name: '' }
    ])
  }

  const handleRemoveProvider = async (index, provider) => {
    setLoading(true)
    if (selectedData?.length) {
      if (wms) {
        try {
          const wmsResponse = await wmsService.delete(provider?._id)
          if (wmsResponse) {
            onDeleteSuccess && onDeleteSuccess()
            setProviders((prevProviders) => prevProviders.filter((_, i) => i !== index))
            setLoading(false)
            handleClose && handleClose()
          }
        } catch (error) {
          console.error('Delete failed:', error)
          handleClose && handleClose()
          throw error
        }
      } else if (tms) {
        try {
          const tmsResponse = await tmsService.delete(provider?._id)
          if (tmsResponse) {
            onDeleteSuccess && onDeleteSuccess()
            setProviders((prevProviders) => prevProviders.filter((_, i) => i !== index))
            setLoading(false)
            handleClose && handleClose()
          }
        } catch (error) {
          console.error('Delete failed:', error)
          handleClose && handleClose()
          throw error
        }
      } else {
        try {
          const omsResponse = await omsService.delete(provider?._id)
          if (omsResponse) {
            onDeleteSuccess && onDeleteSuccess()
            setProviders((prevProviders) => prevProviders.filter((_, i) => i !== index))
            setLoading(false)
            handleClose && handleClose()
          }
        } catch (error) {
          console.error('Delete failed:', error)
          handleClose && handleClose()
          throw error
        }
        setLoading(false)
      }
    } else {
      setProviders((prevProviders) => prevProviders.filter((_, i) => i !== index))
      setLoading(false)
    }
  }

  const handleChange = (index, field, value) => {
    const newProviders = [...providers]
    newProviders[index][field] = value
    setProviders(newProviders)
  }

  const addNewAuthDetails = (e, index) => {
    e.preventDefault()
    const newProviders = [...providers]
    newProviders[index].authDetails = [...(newProviders[index].authDetails || []), { key: '', value: '' }]
    setProviders(newProviders)
  }
  const manageAuthDetails = (index, authIndex, field, value) => {
    const newProviders = [...providers]
    newProviders[index].authDetails = newProviders[index].authDetails.map((x, i) =>
      i == authIndex ? { ...x, [field]: value } : x
    )
    setProviders(newProviders)
  }
  function isValidConfiguration(config) {
    const requiredFields = providers?.find((x) => x.provider === 'evcargo')
      ? [
          'provider',
          'address',
          'country',
          'priority',
          'consignor_name',
          'email',
          'contact_number',
          'address1',
          'city',
          'province',
          'postal',
          'endpoint',
          'authDetails'
        ]
      : [
          'provider',
          'address',
          'country',
          'priority',
          'consignor_name',
          'email',
          'contact_number',
          'address1',
          'city',
          'province',
          'postal',
          'endpoint',
          'authDetails'
        ]

    return requiredFields.every((field) => config[field])
  }
  useEffect(() => {
    setProviders(selectedData)
  }, [selectedData])

  async function addConfig(configurations) {
    for (const config of configurations) {
      if (wms ? (selectedData?.length ? true : isValidConfiguration(config)) : config.provider) {
        if (tms) {
          if (selectedData?.length) {
            try {
              // Assuming you have an API endpoint to add configurations
              const response = await tmsService.edit(
                {
                  provider: config?.provider,
                  endpoint: config?.endpoint,
                  authDetails: config.authDetails
                },
                config?._id
              )
              console.log(`Successfully added configuration for ${config.provider}`)
              console.log(response.data)
              handleClose && handleClose() // Optionally, log the response data
            } catch (error) {
              console.error(`Error adding configuration for ${config.provider}:`, error.message)
            }
          } else {
            try {
              // Assuming you have an API endpoint to add configurations
              const response = await tmsService.create(config)
              console.log(`Successfully added configuration for ${config.provider}`)
              console.log(response.data) // Optionally, log the response data
            } catch (error) {
              console.error(`Error adding configuration for ${config.provider}:`, error.message)
            }
          }
        } else if (wms) {
          if (selectedData?.length) {
            try {
              // Assuming you have an API endpoint to add configurations
              const response = await wmsService.edit(
                {
                  provider: config.provider,
                  address: config.address,
                  country: config.country,
                  priority: config.priority,
                  consignor_name: config.consignor_name,
                  email: config.email,
                  contact_number: config.contact_number,
                  address1: config.address1,
                  address2: config.address2,
                  city: config.city,
                  province: config.province,
                  postal: config.postal,
                  endpoint: config.endpoint,
                  authDetails: config.authDetails
                },
                config?._id
              )
              console.log(`Successfully added configuration for ${config.provider}`)
              console.log(response.data)
              handleClose && handleClose()
              // Optionally, log the response data
            } catch (error) {
              console.error(`Error adding configuration for ${config.provider}:`, error.message)
            }
          } else {
            try {
              // Assuming you have an API endpoint to add configurations
              const response = await wmsService.create(config)
              console.log(`Successfully added configuration for ${config.provider}`)
              console.log(response.data)
              handleClose && handleClose() // Optionally, log the response data
            } catch (error) {
              console.error(`Error adding configuration for ${config.provider}:`, error.message)
            }
          }
        } else {
          if (selectedData?.length) {
            try {
              // Assuming you have an API endpoint to add configurations
              const response = await omsService.edit(
                {
                  provider: config?.provider,
                  shop_name: config?.shop_name
                },
                config?._id
              )
              console.log(`Successfully added configuration for ${config.provider}`)
              console.log(response.data)
              handleClose && handleClose() // Optionally, log the response data
            } catch (error) {
              console.error(`Error adding configuration for ${config.provider}:`, error.message)
            }
          } else {
            try {
              // Assuming you have an API endpoint to add configurations
              const response = await omsService.create(config)
              console.log(`Successfully added configuration for ${config.provider}`)
              console.log(response.data)
              handleClose && handleClose() // Optionally, log the response data
            } catch (error) {
              console.error(`Error adding configuration for ${config.provider}:`, error.message)
            }
          }
        }
        setProviders(InitialData)
        onAddProviderData && onAddProviderData()
      } else {
        console.warn('Skipping configuration:', config, 'Incomplete fields')
        toast.error('Please provide all required Information!', {
          autoClose: 3000
        })
        return
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    // Handle form submission
    addConfig(providers.map((item) => ({ ...item, client_id: selectedClient?._id })))
    // Close the modal after submission
  }
  const CustomTextField = ({ label, value, onChange, readOnly = false, placeholder }) => (
    <FormControl fullWidth variant="outlined">
      <CustomTypography fontSize={12}>{label}</CustomTypography>
      <OutlinedInput
        size="small"
        sx={{ marginTop: 1, backgroundColor: '#EEEEEEEE', fontSize: 12 }}
        placeholder={placeholder || label}
        id={label}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        notched
      />
    </FormControl>
  )
  const removeAuthDetail = (providerIndex, detailIndex) => {
    setProviders((prevState) => {
      const newState = [...prevState]
      if (newState[providerIndex]?.authDetails) {
        newState[providerIndex].authDetails = newState[providerIndex].authDetails.filter(
          (_, i) => i !== detailIndex
        )
      }
      return newState
    })
  }
  const [contactCode, contactNumber] = (() => {
    const parts = (selectedClient?.contact || '').split('-')
    return parts.length > 1 ? [parts[0], parts[1]] : ['', parts[0]]
  })()
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CustomTextField readOnly value={selectedClient?.name || ''} label="Client Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField value={selectedClient?.email || ''} readOnly label="Email" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <CustomTextField value={contactCode || ''} readOnly label="Country Code" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomTextField value={contactNumber || ''} readOnly label="Mobile Number" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField value={selectedClient?.business_name || ''} readOnly label="Business Name" />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <CustomTextField value={selectedClient?.address1 || ''} readOnly label="Address 1" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField value={selectedClient?.address2 || ''} readOnly label="Address 2" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField value={selectedClient?.zipcode || ''} readOnly label="Zip Code" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField value={selectedClient?.city || ''} readOnly label="City" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField value={selectedClient?.state || ''} readOnly label="State" />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <CustomTextField value={selectedClient?.country || ''} readOnly label="Country" />
        </Grid>
      </Grid>

      <div
        style={{
          marginTop: '16px',
          width: '100%',
          justifyContent: 'flex-end',
          display: 'flex'
        }}
      >
        {selectedData?.length ? (
          <></>
        ) : (
          <Button style={{ backgroundColor: '#007DFF' }} variant="contained" onClick={handleAddProvider}>
            <CustomTypography fontSize={12}>{`+ Add Provider`}</CustomTypography>
          </Button>
        )}
      </div>
      {providers.map((provider, index) => (
        <Paper
          key={index}
          variant="outlined"
          style={{
            padding: '16px',
            marginTop: '16px',
            background: '#EEEEEE',
            borderRadius: '4px'
          }}
        >
          {wms ? (
            <Grid container spacing={2}>
              {wms && (
                <Grid item xs={12} sm={6}>
                  <CustomTypography fontWeight={500}>{`Provider ${index + 1}`}</CustomTypography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <IconButton
                  style={{
                    color: '#D13438',
                    padding: 0,
                    background: '#D1343833',
                    height: '30px',
                    width: '30px',
                    borderRadius: '5px',
                    marginLeft: '10px',
                    boxShadow: '0px 0px 4px 0px #00000040',
                    marginTop: '5px'
                  }}
                  aria-label="delete"
                  onClick={() => handleRemoveProvider(index, provider)}
                >
                  <img style={{ height: '15px', width: '15px' }} alt="delete" src="/delete.png"></img>
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Provider <span style={{ color: 'red' }}>*</span>
                  </label>
                  <select
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    value={provider.provider}
                    onChange={(e) => handleChange(index, 'provider', e.target.value)}
                  >
                    <option value="" disabled>
                      Select a provider
                    </option>
                    <option value="rl">rl</option>
                    <option value="evcargo">evcargo</option>
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Address <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Address"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.address}
                    onChange={(e) => handleChange(index, 'address', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Country <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Country"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.country}
                    onChange={(e) => handleChange(index, 'country', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Priority <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Priority"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="number"
                    value={provider.priority}
                    onChange={(e) => handleChange(index, 'priority', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Consignor Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Consignor Name"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.consignor_name}
                    onChange={(e) => handleChange(index, 'consignor_name', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Email <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Email"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.email}
                    onChange={(e) => handleChange(index, 'email', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Contact Number <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Contact Number"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.contact_number}
                    onChange={(e) => handleChange(index, 'contact_number', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Address1 <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Consignor Address1"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.address1}
                    onChange={(e) => handleChange(index, 'address1', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Address2 <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Consignor Address2"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.address2}
                    onChange={(e) => handleChange(index, 'address2', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    City <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="City"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.city}
                    onChange={(e) => handleChange(index, 'city', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Province <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Province"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.province}
                    onChange={(e) => handleChange(index, 'province', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Postal <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Postal"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.postal}
                    onChange={(e) => handleChange(index, 'postal', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Endpoint <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Endpoint"
                    style={{
                      border: '1px solid #D1D1D1',
                      borderRadius: 5,
                      padding: 5,
                      marginTop: 5,
                      fontSize: 13
                    }}
                    type="text"
                    value={provider.endpoint}
                    onChange={(e) => handleChange(index, 'endpoint', e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                  Authentication Details
                  <button
                    style={{
                      backgroundColor: '#fff',
                      width: 30,
                      height: 30,
                      borderRadius: 14,
                      marginLeft: 10
                    }}
                    onClick={(e) => addNewAuthDetails(e, index)}
                  >
                    <AddIcon color="primary" />
                  </button>
                </label>

                {provider.authDetails?.map((x, i) => (
                  <Grid container spacing={2} key={i} style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={6}>
                      <label style={{ fontFamily: 'Poppins', fontSize: 12 }}>
                        Key <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        placeholder="Key"
                        style={{
                          border: '1px solid #D1D1D1',
                          borderRadius: 5,
                          padding: 5,
                          marginTop: 5,
                          fontSize: 13,
                          width: '100%'
                        }}
                        type="text"
                        value={x.key}
                        onChange={(e) => manageAuthDetails(index, i, 'key', e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label style={{ fontFamily: 'Poppins', fontSize: 12 }}>
                        Value <span style={{ color: 'red' }}>*</span>
                      </label>
                      <div style={{ display: 'flex', width: '100%' }}>
                        <input
                          placeholder="Value"
                          style={{
                            border: '1px solid #D1D1D1',
                            borderRadius: 5,
                            padding: 5,
                            marginTop: 5,
                            fontSize: 13,
                            width: '100%'
                          }}
                          type="text"
                          value={x.value}
                          onChange={(e) => manageAuthDetails(index, i, 'value', e.target.value)}
                        />
                        {provider.authDetails.length > 1 && (
                          <IconButton
                            style={{
                              color: '#D13438',
                              padding: 0,
                              background: '#D1343833',
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              marginLeft: 10,
                              boxShadow: '0px 0px 4px 0px #00000040',
                              marginTop: 5
                            }}
                            aria-label="delete"
                            onClick={() => removeAuthDetail(index, i)}
                          >
                            <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png" />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <IconButton
                  style={{
                    color: '#D13438',
                    padding: 0,
                    background: '#D1343833',
                    height: '30px',
                    width: '30px',
                    borderRadius: '5px',
                    marginLeft: '10px',
                    boxShadow: '0px 0px 4px 0px #00000040'
                  }}
                  aria-label="delete"
                  onClick={() => handleRemoveProvider(index, provider)}
                >
                  <img style={{ height: '15px', width: '15px' }} alt="delete" src="/delete.png" />
                </IconButton>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                    Select Provider <span style={{ color: 'red' }}>*</span>
                  </label>

                  <Select
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      backgroundColor: 'white',
                      height: 33
                    }}
                    placeholder="Select Provider"
                    size="small"
                    type="text"
                    value={provider.provider}
                    onChange={(e) => handleChange(index, 'provider', e.target.value)}
                  >
                    {tms ? (
                      <MenuItem value="Boxc" sx={{ height: 20 }}>
                        Boxc
                      </MenuItem>
                    ) : (
                      <MenuItem value="Shopify" sx={{ height: 20 }}>
                        Shopify
                      </MenuItem>
                    )}
                  </Select>
                </div>
              </Grid>
              {!tms && (
                <Grid item xs={12} sm={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                      Shop Name <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                      placeholder="Enter Shop Name"
                      style={{
                        border: '1px solid #D1D1D1',
                        borderRadius: 5,
                        padding: 5,
                        marginTop: 5,
                        fontSize: 13
                      }}
                      type="text"
                      value={provider.shop_name}
                      size="small"
                      onChange={(e) => handleChange(index, 'shop_name', e.target.value)}
                    />
                  </div>
                </Grid>
              )}
              {tms && (
                <>
                  {/* <Grid item xs={12} sm={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                       Endpoint
                      </label>
                      <input
                        placeholder="API Key"
                        style={{
                          border: "1px solid #D1D1D1",
                          borderRadius: 5,
                          padding: 5,
                          marginTop: 5,
                          fontSize: 13,
                        }}
                        type="text"
                        value={provider.api_key}
                        onChange={(e) =>
                          handleChange(index, "api_key", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label style={{ fontFamily: "Poppins", fontSize: 13 }}>
                        Secret Key
                      </label>
                      <input
                        placeholder="Secret Key"
                        style={{
                          border: "1px solid #D1D1D1",
                          borderRadius: 5,
                          padding: 5,
                          marginTop: 5,
                          fontSize: 13,
                        }}
                        type="text"
                        value={provider.secret_key}
                        onChange={(e) =>
                          handleChange(index, "secret_key", e.target.value)
                        }
                      />
                    </div>
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                        Endpoint <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        placeholder="Endpoint"
                        style={{
                          border: '1px solid #D1D1D1',
                          borderRadius: 5,
                          padding: 5,
                          marginTop: 5,
                          fontSize: 13
                        }}
                        type="text"
                        value={provider.endpoint}
                        onChange={(e) => handleChange(index, 'endpoint', e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <label style={{ fontFamily: 'Poppins', fontSize: 13 }}>
                      Authentication Details
                      <button
                        style={{
                          backgroundColor: '#fff',
                          width: 30,
                          height: 30,
                          borderRadius: 14,
                          marginLeft: 10
                        }}
                        onClick={(e) => addNewAuthDetails(e, index)}
                      >
                        <AddIcon color="primary" />
                      </button>
                    </label>

                    {provider.authDetails?.map((x, i) => (
                      <Grid container spacing={2} key={i} style={{ marginTop: 10 }}>
                        <Grid item xs={12} sm={6}>
                          <label style={{ fontFamily: 'Poppins', fontSize: 12 }}>
                            Key <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            placeholder="Key"
                            style={{
                              border: '1px solid #D1D1D1',
                              borderRadius: 5,
                              padding: 5,
                              marginTop: 5,
                              fontSize: 13,
                              width: '100%'
                            }}
                            type="text"
                            value={x.key}
                            onChange={(e) => manageAuthDetails(index, i, 'key', e.target.value)}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <label style={{ fontFamily: 'Poppins', fontSize: 12 }}>
                            Value <span style={{ color: 'red' }}>*</span>
                          </label>
                          <div style={{ display: 'flex', width: '100%' }}>
                            <input
                              placeholder="Value"
                              style={{
                                border: '1px solid #D1D1D1',
                                borderRadius: 5,
                                padding: 5,
                                marginTop: 5,
                                fontSize: 13,
                                width: '100%'
                              }}
                              type="text"
                              value={x.value}
                              onChange={(e) => manageAuthDetails(index, i, 'value', e.target.value)}
                            />
                            {provider.authDetails.length > 1 && (
                              <IconButton
                                style={{
                                  color: '#D13438',
                                  padding: 0,
                                  background: '#D1343833',
                                  height: 30,
                                  width: 30,
                                  borderRadius: 5,
                                  marginLeft: 10,
                                  boxShadow: '0px 0px 4px 0px #00000040',
                                  marginTop: 5
                                }}
                                aria-label="delete"
                                onClick={() => removeAuthDetail(index, i)}
                              >
                                <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png" />
                              </IconButton>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Paper>
      ))}

      <DialogActions style={{ padding: '16px' }}>
        <Button
          style={{
            fontSize: '12px',
            fontFamily: 'Poppins',
            textTransform: 'none'
          }}
          onClick={handleClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          style={{
            backgroundColor: '#007DFF',
            fontSize: '12px',
            fontFamily: 'Poppins',
            textTransform: 'none'
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </form>
  )
}

const ClientProviderModal = ({
  open,
  setOpen,
  onClose,
  oms,
  wms,
  tms,
  selectedClient,
  selectedData,
  onAddProviderData,
  setLoading,
  loading,
  onDeleteSuccess
}) => {
  const handleClose = () => {
    setOpen(false)
    onClose && onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <CustomTypography fontSize={18} style={{ paddingInline: 3, paddingTop: 2, fontWeight: '500' }}>
          {wms
            ? `Create Warehouse`
            : tms
            ? `Create Transport Management System`
            : `Create Order Management System`}
        </CustomTypography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <OrderManagementForm
          onDeleteSuccess={onDeleteSuccess}
          setLoading={setLoading}
          loading={loading}
          onAddProviderData={onAddProviderData}
          selectedData={selectedData}
          tms={tms}
          handleClose={handleClose}
          selectedClient={selectedClient}
          wms={wms}
        />
      </DialogContent>
      {/* <DialogActions sx={{ padding: 2 }}>
        <Button
          sx={{ fontSize: 12, fontFamily: 'Poppins', textTransform: 'none' }}
          onClick={handleClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          sx={{ backgroundColor: '#007DFF', fontSize: 12, fontFamily: 'Poppins', textTransform: 'none' }}
          onClick={handleClose}
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

export default ClientProviderModal
