import React from "react";
import PropTypes from "prop-types";
import { Typography as MuiTypography } from "@mui/material";
import { styled } from "@mui/system";

const StyledTypography = styled(MuiTypography)(
  ({ fontSize, color, fontWeight }) => ({
    fontFamily: "Poppins, sans-serif",
    fontSize: fontSize,
    color: color,
    fontWeight: fontWeight,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  })
);

const CustomTypography = ({
  fontSize,
  variant,
  color,
  children,
  fontWeight,
  style,
  align,
  ...props
}) => {
  return (
    <StyledTypography
      align={align}
      sx={style}
      variant={variant ? "h1" : "body1"}
      fontSize={fontSize}
      color={color}
      fontWeight={fontWeight}
      {...props}
    >
      {children}
    </StyledTypography>
  );
};

CustomTypography.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.bool,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  children: PropTypes.node.isRequired,
};

CustomTypography.defaultProps = {
  fontSize: "16px",
  title: false,
  color: "inherit",
};

export default CustomTypography;
