import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Box, Icon, Modal, Popover, Tooltip, useTheme } from "@mui/material"; // Assuming MUI Icon is used
import AppContext from "../context";
import { Data, getIcon } from "./sidebarData";
import { isMobile } from "../../globalStyles/mobile-design";

const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  text-decoration: none;
  color: ${(props) => props.color || "#000"};
  font-size: 14px;
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 7px;
  &:hover {
    cursor: pointer;
    color: green;
    .MuiSvgIcon-root {
      color: green !important; /* Ensure specificity */
    }
  }
`;
// background: ${(props) => "green"};
const DropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: #000;
  border-radius: 8px;
  margin-left: 30px;
  padding: 7px;
  &:hover {
    margin-left: 30px;
    background: #f6f6f6;
    cursor: pointer;
    color: black;
    border-radius: 8px;
    .MuiSvgIcon-root {
      color: black !important; /* Ensure specificity */
    }
  }
`;
const SubMenuWrapper = styled.div`
  position: relative;
  margin-left: 5px;

  display: ${(props) => (props.hidebar ? "none" : "block")};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 2px;
    height: ${(props) =>
      props.isLastItemForSetting
        ? "calc(100% - 42px)"
        : props.isLastItem
        ? "calc(100% - 25px)"
        : "100%"}; /* Adjust for the last item */
    background-color: #eaeaea;
    z-index: 0;
  }
`;

const CurvedLine = styled.div`
  position: absolute;
  top: 10px;
  left: 15px;
  width: 15px;
  height: 10px;
  border-left: 2px solid #eaeaea;
  border-bottom: 2px solid #eaeaea;
  border-radius: 0 0 0 10px;
  z-index: 1;

  /* Conditional rendering for curved line */
  display: ${(props) =>
    props.hidebar ? "none" : "block"}; /* Hide if hidebar is true */
`;

function getElementByPath(path) {
  const user = JSON.parse(localStorage.getItem("user"));
  const userRole = user?.role;
  // Helper function to recursively search subNav
  function searchSubNav(subNav) {
    for (const item of subNav) {
      if (item.path === path && item.roles.includes(userRole)) {
        return item;
      }
      if (item.subNav) {
        const found = searchSubNav(item.subNav);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  // Search the top-level elements
  for (const item of Data) {
    if (item.path === path && item.roles.includes(userRole)) {
      return { parentNav: item };
    }
    if (item.subNav) {
      const found = searchSubNav(item.subNav);
      if (found) {
        return { subNav: found };
      }
    }
  }

  // If no match is found, return null
  return null;
}

const SubMenu = ({ item, onClickSubMenu, onClickMenu, hideSideBar }) => {
  const {
    currentMenu,
    setCurrentMenu,
    currentSubMenu,
    setCurrentSubMenu,
    user,
    setUser,
    setIsSidebarOpen,
    isSidebarOpen,
  } = useContext(AppContext);
  const location = useLocation();
  const [subnav, setSubnav] = useState(currentSubMenu?.title ? true : false);
  const currentPath = location.pathname;
  const isSelected = (menuItem) =>
    !item.subNav && currentMenu?.title === menuItem?.title;
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const defaultColor = "#ff6a6e";
  const [mainTextColor, setMainTextColor] = useState(
    theme.palette.text.main || defaultColor
  );
  const [hoverColor, setHoverColor] = useState(
    theme.palette.primary.main || defaultColor
  );
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setMainTextColor(localStorage.getItem("text_color")?.text_color || "#FFF");
    setHoverColor(
      localStorage.getItem("primary_color")?.primary_color || defaultColor
    );
  }, [currentPath]);
  const isOpen = Boolean(anchorEl);
  useEffect(() => {
    if (currentPath.toString().includes("manage-product")) {
      setCurrentSubMenu(getElementByPath("/products").subNav);
    } else if (currentPath.toString().includes("order-import")) {
      setCurrentSubMenu(getElementByPath("/orders").subNav);
    } else if (currentPath.toString().includes("manage-order")) {
      setCurrentSubMenu(getElementByPath("/orders").subNav);
    } else {
      if (localStorage.getItem("path") !== currentPath) {
        localStorage.setItem("path", currentPath);
        let currentNav = getElementByPath(localStorage.getItem("path"));
        if (currentNav?.subNav) {
          setCurrentSubMenu(
            getElementByPath(localStorage.getItem("path")).subNav
          );
          setCurrentMenu({ title: "" });
          setSubnav(true);
        } else if (currentNav?.parentNav) {
          setCurrentMenu(
            getElementByPath(localStorage.getItem("path")).parentNav
          );
          setCurrentSubMenu({ title: "" });
          setSubnav(true);
        } else {
          setCurrentMenu(Data[0]);
          setSubnav(true);
        }
      } else {
        if (localStorage.getItem("path")) {
          let currentNav = getElementByPath(localStorage.getItem("path"));
          if (currentNav?.subNav) {
            setCurrentSubMenu(
              getElementByPath(localStorage.getItem("path")).subNav
            );
            setCurrentMenu({ title: "" });
            setSubnav(true);
          } else if (currentNav?.parentNav) {
            setCurrentMenu(
              getElementByPath(localStorage.getItem("path")).parentNav
            );
            setCurrentSubMenu({ title: "" });
            setSubnav(true);
          } else {
            setCurrentMenu(Data[0]);
            setSubnav(true);
          }
        }
      }
    }
  }, [currentPath]);
  useEffect(() => {
    if (currentMenu?.title === item?.title) {
      setSubnav(true);
    }
  }, [currentMenu, item?.title]);
  useEffect(() => {
    if (currentSubMenu?.title) {
      setCurrentMenu({ title: currentSubMenu.parent });
    }
  }, [currentSubMenu]);
  return item?.roles?.includes(user?.role) ? (
    <div style={{ marginInline: 10, paddingBottom: 5 }}>
      <motion.div
        onClick={() => {
          localStorage.setItem("path", item?.path ? item.path : "/");
          if (item.subNav) {
            setSubnav(!subnav);
          } else {
            onClickMenu();
            setCurrentSubMenu("");
            setCurrentMenu(item);
            setSubnav(false);
          }
        }}
      >
        <Tooltip title={hideSideBar ? item?.title : ""} placement="right">
          <SidebarLink
            hoverColor={hoverColor}
            onClick={(e) => {
              if (item.url) {
                window.open(item.url, "_blank");
              }
              if (!isSidebarOpen && item.subNav) {
                handlePopoverOpen(e);
              }
            }}
            isSelected={isSelected(item)}
            subNav={item.subNav}
            style={{
              paddingLeft: 5,
            }}
            to={item.path}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                style={{
                  marginBottom: 5,
                }}
              >
                {getIcon(
                  item.icon,
                  currentMenu?.title === item?.title ? hoverColor : "#000"
                )}
              </Icon>

              <div>
                <p
                  style={{
                    marginLeft: 10,
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: 500,
                    color:
                      isSelected(item) || currentMenu?.title === item?.title
                        ? hoverColor
                        : "",
                  }}
                >
                  {hideSideBar ? "" : item?.title}
                </p>
              </div>
            </div>
            <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </SidebarLink>
        </Tooltip>
      </motion.div>
      {subnav &&
        item.subNav?.map((subItem, index) => {
          const isSelectedSub =
            currentSubMenu?.title === subItem?.title &&
            currentSubMenu?.parent === item?.title;
          return subItem.roles?.includes(user?.role) &&
            subItem.parent === item?.title ? (
            <SubMenuWrapper
              isLastItemForSetting={subItem?.title === "Provider Event Mapping"}
              isLastItem={index + 1 === item.subNav?.length}
              key={index}
              hidebar={hideSideBar}
            >
              <CurvedLine hidebar={hideSideBar} />
              <motion.div key={index}>
                <Tooltip title={hideSideBar ? subItem?.title : ""}>
                  <DropdownLink
                    style={{
                      color: isSelectedSub ? "#000" : "#7C7C7C",
                      justifyContent: hideSideBar ? "center" : "start",
                      maxWidth: hideSideBar ? "50px" : "100%",
                      paddingLeft: hideSideBar ? 0 : "1rem",
                    }}
                    onClick={() => {
                      if (subItem.url) {
                        window.open(subItem.url, "_blank");
                      } else {
                        localStorage.setItem(
                          "path",
                          subItem?.path ? subItem?.path : "/"
                        );
                        setCurrentSubMenu(subItem);
                        onClickSubMenu && onClickSubMenu();
                        setCurrentMenu("");
                      }
                    }}
                    to={subItem.path}
                  >
                    {hideSideBar ? (
                      <Icon
                        style={{
                          marginBottom: 10,
                          height: 35,
                          color: isSelectedSub ? "#fff" : "",
                        }}
                      >
                        {getIcon(
                          subItem.icon,
                          isSelectedSub && !currentMenu?.title ? "#FFF" : "#000"
                        )}
                      </Icon>
                    ) : null}

                    <div
                      style={{
                        color: isSelectedSub ? "#000" : "",
                      }}
                    >
                      <p
                        style={{
                          marginLeft: 10,
                          fontFamily: "Poppins",
                          fontSize: 14,
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        {hideSideBar ? "" : subItem?.title}
                      </p>
                    </div>
                  </DropdownLink>
                </Tooltip>
              </motion.div>
            </SubMenuWrapper>
          ) : null;
        })}
      {!isMobile() ? (
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div style={{ padding: "10px", width: "100%", maxHeight: 300 }}>
            {item.subNav?.map((subItem, index) => {
              const isSelectedSub =
                currentSubMenu?.title === subItem?.title &&
                currentSubMenu?.parent === item?.title;
              return subItem.roles?.includes(user?.role) &&
                subItem.parent === item?.title ? (
                <motion.div key={index}>
                  <DropdownLink
                    style={{
                      color: isSelectedSub ? "#000" : "#7C7C7C",
                      background: isSelectedSub ? "#F6F6F6" : "",
                      justifyContent: "start",
                      width: "100%",
                      marginLeft: 0,
                    }}
                    onClick={() => {
                      if (subItem.url) {
                        window.open(subItem.url, "_blank");
                      } else {
                        localStorage.setItem(
                          "path",
                          subItem?.path ? subItem?.path : "/"
                        );
                        onClickSubMenu && onClickSubMenu();
                        setCurrentSubMenu(subItem);
                        setCurrentMenu("");
                        handlePopoverClose();
                      }
                    }}
                    to={subItem.path}
                  >
                    {subItem?.title}
                  </DropdownLink>
                </motion.div>
              ) : null;
            })}
          </div>
        </Popover>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

export default SubMenu;
