import React from 'react'
import { Card, CardContent, Typography, Grid, Box, IconButton } from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { isMobile } from '../../globalStyles/mobile'

const DataCard = ({
  backgroundColor,
  smallRoundBoxBg,
  minWidth,
  cardTitle,
  cardSubTitle,
  cardCount,
  showIconButton,
  cardContent
}) => (
  <Card
    sx={{
      width: isMobile() ? '95%' : 400,
      height: 250,
      padding: 1,
      backgroundColor: backgroundColor || '#FFE2E5',
      borderRadius: '16px',
      position: 'relative',
      margin: 1
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: -30,
        right: -10,
        width: '80px',
        height: '80px',
        backgroundColor: smallRoundBoxBg || '#FF95A0',
        borderRadius: '100%'
      }}
    />
    <CardContent>
      <div
        style={{
          width: '95%',
          justifyContent: 'flex-start'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography fontSize={25} sx={{ fontWeight: '600', color: '#151D48', textAlign: 'left' }}>
            {cardTitle}
          </Typography>
          <CustomTypography fontSize={25} sx={{ fontWeight: '600', color: '#151D48', textAlign: 'left' }}>
            {cardSubTitle}
          </CustomTypography>
          {showIconButton ? (
            <IconButton>
              <MoreVertIcon color="primary" />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        style={{
          marginTop: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CustomTypography fontSize={40} fontWeight={500} color={'#151D48'}>
          {cardCount}
        </CustomTypography>
      </div>
      {cardContent ? (
        <div
          style={{
            marginTop: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CustomTypography fontSize={20} fontWeight={500} color={'#151D48'}>
            {cardContent}
          </CustomTypography>
        </div>
      ) : (
        <></>
      )}
    </CardContent>
  </Card>
)

export default DataCard
