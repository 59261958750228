export const setEditObjectForOrders = (data, status) => {
  if (status == 'Accepted') {
    return { shipment_status: [...data.shipment_status, status] }
  } else if (status == 'Shipment created') {
    return {
      shipment_status: [...data.shipment_status, ...status],
      shipment_id: data?.shipment_id,
      label_id: data?.label_id
    }
  }
}
