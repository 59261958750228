// api.js

import axiosInstance from "../axiosInstance";

export const portService = {
  create: async (portData) => {
    try {
      let response = await axiosInstance.post("/port", {
        ...portData,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  fetch: async (limit, skip, clientId, searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/port?$limit=${limit || 15}&$skip=${skip || 0}&${
          clientId ? `client_id=${clientId}` : ""
        }&${searchValue ? `&name[$search]=${searchValue}` : ""}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (portData, id) => {
    try {
      let response = await axiosInstance.patch(`/port/${id}`, {
        ...portData,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (id) => {
    try {
      let response = await axiosInstance.delete(`/port/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
