import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import 'react-toastify/dist/ReactToastify.css'

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import 'react-quill/dist/quill.snow.css'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CustomerViewModal from '../customer-details-modal'
import AddEditCustomer from '../add-edit-customer'
import DeleteConfirmationModal from '../delete-confirmation-modal'
import { customerService } from '../../services/customer-service'
import { toast } from 'react-toastify'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #EAECF0',
    color: theme.palette.common.white,
    padding: '6px 6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '6px 6px'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F6F6F6',
    border: '1px solid #EAECF0'
  },

  '&:lastChild td, &:lastChild th': {
    border: 0
  }
}))
const CustomerTableCell = React.memo(({ customer, handleViewClick, handleEditClick, handleDeleteClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const handleActionClick = (action) => {
    handlePopoverClose() // Close the popover before executing the action
    switch (action) {
      case 'view':
        handleViewClick(customer) // Pass `customer` directly
        break
      case 'edit':
        handleEditClick(customer)
        break
      case 'delete':
        handleDeleteClick(customer)
        break
      default:
        break
    }
  }

  return (
    <Box>
      <IconButton
        aria-label="more actions"
        onClick={handlePopoverOpen}
        sx={{
          color: open ? 'primary.main' : 'inherit'
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        disableRestoreFocus={true}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
          <Button
            onClick={() => handleActionClick('view')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <VisibilityIcon />
              <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                View
              </Typography>
            </Box>
          </Button>

          <Button
            onClick={() => handleActionClick('edit')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <EditIcon style={{ color: 'orange' }} />
              <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                Edit
              </Typography>
            </Box>
          </Button>

          <Button
            onClick={() => handleActionClick('delete')}
            sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <DeleteIcon style={{ color: 'red' }} />
              <Typography fontFamily="Poppins" fontSize="16px" color="#333333" fontWeight={500}>
                Delete
              </Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  )
})
const CustomerList = ({
  data,
  rowsPerPage,
  page,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
  onSuccess
}) => {
  const [customers, setCustomers] = useState([...data])
  const [openViewModal, setOpenViewModal] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [customerData, setCustomerData] = useState({
    name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      zip: '',
      province: '',
      country: ''
    }
  })
  const NoDataImage = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: 'cover', height: '300px' }}
      />
    </div>
  )
  const handleViewClick = (customer) => {
    setSelectedCustomer(customer)
    setOpenViewModal(true)
  }
  const handleEditClick = (customer) => {
    setCustomerData(customer)
    setSelectedCustomer(customer)
    setOpenEditModal(true)
    console.log(customer)
  }

  const handleDeleteClick = (customer) => {
    setOpenConfirmation(true)
    setSelectedCustomer(customer)
  }
  const confirmDelete = async (e) => {
    e.preventDefault()
    try {
      const response = await customerService.delete(selectedCustomer._id)
      setSelectedCustomer(null)
      onSuccess && onSuccess()
      toast.success('Customer Deleted successfully!')
      setOpenConfirmation(false)
    } catch (error) {
      console.error('Delete failed:', error)
    }
  }
  const handleEditCustomer = () => {
    try {
      const response = customerService.edit(
        {
          ...customerData,
          name: `${customerData.first_name} ${customerData.last_name}`
        },
        selectedCustomer._id
      )
      setOpenEditModal(false)
      setSelectedCustomer(null)
      onSuccess && onSuccess()
      console.log(response)
      toast.success('Customer Updated successfully!')
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    setCustomers(
      data.map((customer) => ({
        ...customer,
        action: (
          <CustomerTableCell
            customer={customer}
            handleViewClick={handleViewClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        )
      }))
    )
  }, [data])
  return (
    <div
      style={{
        overflowY: 'scroll',
        display: 'flex',
        maxHeight: `calc(100vh - 170px)`,
        width: '100%',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::WebkitScrollbar': { width: 0 }
      }}
    >
      <TableContainer
        sx={{
          mt: 3,
          overflowY: 'auto',
          paddingInline: 0.4,
          maxHeight: '100%'
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            border: '1px solid #EAECF0'
          }}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  Name
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  Email
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  Phone
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  Address1
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  Address2
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  City
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  Zip
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  Country
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontSize="14px" color="#333333" fontWeight={500} fontFamily={'poppins'}>
                  Action
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {customers.length === 0 ? (
              <StyledTableRow>
                <TableCell colSpan={9} align="center">
                  <NoDataImage />
                </TableCell>
              </StyledTableRow>
            ) : (
              customers?.map((customer, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {customer.name}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {customer.email}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {customer.phone}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {customer?.address?.address1}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {customer.address?.address2}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {customer.address?.city}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {customer.address?.zip}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      style={{
                        fontSize: '12px',
                        color: '#667085',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'poppins'
                      }}
                    >
                      {customer.address.country}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell sx={{ width: '3%', textAlign: 'center' }}>
                    {customer?.action}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffffff',
          padding: '2px',
          borderTop: '1px solid #dddddd'
        }}
        rowsPerPageOptions={[10, 15, 25, 45, 75, 100]}
        component="div"
        count={count || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <CustomerViewModal
        handleCloseModal={() => {
          setOpenViewModal(false)
          setSelectedCustomer(null)
        }}
        openModal={openViewModal}
        customer={selectedCustomer}
      ></CustomerViewModal>
      <AddEditCustomer
        handleSubmit={handleEditCustomer}
        isEdit={true}
        handleClose={() => {
          setOpenEditModal(false)
          setSelectedCustomer(null)
        }}
        open={openEditModal}
        customerData={customerData}
        setCustomerData={setCustomerData}
      ></AddEditCustomer>
      <DeleteConfirmationModal
        title={`Are you sure you want to delete  ${selectedCustomer?.first_name} ?`}
        open={openConfirmation}
        handleClose={() => {
          setOpenConfirmation(false)
          setSelectedCustomer(null)
        }}
        handleDelete={(e) => {
          confirmDelete(e)
        }}
      />
    </div>
  )
}

export default CustomerList
