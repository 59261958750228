export const getRequestDetailsForASN = (data) => {
  if (data?.Request?.RequestID) {
    return data.Request.PreStockIns.PreStockIn.Detail;
  } else {
    return data;
  }
};

export const getRequestDetailsForOrder = (data) => {
  console.log(data);

  if (data.provider.provider === "evcargo") {
    return {
      orderNo: data.orderNo,
      estimatePickupDate:
        data.request.Request.PreStockOuts.PreStockOut.Header.EstPickUpDate,
      pickupBy: data.request.Request.PreStockOuts.PreStockOut.Header.PickUpBy,
      warehouse: data.provider.provider,
      soldTo: `${data.request.Request.PreStockOuts.PreStockOut.SoldToParty.Name}, ${data.request.Request.PreStockOuts.PreStockOut.SoldToParty.CityName}, ${data.request.Request.PreStockOuts.PreStockOut.SoldToParty.State}`,
      shipTo: `${data.request.Request.PreStockOuts.PreStockOut.ShipToParty.Name}, ${data.request.Request.PreStockOuts.PreStockOut.ShipToParty.CityName}, ${data.request.Request.PreStockOuts.PreStockOut.ShipToParty.State}`,
      carrier: data.request.Request.PreStockOuts.PreStockOut.Header.Carrier,
    };
  } else if (data.provider.provider === "rl") {
    return {
      orderNo: data.orderNo,
      estimatePickupDate: data.request.Header.EstPickUpDate,
      pickupBy: data.request.Header.PickUpBy,
      soldTo: `${data.request.Header.SoldToParty.Name}, ${data.request.Header.SoldToParty.CityName}, ${data.request.Header.SoldToParty.State}`,
      shipTo: `${data.request.Header.ShipToParty.Name}, ${data.request.Header.ShipToParty.CityName}, ${data.request.Header.ShipToParty.State}`,
      carrier: data.request.Header.Carrier,
      warehouse: data.provider.provider,
    };
  }
};
