import React from "react";
import { TableContainer, Paper, IconButton, Box, Typography } from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { isMobile } from "../../globalStyles/mobile";
const WarehouseList = ({ data, onEditData }) => {
  const MobileCardView=({data})=>{
    return (
      <TableContainer sx={{ mt: 1 }} component={Paper}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            background: "#C3E1FF",
            justifyContent: "space-between",
            padding: 1,
            alignItems: "center",
          }}
        >
          <CustomTypography
            fontSize={14}
            sx={{ fontWeight: "600", textAlign: "center", flexGrow: 1 }}
          >
            {`WAREHOUSE LIST`}
          </CustomTypography>
          {data?.length ? (
            <IconButton
              style={{
                color: "#FEC50F",
                padding: 0,
                background: "#FEC50F33",
                height: 30,
                width: 30,
                borderRadius: 5,
                marginLeft: 15,
                boxShadow: "0px 0px 4px 0px #00000040",
                marginTop: 5,
              }}
              aria-label="edit"
              onClick={() => {
                onEditData && onEditData(data);
              }}
            >
              <BorderColorIcon sx={{ height: 20, width: 20 }} />
            </IconButton>
          ) : null}
        </Box>

        {data?.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              borderBottom: "0.5px solid #737791",
              padding: 1,
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                border: "0.5px solid #737791",
                background: "#EEEEEEEE",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              <Typography>{`Warehouse ${index + 1}`}</Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                border: "0.5px solid #737791",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              {item?.provider}
            </Box>

            <Box
              sx={{
                width: "50%",
                display: "flex",
                border: "0.5px solid #737791",
                background: "#EEEEEEEE",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              Priority
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                border: "0.5px solid #737791",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              {item?.priority}
            </Box>

            <Box
              sx={{
                width: "50%",
                display: "flex",
                border: "0.5px solid #737791",
                background: "#EEEEEEEE",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              Consignor
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                border: "0.5px solid #737791",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              {item?.consignor_name}
            </Box>
          </Box>
        ))}
      </TableContainer>
    );
  }
  return( 
    isMobile() ? (
    <MobileCardView data={data} />
  ) : (
    <TableContainer sx={{ mt: 1 }} component={Paper}>
      <div
        style={{
          display: "flex",
          width: "100%",
          background: "#C3E1FF",
          justifyContent: "space-between",
          padding: 7,
          alignItems: "center",
        }}
      >
        <CustomTypography
          fontSize={14}
          style={{ fontWeight: "600", marginLeft: "40%" }}
        >
          {`WAREHOUSE LIST`}
        </CustomTypography>
        {data?.length ? (
          <IconButton
            style={{
              color: "#FEC50F",
              padding: 0,
              background: "#FEC50F33",
              height: 30,
              width: 30,
              borderRadius: 5,
              marginLeft: 15,
              boxShadow: "0px 0px 4px 0px #00000040",
              marginTop: 5,
            }}
            aria-label="edit"
            onClick={() => {
              onEditData && onEditData(data);
            }}
          >
            <BorderColorIcon sx={{ height: 20, width: 20 }} />
          </IconButton>
        ) : (
          <></>
        )}
      </div>
      {data?.map((item, index) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", minHeight: 30, width: "100%" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                border: "0.5px solid #737791",
                background: "#EEEEEEEE",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
                borderBottom: 0,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              <p>{`Warehouse ${index + 1}`}</p>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "0.5px solid #737791",
                borderBottom: 0,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              {item?.provider}
            </div>
          </div>

          <div style={{ display: "flex", minHeight: 30, width: "100%" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                border: "0.5px solid #737791",
                background: "#EEEEEEEE",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
                borderBottom: 0,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              Priority
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "0.5px solid #737791",
                borderBottom: 0,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              {item?.priority}
            </div>
          </div>

          <div style={{ display: "flex", minHeight: 30, width: "100%" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                border: "0.5px solid #737791",
                background: "#EEEEEEEE",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
                borderBottom: 0,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              Consignor
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "0.5px solid #737791",
                borderBottom: 0,
                fontSize: 13,
                fontFamily: "Poppins",
              }}
            >
              {item?.consignor_name}
            </div>
          </div>
        </div>
      ))}

      {/* <Table>
        <TableHead>
          <TableRow>
            <TableCell>Warehouse</TableCell>
            <TableCell>Priority</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {warehouses.map((wms, index) => (
            <TableRow key={index}>
              <TableCell>{wms.name}</TableCell>
              <TableCell>{wms.priority}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
    </TableContainer>
  ))
};

export default WarehouseList;
