import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  DialogActions,
} from "@mui/material";

const AirWaybillForm = ({ open, handleClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Typography align="center" variant="h5" fontWeight="bold">
          Air Waybill
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <Box padding={2}>
          {/* Accounting Information and Air Waybill Issued By */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Accounting Information"
                multiline
                rows={2}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Air Waybill Issued By"
                variant="outlined"
                multiline
                rows={2}
              />
            </Grid>
          </Grid>

          {/* Shipper and Consignee Section */}
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shipper's Name and Address"
                multiline
                rows={3}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Consignee's Name and Address"
                multiline
                rows={3}
                variant="outlined"
              />
            </Grid>
          </Grid>

          {/* Account Numbers */}
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Shipper's Account Number"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Consignee's Account Number"
                variant="outlined"
              />
            </Grid>
          </Grid>

          {/* Issuing Carrier's Details */}
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Issuing Carrier's Agent Name and City"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Agent's IATA Code"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Account No." variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Airport of Departure and Requested Routing"
                variant="outlined"
              />
            </Grid>
          </Grid>

          {/* Other Details */}
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Reference Number"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Airport of Destination"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Requested Flight/Date"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Currency" variant="outlined" />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Declared Value for Carriage"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Declared Value for Customs"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Amount of Insurance"
                variant="outlined"
              />
            </Grid>
          </Grid>

          {/* Handling Information */}
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Handling Information"
                multiline
                rows={2}
                variant="outlined"
              />
            </Grid>
          </Grid>

          {/* Package Details */}
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="No. of Pkgs RCP" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Gross Weight" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Rate Class" variant="outlined" />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Chargeable Weight"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Rate/Charge" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Total" variant="outlined" />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nature and Quantity of Goods (incl. Dimensions or Volume)"
                multiline
                rows={3}
                variant="outlined"
              />
            </Grid>
          </Grid>

          {/* Charges */}
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Prepaid, Weight Charge, Collect"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Other Charges" variant="outlined" />
            </Grid>
          </Grid>

          {/* Signatures */}
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Signature of Shipper or his Agent"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Signature of Issuing Carrier or its Agent"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            color: "#007DFF",
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            handleClose();
            
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
         
          color="primary"
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: "none",
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AirWaybillForm;
