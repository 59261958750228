import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import { Box, CircularProgress } from "@mui/material";
import { customProviderService } from "../../utils/services/customProviderService";
import CustomProviderModal from "../../components/customProviderModal";
import CustomProviderList from "../../components/customProviderList";

const CustomProvider = () => {
  const [selectedCustomProvider, setSelectedCustomProvider] = useState(null);
  const [customProviderList, setCustomProviderCList] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const fetchCustomProvider = async () => {
    setLoading(true);
    try {
      const response = await customProviderService.fetch(10, 0, searchValue);
      setCustomProviderCList(response.data);
      setLoading(false);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCustomProvider();
  }, [page, rowsPerPage, searchValue]);

  return (
    <DashboardContainer
      hideUploadButton={true}
      hideFilterClient={true}
      header={"Custom Provider List"}
      title={"+ Add Custom Provider"}
      setViewMode={setViewMode}
      onSearchData={(x) => {
        if (x.trim()) {
          setSearchValue(x);
          setPage(0);
        } else {
          setSearchValue("");
        }
      }}
      onClickAddButton={() => setOpenModal(true)}
    >
      <CustomProviderModal
        selectedCustomProvider={selectedCustomProvider}
        handleClose={() => {
          setOpenModal(false);
          setSelectedCustomProvider(null);
        }}
        open={openModal}
        onAddData={fetchCustomProvider}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <CustomProviderList
            dataCount={dataCount}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            onDeleteData={fetchCustomProvider}
            onEditData={(e) => {
              setSelectedCustomProvider(e);
              setOpenModal(true);
            }}
            data={customProviderList}
            viewMode={viewMode}
            selectedCustomProvider={selectedCustomProvider}
            setSelectedCustomProvider={setSelectedCustomProvider}
          />
        </>
      )}
    </DashboardContainer>
  );
};

export default CustomProvider;
