import React, { useState } from 'react'
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { omsLocationService } from '../../utils/services/omsLocationService'
const WarehouseSelectionModal = ({ open, onClose, data, omsData }) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(null)

  const handleSelect = (warehouse) => {
    setSelectedWarehouse(warehouse?.id === selectedWarehouse?.id ? null : warehouse)
  }
  const addOmsLocation = async () => {
    console.log(omsData, selectedWarehouse)
    try {
      const response = await omsLocationService.create({
        client_id: omsData.client_id,
        oms_config: omsData._id,
        location: selectedWarehouse
      })
      return response
    } catch (error) {
      console.error('Fetch failed:', error)
    }
  }
  const handleConfirm = () => {
    addOmsLocation()
    onClose()
    setSelectedWarehouse(null)
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose && onClose()
        setSelectedWarehouse(null)
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '40%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">Select Warehouse</Typography>
          <IconButton
            onClick={() => {
              onClose && onClose()
              setSelectedWarehouse(null)
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {data?.map((warehouse) => (
            <ListItem key={warehouse.id} button onClick={() => handleSelect(warehouse.id)}>
              <ListItemText
                primary={warehouse.name}
                secondary={`${warehouse.address1 || ''} ${warehouse.city || ''}, ${warehouse.country}`}
              />
              <Checkbox
                edge="end"
                checked={selectedWarehouse?.id === warehouse.id}
                onChange={() => handleSelect(warehouse)}
                color="primary"
              />
            </ListItem>
          ))}
        </List>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default WarehouseSelectionModal
