import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { productService } from "../../utils/services/productService"; 
import debounce from "lodash.debounce";

const ProductAutocomplete = ({
  selectedProduct,
  onProductChange,
  selectedClient, // New prop to filter products based on client
  width = 250,
  label = "Select Product",
  errorMessage,
  hideLabel,
}) => {
  const [allProducts, setAllProducts] = useState([]);
  const [productSearchValue, setProductSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  const fetchProductData = useCallback(
    debounce(async (clientID, searchQuery, pageNumber, isLoadMore = false) => {
      if (isLoadMore) {
        setLoadMoreLoading(true);
      } else {
        setLoading(true);
      }

      try {
        const response = await productService.fetch(
          10,
          pageNumber * 10,
          clientID,
          searchQuery
        );
        if (pageNumber === 0) {
          setAllProducts(response.data);
        } else {
          setAllProducts((prevProducts) => [...prevProducts, ...response.data]);
        }
        setTotal(response.total);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        if (isLoadMore) {
          setLoadMoreLoading(false);
        } else {
          setLoading(false);
        }
      }
    }, 300),
    []
  );

  useEffect(() => {
    // Fetch products when the selected client, search value, or page changes
    fetchProductData(
      selectedClient ? selectedClient._id : "",
      productSearchValue,
      page
    );
  }, [productSearchValue, selectedClient, page, fetchProductData]);

  useEffect(() => {
    // Reset product list when search value is empty
    if (productSearchValue === "") {
      fetchProductData(selectedClient ? selectedClient._id : "", "", 0);
    }
  }, [productSearchValue, selectedClient, fetchProductData]);

  const handleProductChange = (event, value) => {
    if (value && value.name === "Load More") {
      return;
    }
    if (onProductChange) {
      onProductChange(value);
    }
    setProductSearchValue("");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setProductSearchValue(value);
    setPage(0);
    setAllProducts([]);
  };

  const handleLoadMoreClick = () => {
    if (allProducts.length < total && !loading && !loadMoreLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Add "Load More" option if more data is available
  const optionsWithLoadMore = [...allProducts];
  if (allProducts.length < total && allProducts.length % 10 === 0) {
    optionsWithLoadMore.push({ name: "Load More" });
  }

  return (
    <Autocomplete
      size="small"
      id="product-select"
      options={optionsWithLoadMore}
      getOptionLabel={(option) => option.name || ""}
      value={selectedProduct}
      onChange={handleProductChange}
      renderOption={(props, option) =>
        option.name === "Load More" ? (
          <li
            {...props}
            onClick={handleLoadMoreClick}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <Typography
              color="primary"
              variant="body2"
              style={{ marginRight: 8 }}
            >
              Load More...
            </Typography>
            {loadMoreLoading && <CircularProgress color="inherit" size={16} />}
          </li>
        ) : (
          <li {...props}>{option.name}</li>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          label={hideLabel ? "" : label}
          placeholder="Select Product"
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      )}
      style={{ width }}
    />
  );
};

export default ProductAutocomplete;
