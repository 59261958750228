import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import CustomTypography from '../../globalStyles/fonts'

const DeleteConfirmationModal = ({
  open,
  handleClose,
  handleDelete,
  title = 'Delete Title',
  deleteButtonTitle
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
            <img src="deleteIcon.png" width={60} height={60} style={{ marginTop: 10 }} />
            <div style={{ marginTop: 20, maxWidth: '400px' }}>
              <Typography fontSize={16} fontWeight={600}>
                {title}
              </Typography>
              {/* <span>
                <CustomTypography color={"#475467"}>
                  Are you sure you want to delete this ....?
                </CustomTypography>
              </span> */}
            </div>
          </div>
          <IconButton>
            <CloseIcon onClick={handleClose} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent></DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          style={{
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderColor: '#D0D5DD',
            color: '#344054',
            borderRadius: 8,
            textTransform: 'none'
          }}
        >
          <CustomTypography fontSize={14} fontWeight={600}>
            Cancel
          </CustomTypography>
        </Button>
        <Button onClick={handleDelete} variant="contained" color="error" style={{ textTransform: 'none' }}>
          <CustomTypography fontSize={14} fontWeight={600}>
            {deleteButtonTitle ? deleteButtonTitle : 'Delete'}
          </CustomTypography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmationModal
