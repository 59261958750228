import React, { useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { orderService } from "../../services/order-service";
import OrderList from "../../components/order-list";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState();
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchOrders = async () => {
    try {
      const response = await orderService.fetch(
        rowsPerPage,
        rowsPerPage * page
      );
      setOrders(response.data);
      setCount(response.total);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [rowsPerPage, page]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{ textTransform: "none", backgroundColor: "#DC585C" }}
          onClick={() => navigate("/order-import")}
        >
          <Typography
            fontFamily={"poppins"}
            fontSize={"14px"}
            fontWeight={500}
            color="#fff"
          >
            Add/Upload
          </Typography>
        </Button>
      </div>
      <OrderList
        data={orders}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Orders;
