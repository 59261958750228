import React from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Select,
  MenuItem,
} from "@mui/material";
import { isMobile } from "../../globalStyles/mobile-design";

const TemplateCard = ({
  title,
  description,
  templates = [],
  onTemplateChange,
  onNext,
  image,
  defaultTemplate = "",
  id,
  displayKey,
  selectedTemplate,
}) => {
  return (
    <>
      <Card
        sx={{
          border: "1px solid #D9D9D9",
          backgroundColor: "#ffff",
          padding: 1,
          borderRadius: "8px",
          minHeight: 200,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: isMobile() ? 0 : 15,
            }}
          >
            {image && isMobile() && (
              <Box
                sx={{
                  width: { xs: "70px", sm: "120px", md: "230px" },
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={image}
                  alt="Template Preview"
                  sx={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    borderRadius: "8px",
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: 500,
                  fontSize: { sm: "18px", md: "24px", xs: "15px" },
                  color: "#333333",
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: 400,
                  fontSize: { sm: "14px", md: "16px", xs: "12px" },
                  color: "#333333",
                }}
              >
                {description}
              </Typography>
              <Select
                size="small"
                fullWidth
                defaultValue={defaultTemplate}
                sx={{ marginTop: 2, fontSize: "14px" }}
                onChange={(e) => onTemplateChange(e.target.value)}
              >
                {templates.map((template, index) => (
                  <MenuItem
                    key={index}
                    value={template[id]}
                    selected={selectedTemplate}
                  >
                    {template[displayKey]}
                  </MenuItem>
                ))}
              </Select>
              <Box sx={{ display: "flex", gap: 2, mt: isMobile() ? 1 : 3 }}>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#DC585C",
                  }}
                  onClick={onNext}
                >
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: 500,
                      fontSize: { sm: "12px", md: "14px", xs: "12px" },
                      color: "#ffff",
                    }}
                  >
                    Next
                  </Typography>
                </Button>
              </Box>
            </Box>
            {image && !isMobile() && (
              <Box
                sx={{
                  width: { xs: "70px", sm: "120px", md: "230px" },
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={image}
                  alt="Template Preview"
                  sx={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    borderRadius: "8px",
                  }}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default TemplateCard;
