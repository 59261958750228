import React from "react";
import { Stepper, Step, StepLabel, StepConnector } from "@mui/material";
import { styled } from "@mui/system";

// Custom StepConnector to control line colors
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor: "gray", // Default color of the line
  },
  "&.Mui-completed .MuiStepConnector-line": {
    borderColor: "#A2F07B", // Green for completed steps
  },
  "&.Mui-active .MuiStepConnector-line": {
    borderColor: "#A2F07B", // Green for the active step
  },
}));

const StepperComponent = ({ activeStep, steps, handleStepClick }) => {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<CustomConnector />}
      sx={{ marginBottom: 4, width: 700 }}
    >
      {steps.map((label, index) => (
        <Step
          key={index}
          onClick={() => handleStepClick(index)}
          sx={{ cursor: "pointer" }}
        >
          <StepLabel
            sx={{
              "& .MuiStepIcon-root": {
                color: index < activeStep ? "#A2F07B" : "gray", // Green for completed steps, gray otherwise
              },
              "& .MuiStepIcon-root.Mui-active": {
                color: "#FFD174", // Yellow for the active step
              },
              "& .MuiStepIcon-text": {
                fill: "white", // Text inside the number icon remains visible
              },
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepperComponent;
