// api.js
import axiosInstance from "../axiosInstance";


export const transactionService = {
  
  fetch: async (limit, skip, clientId,searchValue) => {
    try {
      let response = await axiosInstance.get(
        `/shipment-transaction?$populate=client_id&$limit=${
          limit || 15
        }&$skip=${skip || 0}&${clientId ? `client_id=${clientId}` : ""}&${
          searchValue ? `order_number=${searchValue}` : ""
        }`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  
 
};