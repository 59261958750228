import React, { useState, useEffect, useRef } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS file
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { isMobile } from "../../globalStyles/mobile";
import DateRangeIcon from "@mui/icons-material/DateRange";

const DateRangePicker = ({ selectionRange, setSelectionRange }) => {
  const [showPicker, setShowPicker] = useState(false);
  const dateRangePickerRef = useRef(null);
 const isMobile = useMediaQuery("(max-width:900px)");

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dateRangePickerRef.current &&
        !dateRangePickerRef.current.contains(event.target)
      ) {
        setShowPicker(false);
      }
    };

    if (!isMobile) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      if (!isMobile) {
        document.removeEventListener("mousedown", handleOutsideClick);
      }
    };
  }, [isMobile]);

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div
      style={{ alignSelf: "end", position: "relative" }}
      className="date-range-picker"
      ref={dateRangePickerRef}
    >
      {isMobile ? (
        <IconButton onClick={() => setShowPicker(true)}>
          <DateRangeIcon />
        </IconButton>
      ) : (
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: 12,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="date-range-text"
          onClick={togglePicker}
        >
          {`${formatDate(selectionRange.startDate)} - ${formatDate(
            selectionRange.endDate
          )}`}
        </div>
      )}

      {showPicker && isMobile ? (
        <Dialog open={showPicker} onClose={() => setShowPicker(false)}>
          <DialogContent>
            <DateRange
              ranges={[selectionRange]}
              onChange={handleSelect}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPicker(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        showPicker && (
          <div className="date-range-modal">
            <div className="modal-content">
              <DateRange
                ranges={[selectionRange]}
                onChange={handleSelect}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default DateRangePicker;
