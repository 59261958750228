import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { providerService } from "../../utils/services/providerService";

const ProviderModal = ({ open, handleClose, onAddData, selectedProvider }) => {
  const [providerName, setProviderName] = useState("");
  const [providerType, setProviderType] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!providerName) {
      errors.name = "Name is required";
    }
    if (!providerType) {
      errors.type = "Type is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (selectedProvider) {
      setProviderName(selectedProvider.name || "");
      setProviderType(selectedProvider.type || "");
    } else {
      resetData();
    }
  }, [selectedProvider]);

  const resetData = () => {
    setProviderName("");
    setProviderType("");
    setFormErrors({});
  };

  const handleNameChange = (e) => {
    setProviderName(e.target.value);
  };

  const handleTypeChange = (e) => {
    setProviderType(e.target.value);
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await providerService.edit(selectedProvider._id, {
        name: providerName,
        type: providerType,
      });
      onAddData && onAddData();
      toast.success("Provider edited successfully!");
      resetData();
    } catch (error) {
      toast.error("Failed to edit event.");
      console.error(error);
    }
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await providerService.create({
        name: providerName,
        type: providerType,
      });
      onAddData && onAddData();
      toast.success("Provider created successfully!");
      resetData();
      handleClose();
    } catch (error) {
      toast.error("Failed to create event.");
      console.error(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        resetData();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle id="form-dialog-title" style={{ fontSize: 24 }}>
          {selectedProvider ? "Edit" : "Create"} Provider
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography>
              Name <span style={{ color: "red" }}>*</span>
            </CustomTypography>
            <TextField
              size="small"
              name="name"
              value={providerName}
              onChange={handleNameChange}
              fullWidth
              placeholder="Enter Name"
              margin="dense"
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <CustomTypography>
                Type <span style={{ color: "red" }}>*</span>
              </CustomTypography>
              <Select
                size="small"
                margin="dense"
                value={providerType}
                onChange={handleTypeChange}
                displayEmpty
                error={!!formErrors.type}
              >
                <MenuItem value="WMS">WMS</MenuItem>
                <MenuItem value="OMS">OMS</MenuItem>
                <MenuItem value="TMS">TMS</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          onClick={() => {
            handleClose();
            resetData();
          }}
          color="primary"
          variant="outlined"
          className="cancel-button-style"
          style={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedProvider ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          className="add-button-style"
          style={{ textTransform: "none" }}
        >
          {selectedProvider ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProviderModal;
